

function generateYearList(startAtYear){
    // Generate list of last of years since 'startAtYear'.
    
    const currentYear = new Date().getFullYear()
    const yearList = [{text: 'Todo', value: null}]

    for (let i = currentYear; i >= startAtYear; i--){
        let year = (i).toString()
        yearList.push({text: year, value: year})
    }

    return yearList
}

function buildParamStr(paramsObj){
    /*
    Built url parameters string from an object (key-value pair mapping).
    */
    let keys = Object.getOwnPropertyNames(paramsObj)
    if (keys.length < 1) return '';
    let paramStr = '?',
    // Filter out items containing null or undefined values. A parameter
    // with value of 0 is considered valid and not filtered out.
    filtered = keys.filter(key => paramsObj[key] ? true: paramsObj[key] === 0)
    let joinedParamStr = filtered.map(
        key => key + '=' + paramsObj[key]
    ).join('&')

    if (joinedParamStr) return paramStr + joinedParamStr;

    return '';
}


export {generateYearList, buildParamStr}