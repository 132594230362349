import React from 'react';
import {formatDate} from '../utils';

function JobItem (props) {

    function extractTimestamp(timestamps){
        try {
            let ts = Object.keys(timestamps);
            if (ts.length > 0)
            return formatDate(new Date(timestamps[ts[0]][0]));
        } catch{
            return null;
        }

        return null;
    }

    return (
        <tr name='alert_row' onClick={(e) => props.view('edit', props.id)} style={{cursor:'pointer'}}>
            <td className="mdl-data-table__cell--non-numeric">{props.region_code}</td>
            <td className="mdl-data-table__cell--non-numeric">{props.description}</td>
            <td className="mdl-data-table__cell--non-numeric">{extractTimestamp(props.timestamps)}</td>
            <td className="mdl-data-table__cell--non-numeric">{props.enabled ? <span style={{color:'lightgreen'}} role="img">✓</span> : '❌'}</td>
        </tr>
    );
}

function JobTable (props) {
    
    return <div className='mdl-cell mdl-cell--8-col'>
        <form className='k-form'>
            <fieldset>
                <legend>{props.intl.formatMessage({id:'app.dga.jobTable.uploadJob', defaultMessage:'Upload Jobs'})}</legend>
                <table className="mdl-data-table mdl-js-data-table" style={{width: '100%', marginTop:'10px'}}>
                    <thead>
                        <tr >
                            <th className="mdl-data-table__cell--non-numeric" style={{textAlign:"left"}}>
                                {props.intl.formatMessage({id:'app.dga.jobTable.region', defaultMessage:'Region'})}
                            </th>
                            <th style={{textAlign:"left"}}>{props.intl.formatMessage({id:'app.dga.jobTable.desc', defaultMessage:'Desc'})}</th>
                            <th style={{textAlign:"left"}}>{props.intl.formatMessage({id:'app.dga.jobTable.lastUpload', defaultMessage:'Last Upload'})}</th>
                            <th style={{textAlign:"left"}}>{props.intl.formatMessage({id:'app.dga.jobTable.enabled', defaultMessage:'Enabled'})}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.children}
                    </tbody>
                </table>
            </fieldset>
        </form>
    </div>;
}

export default JobTable;
export {JobItem};