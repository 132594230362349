import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from '../utils';
import loading from '../loading.svg';
import EventItem from './EventItem';
import FilterOptions from './FilterOptions';
import { StationContext } from './TabLayout';
import { TimezoneContext } from './TabLayout';

export default function IrrigationEvents(props) {
    const intl = useIntl();
    const [logs, setLogs] = useState([]);
    const { timezone, setTimezone, timezones } = useContext(TimezoneContext);

    const irrigationStatus = [
        // translate the type part in any language
        { status: intl.formatMessage({ id: "app.scheduler.status_saved", defaultMessage: 'Saved' }), key: 'Saved' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending", defaultMessage: 'Sending' }), key: 'Sending' },
        { status: intl.formatMessage({ id: "app.scheduler.status_removing", defaultMessage: 'Removing' }), key: 'Removing' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending_failed", defaultMessage: 'Sending irrigation has failed' }), key: 'Sending irrigation has failed' },
        { status: intl.formatMessage({ id: "app.scheduler.status_running", defaultMessage: 'Running' }), key: 'Running' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_ok", defaultMessage: 'Executed OK' }), key: 'Executed OK' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_failure", defaultMessage: 'Executed with failure' }), key: 'Executed with failure' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_by_user", defaultMessage: 'Stopped by User' }), key: 'Stopped by User' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_manual_irrigation", defaultMessage: 'Stopped due to Manual Irrigation' }), key: 'Stopped due to Manual Irrigation' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_communication_issues", defaultMessage: 'Stopped due to Communication issues' }), key: 'Stopped due to Communication issues' }
    ]


    const [filterVars, setFilterVars] = useState({
        device: {},
        sector: {},
        status: {},
        start: null,
        end: null
    })
    const {
        handleStationFilterChange,
        stations,
        setStation,
        setStations,
        stationsCopy,
        setStationsCopy,
        sectors } = useContext(StationContext);

    const [range, setRange] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - 5)),
        end: new Date(new Date().setDate(new Date().getDate() + 5))
    });

    const [loadingLogs, setLoadingLogs] = useState(false);

    const [loadingNextPage, setLoadingNextPage] = useState(false);
    const [loadingPrevPage, setLoadingPrevPage] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);

    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [intervalId, setIntervalId] = useState(null);

    const [showFilter, setShowFilter] = useState(false);


    useEffect(() => {
        filter();
    }, [filterVars, range])


    useEffect(() => {
        if (!intervalId)
            setIntervalId(setInterval(() => {
                filter()
            }, 1000 * 60 * 10) // Fetch events after every 10 minutes
            )

    }, [intervalId])

    function filter(pagination_url = null) {
        setLoadingLogs(true);
        const variables = {
            status: filterVars?.status?.key ? filterVars?.status?.key : '',
            device: filterVars.device.serial ? filterVars.device.serial : '',
            sector: filterVars.sector.id ? filterVars.sector.id : '',
            start_date: filterVars.start ? filterVars?.start.toJSON() : '',
            end_date: filterVars.end ? filterVars?.end.toJSON() : '',
            timezone: timezone
        }


        let url = BASEURI + `/api/scheduler/irrigation-task-event/filter/?` + new URLSearchParams(variables).toString(),
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        if (pagination_url)
            url = pagination_url

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                // no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (!response.ok)
                            return response.text().then(text => { throw new Error(text) });
                        return response.json();
                    })

                    .then((data) => {

                        setLoadingLogs(false);
                        setLogs(data.results);
                        setNextPage(data.next);
                        setPrevPage(data.previous);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);
                        setPageNumber(data.page);
                        setTotalPages(data.total_pages);

                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setLoadingLogs(false);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);
                    });
            });
    }

    function handleNextPage() {
        if (!nextPage)
            return

        clearInterval(intervalId);
        setLoadingNextPage(true);
        filter(nextPage);
    }

    function handlePreviousPage() {
        if (!prevPage)
            return
        setLoadingPrevPage(true);
        filter(prevPage);
    }


    function handleChange(e) {

        const name = e.target.name;
        const value = e.target.value;

        setResetFilter(true)

        if (name === 'device') {
            setFilterVars({ ...filterVars, sector: {}, [name]: value });
            setStation(value);
        } else {
            setFilterVars({ ...filterVars, [name]: value })
        }

        clearInterval(intervalId);
        setIntervalId(null);
    }

    function clearFilter() {
        setResetFilter(false);
        setRange({
            start: new Date(new Date().setDate(new Date().getDate() - 5)),
            end: new Date(new Date().setDate(new Date().getDate() + 5))
        })
        setFilterVars({
            device: {},
            sector: {},
            status: {}
        })
    }

    return <>
        <div class="m-5 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="rounded-lg bg-gray-50">
                <FilterOptions
                    stations={stations}
                    filterVars={filterVars}
                    // handleDateChange={handleChange}
                    handleChange={handleChange}
                    sectors={sectors}
                    handleStationFilterChange={handleStationFilterChange}
                    irrigationStatus={irrigationStatus}
                // range={range}
                />
            </div>

            <div class="gap-2 rounded-lg bg-gray-50 lg:col-span-2 h-screen overflow-scroll">

                {logs.length > 0 ?
                    logs.map((item) => {

                        return <EventItem
                            key={item.id}
                            status={item.status}
                            deviceName={item?.irrigation_task?.device?.name}
                            sectorName={item?.irrigation_task?.zone?.name}
                            created={item.created}
                            item={item}
                            irrigationStatus={irrigationStatus}
                        />
                    }) :
                    <div className='text-xl text-center text-slate-500 mt-8'>{intl.formatMessage({ id: "app.scheduler.noReports", defaultMessage: "Nothing to show!" })}</div>
                }




            </div>


            <div className='flex justify-center mb-8'>
                <div class="inline-flex items-center justify-center gap-3">
                    <a
                        onClick={handlePreviousPage}
                        class="inline-flex w-8 h-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
                    >
                        <span class="sr-only">Prev Page</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>

                    <p class="text-xs text-gray-900">
                        {pageNumber}
                        <span class="mx-0.25">/</span>
                        {totalPages}
                    </p>

                    <a
                        onClick={handleNextPage}
                        class="inline-flex w-8 h-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180"
                    >
                        <span class="sr-only">Next Page</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </>
}