import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import ClickAwayListener from 'react-click-away-listener';
import { Input } from '@progress/kendo-react-inputs';

class StatMenu extends React.Component {
  anchor = null;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      currentName: "",
      show: false,
      inEdit: false
    };

    this.deleteWidget = this.deleteWidget.bind(this);
    this.addStat = this.addStat.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.setState({
      show: false,
      name: this.props.name,
      currentName: this.props.name
    });
  }

  popupAlign = { horizontal: 'right', vertical: 'top' };
  anchorAlign = { horizontal: 'center', vertical: 'bottom' };

  deleteWidget(e) {
    e.preventDefault();
    this.setState({ show: false });
    this.props.delete(this.props.widgetId);
  }

  addStat(e) {
    e.preventDefault();
    this.props.initNewStat();
    this.setState({ show: false });
  }

  saveWidget = () => {
    if (this.state.name === "") {
      alert(
        this.props.intl.formatMessage({ id: 'app.stat.statMenu.nameError', defaultMessage: 'please enter a name.' }));
      return
    }
    this.props.renameWidget(this.props.widgetId, this.state.name);
    this.setState({ inEdit: false, currentName: this.state.name });
  }

  handleChange(e) {
    if (e.key === "Enter") {
      this.saveWidget();
    }
    this.setState({ name: e.target.value });
  }


  render() {

    var inEditStyle = this.state.inEdit ? { backgroundColor: 'white', overflow: 'visible' } : {};
    return (
      <div className="p-0 m-0" style={inEditStyle}>
        {
          this.state.inEdit ?
            <div style={{ display: 'block', }} className='m-2 max-w-fit'>
              <Input onKeyDown={this.handleChange} autoFocus={true} style={{ marginRight: 5, width: '99%' }} onChange={this.handleChange} value={this.state.name} />
              <div className="menu-inEdit-buttons">
                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary"
                  onClick={this.saveWidget}>
                  {this.props.intl.formatMessage({ id: 'app.stat.statMenu.save', defaultMessage: 'Save' })}
                </button>
                <button
                  className="mdl-button mdl-button--colored"
                  onClick={() => this.setState({ inEdit: false, name: this.state.currentName })} >
                  {this.props.intl.formatMessage({ id: 'app.stat.statMenu.cancel', defaultMessage: 'Cancel' })}
                </button>

              </div>
            </div> :
            <div
              title={this.state.name}
              className='bg-[#1E293B] text-white text-xl p-3 rounded-[15px] mb-2 drop-shadow max-w-fit'
              onClick={this.close}
              id={this.props.menuId}
              ref={(span) => { this.anchor = span }}
              style={{ cursor: 'pointer' }}
            >
              {this.state.name}
            </div>
        }

        <Popup
          popupAlign={this.popupAlign}
          anchorAlign={this.anchorAlign}
          anchor={this.anchor}
          show={this.state.show}
          animate={false}
          popupClass={'ag-chart-popup'}
        >
          <ClickAwayListener onClickAway={this.close}>
            <ul>
              <li onClick={() => this.setState({ inEdit: true, show: false })}>
                <a href="#dummy">
                  {this.props.intl.formatMessage({ id: 'app.stat.statMenu.renameWidget', defaultMessage: 'Rename Widget' })}
                </a>
              </li>
              <li onClick={(e) => this.addStat(e)}>
                <a href="#dummy">
                  {this.props.intl.formatMessage({ id: 'app.stat.statMenu.addStat', defaultMessage: 'Add Stat' })}
                </a>
              </li>
              <li onClick={(e) => window.confirm(this.props.intl.formatMessage({ id: 'app.stat.deleteMessage', defaultMessage: 'Are you sure you want to delete stat?' })) ? this.deleteWidget(e) : null}>
                <a href="#dummy" style={{ color: 'red' }} > {this.props.intl.formatMessage({ id: 'app.stat.statMenu.delete', defaultMessage: 'Delete' })}</a>
              </li>
            </ul>
          </ClickAwayListener>
        </Popup>
      </div>
    );
  }

  close = (e) => {
    if (e)
      this.setState({ show: !this.state.show });
    else
      this.setState({ show: false });
  }
}

export default StatMenu;