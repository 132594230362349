import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { getAuthToken, formatDate, BASEURI } from '../utils';
import Modal from '../general/Modal';
import { FormattedMessage } from 'react-intl';
import loading from '../loading.svg';


class AlertEventItem extends React.Component {

    typeIcon = {
        w: '⚠',
        c: '⚠',
        i: 'ℹ',
        p: <i style={{ color: "red" }} className="material-icons">money_off</i>
    }

    titleClass = this.props.read ? 'title' : 'title unread';

    render() {
        return <li>
            <details>
                <summary onClick={(e) => this.props.markAsRead(e, this.props)}>
                    <div className={this.titleClass}>
                        <span className={"type " + this.props.alert._type}>{this.typeIcon[this.props.alert._type]}</span>
                        <span className="name"> {this.props.alert.name} </span>
                    </div>
                    <div className="time">
                        <span className="time">{formatDate(this.props.date)}</span> {/* Date here must be handled with intl */}
                    </div>
                </summary>
                <div className="event-message" style={{ padding: '10px' }}>
                    <div>
                        {this.props.alert.message}
                    </div>
                    {
                        this.props.graph ?
                            <div>
                                <FormattedMessage id='app.noti.alertEventItem.chart' defaultMessage='Chart' />: {this.props.graph.chart.name}
                            </div> : ""
                    }
                    {
                        this.props.graph ?
                            <div>
                                <FormattedMessage id='app.noti.alertEventItem.graph' defaultMessage='Graph' />: {this.props.graph.label}
                            </div> : ""
                    }
                    {
                        this.props.graph ?
                            <div>
                                <FormattedMessage id='app.noti.alertEventItem.value' defaultMessage='Value' />: {this.props.value}
                            </div> : ""
                    }
                </div>
            </details>
        </li>;
    }
}


class ViewAlertEventsDialog extends React.Component {

    render() {

        return this.props.show &&
            <Dialog title={<FormattedMessage id='app.noti.notifications' defaultMessage='Notifications' />} onClose={this.props.toggle} >

                <div className='modal-wrap'>
                    <div className='modal-form-wrap'>
                        <div className='alert-modal-form-inputs-wrap'>

                            <ul className='notifications'>

                                {this.props.children}

                            </ul>

                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.toggle}>
                        <FormattedMessage id='app.noti.done' defaultMessage='Done' />
                    </button>
                    <button disabled={!this.props.prevUrl} className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md' onClick={this.props.handlePrevUrl}>
                        {
                            this.props.loadingPrevPage ?
                                <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                                <FormattedMessage id='app.noti.previous' defaultMessage='Previous' />
                        }
                    </button>
                    <button disabled={!this.props.nextUrl} className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md' onClick={this.props.handleNextUrl}>
                        {
                            this.props.loadingNextPage ?
                                <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                                <FormattedMessage id='app.noti.next' defaultMessage='Next' />
                        }
                    </button>
                </DialogActionsBar>
            </Dialog>;
    }

}


class ViewAlertEvents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingNextPage: false,
            loadingPrevPage: false,
            nextUrl: null,
            prevUrl: null,
            unreadCount: 0,
            events: [],
            showDialog: false
        }

        this.loadEvents = this.loadEvents.bind(this);
        this.markEventAsRead = this.markEventAsRead.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleNextUrl = this.handleNextUrl.bind(this);
        this.handlePrevUrl = this.handlePrevUrl.bind(this);

    }

    componentDidMount() {

        this.loadEvents();

        // this.interval = setInterval(() => {
        //     this.loadEvents();
        // }, 60000 );

    }

    componentWillUnmount() {
        // clearInterval(this.interval);
    }

    handleNextUrl() {
        if (!this.state.nextUrl)
            return

        this.setState({ loadingNextPage: true })
        this.loadEvents(this.state.nextUrl)
    }

    handlePrevUrl() {
        if (!this.state.prevUrl)
            return

        this.setState({ loadingPrevPage: true })
        this.loadEvents(this.state.prevUrl)
    }


    loadEvents(url = `${BASEURI}/api/monitor/events/`) {
        //run every 5 minutes: get new events, update unread events count and notify user

        let options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then(response => {
                        this.setState(state => ({
                            events: response.results,
                            unreadCount: response.unreaded,
                            nextUrl: response.next,
                            prevUrl: response.previous,
                            loadingNextPage: false,
                            loadingPrevPage: false
                        }));
                    });
            })
            .catch(error => console.log('Error:', error));
    }

    markEventAsRead(e, props) {
        //mark event as read and update unread count

        let url = `${BASEURI}/api/monitor/events/rud/${props.id}/`,
            event = { ...props };
        event.read = true;
        delete event.markAsRead;
        let options = {
            method: 'PUT',
            body: JSON.stringify(event),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then(event => {

                        let eventList = this.state.events.slice();

                        let eventToReplace = null;
                        for (let i in eventList) {
                            if (eventList[i].id === event.id) {
                                eventToReplace = i;
                                break;
                            }
                        }

                        eventList.splice(eventToReplace, 1, event);

                        this.setState({ events: eventList });

                        // this.loadEvents();

                    })
            })
            .catch(error => console.log('Error:', error));

    }

    toggleDialog() {

        this.setState(state => ({ showDialog: !state.showDialog }));
    }

    componentDidUpdate() {
        // console.log(this.state);
    }

    render() {

        let events = this.state.events.map(event => <AlertEventItem markAsRead={this.markEventAsRead} key={event.id} {...event} />);

        return (
            <div>
                {this.state.unreadCount > 0 ?
                    <div
                        className="material-icons mdl-badge mdl-badge--overlap"
                        data-badge={this.state.unreadCount > 1000 ? `${parseFloat(this.state.unreadCount / 1000).toFixed(1)}k` : this.state.unreadCount}
                        style={{ cursor: 'pointer' }}
                        onClick={this.toggleDialog}
                    >
                        notification_important
                    </div> :
                    <div
                        className="material-icons mdl-badge mdl-badge--overlap"
                        style={{ cursor: 'pointer' }}
                        onClick={this.toggleDialog}
                    >
                        notification_important
                    </div>
                }
                <Modal>
                    <ViewAlertEventsDialog
                        events={this.state.events}
                        show={this.state.showDialog}
                        toggle={this.toggleDialog}
                        nextUrl={this.state.nextUrl}
                        prevUrl={this.state.prevUrl}
                        handleNextUrl={this.handleNextUrl}
                        handlePrevUrl={this.handlePrevUrl}
                        loadingNextPage={this.state.loadingNextPage}
                        loadingPrevPage={this.state.loadingPrevPage}
                    >

                        {events}

                    </ViewAlertEventsDialog>
                </Modal>
            </div>
        );

    }

}


export default ViewAlertEvents;