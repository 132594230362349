import React from 'react';


export default function AgViewerPrivacyPolicy() {


    return (
        <section className="p-4 sm:p-2">
            <div className='md:pl-[20%] md:pr-[20%]'>
                <h1 className="p-2 text-2xl">Ag-Viewer Privacy Policy</h1>
                <h3 className="p-2">Effective Date: <span className="font-mono">2017/3/12</span></h3>
                <p className="p-2">Welcome to Ag-Viewer! This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information. Please take a moment to familiarize yourself with our practices, and if you have any questions or concerns, feel free to contact us at rferreyra@morph2ola.com.</p>
                <ol className="list-inside">
                    <li className="p-2">
                        <h1 className="text-xl">1. Information We Collect</h1>
                        <ol>
                            <li>
                                <h1 className="inline text-lg">1.1 Account Information: </h1>
                                <span>To use Ag-Viewer, users are required to create an account. We collect and store the following information:</span>
                                <ul className="list-inside pl-6">
                                    <li className="list-disc">Full name</li>
                                    <li className="list-disc">Email address</li>
                                    <li className="list-disc">Password (encrypted)</li>
                                    <li className="list-disc">Phone Number</li>
                                </ul>
                            </li>
                            {/* <li>
                                <h1 className="inline text-lg">1.2 Tree Buds Scouts Information: </h1>
                                <span>Ag-Viewer allows users to collect and share information about tree buds. This includes:</span>
                                <ul className="list-inside pl-6">
                                    <li className="list-disc">Pictures of tree buds uploaded by users</li>
                                    <li className="list-disc">Location data associated with the uploaded pictures</li>
                                </ul>
                            </li> */}
                            <li>
                                <h1 className="inline text-lg">1.3 Log Data: </h1>
                                <span>Our servers automatically record information that your device sends whenever you use Ag-Viewer. This may include:</span>
                                <ul className="list-inside pl-6">
                                    <li className="list-disc">IP address</li>
                                    <li className="list-disc">Date and time of usage</li>
                                    <li className="list-disc">App features used</li>
                                    <li className="list-disc">Crashes and errors</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">2. How We Use Your Information</h1>
                        <ol>
                            <li>
                                <h1 className="inline text-lg">2.1 Account Management: </h1>
                                <span>We use the account information to manage user accounts, provide personalized experiences, and communicate with users about their account and app-related activities.</span>
                            </li>
                            {/* <li>
                                <h1 className="inline text-lg">2.2 Tree Buds Scouts Information:</h1>
                                <span>The pictures and location data of tree buds are used to enhance the user experience and provide features related to tree exploration and scouting.</span>
                            </li> */}
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">3. Sharing of Information</h1>
                        <ol>
                            <li>
                                <h1 className="inline text-lg">3.1 Third-Party Service Providers: </h1>
                                <span>We will not share your private information, such as phone number, email, or user location, with third-party services. Additionally, we will not share your collected telemetry data. All analysis and calculations are performed within our servers. Your privacy is our priority, and we are committed to safeguarding your personal information from external entities.</span>
                            </li>
                            <li>
                                <h1 className="inline text-lg">3.2 Legal Requirements: </h1>
                                <span>We may disclose your information if required to do so by law or in response to a subpoena or court order.</span>
                            </li>
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">4. Security</h1>
                        <ol>
                            <li>
                                <span>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</span>
                            </li>
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">5. Your Choices</h1>
                        <ol>
                            <li>
                                <span>Users can update or delete their account information and preferences within the app. If you wish to delete your account entirely, please contact us at rferreyra@morph2ola.com.</span>
                            </li>
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">6. Updates to this Privacy Policy</h1>
                        <ol>
                            <li>
                                <span>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Users will be notified of any material changes.</span>
                            </li>
                        </ol>
                    </li>
                    <li className="p-2">
                        <h1 className="text-xl">7. Contact Information</h1>
                        <ol>
                            <li>
                                <span>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at rferreyra@morph2ola.com.</span>
                            </li>
                        </ol>
                    </li>
                </ol>

                <p className="p-2">By using Ag-Viewer, you agree to the terms outlined in this Privacy Policy.</p>
                <h3 className="p-2 text-xl">Aganalytics Spa</h3>
            </div>
        </section>

    )
}


