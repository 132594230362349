// React
import React, { useEffect, useReducer, useState } from 'react';

// Project
import { BASEURI, getAuthToken, SCOUTURI, SCOUTAPIKEY } from '../utils';
import MapContainer from './map/MapContainer';
import ScoutMap from './map/ScoutMap';
import Farms, { FarmDropDownList, YearSelect, ScoutTypeSelect } from './map/Farms';
import ScoutChart from './chart/ChartWidget';
import ScoutHistogram from './chart/Histogram';
import UserModal from './panel/UserModal';

import PlotStatCardContainer from './components/PlotStatCardContainer';
import { buildParamStr } from './utils';
import ExcelExport from '../utils/ExcelExport';
import { useIntl } from 'react-intl';

function pageStateReducer(state, action) {

    switch (action.type) {
        case 'year':
            return { ...state, year: action.value }
        case 'scoutType':
            return { ...state, scoutType: action.value }
        case 'farm':
            return { ...state, farm: action.value }
        case 'all':
            return action.value
    }

}

function ScoutPage() {

    const allScoutTypes = [{ id: null, scout_type: 'Todo' }]
    const intl = useIntl()

    // Farms/plots multiselect value
    // const [selectedFarm, setSelectedFarm] = useState()
    // const [year, setYear] = useState(new Date().getFullYear().toString())
    // const [scoutType, setScoutType] = useState(defaultScoutType)
    const [showModal, setShowModal] = useState(false)
    const [farms, setFarms] = useState([]);
    const [plots, setPlots] = useState([]);
    const [scoutTypes, setScoutTypes] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false)
    const [users, setUsers] = useState([])
    const [retry, setRetry] = useState(false)
    const initialPageState = {
        year: null,
        farm: null,
        scoutType: null
    }
    const [pageState, pageStateDispatch] = useReducer(pageStateReducer, {})

    const [scouts, setScouts] = useState([])

    function onYearChange(event) {
        // setYear(event.target.value)
        pageStateDispatch({ type: 'year', value: event.target.value })
    }

    function onScoutTypeChange(event) {
        // setScoutType(event.target.value)
        pageStateDispatch({ type: 'scoutType', value: event.target.value })
    }

    function onFarmChange(event) {
        // setSelectedFarm(event.target.value)
        pageStateDispatch({ type: 'farm', value: event.target.value })
    }

    function getPlotName(id) {
        return plots.find(item => item.plot_id === id)?.plot_name
    }

    function loadFarms(orgIDs) {

        if (!orgIDs || orgIDs.length < 1)
            return

        let url = `${SCOUTURI}/api/v1.0/farms/${orgIDs}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        const APIKEY = SCOUTAPIKEY

        options.headers.Authorization = `Api-Key ${APIKEY}`;

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    response.text().then(err => console.log(err));
                    throw new Error(response.statusText, response.status);
                }
                return response.json();
            })
            .then((response) => {
                setFarms(response.data)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function getUsers() {
        setRetry(false)

        let url = `${BASEURI}/api/agscout/users/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                // No token exists or all tokens are expired.
                if (token === false) {
                    return false;
                }
                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            setRetry(true)
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        if (response && response.data) {
                            setUsers(response.data)
                            setRetry(false)
                        }
                    })
                    .catch(error => {
                        setRetry(true)
                        console.error(error);
                    });
            });
    }

    function loadFarmScouts() {

        if (!pageState.scoutType || !pageState.farm)
            return

        setShowSpinner(true)

        if (!pageState.farm)
            return

        let params = buildParamStr({
            year: pageState.year.value,
            type: pageState.scoutType?.id
        }),
            url = `${SCOUTURI}/api/v1.0/farmscouts/${pageState.farm.id}/` + params,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        const APIKEY = SCOUTAPIKEY

        options.headers.Authorization = `Api-Key ${APIKEY}`;

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText, response.status);
                }
                return response.json();
            })
            .then((response) => {
                setScouts(response.data)
                setShowSpinner(false)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function getScoutTypes() {

        let url = `${SCOUTURI}/api/v1.0/scouttypes/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        const APIKEY = SCOUTAPIKEY

        options.headers.Authorization = `Api-Key ${APIKEY}`;

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText, response.status);
                }
                return response.json();
            })
            .then((response) => {
                setScoutTypes(allScoutTypes.concat(response.data))
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function getPageState() {

        let url = `${BASEURI}/api/agscout/pagestate/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(async token => {

                // No token exists or all tokens are expired.
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                return await fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            setRetry(true)
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        if (response && response.data)
                            pageStateDispatch({ type: 'all', value: response.data.state })
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });

    }

    function updatePageState() {

        if (!pageState?.year && !pageState?.scoutType && !pageState?.farm)
            return

        let url = `${BASEURI}/api/agscout/pagestate/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                    state: {
                        year: pageState?.year,
                        scoutType: pageState?.scoutType,
                        farm: pageState?.farm
                    }
                })
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                // No token exists or all tokens are expired.
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            setRetry(true)
                            throw new Error(response.statusText, response.status);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });
    }

    useEffect(() => {
        getUsers()
        getPageState()
        getScoutTypes()
    }, [])

    useEffect(() => {
        loadFarms(users.map(user => user.org_id))
    }, [users])

    useEffect(() => {
        if (pageState?.farm) loadFarmScouts();

        updatePageState()
    }, [pageState])

    return <div className="bg-green-50 min-h-full flex flex-col gap-2">
        <div className='breadcrumb flex flex-row flex-wrap justify-between w-full py-4 px-4'>
            <span className='flex flex-col justify-center'>
                <span>
                    AG-SCOUT
                </span>
            </span>
            <span className='flex flex-row flex-wrap gap-2'>
                <span className="config-btn" onClick={() => setShowModal(true)}>
                    <span className="mr-2 align-middle material-icons">
                        settings
                    </span>
                    USUARIOS
                </span>
                <ExcelExport
                    excelData={scouts.map(item => {
                        return {
                            'ID': item.id,
                            'LAT': item.lat,
                            'LNG': item.lon,
                            'ACCURACY': item.accuracy,
                            'SCOUTED DATE': intl.formatDate(
                                new Date(item.scouted_date),
                                {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric'
                                }),
                            'PLOT': getPlotName(item.plot),
                            'VARIETY': item.variety,
                            'SCOUT TYPE': item?.type_of_scout?.scout_type,
                            'PLANT NUMBER': item.plant_number,
                            'ROW NUMBER': item.row_number,
                            'NUMBER OF LATERALS': item.number_of_laterals,
                            'NUMBER OF BRANCHES': item.number_of_branches,
                            'NUMBER OF COUNTS': item.number_of_counts,
                        }
                    })}
                    fileName={pageState?.farm?.name || 'scouts'} />
            </span>


        </div>
        <MapContainer>
            <Farms>
                <div className="flex flex-row gap-2">
                    <YearSelect onChange={onYearChange} value={pageState.year} />
                    <ScoutTypeSelect
                        data={scoutTypes}
                        onChange={onScoutTypeChange}
                        value={pageState?.scoutType}
                    />
                </div>
                <FarmDropDownList
                    onChange={onFarmChange}
                    value={pageState?.farm}
                    data={farms}
                />

            </Farms>
            <ScoutMap
                pageState={pageState}
                markers={scouts}
                showSpinner={showSpinner}
            />
            <div className="col-span-12 row-span-2 md:col-span-4 md:row-span-5">
                <ScoutHistogram data={scouts} />
                <ScoutChart
                    farm={pageState?.farm}
                    year={pageState?.year?.value}
                    scoutType={pageState?.scoutType}
                />
            </div>
        </MapContainer>

        <PlotStatCardContainer
            setPlots={setPlots}
            farm={pageState?.farm}
            year={pageState?.year?.value}
        />

        {
            showModal &&
            <UserModal
                users={users}
                retry={retry}
                getUsers={getUsers}
                close={() => setShowModal(false)}
            />
        }
    </div>
}


export default ScoutPage;