import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Switch } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';

export default function FilterOptions(props) {
    const intl = useIntl();

    function itemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps?.dataItem?.device?.name} [{itemProps?.dataItem?.zone_id}]</div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    return <div className="p-4">
        <div className='w-full p-2'>
            <DropDownList
                name='device'
                label={intl.formatMessage({ id: "app.scheduler.events.station", defaultMessage: 'Station' })}
                dataItemKey='serial'
                textField='name'
                valueField='serial'
                data={props.stations}
                value={props.filterVars.device}
                onChange={props.handleChange}
                filterable={true}
                onFilterChange={props.handleStationFilterChange}
                style={{ width: '100%' }}
            />
        </div>
        <div className='w-full p-2'>
            <DropDownList
                name='sector'
                label={intl.formatMessage({ id: "app.scheduler.events.filter.sector", defaultMessage: 'Sector' })}
                data={props.sectors}
                textField='name'
                value={props.filterVars.sector}
                onChange={props.handleChange}
                itemRender={itemRender}
                style={{ width: '100%' }}
            />
        </div>
        <div className='w-full p-2'>
            <DropDownList
                name='status'
                label={intl.formatMessage({ id: "app.dga.logTable.status", defaultMessage: 'Status' })}
                data={props.irrigationStatus}
                textField='status'
                dataItemKey='key'
                value={props.filterVars.type}
                onChange={props.handleChange}
                // itemRender={itemRender}
                style={{ width: '100%' }}
            />
        </div>
        <div className='w-full p-2'>
            <DatePicker
                // endDateInput={(props) => { return <div></div> }}
                name={'start'}
                value={props.start}
                onChange={props.handleChange}
                // calendarSettings={{ views: 1 }}
                label={intl.formatMessage({ id: "app.chart.quickDateMenu.start", defaultMessage: 'Start' })}
                style={{ width: '100%' }}
            />
        </div>

        <div className='w-full p-2'>
            <DatePicker
                // endDateInput={(props) => { return <div></div> }}
                name={'end'}
                value={props.end}
                onChange={props.handleChange}
                // calendarSettings={{ views: 1 }}
                label={intl.formatMessage({ id: "app.chart.quickDateMenu.end", defaultMessage: 'End' })}
                style={{ width: '100%' }}
            />
        </div>
    </div>
}