import React, { useState, useEffect, useMemo } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';
import { Label } from "@progress/kendo-react-labels";


export const RecurrenceEditor = props => {
  const intl = useIntl();
  const [recurrenceRule, setRecurrenceRule] = useState({
    FREQ: { value: intl.formatMessage({ id: "app.scheduler.taskModel.never", defaultMessage: 'Never' }), key: 'NEVER' },
    INTERVAL: null,
    BYDAY: [],
    BYMONTHDAY: '',
  });

  useEffect(() => {

    if (props.value)
      deserializeRule(props.value)
    else
      props.onChange.call(undefined, {
        value: serializeRule(recurrenceRule),
        name: 'recurrence_rule'
      });

  }, [])


  const WEEKDAYS = [
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.sunday", defaultMessage: 'Sunday' }), key: 'SU' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.monday", defaultMessage: 'Monday' }), key: 'MO' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.tuesday", defaultMessage: 'Tuesday' }), key: 'TU' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.wednesday", defaultMessage: 'Wednesday' }), key: 'WE' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.thursday", defaultMessage: 'Thursday' }), key: 'TH' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.friday", defaultMessage: 'Friday' }), key: 'FR' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.saturday", defaultMessage: 'Saturday' }), key: 'SA' }
  ]

  const FREQUENCY = [
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.never", defaultMessage: 'Never' }), key: 'NEVER' },
    // { value: intl.formatMessage({ id: "app.scheduler.taskModel.daily", defaultMessage: 'Daily' }), key: 'DAILY' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.weekly", defaultMessage: 'Weekly' }), key: 'WEEKLY' },
    { value: intl.formatMessage({ id: "app.scheduler.taskModel.monthly", defaultMessage: 'Monthly' }), key: 'MONTHLY' }
  ]

  const deserializeRule = (ruleStr) => {
    const rules = ruleStr.split(';')
    let ruleObj = {}

    for (let i in rules) {
      try {
        const rule = rules[i].split('=')
        ruleObj[rule[0]] = rule[1]
      } catch {
        console.log("Can't deserialize rule!")
      }
    }


    if (ruleObj.hasOwnProperty('BYDAY')) {
      const days = ruleObj['BYDAY'].split(',')
      ruleObj['BYDAY'] = WEEKDAYS.filter((item) => {

        if (days.includes(item.key))
          return true
      })
    }

    if (ruleObj.hasOwnProperty('FREQ')) {
      ruleObj['FREQ'] = FREQUENCY.find((item) => {
        if (ruleObj['FREQ'] === item.key)
          return item
      })
    }

    setRecurrenceRule(ruleObj);
  }

  const serializeRule = (ruleObj) => {
    let ruleStr = '';
    for (const [key, value] of Object.entries(ruleObj)) {

      if (ruleObj.FREQ.key === 'NEVER') {
        continue
      }

      if (ruleObj.FREQ.key === 'DAILY') {
        if (key === 'BYDAY' || key === 'BYMONTHDAY')
          continue
      }
      if (ruleObj.FREQ.key === 'WEEKLY') {
        if (key === 'INTERVAL' || key === 'BYMONTHDAY')
          continue
      }
      if (ruleObj.FREQ.key === 'MONTHLY') {
        if (key === 'BYDAY' || key === 'INTERVAL')
          continue
      }

      if (key === 'BYDAY') {
        ruleStr += `${key}=${value.map(d => d.key).join()};`
      } else if (key === 'FREQ') {
        ruleStr += `${key}=${value.key};`
      } else {
        ruleStr += `${key}=${value};`
      }
    }

    return ruleStr
  }

  const handleChange = event => {

    if (props.onChange) {

      const name = event.target.name;
      const value = event.value;
      const new_rule = { ...recurrenceRule, [name]: value }

      if (name == 'BYMONTHDAY') {
        const re = /^[0-9,]*$/
        if (!re.test(value))
          return
      }

      setRecurrenceRule(new_rule)


      props.onChange.call(undefined, {
        value: serializeRule(new_rule),
        name: 'recurrence_rule'
      });
      console.log(serializeRule(new_rule))
    }
  };

  return <>
    <div className='modal-form-inputs-wrap-row'>
      <DropDownList
        label={intl.formatMessage({ id: "app.scheduler.taskModel.frequency", defaultMessage: 'Frequency' })}
        value={recurrenceRule.FREQ}
        name='FREQ'
        data={FREQUENCY}
        onChange={handleChange}
        dataItemKey='key'
        textField='value'
      />
    </div>
    <div className='modal-form-inputs-wrap-row'>
      {recurrenceRule.FREQ.key === 'DAILY' &&
        <NumericTextBox
          value={recurrenceRule.INTERVAL}
          label={intl.formatMessage({ id: "app.scheduler.taskModel.interval", defaultMessage: 'Interval' })}
          name='INTERVAL'
          placeholder={intl.formatMessage({ id: "app.scheduler.taskModel.i.e", defaultMessage: 'i.e. every x days' })}
          onChange={handleChange}
          required={true}
        />}
      {
        recurrenceRule.FREQ.key === 'WEEKLY' &&
        <MultiSelect
          label={intl.formatMessage({ id: "app.scheduler.taskModel.weekDays", defaultMessage: 'Week Days' })}
          dataItemKey='key'
          textField='value'
          name='BYDAY'
          value={recurrenceRule.BYDAY}
          data={WEEKDAYS}
          onChange={handleChange}
          required={true}
          style={{ height: 'auto' }}
        />}
      {recurrenceRule.FREQ.key === 'MONTHLY' &&
        <Input
          label={intl.formatMessage({ id: "app.scheduler.taskModel.monthDays", defaultMessage: 'Month Days' })}
          value={recurrenceRule.BYMONTHDAY}
          name='BYMONTHDAY'
          placeholder={intl.formatMessage({ id: "app.scheduler.taskModel.e.g", defaultMessage: 'e.g. 1,2,3,4' })}
          onChange={handleChange}
          required={true}
        />}
    </div>
  </>;

};

export const TitleEditor = props => {
  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }
  };

  return <Input value={props.value} required={true} onChange={handleChange} disabled={props.disabled} />;
};

export const DurationEditor = props => {
  const intl = useIntl();
  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }
  };

  return <NumericTextBox
    required={true}
    placeholder={intl.formatMessage({ id: 'app.scheduler.taskModel.duration', defaultMessage: 'Duration (H)' })}
    width={"250px"}
    value={props.value}
    format="n2"
    onChange={handleChange} />;
};

export const PrecipitationEditor = props => {
  const [value, setValue] = useState(0)
  const intl = useIntl();

  const unit = useMemo(() => {
    if (props.name == 'precipitation') {
      return props?.value?.unitAbrev
    }
  }, [])

  useEffect(() => {
    if (props.name == 'precipitation') {
      // set value from precipition json field
      setValue(props?.value?.value)
    }
    else
      setValue(props.value)

  }, [])


  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }
    setValue(event.value)
  };

  return <>
    <NumericTextBox
      placeholder={intl.formatMessage({ id: 'app.scheduler.taskModel.precip', defaultMessage: 'Precipitation ({unit})' }, { unit: props.unit ? props.unit : 'N/A' })}
      style={{ width: '250px' }}
      value={value}
      format="n3"
      onChange={handleChange}
      label={intl.formatMessage({ id: 'app.scheduler.taskModel.precip', defaultMessage: 'Precipitation ({unit})' }, { unit: props.unit ? props.unit : 'N/A' })}
    />
  </>;
};

export const DeviceEditor = props => {
  const { devices, setDevice } = props;
  const [stations, setStations] = useState([]);

  useEffect(() => {
    setStations(devices);
  }, [devices])

  useEffect(() => {
    setDevice(props.value);
  }, [props.value])


  function handleStationFilterChange(e) {
    let filtered = devices.filter(item => {
      let filterText = e.filter.value,
        itemText = `${item.name.toLowerCase()}`;

      for (let i in filterText.split(' ')) {
        let textSplit = filterText.split(' ')[i];

        if (itemText.indexOf(textSplit) !== -1)
          return true
        else
          return false
      }
      return true;
    });
    setStations(filtered)
  }


  const handleChange = event => {

    if (props.onChange) {
      setDevice(event.value);
      props.onChange.call(undefined, {
        value: event.value
      });
    }
  };

  return <DropDownList
    onChange={handleChange}
    value={props.value}
    data={stations}
    dataItemKey={'serial'}
    textField={'name'}
    required={true}
    filterable={true}
    onFilterChange={handleStationFilterChange}
    disabled={props.disabled}
  />;

};

// Sector Section
export const SectorEditor = props => {


  function itemRender(li, itemProps) {
    const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port ? itemProps.dataItem.port : itemProps.dataItem.zone_id}]</div> </div>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const { sectors } = props;

  const handleChange = event => {

    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }
  };

  return <DropDownList
    onChange={handleChange}
    value={props.value}
    data={sectors}
    dataItemKey={'id'}
    textField={'name'}
    required={true}
    itemRender={itemRender}
    disabled={props.disabled}
  />;
};



export function IrrigationTypeEditor(props) {
  const [value, setValue] = useState({ value: 'Duration', key: 'Duration' })
  const intl = useIntl();

  useEffect(() => {
    if (props.value) {
      const irrigationTypeValue = data.find((item) => item?.key === props.value)
      setValue(irrigationTypeValue)

      props.onChange.call(undefined, {
        value: irrigationTypeValue
      });
    } else {
      props.onChange.call(undefined, {
        value: value
      });
    }


  }, [])

  const handleChange = event => {

    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }

    setValue(event.value);

  };

  const data = [
    // Translate these field to spanish
    { value: intl.formatMessage({ id: 'app.scheduler.datetime', defaultMessage: 'End Date and Time' }), key: 'Date/Time' },
    { value: intl.formatMessage({ id: 'app.scheduler.duration', defaultMessage: 'Precipitation' }), key: 'Duration' },
    { value: intl.formatMessage({ id: 'app.scheduler.layout.precipitation', defaultMessage: 'Precipitation' }), key: 'Volume' },
  ]


  const irrigationType = (irrigation_type) => {
    switch (irrigation_type) {
      case 'Date/Time':
        return props.endEditor
      case 'Duration':
        return props.durationEditor
      case 'Volume':
        return props.precipitationEditor
    }
  }

  return (<>
    <DropDownList
      onChange={handleChange}
      value={value}
      data={data}
      dataItemKey={'key'}
      textField={'value'}
      required={true}
    />
    {props.startEditor}
    {irrigationType(value?.key)}
  </>
  )
}