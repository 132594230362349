import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import Modal from '../general/Modal';
import { BASEURI, getAuthToken } from '../utils';
import loadingIcon from '../loading.svg';


function SubscriptionModal(props) {
    const [subscription, setSubscription] = useState({
        id: undefined,
        name: '',
        _type: undefined,
        description: '',
        service: undefined,
        rate: 0

    });
    const [newSubscription] = useState({
        id: undefined,
        name: '',
        _type: undefined,
        description: '',
        service: undefined,
        rate: 0

    });

    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const intl = useIntl()

    const [types] = useState([
        { value: 'organization', 'text': intl.formatMessage({ id: 'app.bills.subscriptionModal.organization', defaultMessage: 'Organization' }) },
        { value: 'normal', 'text': intl.formatMessage({ id: 'app.bills.subscriptionModal.normal', defaultMessage: 'Normal' }) },
    ]);
    const [services] = useState([
        { value: 'device', text: intl.formatMessage({ id: 'app.bills.subscriptionModal.device', defaultMessage: 'Device' }) },
        { value: 'marker', text: intl.formatMessage({ id: 'app.bills.subscriptionModal.marker', defaultMessage: 'Marker' }) },
        { value: 'forecast', text: intl.formatMessage({ id: 'app.bills.subscriptionModal.forecast', defaultMessage: 'Forecast' }) },
        { value: 'whatsapp', text: intl.formatMessage({ id: 'app.bills.subscriptionModal.whatsappAlerts', defaultMessage: 'Whatsapp Alerts' }) },
        { value: 'sms', text: intl.formatMessage({ id: 'app.bills.subscriptionModal.smsAlerts', defaultMessage: 'SMS Alert' }) },
    ])


    useEffect(() => {
        props.viewingSubscription && setSubscription(props.viewingSubscription);
    }, [props.viewingSubscription])

    function handleChange(e) {
        setSubscription({ ...subscription, [e.target.name]: e.target.value });
    }

    function edit() {
        setLoading(true);
        setMsg("");

        let params = {
            id: subscription.id,
            name: subscription.name,
            _type: subscription._type.value,
            description: subscription.description,
            service: subscription.service.value,
            rate: subscription.rate
        }

        let url = `${BASEURI}/api/billing/subscription/${subscription.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((sub) => {
                        setLoading(false);
                        let subs = props.subscriptions.slice();
                        let subToReplace = null;
                        for (let i in subs) {
                            if (subs[i].id === sub.id) {
                                subToReplace = i;
                                break;
                            }
                        }
                        subs.splice(subToReplace, 1, sub);
                        props.setSubscriptions(subs);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }


    function save() {
        setLoading(true);
        setMsg("");
        let params;
        try {
            params = {
                id: subscription.id,
                name: subscription.name,
                _type: subscription._type.value,
                description: subscription.description,
                service: subscription.service.value,
                rate: subscription.rate
            }
        } catch (error) {
            console.error(error);
            return;
        }

        let url = `${BASEURI}/api/billing/subscription/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((subscription) => {
                        setLoading(false);
                        if (subscription.msg) {
                            setMsg(subscription.msg)
                        } else {
                            props.setSubscriptions([...props.subscriptions, subscription])
                        }
                    })
                    .catch(error => console.error('Error:', error));

            });

    }




    return props.show && <Modal>
        <Dialog onClose={props.onClose} title={intl.formatMessage({ id: 'app.bills.subscriptionModal.createSub', defaultMessage: 'CREATE SUBSCRIPTION' })}>
            <div className='mdl-grid' style={{ justifyContent: 'center' }} >
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone' >
                    <form>
                        <div className='modal-form-inputs-wrap' >
                            <div className='modal-form-inputs-wrap-row' >
                                <Input
                                    name='name'
                                    value={subscription.name}
                                    onChange={handleChange}
                                    label={intl.formatMessage({ id: 'app.bills.subscriptionModal.name', defaultMessage: 'NAME' })}

                                />
                            </div>
                            <div className='modal-form-inputs-wrap' >
                                <Input
                                    name='description'
                                    value={subscription.description}
                                    onChange={handleChange}
                                    label={intl.formatMessage({ id: 'app.bills.subscriptionModal.description', defaultMessage: 'DESCRIPTION' })}

                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <DropDownList
                                    textField="text"
                                    name='_type'
                                    value={subscription._type}
                                    label={intl.formatMessage({ id: 'app.bills.subscriptionModal.type', defaultMessage: 'Type' })}
                                    data={types}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <DropDownList
                                    name="service"
                                    textField='text'
                                    data={services}
                                    label={intl.formatMessage({ id: 'app.bills.subscriptionModal.service', defaultMessage: 'SERVICE' })}
                                    onChange={handleChange}
                                    value={subscription.service}
                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <Input
                                    name='rate'
                                    type="number"
                                    label={intl.formatMessage({ id: 'app.bills.subscriptionModal.rate', defaultMessage: 'RATE ($)' })}
                                    onChange={handleChange}
                                    value={subscription.rate}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <p style={{ color: 'red' }}>{msg}</p>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={() => { props.onClose(); setSubscription(newSubscription) }}>
                    {intl.formatMessage({ id: 'app.bills.subscriptionModal.close', defaultMessage: 'Close' })}
                </button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={subscription.id ? edit : save}>{
                    loading ? <img src={loadingIcon} alt="loading" width={25} height={25} /> :
                        subscription.id ?
                            intl.formatMessage({ id: 'app.bills.subscriptionModal.edit', defaultMessage: 'EDIT' }) :
                            intl.formatMessage({ id: 'app.bills.subscriptionModal.add', defaultMessage: 'ADD' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>;
}

export default SubscriptionModal
