import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI, advancedFilter } from '../../utils';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { MultiSelect } from '@progress/kendo-react-dropdowns';



function WeatherStations(props) {

    const [stations, setStations] = useState([])
    const [allStations, setAllStations] = useState([]);
    const [selectedStations, setSelectedStations] = useState([]);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        setStations(props.stations);
        setAllStations(props.stations);
    }, [props.stations])

    useEffect(() => {
        getStations();
    }, [])

    function handleFilterChange(e) {
        advancedFilter(e.filter.value, allStations, setStations, ['name', 'serial'])
    }

    function getStations() {
        let url = `${BASEURI}/api/reports/stations/`,
            options = {
                method: 'get',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;

                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => console.log(err));
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        setSelectedStations(response)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            });

    }

    function removeStation(serial) {

        let url = `${BASEURI}/api/reports/details/${serial}`,
            options = {
                method: 'delete',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;

                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => console.log(err));
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        setMessage(response.message);
                        props.setRefreshAll(!props.refreshAll)
                        setSuccess(true);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            });

    }


    function addStation(serial) {

        let url = `${BASEURI}/api/reports/details/${serial}`,
            options = {
                method: 'get',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;

                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            response.text().then(err => console.log(err));
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        setMessage(response.message);
                        props.setRefreshAll(!props.refreshAll)
                        setSuccess(true);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            });

    }

    function handleChange(e) {
        let value = e.target.value;

        if (selectedStations.length < value.length) {

            const station = value.slice(-1)[0]

            if (!window.confirm(
                intl.formatMessage({
                    id: 'app.admin.weather.stationsAddMessage',
                    defaultMessage: `Are You Sure want to add [{station}]?`
                }, { station: station.name })))
                return

            addStation(station.serial);
        }
        else {

            for (let i in selectedStations) {
                if (!value.includes(selectedStations[i])) {

                    let station = selectedStations[i];


                    if (!window.confirm(
                        intl.formatMessage({
                            id: 'app.admin.weather.stationsDeleteMessage',
                            defaultMessage: `Are You Sure want to delete [{station}]?`
                        }, { station: station.name })))
                        return

                    removeStation(station.serial)
                }
            }
        }

        setSelectedStations(e.target.value);
    }


    return <>
        <MultiSelect
            style={{ minWidth: '150px', height: 'auto' }}
            name='stations'
            label={intl.formatMessage({ id: 'app.admin.stationGrid.station', defaultMessage: 'Stations' })}
            data={stations}
            textField="name"
            dataItemKey='serial'
            filterable={true}
            onFilterChange={handleFilterChange}
            onChange={handleChange}
            value={selectedStations}
            tags={
                selectedStations.length > 0 ?
                    [
                        {
                            text: `${selectedStations.length} ${intl.formatMessage({ id: 'app.admin.weather.stationsAdded', defaultMessage: 'added' })}`,
                            data: [...selectedStations],
                        },
                    ] : []
            }
            style={{ height: 'auto' }}
        />
        <NotificationGroup
            style={{
                left: '50%',
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse',
                zIndex: 100
            }}>
            <Fade>
                {success &&
                    <Notification
                        type={{
                            style: 'info',
                            icon: true,
                        }}
                        closable={true}
                        style={{ padding: '5px', background: '#334155' }}

                        onClose={() => setSuccess(false)}>
                        <span>
                            {message}
                        </span>

                    </Notification>}
            </Fade>
        </NotificationGroup>
    </>
}

export default WeatherStations;