import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import ClickAwayListener from 'react-click-away-listener';
import Modal from '../general/Modal';
import ConfirmDeleteDialog from '../general/ConfirmDeleteDialog';

class MoreMenu extends React.Component {
  anchor = null;
  constructor(props) {
    super(props);
    this.state = { show: false, showDeleteDialog: false, };
    //  this.deleteChart = this.deleteChart.bind(this);

  }

  componentDidMount() {
    this.setState({ show: false });
  }

  popupAlign = { horizontal: 'right', vertical: 'bottom' };
  anchorAlign = { horizontal: 'center', vertical: 'top' };

  //  deleteChart(e, chart){
  //     e.preventDefault();
  //     this.handleDeleteButton();
  //     // if(window.confirm('Are you sure you want to delete chart ?')){
  //     //   this.props.onClick(chart);
  //     // }
  //     this.setState({show:false});
  //  }

  editChart(e, chart) {
    e.preventDefault();
    this.setState({ show: false });
    this.props.editChart(chart);
  }

  render() {
    return this.state.showDeleteDialog ?
      <Modal>
        <ConfirmDeleteDialog
          message={<p> <b>{this.props.chartName} </b> {this.props.intl.formatMessage({ id: 'app.chart.deleteChart.deleteMessage', defaultMessage: "will be deleted along with all of it's data. Type {delete} below to confirm." }, { delete: <span style={{ color: 'red' }}>DELETE</span> })}</p>}
          onClose={() => this.setState({ showDeleteDialog: false, show: false })}
          remove={() => {
            this.props.onClick(this.props.chartId);
            this.setState({ showDeleteDialog: false, show: false })
          }} />
      </Modal>
      : (
        <div>
          <button id={this.props.menuId}
            className=" mdl-button mdl-js-button mdl-button--icon text-slate-700"
            onClick={this.close}
            ref={(button) => {
              this.anchor = button;
            }
            }
            title='Edit chart'
          >
            <i className="material-icons">more_vert</i>
          </button>
          <Popup
            popupAlign={this.popupAlign}
            anchorAlign={this.anchorAlign}
            anchor={this.anchor}
            show={this.state.show}
            animate={false}
            popupClass={'ag-chart-popup'}
          // onFocusOut = {console.log('clicked away')}
          >
            <ClickAwayListener onClickAway={this.close}>
              <ul>
                <li onClick={() => this.setState({ showDeleteDialog: true })}>
                  <a href="#dummy">
                    <i className="material-icons" style={{ verticalAlign: 'sub' }}>delete</i>
                    {this.props.intl.formatMessage({ id: 'app.chart.moreMenu.delete', defaultMessage: 'Delete' })}
                  </a>
                </li>
                <li onClick={(e) => this.editChart(e, this.props.chartId)}>
                  <a href="#dummy"><i className="material-icons" style={{ verticalAlign: 'sub' }}>edit</i>
                    {this.props.intl.formatMessage({ id: 'app.chart.moreMenu.edit', defaultMessage: 'Edit' })}
                  </a>
                </li>
              </ul>
            </ClickAwayListener>
          </Popup>
        </div>
      );
  }

  close = (e) => {
    if (e)
      this.setState({ show: !this.state.show });
    else
      this.setState({ show: false });
  }
}

export default MoreMenu;