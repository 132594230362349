import React, { useState, useEffect } from 'react';
import ReportChart from './ReportChart';
import { getAuthToken, BASEURI } from '../../utils';
import { useIntl } from 'react-intl';

export function formater(value) {
    if (value >= 1000000000) {
        // For billions
        return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
        // For millions
        return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
        // For thousands
        return (value / 1000).toFixed(1) + 'K';
    } else {
        // For values less than 1000
        return value;
    }
}



const IrrigationReport = () => {
    // const [stats, setStats] = useState({});
    const [deviceReports, setDeviceReports] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        loadReports()
    }, [])

    const loadReports = () => {

        let url = BASEURI + `/api/scheduler/reports/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((reports) => {
                        // setStats(reports.total_stats);
                        setDeviceReports(reports.device_stats);
                        console.log('the reports:: ', reports)
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }



    return (
        <div class="flex flex-col p-4 space-y-4 ">
            {/* <div class="flex justify-between p-4 bg-white rounded-lg shadow-md space-x-4 ring-1 ring-gray-50">
                <div class="flex-1 text-center">
                    <p class="text-sm text-gray-500">{intl.formatMessage({ id: 'app.scheduler.layout.irrigation_hours', defaultMessage: 'Irrigation Hours' })}</p>
                    <p class="text-3xl font-bold text-[#4DB6AC]">{formater(stats.total_duration)}</p>
                    <p class="text-xs text-gray-500">{new Date().getFullYear()}</p>
                </div>


                <div class="flex-1 text-center border-l border-gray-200">
                    <p class="text-sm text-gray-500">{intl.formatMessage({ id: 'app.scheduler.layout.precipitation', defaultMessage: 'Precipitation' })} (in)</p>
                    <p class="text-3xl font-bold text-[#FFA726]">{formater(stats.total_precipitation)}</p>
                    <p class="text-xs text-gray-500">
                        <span class="inline-flex items-center">
                            <svg class="w-4 h-4 mr-1 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5 8a1 1 0 00-2 0v4a1 1 0 001 1h4a1 1 0 100-2H5.414l4.293-4.293a1 1 0 10-1.414-1.414L4 9.586V8z" clip-rule="evenodd" />
                            </svg>
                            {new Date().getFullYear()}
                        </span>
                    </p>
                </div>

                <div class="flex-1 text-center border-l border-gray-200">
                    <p class="text-sm text-gray-500">{intl.formatMessage({ id: 'app.scheduler.layout.volume', defaultMessage: 'Volume' })} (gal)</p>
                    <p class="text-3xl font-bold text-[#AB47BC]">{formater(stats.total_volume)}</p>
                    <p class="text-xs text-gray-500">
                        <span class="inline-flex items-center">
                            <svg class="w-4 h-4 mr-1 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M15 8a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h3.586l-4.293-4.293a1 1 0 011.414-1.414l4 4V8z" clip-rule="evenodd" />
                            </svg>
                            {new Date().getFullYear()}
                        </span>
                    </p>
                </div>
            </div> */}


            <div class="bg-white p-4 rounded-lg shadow-md ring-1 ring-gray-50 min-h-screen">
                {deviceReports.length > 0 ?
                    deviceReports.map((report) =>
                        <ReportChart
                            totalDevicePrecipitation={parseFloat(report.total_device_precipitation.toFixed(2))}
                            totalDeviceVolume={parseFloat(report.total_device_volume.toFixed(2))}
                            totalDeviceDuration={parseFloat(report.total_device_duration.toFixed(2))}
                            reportID={report.serial}
                            data={report.sectors_stats}
                            title={report.name}
                            precip_unit={report?.precip_unit}
                            volume_unit={report?.volume_unit}
                            colors={['#A0C2E5', '#2F97FF']} />) :
                    <div className='text-xl text-center text-slate-500 '>{intl.formatMessage({ id: "app.scheduler.noReports", defaultMessage: "Nothing to show!" })}</div>
                }
            </div>
        </div>
    );
};

export default IrrigationReport;
