import React from 'react'
import colors from '../colors';

function Row(props){
    return <div className='value-ranges-row flex justify-between text-sm'>
        <span
            className='circle inline-block w-3 h-3 border border-gray-400 rounded-full'
            style={{backgroundColor: props.bgColor}}
        >
        </span>
        <span className='ml-3 align-top text-sm text-slate-600'>
            ( <span className='font-bold'>{props.valueStart} - {props.valueEnd}</span> ]
        </span>
    </div>
}


function Ranges(props){

    // const colorClasses = [
    //     'bg-white',
    //     // Lime
    //     'bg-lime-200',
    //     'bg-lime-400',
    //     'bg-lime-600',
    //     'bg-lime-800'
    //     // Red
    //     // 'bg-red-200',
    //     // 'bg-red-400',
    //     // 'bg-red-600',
    //     // 'bg-red-800'
    // ]

    return <div className='agscout__value-ranges flex flex-col p-3 bg-white text-sm w-44 rounded'>
        <div className='mb-4 font-normal text-base text-slate-600'> Conteo {props.scoutType?.scout_type} </div>
        { props.ranges.map((row, i) => i < props.ranges.length - 1 && <Row 
            key={i}
            bgColor={colors[i]}
            valueStart={Math.ceil(row)}
            valueEnd={props.ranges[i+1]}
            />)
        }
    </div>
}


export default Ranges