import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Grid as KGrid, GridCell, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { getAuthToken, BASEURI } from '../utils';
import SubscriptionModal from './SubscriptionModal';







function CommandCell({ editField, edit }) {
    const intl = useIntl();
    return props => {
        // render() {
        const { dataItem } = props;
        return (
            <td className="k-command-cell">
                <button
                    className="k-primary k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => edit(dataItem)}
                >
                    {intl.formatMessage({ id: 'app.bills.subscriptionGrid.edit', defaultMessage: 'EDIT' })}
                </button>
            </td>

        );
        // }
    }
};






function SubscriptionsGrid(props) {
    const [filter, setFilter] = useState([]);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [sort, setSort] = useState([]);
    const [viewingSubscription, setViewingSubscription] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const intl = useIntl();

    function getSubscriptions() {
        let url = `${BASEURI}/api/billing/subscription/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((subscriptions) => {
                        setSubscriptions(subscriptions);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }


    useEffect(() => { getSubscriptions(); }, []);

    function edit(dataItem) {
        let sub = normalizeSubscription(dataItem);
        setViewingSubscription(sub);
        setShowSubscriptionModal(true);
    }

    const Commands = CommandCell({
        edit: edit,
        editField: "inEdit",

    });

    function normalizeSubscription(subscriptionToNormalize) {
        let subscription = {};
        subscription = Object.assign(subscription, subscriptionToNormalize);

        let typeValues = {
            'normal': { value: 'normal', 'text': intl.formatMessage({ id: 'app.bills.subscriptionGrid.normal', defaultMessage: 'Normal' }) },
            'organization': { value: 'organization', 'text': intl.formatMessage({ id: 'app.bills.subscriptionGrid.organization', defaultMessage: '' }) },

        }
        let serviceValues = {
            'device': { value: 'device', text: intl.formatMessage({ id: 'app.bills.subscriptionGrid.device', defaultMessage: 'Device' }) },
            'marker': { value: 'marker', text: intl.formatMessage({ id: 'app.bills.subscriptionGrid.marker', defaultMessage: 'Marker' }) },
            'forecast': { value: 'forecast', text: intl.formatMessage({ id: 'app.bills.subscriptionGrid.forecast', defaultMessage: 'Forecast' }) },
            'whatsapp': { value: 'whatsapp', text: intl.formatMessage({ id: 'app.bills.subscriptionGrid.whatsappAlerts', defaultMessage: 'Whatsapp Alerts' }) },
            'sms': { value: 'sms', text: intl.formatMessage({ id: 'app.bills.subscriptionGrid.smsAlerts', defaultMessage: 'SMS Alert' }) },
        };

        subscription._type = typeValues[subscription._type];
        subscription.service = serviceValues[subscription.service];

        return subscription
    }

    function TypeCell(props) {
        return <td style={{ textTransform: 'capitalize' }}>
            {props.dataItem._type}
        </td>

    }
    function ServiceCell(props) {
        return <td style={{ textTransform: 'uppercase' }}>
            {props.dataItem.service}
        </td>

    }

    function RateCell({ dataItem }) {
        return <td>${dataItem.rate}</td>
    }

    return (
        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            <SubscriptionModal
                subscriptions={subscriptions}
                users={props.users}
                show={showSubscriptionModal}
                viewingSubscription={viewingSubscription}
                setSubscriptions={setSubscriptions}
                onClose={() => setShowSubscriptionModal(false)} />
            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid'>
                    <div className="mdl-cell mdl-cel--6-col">
                        {intl.formatMessage({ id: 'app.bills.subscriptionGrid.subscriptions', defaultMessage: 'SUBSCRIPTIONS' })}
                    </div>
                    <div className="mdl-cell mdl-cell-6-col"></div>

                    <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone subscription-grid-ctn'>
                        <KGrid
                            data={filterBy(orderBy(subscriptions, sort), filter)}
                            sort={sort}
                            onSortChange={e => setSort(e.sort)}
                            filterable
                            filter={filter}
                            editField="inEdit"
                            onFilterChange={(e) => {
                                setFilter(e.filter);
                            }}
                        >
                            <GridNoRecords>
                                {/* Use graph grid no data message */}
                                {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                            </GridNoRecords>
                            <GridToolbar >
                                <button
                                    className="mdl-button mdl-js-button mdl-js-ripple-effect"
                                    onClick={() => setShowSubscriptionModal(true)}>
                                    {intl.formatMessage({ id: 'app.bills.subscriptionGrid.addSubscription', defaultMessage: 'Add Subscription' })}
                                </button>
                            </GridToolbar>
                            <Column field="name" title={intl.formatMessage({ id: 'app.bills.subscriptionGrid.name', defaultMessage: 'Name' })} />
                            <Column field="_type" title={intl.formatMessage({ id: 'app.bills.subscriptionGrid.type', defaultMessage: 'Type' })} cell={TypeCell} />
                            <Column field="description" title={intl.formatMessage({ id: 'app.bills.subscriptionGrid.description', defaultMessage: 'Description' })} />
                            <Column field="service" title={intl.formatMessage({ id: 'app.bills.subscriptionGrid.service', defaultMessage: 'Service' })} cell={ServiceCell} />
                            <Column field="rate" title={intl.formatMessage({ id: 'app.bills.subscriptionGrid.rate', defaultMessage: 'Rate' })} cell={RateCell} />
                            <Column cell={Commands} filterable={false} sortable={false} />


                        </KGrid>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default SubscriptionsGrid

