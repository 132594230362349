import React, { useEffect, useState } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { BASEURI, getAuthToken } from '../../../utils';
import Modal from '../../../general/Modal';
import loadingIcon from '../../../loading.svg';
import { useIntl } from 'react-intl';

function ForecastConfig(props) {
    const [frostThreshold, setFrostThreshold] = useState({ frost_threshold: 0 });
    const [loading, setLoading] = useState(false);
    const intl = useIntl();

    function handleInputChange(e) {
        setFrostThreshold({ ...frostThreshold, frost_threshold: e.target.value });
    }

    useEffect(() => {
        let url = BASEURI + '/api/meteoblue/charts/frost_threshold/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoading(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((threshold) => {
                        setFrostThreshold(threshold);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }, [])

    function saveConfigurations() {
        setLoading(true);
        let url = BASEURI + '/api/meteoblue/charts/frost_threshold/',
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(frostThreshold)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoading(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((threshold) => {
                        setFrostThreshold(threshold)
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error('Error:', error.code);
                    });
            });
    }

    return (
        <Modal>
            <Dialog title={intl.formatMessage({ id: 'app.admin.forecast.configurations', defaultMessage: 'CONFIGURATIONS' })} onClose={props.onClose} height="600px">

                <div className="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                    <Input
                        style={{ width: "100%" }}
                        value={frostThreshold.frost_threshold}
                        name="frost_threshold" onChange={handleInputChange}
                        label={intl.formatMessage({ id: 'app.admin.forecast.frostThreshold', defaultMessage: 'Frost Threshold' })} />
                </div>

                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={saveConfigurations}>{loading ?
                        <img style={{ width: '24px' }} src={loadingIcon} alt='loading' /> :
                        intl.formatMessage({ id: 'app.admin.forecast.save', defaultMessage: 'Save' })}
                    </button>
                    <button onClick={props.onClose} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" >
                        {intl.formatMessage({ id: 'app.admin.forecast.close', defaultMessage: 'Close' })}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    )
}

export default ForecastConfig;