import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import loadingIcon from '../loading.svg';
import Modal from '../general/Modal';
import { Input } from '@progress/kendo-react-inputs';
import { BASEURI, getAuthToken } from '../utils';


export default function ManualRefrenceModal(props) {
    const [loading, setLoading] = useState(false);
    const [reference, setReference] = useState("");


    function markAsPaid() {
        setLoading(true)
        let url = BASEURI + `/api/billing/bill/${props.bill.id}/issue/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ action: 'markAsPaid', 'reference': reference })
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({
                        loggedin: false
                    });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((bill) => {
                        setLoading(false);
                        // props.setBill(bill);
                        props.onClose()
                        setReference("")
                    });
            })
            .catch(error => {
                console.error('Error:', error.code);
                this.setState({ loadingMarkers: false });
            });
    }


    return props.show && <Modal>
        <Dialog closeIcon onClose={props.onClose} height={150} width={300}>
            <div className='modal-form-inputs-wrap'>
                <div className="modal-form-inputs-wrap-row">
                    <Input
                        name='reference'
                        value={reference}
                        required={true}
                        onChange={(e) => setReference(e.value)}
                        label="PAYMENT REFERENCE"
                    />
                </div>

            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>Close</button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={markAsPaid}>{
                    loading ? <img src={loadingIcon} alt="loading" width={25} height={25} /> : "APPLY"}
                </button>
            </DialogActionsBar>

        </Dialog>
    </Modal>;
}
