import React from 'react';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import addExporting from 'highcharts/modules/export-data';
import Annotations from 'highcharts/modules/annotations';
import loading from '../loading.svg';
import { formatDate, BASEURI, getAuthToken } from '../utils';
import './irrigation/tipbox.css';
import getIrrigationTipText from './irrigation/irrigationTip';
import { getAvgPAW } from '../admin/markers';
import { toZonedTime } from 'date-fns-tz';

class Chart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
    this.createRange = this.createRange.bind(this);
    this.downloadJson = this.downloadJson.bind(this);
    this.downloadXML = this.downloadXML.bind(this);
    this.addEventToAnno = this.addEventToAnno.bind(this);
    // this.handleResize = this.handleResize.bind(this);
    this.getYAxisLabelsPositionX = this.getYAxisLabelsPositionX.bind(this);
    this.getYAxisTitleOffset = this.getYAxisTitleOffset.bind(this);
    this.getYAxisTitleText = this.getYAxisTitleText.bind(this);
    this.getLegendText = this.getLegendText.bind(this);
    this.getGraphTotalValue = this.getGraphTotalValue.bind(this);
    this.state = {
      labelOptions: {
        backgroundColor: 'white',
      },
      graphsLoaded: true,
      viewportWidth: window.innerWidth
    }
    this.BREAKPOINT = 480;
  }

  // handleResize(){
  //   this.setState({viewportWidth: window.innerWidth});
  // }

  componentDidMount() {
    this.createChart(this.props.graphs);
  }

  componentDidUpdate() {
    this.createChart(this.props.graphs);
    if (!this.props.isForecast & !this.props.graphs.find((graph) => graph.calculation === 'Forecast')) {
      this.chart.addAnnotation({ labels: this.props.annotations, labelOptions: this.state.labelOptions });
      if (this.props.annotations.length !== 0) {
        // Check if annotations is available
        // sometimes is an empty list
        this.addEventToAnno();
      }
    }
  }

  // componentWillUnmount(){
  //   window.removeEventListener('resize', this.handleResize);
  // }

  addEventToAnno() {
    let anoButton = document.getElementsByClassName('annotation-del');
    for (let i in anoButton) {
      if (typeof (anoButton[i]) === 'object') {

        anoButton[i].onclick = (e) => {
          this.props.setLoading(true);
          const id = e.target.id;
          let url = `${BASEURI}/api/chart/annotation/${id}/`,
            options = {
              method: 'DELETE',
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },

            };
          getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

              //no token exists or all tokens are expired
              if (token === false) {
                return false;
              }

              options.headers.Authorization = `Bearer  ${token}`;


              fetch(url, options)
                .then((response) => {
                  let labels = [...this.props.annotations];
                  const label = labels.find((item) => item.id == id)
                  labels.splice(labels.indexOf(label), 1);
                  this.props.setAnnotations(labels);
                  this.props.setLoading(false);
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            });
        }
      }
    }
  }


  createRange(axis, _from, to, color, text) {

    let range = axis.axisRanges.create();
    range.value = _from;
    range.endValue = to;
    range.axisFill.fill = color;
    range.axisFill.fillOpacity = 0.5;
    range.label.inside = true;
    range.label.align = 'left';
    range.label.text = text;
    // range.label.fill = am4core.color('#000000');
    range.label.fillOpacity = 0.5;
  }

  getYAxisLabelsPositionX(yAxesLength) {
    return this.state.viewportWidth > this.BREAKPOINT
      ? null
      : yAxesLength % 2 == 0 ? -5 : 5;
  }

  getYAxisTitleOffset() {
    return this.state.viewportWidth > this.BREAKPOINT
      ? null
      : 5;
  }

  getGraphTotalValue(graph) {
    let totalValue = 0;
    const initial_value = 0;

    if (!graph?.data?.length)
      return null

    try {
      // if (['sum', 'avg'].indexOf(graph.resample_fn) !== -1) {
      //   totalValue = graph.data?.reduce((accumulator, currentValue) => accumulator + currentValue[1], initial_value)

      //   if (graph.resample_fn === 'avg')
      //     return totalValue / graph.data?.length

      //   return totalValue
      // }

      if (graph.resample) {
        // Sum for every resmaple function
        totalValue = graph.data?.reduce((accumulator, currentValue) => accumulator + currentValue[1], initial_value)
        return totalValue
      }

      // if (graph.resample_fn === 'cumulative_sum' && graph.data[graph.data.length - 1][1]) {
      //   return graph.data[graph.data.length - 1][1]
      // }

      // if (graph.resample_fn === 'min') {

      //   return Math.min(...graph.data.map(value => value[1]))
      // }
      // if (graph.resample_fn === 'max') {
      //   return Math.max(...graph.data.map(value => value[1]))
      // }
    } catch {
      console.log(`Could not calculate graph label value, graph ID: ${graph.id}`)
    }

    return null
  }

  getYAxisTitleText(text) {
    return this.state.viewportWidth > this.BREAKPOINT ? text : null;
  }

  getLegendText(graph) {
    return this.state.viewportWidth > this.BREAKPOINT ?
      graph.label :
      graph.label + ` (${graph.y_axis_legend})`;
  }

  createChart(graphs) {
    let annotations = [];
    if (this.props.loading && this.chart) {
      this.chart.showLoading();
      return;
    }

    Annotations(Highcharts);
    Exporting(Highcharts);
    addExporting(Highcharts);
    Highcharts.setOptions({
      global: {
        useUTC: true
      },
      lang: {
        loading: `<img src="${loading}" style="width:40px;">`
      }
    });

    let series = [], yAxes = [], pawBandsAdded = false, axisExists = false, axisIndex, pawBands = [
      {
        from: 1,
        to: 30,
        color: 'rgba(255,179,186,.3)',
        label: {
          text: this.props.intl.formatMessage({ id: 'app.admin.map.severeStress', defaultMessage: 'Severe stress' }),
          style: {
            color: 'rgba(128,128,128, .8)'
          }
        }
      },
      {
        from: 30,
        to: 70,
        color: 'rgba(255,255,186,.3)',
        label: {
          // text: this.props.intl.formatMessage({ id: 'app.admin.map.startStress', defaultMessage: 'Start stress' }),
          style: {
            color: 'rgba(128,128,128, .8)'
          },
          y: +10
        }
      },
      {
        from: 70,
        to: 100,
        color: 'rgba(186,255,201,.3)',
        label: {
          text: this.props.intl.formatMessage({ id: 'app.admin.map.optimal', defaultMessage: 'Optimal' }),
          style: {
            color: 'rgba(128,128,128, .8)'
          }
        }
      }, {
        from: 100,
        to: 200,
        color: 'rgba(110,192,255,.3)',
        label: {
          text: this.props.intl.formatMessage({ id: 'app.admin.map.excessOfWater', defaultMessage: 'Excess of water' }),
          style: {
            color: 'rgba(128,128,128, .8)'
          }
        }
      }
    ],
      pawLines = [{
        dashStyle: 'dash',
        value: 50,
        label: {
          text: this.props.intl.formatMessage({ id: 'app.chart.maxAeration', defaultMessage: 'Max aeration' }),
          padding: '20px',
          style: {
            color: 'rgba(128,128,128, .8)',
          },
          align: 'left',
          // x: -10
        },
        colorAxis: axisIndex
      }],
      // tickPositions = [0, 30, 50, 70, 100, 120, 160, 200];
      tickPositions = [0, 30, 50, 70, 100, 140];


    // let setXAxisTickPositions = false, xAxisTickPositions = [];

    for (let i in graphs) {

      // check if chart contains a 15D or 7D resampled graph
      // if (['15D','7D'].indexOf(graphs[i].resample) !== -1)
      // setXAxisTickPositions = true;
      // else
      // setXAxisTickPositions = false;

      let axis_min = null;
      let axis_max = null;
      if (graphs[i].paw_guides & ['paw', 'PAW'].indexOf(graphs[i].calculation) !== -1) {
        axis_min = graphs[i].axis_min ? graphs[i].axis_min : 0
        axis_max = graphs[i].axis_max ? graphs[i].axis_max : 200
      }
      else {
        axis_min = graphs[i].axis_min
        axis_max = graphs[i].axis_max
      }

      axisExists = false;

      if (['raw', 'Raw'].indexOf(graphs[i].calculation) === -1) {

        // use an existing axis because of shared graph.y_axis_legend
        for (let a in yAxes) {
          if (yAxes[a].id === graphs[i].y_axis_legend) {
            axisExists = true;
            axisIndex = parseInt(a);
            // Add paw guides to the shared axis if none exists
            if (graphs[i].paw_guides & !pawBandsAdded) {
              pawBandsAdded = true
              yAxes[a].plotBands = pawBands;
              yAxes[a].plotLines = pawLines;
              yAxes[a].tickPositions = tickPositions;
            }

            if (yAxes[a].min === undefined || yAxes[a].min === null)
              yAxes[a].min = axis_min;

            if (yAxes[a].max === undefined || yAxes[a].max === null)
              yAxes[a].max = axis_max;
            break;
          }
        }

        // paw graphs are rendered in black by default
        let graphColor = '#000';
        if (['paw', 'PAW'].indexOf(graphs[i].calculation) === -1)
          graphColor = graphs[i].color;


        for (let a in yAxes) {
          // y_axis_legend is set remove existing legend
          if (yAxes[a].id === graphs[i].calculation && graphs[i].y_axis_legend) {
            axisIndex = parseInt(a);
            let newAxis = {
              id: graphs[i].y_axis_legend,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].y_axis_legend),
                // text: graphs[i].y_axis_legend,
                style: {
                  color: graphColor
                }
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphColor
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0,
            };

            // add paw guides 
            if (graphs[i].paw_guides & !pawBandsAdded) {
              pawBandsAdded = true
              newAxis.plotBands = pawBands;
              newAxis.plotLines = pawLines;
              newAxis.tickPositions = tickPositions;
            }

            if (!axisExists) {
              yAxes.splice(axisIndex, 1, newAxis);
              axisExists = true;
            }
            break;
          } else if (yAxes[a].id === graphs[i].calculation) {
            //y_axis_legend is not set keep existing legend
            axisExists = true;
            break;
          }
        }

        // create new axis because none exists that has the same
        // graph[i].y_axis_legend
        if (!axisExists) {
          let newAxis;
          if (graphs[i].y_axis_legend)
            newAxis = {
              id: graphs[i].y_axis_legend,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].y_axis_legend),
                style: {
                  color: graphColor
                }
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphColor
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0,
              // gridLineWidth: 0
            };
          else
            newAxis = {
              id: graphs[i].calculation,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].label),
                style: {
                  color: graphColor
                }
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphColor
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0,
              // gridLineWidth: 0
            };

          // add paw guides to the new axis
          if (graphs[i].paw_guides & !pawBandsAdded) {
            pawBandsAdded = true
            newAxis.plotBands = pawBands;
            newAxis.plotLines = pawLines;
            newAxis.tickPositions = tickPositions;
          }

          yAxes.push(newAxis);
        }

      } else {

        for (let a in yAxes) {
          if (yAxes[a].id === graphs[i].y_axis_legend) {
            axisExists = true;
            axisIndex = parseInt(a);
            break;
          }
        }

        for (let a in yAxes) {
          if (yAxes[a].id === graphs[i].extract[0]?.extract_id && graphs[i].y_axis_legend) {
            //y_axis_legend is set remove existing legend
            axisIndex = parseInt(a);
            let newAxis = {
              id: graphs[i].y_axis_legend,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].y_axis_legend),
                style: {
                  color: graphs[i].color
                }
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphs[i].color
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0,
            };
            if (!axisExists) {
              yAxes.splice(axisIndex, 1, newAxis);
              axisExists = true;
            }
            break;
          } else if (yAxes[a].id === graphs[i].extract[0]?.extract_id) {
            //y_axis_legend is not set keep existing axis
            axisExists = true;
            break;
          }
        }

        if (!axisExists) {
          if (graphs[i].y_axis_legend)
            yAxes.push({
              id: graphs[i].y_axis_legend,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].y_axis_legend),
                style: {
                  color: graphs[i].color
                }
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphs[i].color
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0,
            });
          else
            yAxes.push({
              id: graphs[i].extract[0]?.extract_id,
              title: {
                offset: this.getYAxisTitleOffset(),
                text: this.getYAxisTitleText(graphs[i].extract[0].description),
                color: graphs[i].color
              },
              labels: {
                x: this.getYAxisLabelsPositionX(yAxes.length),
                style: {
                  color: graphs[i].color
                }
              },
              min: axis_min,
              max: axis_max,
              opposite: yAxes.length % 2 !== 0
            });
        }

      }

      // let data = graphs[i].data.map(d => ([new Date(d[0]).getTime(), d[1]]));

      // prevent form infinit loop and render new added annotation
      if (!this.props.isForecast && this.state.graphsLoaded) {
        var an = graphs[i].annotations.map((item) => {
          item.text = `<span><button class="annotation-del" id=${item.id}>X</button>${item.text}</span>`;
          item.useHTML = true;
          item.point.yAxis = axisExists ? axisIndex : yAxes.length - 1;
          item.point.xAxis = 0;
          return item;
        });

        annotations.push(...an);

        if (graphs.length === parseInt(i) + 1) {
          this.props.setAnnotations(annotations);
          this.setState({ graphsLoaded: false });
        }

      }

      series.push({
        intl: this.props.intl,
        totalValue: this.getGraphTotalValue(graphs[i]),
        id: graphs[i].id,
        events: {
          click: (e) => {
            if (!this.props.isForecast) {
              const index = this.chart.get(graphs[i].id).index
              this.props.renderTextBox(e, graphs[i].id, index);
            }
          }
        },
        marker: {
          enabled: undefined,
          radius: 4,
        },
        dataGrouping: {
          groupPixelWidth: 0.5,
          approximation: "average",
          enabled: true,
        },
        yAxis: axisExists ? axisIndex : yAxes.length - 1,
        type: graphs[i]._type === 'line' ? 'line' : 'column',
        name: this.getLegendText(graphs[i]),
        data: graphs[i].data,
        color: graphs[i].color,
        dataLabels: {
          enabled: ['Custom Formula', 'custom', "ETc"].indexOf(graphs[i].calculation) !== -1 && graphs[i].data_labels,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 2);
          }
        }
      });
    }

    // if (setXAxisTickPositions){
    //   for (let i in graphs)
    //   for (let j in graphs[i].data)
    //   xAxisTickPositions.push(graphs[i].data[j][0])
    // }

    // Add irrigation prescription label
    let labels = [],
      labelTemplate = {
        point: {
          x: 0,
          y: 0
        },
        backgroundColor: null,
        borderWidth: 0,
        style: {
          color: 'black',
          borderWidth: 0
        },
        useHTML: true,
        allowOverlap: true
      };
    if (this.props.prescription) {
      labelTemplate.text = getIrrigationTipText(
        this.props.prescription,
        this.props.intl,
        this.props.locale,
        getAvgPAW(graphs.filter(g => ['paw', 'PAW'].indexOf(g.calculation) !== -1)))

      labels.push(labelTemplate);
    }

    this.chart = Highcharts.stockChart(this.props.chartId, {
      plotOptions: {
        line: {
          keys: ['x', 'y', 'marker']
        }
      },
      chart: {
        events: {
          load: () => {
            if (!this.props.isForecast) {
              if (this.props.annotations.length !== 0) {
                // Check if annotations is available
                // sometimes is an empty list
                this.addEventToAnno();
              }
            }
          }
        },
        zoomType: 'x',
        style: {
          fontFamily: 'inherit'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        useHTML: true,
        text: this.props.title,
        align: 'left',
        margin: 0,
        x: 30
      },
      xAxis: {
        type: 'datetime',
        ordinal: false,
        // tickInterval: 3600*24*15*1000
        // tickPositioner: function (){
        //   if (this.tickPositions.info.unitRange >= 24*60*60*1000){ // >= one day
        //     for (let i in this.tickPositions){
        //       if(i !== 'info')
        //       //shift tick positions forward by 12 hours
        //       this.tickPositions[i] = this.tickPositions[i] + 12*60*60*1000;
        //     }
        //     return this.tickPositions;
        //   }
        //   return this.tickPositions;
        // },
        // Set tick positions manually
        // tickPositions: setXAxisTickPositions ? xAxisTickPositions : null,
        // labels:{
        //   formatter: function (){
        //     // When tick positions are set manually for a datetime axis
        //     // Highcharts does not format them and instead renders milliseconds
        //     // on the date axis. 
        //     // This custom formatter formats dates when ticks positions are set manually
        //     // otherwise uses the default label formatter.
        //     if (setXAxisTickPositions)
        //     return Highcharts.dateFormat('%e. %b', this.value);
        //     else
        //     return this.axis.defaultLabelFormatter.call(this);
        //   }
        // }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      yAxis: yAxes,
      series: series,
      legend: {
        enabled: true,
        useHTML: true,
        labelFormatter: function () {
          return `
            <span '>
              <span>${this.name}</span>
              ${this.options.totalValue !== null ?
              `<span class="font-normal">(${this.options.intl.formatNumber(this.options.totalValue)})</span>` :
              ''
            }
            </span>`
        },
        verticalAlign: 'top',
      },
      tooltip: {
        crosshairs: [false, false],
        shared: true,
        valueDecimals: 1,
        split: false,
        useHTML: true
      },
      annotations: [{
        labels: labels
      }],
      loading: {
        labelStyle: {
          backgroundColor: 'rgba(255,255,255,0.5)',
          opacity: 1,
          position: 'relative',
          display: 'inline-block',
          // width: '136px',
          // height: '100px',
          top: '45%'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'separator',
              {
                text: 'Download CSV',
                onclick: () => { this.downloadCSV(this.props.chartId, this.props.name) },
                separator: false
              },
              {
                text: 'Download XLSX',
                onclick: () => { this.downloadXLS(this.props.chartId, this.props.name) },
                separator: false
              },
              {
                text: 'Download JSON',
                onclick: () => { this.downloadJson(this.props.chartId) },
                separator: false
              },
              {
                text: 'Download XML',
                onclick: () => { this.downloadXML(this.props.chartId) },
                separator: false
              }

            ]
          }
        }
      },
      navigation: {
        menuItemHoverStyle: {
          background: '#49AC7A'
        }
      }
    });
  }

  downloadCSV(id, chartName) {
    // toggle download notification
    if (!this.props.isForecast)
      this.props.onToggle(true);

    let url = `${BASEURI}/api/download/chart-data/csv/?chart_id=${id}`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;

        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            return response.blob()
          })
          .then((response) => {
            if (!this.props.isForecast)
              this.props.onToggle(false);
            var element = document.createElement('a');
            element.href = URL.createObjectURL(response);
            element.download = `${chartName}.csv`;
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();

          })
          .catch(error => {
            console.error('Error:', error);
            if (!this.props.isForecast)
              this.props.onToggle(false);
          });
      });
  }


  downloadXLS(id, chartName) {
    // toggle download notification
    if (!this.props.isForecast)
      this.props.onToggle(true);


    let url = `${BASEURI}/api/download/chart-data/?chart_id=${id}`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;

        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            // return response.json();
            return response.blob()
          })
          .then((response) => {
            if (!this.props.isForecast)
              this.props.onToggle(false);
            var element = document.createElement('a');
            element.href = URL.createObjectURL(response);
            element.download = `${chartName}.xlsx`;
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();

          })
          .catch(error => {
            console.error('Error:', error);
            if (!this.props.isForecast)
              this.props.onToggle(false)
          });
      });
  }


  downloadJson(id) {
    const chartData = this.chart.series.map((item) => {
      return ({
        chartId: id,
        name: item.userOptions.name,
        data: item.userOptions.data.map((pointData) => {
          return ([formatDate(toZonedTime(pointData[0], 'Etc/UTC')), pointData[1]]);
        })
      });
    });
    const chartDataJSON = JSON.stringify(chartData, null, 1);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(chartDataJSON));
    element.setAttribute('download', 'chart-data.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  downloadXML(id) {
    const data = this.chart.series;
    let dataItemList = [];
    let dataItem = '';
    for (let i in data) {
      for (let j in data[i].userOptions.data) {
        dataItem +=
          `<data date="${formatDate(toZonedTime(data[i].userOptions.data[j][0], 'Etc/UTC'), data[i].userOptions?.timezone)}">${data[i].userOptions.data[j][1]}</data>\n`
      };
      dataItemList.push(dataItem);
    };
    let seriesItem = '';
    for (let i in data) {
      seriesItem +=
        `
      <chartId>${id}</chartId>
      <name>${data[i].userOptions.name}</name>
      ${dataItemList[i]}
      \n`;
    }

    const chartDataXML = `<?xml version='1.0' encoding='utf-8'?>
      <chart>
      ${seriesItem}
      </chart>`;

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(chartDataXML));
    element.setAttribute('download', 'chart-data.xml');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  render() {
    return (
      <div id={this.props.chartId} style={{ width: "100%", height: this.props.isForecast ? '450px' : '100%' }}>
      </div>
    );
  }

}

export default Chart;