import React from 'react';
import { formatDate, getAuthToken, BASEURI } from '../utils';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import Modal from '../general/Modal';
import ModalForm from './ChartDialog';
import Chart from './Chart';
import MoreMenu from './MoreMenu';
import QuickDateMenu from './QuickDateMenu';
import ChartTable, { TableViewSwitch } from './ChartTable';
import loadingIcon from '../loading_white.svg';
import ResampleDropdown from './ResampleDropdown';
import resampleIcon from '../images/resample_icon.png';
import AnnotationTextbox from './AnnotationTextBox';
import { toZonedTime } from 'date-fns-tz';

function areGraphsEqual(a, b) {
  // Compare graph.data lengths
  if (a.data.length !== b.data.length)
    return false;

  // Compare graph.data first element's time
  if (a.data.length > 0 && b.data.length > 0 && a.data[0][0] !== b.data[0][0])
    return false;

  // Compare graph.data last element's time
  if (a.data.length > 0 && b.data.length > 0 && a.data[a.data.length - 1][0] !== b.data[a.data.length - 1][0])
    return false;

  // Assume graphs have the same data if all of the above are not true.
  return true;
}

class ChartContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allowAnnotation: false,
      annotations: [],
      showAnnotationTextBox: false,
      newAnnotation: {
        graph: null,
        date: null,
        annotation: {
          point: {
            x: null,
            y: null,
          },
          text: '',
        }
      },
      showResampleDropdown: false,
      downloading: false,
      success: false,
      errors: {},
      savingGraph: false,
      title: '',
      graphs: [],
      editing: false,
      sensors: [],
      data: [],
      chart: null,
      shouldUpdate: false,
      loading: false,
      changing: null,
      showChartTable: false,
    };

    this.createData = this.createData.bind(this);
    this.openEditingModal = this.openEditingModal.bind(this);
    this.closeEditingModal = this.closeEditingModal.bind(this);
    this.updateChart = this.updateChart.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.addGraph = this.addGraph.bind(this);
    this.removeGraph = this.removeGraph.bind(this);
    this.normalizeEToGraphVariables = this.normalizeEToGraphVariables.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.toggleResampleDropdown = this.toggleResampleDropdown.bind(this);
    this.updateGraphs = this.updateGraphs.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.handleAnnotationChange = this.handleAnnotationChange.bind(this);
    this.renderTextBox = this.renderTextBox.bind(this);
    this.saveAnnotation = this.saveAnnotation.bind(this);
    this.setAnnotations = this.setAnnotations.bind(this);
    this.toggleAnnotationTextBox = this.toggleAnnotationTextBox.bind(this);
    this.handleAnnotationAllow = this.handleAnnotationAllow.bind(this);
  }

  // normalizeEToGraphVariables(graphVariables){
  //   console.log(graphVariables);
  //   //check if variables is already normalized
  //   //do not normalize again if so
  //   if (graphVariables.temp.sensor.hasOwnProperty('id')){
  //     //graph varialbles are already normalized
  //     return graphVariables;    
  //   }


  //   let variables = {
  //     temp: {
  //       sensor: [], 
  //       extract: ''
  //     },
  //     radiation: {
  //       sensor: [], 
  //       extract: ''
  //     },
  //     wind: {
  //       sensor: [], 
  //       extract: ''
  //     },
  //     humidity: {
  //       sensor: [], 
  //       extract: ''
  //     }
  //   };
  //   for (let prop in graphVariables){
  //     if(['temp', 'radiation', 'wind', 'humidity'].indexOf(prop) === -1)
  //       continue;
  //     if (graphVariables[prop].hasOwnProperty('sensor')){
  //       let sensorId = graphVariables[prop].sensor, sensorObj;
  //       for (let i in this.props.sensors){
  //         if (this.props.sensors[i].id === sensorId){
  //           console.log('sensor normalized');
  //           sensorObj = this.props.sensors[i];
  //           variables[prop].sensor.push(sensorObj);
  //         }
  //       }

  //       if(sensorObj !== undefined){

  //         for (let i in sensorObj.extract){
  //           if (sensorObj.extract[i].id === graphVariables[prop].extract)
  //             variables[prop].extract = sensorObj.extract[i];
  //         }

  //       }


  //     }
  //   }

  //   for (let prop in variables){
  //     graphVariables[prop] = variables[prop];
  //   }
  //   console.log(graphVariables);
  //   return graphVariables;
  // }

  toggleAnnotationTextBox() {
    this.setState((prevState) => { return { showAnnotationTextBox: !prevState.showAnnotationTextBox } })
  }

  setAnnotations(annotations) {
    this.setState({ annotations: annotations })
  }

  handleAnnotationChange(e) {
    this.setState({
      newAnnotation: {
        ...this.state.newAnnotation,
        annotation: {
          ...this.state.newAnnotation.annotation,
          text: e.target.value
        }
      }
    })
  }

  renderTextBox(e, graphId, index) {
    if (!this.state.allowAnnotation)
      return

    this.setState({
      showAnnotationTextBox: true,
      newAnnotation: {
        ...this.state.newAnnotation,
        graph: graphId,
        date: new Date(e.point.x),
        annotation: {
          ...this.state.newAnnotation.annotation,
          point: {
            ...this.state.newAnnotation.annotation.point,
            x: e.point.x,
            y: e.point.y,
            yAxis: index
          }

        }
      }
    })
  }

  saveAnnotation() {
    this.setState({ loading: true, showAnnotationTextBox: false, });
    let url = `${BASEURI}/api/chart/annotation/`,
      options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(this.state.newAnnotation)
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;


        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText, response.status);
            }
            return response.json();
          })
          .then((label) => {
            label.text = `<button class="annotation-del" id=${label.id}>X</button>${label.text}`;
            label.useHTML = true;
            label.point.xAxis = 0;
            this.setState((prevState) => {
              return {
                loading: false,
                newAnnotation: {
                  ...prevState.newAnnotation,
                  annotation: {
                    ...prevState.newAnnotation.annotation,
                    text: ''
                  }
                }, annotations: [label, ...prevState.annotations]
              }
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });
  }

  normalizeEToGraphVariables(graphVariables) {

    //check if variables is already normalized
    //do not normalize again if so
    if (graphVariables?.temp?.extract?.hasOwnProperty('id'))
      //graph varialbles are already normalized
      return graphVariables;


    let variables = {
      temp: {
        sensor: [],
        extract: ''
      },
      radiation: {
        sensor: [],
        extract: ''
      },
      wind: {
        sensor: [],
        extract: ''
      },
      humidity: {
        sensor: [],
        extract: ''
      },
      ec: {
        sensor: [],
        extract: ''
      },
      vwc: {
        sensor: [],
        extract: ''
      },
      permitivity: {
        sensor: [],
        extract: ''
      }
    };

    for (let prop in graphVariables) {
      if (['temp', 'radiation', 'wind', 'humidity', 'ec', 'vwc', 'permitivity'].indexOf(prop) === -1)
        continue;
      if (graphVariables[prop].hasOwnProperty('sensor')) {
        let sensorId = graphVariables[prop].sensor, sensorObj;
        for (let i in this.props.sensors) {
          if (this.props.sensors[i].id === sensorId) {
            sensorObj = this.props.sensors[i];
            variables[prop].sensor.push(sensorObj);
          }
        }

        if (sensorObj !== undefined) {

          for (let i in sensorObj.extract) {
            if (sensorObj.extract[i].id === graphVariables[prop].extract)
              variables[prop].extract = sensorObj.extract[i];
          }

        }


      }
    }

    for (let prop in variables) {
      graphVariables[prop] = variables[prop];
    }

    return graphVariables;
  }

  createData(graphs) {

    let data = [];
    for (let i = 0; i < graphs.length; i++) {
      for (let j = 0; j < graphs[i].data.length; j++) {
        if (data[j] === undefined)
          data[j] = {};
        data[j][graphs[i].label] = graphs[i].data[j].value;
        data[j]['date'] = new Date(graphs[i].data[j].date);
      }
    }

    return data;
  }

  componentDidMount() {

    this.setState({
      graphs: this.props.graphs,
      // data: data, 
      shouldUpdate: this.props.isCurrentPage,
      loading: false,
      // dataSets: dataSets,
      // panels: panels
    });

  }

  componentDidUpdate(prevProps) {
    // <Chart/> child component's data comes from this.state.graphs 
    // which is only set once when this component mounts. 
    // this.state.graphs however, is not set/updated otherwise i.e.
    // when props change and <Chart/> is thus not updated. 
    // Here, we set/update state.graphs manually if it changed.
    if (!prevProps.graphs || !this.props.graphs)
      return

    if (prevProps.graphs.length !== this.props.graphs.length)
      this.setState({ graphs: this.props.graphs })

    for (let i = 0; i < prevProps.graphs.length; i++)
      if (!areGraphsEqual(prevProps.graphs[i], this.props.graphs[i])) {
        this.setState({ graphs: this.props.graphs })
        break;
      }
  }

  openEditingModal() {
    if (!this.props.editWidgets) {
      window.alert(this.props.intl.formatMessage({ id: 'app.chart.permissionAlert', defaultMessage: 'You do not have permission to edit charts!' }))
      return
    }
    this.setState({
      editing: true,
    });
  }

  closeEditingModal() {
    this.setState({
      editing: false,
    });
  }

  updateChart(data) {

    if (this.state.editing)
      this.closeEditingModal();
  }

  setLoading(value) {
    this.setState({ loading: value })
  }

  changeDate(range) {
    let options;
    this.setState({ loading: true });
    let url = `${BASEURI}/api/chart/${this.props.chartId}/`;
    if (range.start !== undefined) {
      options = {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          min_date: range.start,
          max_date: range.end !== null ? range.end : range.start
        })
      };
    } else {
      options = {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          range: range
        })
      };
    };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((chart) => {
            // let data = this.createData(chart.graphs);
            // let data = this.createData(myGraph);
            this.setState({
              graphs: chart.graphs,
              // graphs: myGraph,
              // data: data,
              loading: false
            });
          })
          .catch(error => console.error('Error:', error));

      });

  }

  addGraph(graph) {
    //renders loading indicator on graph chip
    this.setState({ changing: graph.id, savingGraph: true });

    let newChangedGraph = JSON.parse(JSON.stringify(graph));
    if (graph.calculation === 'ETo' || graph.calculation === 'Dew Point' ||
      graph.calculation === 'Saturation ex EC' || graph.calculation === 'VPD' || (graph.calculation === 'ETc' && !graph.variables['preCalculatedEto'])) {
      newChangedGraph.extract = [newChangedGraph.variables.temp.extract.id];
      let sensors = newChangedGraph.variables.temp.sensor.map(sensor => sensor.id);
      newChangedGraph.sensors = sensors;

      newChangedGraph.variables.temp.sensor = graph.variables.temp.sensor[0].id;
      newChangedGraph.variables.temp.extract = graph.variables.temp.extract.id;

      if (graph.calculation === 'ETo' || graph.calculation === 'Dew Point' || graph.calculation === 'VPD' || (graph.calculation === "ETc" && !graph.variables['preCalculatedEto'])) {
        newChangedGraph.variables.humidity.sensor = graph.variables.humidity.sensor[0].id;
        newChangedGraph.variables.humidity.extract = graph.variables.humidity.extract.id;
      }

      if (graph.calculation === 'ETo' || (graph.calculation === 'ETc' && !graph.variables['preCalculatedEto'])) {
        newChangedGraph.variables.radiation.sensor = graph.variables.radiation.sensor[0].id;
        newChangedGraph.variables.radiation.extract = graph.variables.radiation.extract.id;
        newChangedGraph.variables.wind.sensor = graph.variables.wind.sensor[0].id;
        newChangedGraph.variables.wind.extract = graph.variables.wind.extract.id;
      }

      if (graph.calculation === 'Saturation ex EC') {
        newChangedGraph.variables.ec.sensor = graph.variables.ec.sensor[0].id;
        newChangedGraph.variables.ec.extract = graph.variables.ec.extract.id;
        newChangedGraph.variables.vwc.sensor = graph.variables.vwc.sensor[0].id;
        newChangedGraph.variables.vwc.extract = graph.variables.vwc.extract.id;
        newChangedGraph.variables.permitivity.sensor = graph.variables.permitivity.sensor[0].id;
        newChangedGraph.variables.permitivity.extract = graph.variables.permitivity.extract.id;
      }

    }
    else if (graph.calculation === 'Forecast') {
      newChangedGraph.location_marker = graph.location_marker.id;
      newChangedGraph.extract = [];
    }
    else {
      let extract = newChangedGraph.extract.map(item => item.id),
        sensors = newChangedGraph.sensors.map(sensor => sensor.id);

      newChangedGraph.extract = extract;
      newChangedGraph.sensors = sensors;
    }
    if (!graph.variables)
      newChangedGraph.variables = {};

    let isNewGraph = false;
    if (!graph.id) {
      newChangedGraph.chart = this.props.chartId;
      isNewGraph = true;
    }

    let url = isNewGraph
      ? `${BASEURI}/api/graphs/`
      : `${BASEURI}/api/graph/${graph.id}/`,
      options = {
        method: isNewGraph ? 'POST' : 'PUT',
        body: JSON.stringify(newChangedGraph),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            this.setState({ success: response.ok })
            return response.json()
          })


          .then((newGraph) => {

            if (!this.state.success) {
              this.setState({
                errors: newGraph,
                savingGraph: false,
                changing: null
              })
              return
            } else {
              this.setState({ errors: {} })
            }

            let graphList = this.state.graphs.slice();

            if (newGraph.calculation === 'ETo' || newGraph.calculation === 'Dew Point' ||
              newGraph.calculation === 'Saturation ex EC' || (newGraph.calculation === 'ETc' && newGraph.variables.plant)) {
              newGraph.variables = this.normalizeEToGraphVariables(newGraph.variables);
            }

            //find sensor
            let sensors = [];
            if (this.props && this.props.sensors) {
              for (let i in this.props.sensors) {
                if (newGraph.sensors.indexOf(this.props.sensors[i].id) !== -1)
                  sensors.push(this.props.sensors[i]);
              }
            }

            newGraph.sensors = sensors;

            let extract = [];
            for (let i in sensors) {
              for (let j in sensors[i].extract) {
                if (newGraph.extract.indexOf(sensors[i].extract[j].id) !== -1) {
                  extract.push(sensors[i].extract[j])
                }
              }
            }

            newGraph.extract = extract;

            // let graphTypes = {
            //   line: {text:'Line', value:'line'},
            //   column: {text:'Bar', value:'column'}
            // }

            // newGraph._type = graphTypes[newGraph._type];

            let graphIndex;
            if (isNewGraph)
              graphList.push(newGraph);
            else {
              for (let i in graphList)
                if (graphList[i].id === newGraph.id) {
                  graphIndex = i;
                  graphList.splice(graphIndex, 1, newGraph);
                  break;
                }
            }

            this.setState({
              savingGraph: false,
              graphs: graphList,
              changing: null,
            });

          })//.then(() => this.updateChart())
          .catch(error => {
            this.setState({
              savingGraph: false,
              changing: null
            })
            console.error('Error:', error)
          });

      });
  }

  removeGraph(graph) {

    if (!window.confirm('Are you sure you want to delete this graph?'))
      return;


    let url = `${BASEURI}/api/graph/${graph}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(response => {
            let graphList = this.state.graphs.slice();
            let deletedGraph = null;
            for (let i = 0; i < graphList.length; i++) {
              if (graphList[i].id === graph)
                deletedGraph = i;
            }

            graphList.splice(deletedGraph, 1)

            this.setState({ graphs: graphList });
          });

      });

  }

  onToggle(value) {
    this.setState({ downloading: value })
  }


  updateGraphs(graphs_data) {
    const graphs = this.state.graphs.slice();


    this.setState({
      graphs: graphs.map((graph) => {

        if (graphs_data[graph.id]) {
          graph.data = graphs_data[graph.id]
          return graph
        }
        else {
          return graph
        }
      })
    })
  }

  toggleResampleDropdown() {
    this.setState({ showResampleDropdown: !this.state.showResampleDropdown })
  }
  handleAnnotationAllow() {
    this.setState({ allowAnnotation: !this.state.allowAnnotation })
  }

  render() {
    let lastDates, maxChartDate;
    if (this.state.graphs.length > 0) {
      lastDates = this.state.graphs.map((graph) => graph.data.length > 0 ? graph.data[graph.data.length - 1][0] : null);
      if (lastDates.length > 0)
        maxChartDate = lastDates.reduce((p, v) => p > v ? p : v);
    }

    let chartCtnClasses = this.props.maximized ?
      'mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'
      :
      'mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone';

    return (
      <div className={chartCtnClasses}>
        <div id="chart-ctn">
          <div className='chart-card mdl-card mdl-shadow--2dp'>
            <div className='mdl-card__actions mdl-card--border'>

              <div className="mdl-layout-spacer text-slate-700">
                {this.props.name} <span className='text-slate-500' style={{ fontSize: '.8em' }}> &nbsp; {maxChartDate ? this.props.intl.formatDate(toZonedTime(maxChartDate, 'Etc/UTC'), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : ''}</span>
              </div>
              <button
                className='mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect text-slate-700'
                onClick={this.handleAnnotationAllow}
                title='Add Comment'>
                <i className='material-icons text-[21px] mt-[1.5px]'>{this.state.allowAnnotation ? 'comments_disabled' : 'add_comment'}</i>
              </button>
              <button
                className='mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect text-slate-700'
                onClick={(e) => this.props.updateChart(e, this.props.chartId)}
                title='Expand/Shrink'>
                <i className='material-icons'>{this.props.maximized ? 'view_column' : 'view_stream'}</i>
              </button>
              <span className='relative' title={this.props.intl.formatMessage({ id: 'app.chart.resample', defaultMessage: 'Resample' })}>
                <button
                  className='mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect text-slate-700'
                  onClick={this.toggleResampleDropdown}
                  title={this.props.intl.formatMessage({ id: 'app.chart.resample', defaultMessage: 'Resample' })}>
                  <img src={resampleIcon} className='w-[19px] m-[6px]'></img>
                </button>
                {this.state.showResampleDropdown && <ResampleDropdown
                  setLoading={this.setLoading}
                  toggleResampleDropdown={this.toggleResampleDropdown}
                  chartId={this.props.chartId}
                  updateGraphs={this.updateGraphs}
                />}
              </span>
              {
                this.props.editWidgets &&
                <button
                  className='mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect text-slate-700'
                  onClick={this.openEditingModal}
                  title='Edit graphs'>
                  <i className='material-icons'>tune</i>
                </button>
              }
              <Modal>
                {<ModalForm
                  intl={this.props.intl}
                  handleMarkersFilterChange={this.props.handleMarkersFilterChange}
                  loadingWeatherVariables={this.props.loadingWeatherVariables}
                  weatherVariables={this.props.weatherVariables}
                  loadingMarkers={this.props.loadingMarkers}
                  markers={this.props.markers}
                  handleStationChange={this.props.handleStationChange}
                  station={this.props.station}
                  setStations={this.props.setStations}
                  allStations={this.props.allStations}
                  visible={this.state.editing}
                  close={this.closeEditingModal}
                  updateChart={this.updateChart}
                  chartTitle={this.state.title}
                  // graphs={this.state.graphs.length > 0 ? this.state.graphs : this.props.graphs}
                  graphs={this.state.graphs}
                  sensors={this.props.sensors}
                  stations={this.props.stations}
                  chartId={this.props.chartId}
                  addGraph={this.addGraph}
                  removeGraph={this.removeGraph}
                  changing={this.state.changing}
                  savingGraph={this.state.savingGraph}
                  normalizeEToGraphVariables={this.normalizeEToGraphVariables}
                  handleFilterChange={this.props.filter}
                  noData={this.props.noData}
                  loadingSensors={this.props.loadingSensors}
                  errors={this.state.errors}
                />}
              </Modal>
              <QuickDateMenu
                intl={this.props.intl}
                onClick={this.changeDate}
                menuId={'quickdatemenu-' + this.props.chartId}
                handleChange={this.props.handleChange}
              />
              <TableViewSwitch onClick={() => this.setState({ showChartTable: !this.state.showChartTable })} state={!this.state.showChartTable} />
              <MoreMenu
                intl={this.props.intl}
                chartName={this.props.name}
                onClick={this.props.delete}
                chartId={this.props.chartId}
                menuId={'moremenu-' + this.props.chartId}
                editChart={this.props.editChart}
                title='Edit chart'
              />

            </div>
            <div className='mdl-card__title'>
              {this.state.showAnnotationTextBox &&
                <AnnotationTextbox
                  handleAnnotationChange={this.handleAnnotationChange}
                  newAnnotation={this.state.newAnnotation}
                  saveAnnotation={this.saveAnnotation}
                  toggleAnnotationTextBox={this.toggleAnnotationTextBox}
                />}
              {!this.state.showChartTable ?
                <Chart
                  locale={this.props.locale}
                  intl={this.props.intl}
                  graphs={this.state.graphs}
                  prescription={this.props.prescription}
                  key={this.props.chartId}
                  chartId={this.props.chartId}
                  loading={this.state.loading}
                  name={this.props.name}
                  maximized={this.props.maximized}
                  onToggle={this.onToggle}
                  annotations={this.state.annotations}
                  renderTextBox={this.renderTextBox}
                  setAnnotations={this.setAnnotations}
                  setLoading={this.setLoading}
                />
                :
                <ChartTable graphs={this.state.graphs} />
              }
            </div>


            <div className='mdl-card__menu'>

            </div>
          </div>
        </div>
        <NotificationGroup
          style={{
            right: 20,
            bottom: 0,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse',
            zIndex: 100,
          }}>
          <Fade>
            {this.state.downloading &&
              <Notification
                type={{
                  style: 'success',
                  // icon: true
                }}
                closable={true}
                style={{ padding: '5px', background: '#16a34a' }}
                onClose={() => this.setState({
                  downloading: false
                })}>
                <img
                  style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top' }}
                  src={loadingIcon}
                  alt='loading' />

                <span>
                  {this.props.intl.formatMessage({ id: 'app.chart.downloading', defaultMessage: 'Downlaoding...' })}
                </span>
              </Notification>}
          </Fade>
        </NotificationGroup>
      </div>
    );
  }
}

export default ChartContainer;