import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Grid as KGrid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { BASEURI, getAuthToken, formatDate } from '../utils';
import { UserCell } from './Bills';

function PaymentsGrid(props) {
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState([]);
    const [payments, setPayments] = useState([]);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [take, setTake] = useState(5);
    const intl = useIntl();

    useEffect(() => {
        loadPayments();
    }, [])


    function onPageChange(event) {

        setSkip(event.page.skip);
        setTake(event.page.take);   
        const currPage = Math.floor(event.page.skip / event.page.take) + 1;
        let url = `${BASEURI}/api/billing/payment/?page=${currPage}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                return response.json();
            })
            .then((resp) => {
                let payments = resp.results.map(payment => payment.id && {...payment, date: formatDate(payment.date)})
                setTotal(resp.count);
                setPayments(payments);
            })
            .catch(error => console.error('Error:', error));

        });

    }


    function loadPayments() {

        let url = BASEURI + '/api/billing/payment/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        let payments = resp.results.map(payment => payment.id && {...payment, date: formatDate(payment.date)})
                        setTotal(resp.count);
                        setPayments(payments);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const Users = UserCell({users: props.users});

    return (
        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid'>
                    <div className="mdl-cell mdl-cel--6-col">
                    {intl.formatMessage({id:'app.bills.paymentGrid.payment', defaultMessage:'PAYMENTS'})}
                    </div>
                    <div className="mdl-cell mdl-cell-6-col"></div>

                    <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone'>
                        <KGrid
                            take={take}
                            skip={skip}
                            total={total}
                            pageable
                            data={filterBy(orderBy(payments, sort), filter)}
                            onPageChange={onPageChange}
                            sort={sort}
                            onSortChange={e => setSort(e.sort)}
                            filterable
                            filter={filter}
                            editField="inEdit"
                            onFilterChange={(e) => {
                                setFilter(e.filter);
                            }}
                        >
                            <GridNoRecords>
                                {intl.formatMessage({id:'app.admin.graph_grid.no_data', defaultMessage:'No Records Available'})}
                            </GridNoRecords>
                            <Column field="reference" title={intl.formatMessage({id:'app.bills.paymentGrid.internalReference', defaultMessage:'Internal Reference'})} />
                            <Column field="user" title={intl.formatMessage({id:'app.bills.paymentGrid.user', defaultMessage:'User'})} cell={Users} filterable={false} />
                            <Column field="paypal_id" title={intl.formatMessage({id:'app.bills.paymentGrid.paypalReference', defaultMessage:'Paypal Reference'})} />
                            <Column field="date"  title={intl.formatMessage({id:'app.bills.paymentGrid.paymentDate', defaultMessage:'Payment Date'})} />
                            <Column field="amount" title={intl.formatMessage({id:'app.bills.paymentGrid.amount', defaultMessage:'Amount'})} />


                        </KGrid>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default PaymentsGrid
