import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import Modal from '../general/Modal';
import { useIntl } from 'react-intl';
import loading from '../loading.svg';

function ConfirmDeleteDialog(props) {

    const [text, setText] = useState('');
    const intl = useIntl();

    function textChange(event) {
        setText(event.target.value);
    }

    return (<Modal>
        <Dialog title={intl.formatMessage({ id: 'app.chart.deleteChart.pleaseConfirm', defaultMessage: 'Please confirm' })} onClose={props.onClose}>
            <div className='mdl-grid'>
                <div className='mdl-cell mdl-cell--12-col'>
                    {props.message}
                </div>
                <div className='mdl-cell mdl-cell--12-col'>
                    <Input required={true} value={text} onChange={textChange} />
                </div>

            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>{intl.formatMessage({ id: 'app.chart.deleteChart.cancel', defaultMessage: 'Cancel' })}</button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" style={{ color: 'red' }} disabled={text !== 'DELETE'} onClick={props.remove}>
                    {props.deleting ? <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading' /> :
                        intl.formatMessage({ id: 'app.chart.deleteChart.delete', defaultMessage: 'DELETE' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>);
}

export default ConfirmDeleteDialog;