import React, { useEffect, useState } from 'react'

function RefreshButton(props) {
    
    const [refreshAnimateClass, setRefreshAnimateClass] = useState("");

    
    useEffect(() => {
        props.loading ? setRefreshAnimateClass("refresh-start") : setRefreshAnimateClass("");
    }, [props.loading]);

    return (
        <div
            className={`material-icons mdl-badge mdl-badge--overlap ${refreshAnimateClass}`}
            id={'refresh'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                // console.log(props.loading)
                props.onClick();
            }}
        >
            cached
        </div>
    )
}

export default RefreshButton
