
function getBulbColor(avgPAW) {

    if (!avgPAW)
        return 'tip-bulb-red';

    if (avgPAW <= 30) {
        return 'tip-bulb-red';
    }
    else if (avgPAW <= 70) {
        return '!text-[#fbdb67]';
    }
    else if (avgPAW <= 100) {
        return '!text-[#3fd75a]';
    }
    else {
        return '!text-[#00BFFF]';
    }
}

export default function getIrrigationTipText(prescription, intl, locale, avgPAW) {
    let frequencyText = '',
        scheduleLine,
        prescriptionLine = '',
        refillText = '',
        prescriptionText = '';

    if (prescription.frequency !== null)
        frequencyText = `${intl.formatMessage({ id: 'app.charts.irrigation.each', defaultMessage: 'each' })} 
    ${prescription.frequency} ${intl.formatMessage({ id: 'app.charts.irrigation.days', defaultMessage: ' Days' })}`

    if (prescription.frequency === 0)
        scheduleLine = `<span class="narrow">${intl.formatMessage({ id: 'app.charts.irrigation.endOfSeason', defaultMessage: 'END OF SEASON' })}</span>`
    else
        scheduleLine = `<span class="narrow">${intl.formatMessage({ id: 'app.charts.irrigation.storage', defaultMessage: 'STORAGE' })}</span> ` +
            `${intl.formatNumber(prescription.ideal_irrigation_amount, { style: 'unit', unit: locale == 'en' ? 'inch' : 'millimeter', unitDisplay: 'narrow' })} ` +
            frequencyText


    if (prescription.frequency !== 0) {

        refillText = `<span class="narrow">${intl.formatMessage({ id: 'app.charts.irrigation.toFill', defaultMessage: 'To fill' })} </span>` +
            `${intl.formatNumber(prescription.amount, { style: 'unit', unit: locale == 'en' ? 'inch' : 'millimeter', unitDisplay: 'narrow' })}`

        if (prescription.days_to_irrigation !== null && prescription.frequency != null)
            prescriptionText = '<span class="narrow">'
                + `${prescription.irrigate_now ?
                    intl.formatMessage({ id: 'app.charts.irrigation.waterNow', defaultMessage: 'Water now' }) :
                    intl.formatMessage({ id: 'app.charts.irrigation.irrigationIn', defaultMessage: 'Irrigation in ' })}</span>`
                + `${!prescription.irrigate_now ? prescription.days_to_irrigation : ''}`
                + `<span class="narrow">${prescription.irrigate_now ?
                    '' :
                    intl.formatMessage({ id: 'app.charts.irrigation.days', defaultMessage: ' Days' })}</span> · `

        prescriptionLine = prescriptionText + refillText
    }

    let bulbColor = 'tip-bulb-red'

    return `<div class="tip-box">
        <span class="material-icons tip-bulb ${getBulbColor(avgPAW)}">
            tips_and_updates
        </span> 
        <div class="text full">
            <div class="line one">
                ${scheduleLine}
            </div>
            <div class="line two">
                ${prescriptionLine}
            </div>
        </div>
    </div>`;
}