import React from 'react'
import Bills from './Bills';



function BillsGrid(props) {



    return (
        <div className="mdl-grid" style={{ alignItems: 'center', flexDirection: 'column' }}>
            <Bills users={props.users} isAdmin={props.isAdmin}>

            </Bills>



        </div>
    );
}

export default BillsGrid;


