const BASEURI = 'https://agviewer.com';
// const SCOUTURI = 'http://localhost:8001';
// const SCOUTAPIKEY = 'JMLqcZcv.utfnVvptMQep9XL6kQzAdWOaNJpQis3A';
const SCOUTURI = 'https://scout.agviewer.com';
const SCOUTAPIKEY = 'TKLbJyfW.IMfhDLSn3VTW5t4x0otiYGwTiJj7oh4T';

var cookies = {
  getItem: function (sKey) {
    if (!sKey) { return null; }
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          /*
          Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
          version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
          the end parameter might not work as expected. A possible solution might be to convert the the
          relative time to an absolute time. For instance, replacing the previous line with:
          */
          /*
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
          */
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
        default:
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) { return false; }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
};




function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

function retrieveToken(name) {
  if (storageAvailable('localStorage')) {
    return localStorage.getItem(name);
  }
  else {

    console.log('localstorage not available');

    return cookies.getItem(name);


  }


}

function storeToken(name, value) {

  if (storageAvailable('localStorage')) {
    localStorage.setItem(name, value);
  }
  else {
    console.log('localstorage not available');
    cookies.setItem(name, value);

  }

}

function getNewToken(tokenType = 'token') {

  return new Promise((resolve, reject) => {

    var token = JSON.parse(retrieveToken(tokenType));

    // console.log('getting new token');

    const url = BASEURI + '/api/token/refresh/';
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify({ refresh: token.refresh }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((accessToken) => {


        token.access = accessToken.access;
        // console.log('storing new token and resolving true');
        storeToken(tokenType, JSON.stringify(token));

        resolve(true);

      })
      .catch(error => {
        // console.log('getting new token failed rejecting');
        return reject(error)
      });
  })

}

function parseJWT(token) {

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jToken = JSON.parse(window.atob(base64));

  // console.log(jToken);
  return jToken;

}


function getAuthToken(tokenType = 'token') {


  return new Promise((resolve, reject) => {


    var token = retrieveToken(tokenType);

    if (!token) {
      // console.log({message:'no token found rejecting'});
      reject(false);
    }


    token = JSON.parse(token);

    //if somehow there is an auth token in localStorage 
    //but no access token
    if (!token.access) {
      // console.log({message:'no access token found rejecting'}); 
      reject(false);
    }


    var accessToken = parseJWT(token.access);


    // console.log({
    //   tokenTime:new Date(accessToken.exp * 1000),
    //   tokenReduced: new Date((accessToken.exp -15) * 1000),
    //   now: new Date()
    //   });  

    if (new Date((accessToken.exp - 15) * 1000) < new Date()) {
      // console.log({
      //   isExpired: new Date((accessToken.exp - 15 )*1000) < new Date(),
      //   message: 'token expired'
      // });

      //if somehow there is an auth token in localStorage 
      //but no refresh token
      if (!token.refresh) {
        // console.log('no refresh token found');
        reject(false);
      }


      var refreshToken = parseJWT(token.refresh);


      if (new Date((refreshToken.exp - 15) * 1000) < new Date()) {
        // console.log({
        //   isExpired: new Date((refreshToken.exp - 15)*1000) < new Date(),
        //   message: 'refresh token expired rejecting'
        // });
        reject(false);
      }


      getNewToken(tokenType)
        .then(success => {
          token = retrieveToken(tokenType);
          token = JSON.parse(token);
          // console.log('got new token');
          resolve(token.access);
        });

    } else {
      // console.log({
      //   isExpired: new Date((accessToken.exp - 15 )*1000) < new Date(),
      //   message: 'token valid rejecting'
      // });
      reject(token.access);
    }

  });


}

function advancedFilter(value, items = [], setItems, searchableFields = []) {
  // Process the input filter value: remove spaces and convert to lowercase
  const filterValue = value.toLowerCase().trim();
  let filtered = [];

  // Helper function to generate searchable text from the item
  const generateSearchableText = (item) => {
    return Object.entries(item)
      .filter(([key]) => searchableFields.includes(key))
      .map(([, val]) => String(val).toLowerCase())
      .join(' ');
  };

  // Escape the filter value to safely use in a regular expression
  const escapedFilterValue = filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const filterRegex = new RegExp(escapedFilterValue, 'i');

  // Case 1: Try a whole-word match or partial word match
  filtered = items.filter(item => {
    const searchableText = generateSearchableText(item).replace(/\s+/g, ' ');
    return filterRegex.test(searchableText); // Matches anywhere within the searchable text
  });

  // Case 2: If no matches, use a fallback strategy to match each character in sequence
  if (filtered.length < 1) {
    filtered = items.filter(item => {
      const searchableText = generateSearchableText(item).replace(/\s+/g, '');
      let matchIndex = 0;

      for (const char of filterValue) {
        matchIndex = searchableText.indexOf(char, matchIndex);
        if (matchIndex === -1) return false;
        matchIndex += 1;
      }
      return true;
    });
  }

  // Set the filtered results
  setItems(filtered);
}




function formatDate(date) {
  date = new Date(date);
  let minutes = date.getMinutes(),
    hours = date.getHours(),
    dateString,
    day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear() % 2000;

  if (hours < 10)
    hours = '0' + hours;

  if (minutes < 10)
    minutes = '0' + minutes;

  if (day < 10)
    day = '0' + day;

  if (month < 10)
    month = '0' + month


  dateString = `${day}/${month}/${year} ${hours}:${minutes}`;

  return dateString;
}


export { getAuthToken, formatDate, BASEURI, SCOUTURI, SCOUTAPIKEY, storeToken, retrieveToken, cookies, advancedFilter };
