import React from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { getAuthToken, BASEURI } from '../utils';

class ChillPortionsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			graphName: '',
			// colors: ['blue', 'lightblue', 'tomato', 'lime', 'green', 'lightgreen', 'skyblue', 'red'],
			sensors: [],
			extracts: [],
			extract: null,
			paw_guides: false,
			pawGuides: null,
			color: '#000000'
		};

		this.setInitialValues = this.setInitialValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.itemRender = this.itemRender.bind(this);
	}

	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.name === 'graphType' || event.target.name === 'paw_guides' ? event.target.value.value : event.target.value,
			pawGuides: event.target.name === 'paw_guides' ? event.target.value : this.setState.pawGuides
		});

		if (event.target.name === 'sensors') {
			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});
			}
		}
	}

	componentDidUpdate() {
		// console.log(this.state);
		// this.props.handleChange('chill_portions', this.state);
	}

	itemRender(li, itemProps) {

		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	extractItemRender(li, itemProps) {
		const itemChildren =
			<div>
				{itemProps.dataItem.description} [{itemProps.dataItem.unit && itemProps.dataItem.unit}]
				<div style={{ color: "#00F" }}>{itemProps.dataItem.sensor.name}</div>
			</div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	render() {

		return (
			<div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">

					<MultiSelect key='sensor-select'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						name='sensors'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensor', defaultMessage: 'SENSOR(S)' })}
						data={this.props.sensors}
						value={this.props.graph.sensors}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<MultiSelect
						key='extract-select'
						name='extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensorExtract', defaultMessage: 'SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleExtractFilterChange}
						style={{ height: 'auto' }} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='reset_on'
						label={this.props.intl.formatMessage({ id: 'app.chart.chillPortionsForm.resetOn', defaultMessage: 'RESET ON (MM/DD)' })}
						value={this.props.graph.variables.reset_on}
						onChange={this.props.handleChange} />
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
						name='_type'
						data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
						textField='text'
						dataItemKey='value'
						value={this.graphTypes[this.props.graph._type]}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						type='color'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
						name='color'
						data={this.state.color}
						value={this.props.graph.color}
						onChange={this.props.handleChange} />
					<Input
						required={true}
						name='label'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
						value={this.props.graph.label}
						onChange={this.props.handleChange} />
				</div>
				{/* <div style={{marginTop: '20px', color:'gray'}}>
					<span style={{paddingRight:'20px'}}>
						PAW RANGE GUIDES
					</span>
					<Switch 
						name='paw_guides'
						checked={this.props.graph.paw_guides}
						onChange={this.props.handleChange}
					/>
				</div> */}
				{/* <DropDownList
					label="PAW RANGE GUIDES" 
					name='paw_guides'
					data={[{text:'ON', value:true}, {text:'OFF', value:false}]}
					textField='text'
					dataItemKey='value'
					value={this.props.graph.pawGuides}
					onChange={this.handleChange}/> */}
				<div className="modal-form-inputs-wrap-row">
					<Input
						name='axis_min'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
						value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
						onChange={this.props.handleChange} />
					<Input
						name='axis_max'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
						value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='y_axis_legend'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
						value={this.props.graph.y_axis_legend}
						onChange={this.props.handleChange} />
					<div></div>
				</div>

			</div>
		);
	}
}

export default ChillPortionsForm;