import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import Modal from '../general/Modal';
import { getAuthToken, BASEURI } from '../utils';


function ViewBillModal(props) {

    const [bill, setBill] = useState({
        paid: false,
        issued: false,
    });
    const [user, setUser] = useState(null);
    // List of Bill Items for the above bill, or new created Bill Items 
    const [billItems, setBillItems] = useState([]);
    const intl = useIntl();

    // set bill and user if an existing bill opened.
    useEffect(() => {
        if (props.bill) {
            setBill(props.bill);
            let user = props.users.filter(user => props.bill.user === user.id)[0];
            setUser(user);
        }

    }, [props.bill])


    // get data if existing bill opened.
    useEffect(() => {
        getBillItems();
    }, [props.bill]);


    function getBillItems() {
        if (!props.bill.id) return;
        let url = BASEURI + `/api/billing/bill/${props.bill.id}/items/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                // body: JSON.stringify(params)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({
                        loggedin: false
                    });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((billItems) => {
                        setBillItems(billItems);
                    });
            })
            .catch(error => {
                console.error('Error:', error.code);
                this.setState({ loadingMarkers: false });
            });
    }


    function issue() {
        let url = BASEURI + `/api/billing/bill/${bill.id}/issue/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ action: 'issue' })
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({
                        loggedin: false
                    });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((bill) => {
                        setBill(bill);
                    });
            })
            .catch(error => {
                console.error('Error:', error.code);
                this.setState({ loadingMarkers: false });
            });
    }
    function markAsPaid() {
        props.setBill(bill)
        props.onClose()
        props.showRefModal(true)
    }

    function reset() {
        props.setBill({});
        setBill({});
        setBillItems([]);
        setBillItems([]);
    }



    return props.show && <Modal>
        <Dialog onClose={() => { props.onClose(); reset(); }} title={`${user && (user.first_name ? user.first_name : user.username)}`}>
            <div className='mdl-grid' style={{ justifyContent: 'center' }}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <div className='modal-wrap'>
                        <div className='modal-form-wrap'>
                            <div className='alert-modal-form-inputs-wrap payment-dialog'>
                                <p>{intl.formatMessage({ id: 'app.bills.viewBill.period', defaultMessage: 'Period' })}: <b>{bill && bill.period}</b></p>
                                <div className='modal-form-row'>
                                    <div style={{ marginTop: '15px' }}>
                                        {bill && <BillItems bill={bill} items={billItems} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={(e) => { props.onClose(); reset(); }}>
                    {intl.formatMessage({ id: 'app.bills.viewBill.close', defaultMessage: 'Close' })}
                </button>
                <button disabled={bill.paid} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md " onClick={bill.issued && !bill.paid ? markAsPaid : issue} >
                    {bill.issued && !bill.paid ?
                        intl.formatMessage({ id: 'app.bills.viewBill.markAsPaid', defaultMessage: 'Mark as Paid' }) :
                        bill.paid ?
                            intl.formatMessage({ id: 'app.bills.viewBill.markAsPaid', defaultMessage: 'Mark as Paid' }) :
                            intl.formatMessage({ id: 'app.bills.viewBill.issue', defaultMessage: 'Issue' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>;
}

export default ViewBillModal;


function BillItems(props) {

    const intl = useIntl();

    return <table className='ag-billitems'>
        <thead>
            <tr>
                <th>{intl.formatMessage({ id: 'app.bills.billItem.item', defaultMessage: 'Item' })}</th>
                <th>{intl.formatMessage({ id: 'app.bills.billItem.qty', defaultMessage: 'Qty' })}</th>
                <th>{intl.formatMessage({ id: 'app.bills.billItem.amount', defaultMessage: 'Amount' })}</th>
            </tr>
        </thead>
        <tbody>
            {
                props.items.map((i, k) => {
                    console.log('key', k);
                    return <tr key={k}>
                        <td>{i.subscription}</td><td>{i.count}</td><td>${i.total}</td>
                    </tr>
                })
            }
        </tbody>
        <tfoot>
            <tr>
                <td colSpan={2}>{intl.formatMessage({ id: 'app.bills.billItem.total', defaultMessage: 'Total' })}</td>
                <td>${props.bill.amount}</td>
            </tr>
            <tr>
                <td colSpan={2}>{intl.formatMessage({ id: 'app.bills.billItem.discount', defaultMessage: 'Discount' })}</td>
                <td>${props.bill.discount_amount}</td>
            </tr>
            <tr>
                <td colSpan={2}>{intl.formatMessage({ id: 'app.bills.billItem.salesTax', defaultMessage: 'Sales Tax' })}</td>
                <td>${props.bill.sales_tax}</td>
            </tr>
            <tr>
                <td colSpan={2}>{intl.formatMessage({ id: 'app.bills.billItem.amountPayable', defaultMessage: 'Amount Payable' })}</td>
                <td>${props.bill.amount_payable}</td>
            </tr>
        </tfoot>
    </table>;
}
