import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import loading from '../loading.svg';

function StationItem(props) {
    const [adding, setAdding] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (!props.loading)
            setAdding(props.loading);
    }, [props.loading])

    const { station } = props;

    return <tr className="border-b-[1px]">
        <td className="p-2">{station.name}</td>

        <td className="p-2">{station.id}</td>
        <td className="p-2">
            {adding ? <img
                title={intl.formatMessage({ id: 'app.stations.stationForm.adding', defaultMessage: 'Adding station please wait.' })}
                className='p-0 m-0'
                style={{ color: 'gray', width: '31px' }}
                src={loading} alt='loading'></img> :
                <>
                    {!station.is_added ?
                        <button className="text-green-500" onClick={() => {
                            setAdding(true);
                            props.addStation({
                                addingWiseconnDevice: true,
                                api: 'Wiseconn',
                                serial: station.id,
                                name: station.name,
                                details: {
                                    timezone: station.timeZoneName,
                                    location: {
                                        lat: station.latitude,
                                        lng: station.longitude
                                    }
                                }
                            })
                        }}><i title={intl.formatMessage({ id: 'app.stations.stationForm.addStation', defaultMessage: 'Add station' })} className="material-icons" style={{ 'color': '#7bd47b' }}>add</i></button> :

                        <i title={intl.formatMessage({ id: 'app.stations.stationForm.alreadyAded', defaultMessage: 'Already added' })} className="material-icons" style={{ 'color': '#7bd47b' }}>check</i>
                    }
                </>}
        </td>
        <td className="p-2"><button className="text-green-500"></button></td>
    </tr>
}


function WiseconnStationsList(props) {
    const intl = useIntl();

    return (
        <div className="mt-4 rounded-md border-[1px] p-0 text-gray-600">
            <div className="border-b-[1px] p-2 uppercase">{intl.formatMessage({ id: 'app.stations.stationForm.wiseconnStations', defaultMessage: 'Wiseconn Stations List' })}</div>
            <table className="m-0 w-full p-0">
                <thead>
                    <tr className="border-b-[1px]">
                        <td className="p-2 capitalize">{intl.formatMessage({ id: 'app.admin.filterMarker.name', defaultMessage: 'Name' })}</td>
                        <td className="p-2 capitalize">{intl.formatMessage({ id: 'app.admin.stationGrid.serial', defaultMessage: 'Serial' })}</td>
                        <td className="p-2 capitalize">{intl.formatMessage({ id: 'app.stations.modelFrom.addStation', defaultMessage: 'Add Station' })}</td>
                    </tr>
                </thead>
                <tbody>

                    {props.wiseconnStations.map((station) => <StationItem key={station.id} addStation={props.addStation} loading={props.loading} station={station} />)}

                </tbody>
            </table>
            {props.loadingDevices ?
                <div className="p-2 text-center text-sm font-light">{intl.formatMessage({ id: 'app.stations.stationForm.loadingStations', defaultMessage: 'Loading Wiseconn Stations...' })}</div> :
                <div className="p-2 text-center text-sm font-light">{intl.formatMessage({ id: 'app.stations.stationForm.total', defaultMessage: 'Total {count} Stations' }, { count: props.wiseconnStations.length })}</div>}
        </div>
    )
}

export default WiseconnStationsList;