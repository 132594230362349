// React
import React from 'react';
import { useIntl } from 'react-intl';

// Third-party
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';

// Project
import loadingIcon from '../../loading_white.svg';
import Modal from '../../general/Modal'


export default function SettingsModal(props) {

    const intl = useIntl();
    const {
        show,
        onClose,
        onSave,
        onChange,
        saving,
        plot,
        showErrorMessage,
    } = props;

    return (
        <Modal>
            {show &&
                <Dialog
                    title={`Edición de ${plot.plot_id}`}
                    closeIcon={true}
                    onClose={onClose}
                >
                    <legend>
                        factor de conversión kg (x = conteo)
                    </legend>
                    <div>
                        <Input
                            valid={!showErrorMessage}
                            value={plot.to_kg_formula}
                            placeholder='i.e. x*1.2'
                            onChange={(e) => {
                                const re = /^[^,]+$/;
                                if (!re.test(e.target.value))
                                    return
                                onChange({ type: 'update', value: { to_kg_formula: e.target.value } })
                            }}
                        />
                        {showErrorMessage &&
                            <div>
                                <span className='text-red-500'>{intl.formatMessage({ id: 'app.agscout.invalidFormula', defaultMessage: '* Invalid formula' })}</span>
                            </div>
                        }
                    </div>


                    <DialogActionsBar>
                        <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={onClose} >
                            {intl.formatMessage({ id: 'app.agscout.done', defaultMessage: 'DONE' })}
                        </button>
                        <button
                            className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary"
                            onClick={onSave}
                        >
                            {saving ?
                                <img
                                    style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto' }}
                                    src={loadingIcon}
                                    alt='loading'
                                />
                                :
                                intl.formatMessage({ id: 'app.agscout.save', defaultMessage: 'SAVE' })
                            }
                        </button>
                    </DialogActionsBar>
                </Dialog>
            }
        </Modal>
    )

}