import React, { useState, useEffect, useRef } from 'react';
import { getAuthToken, BASEURI } from '../utils';
import PaymentsGrid from './PaymentsGrid';
import Discounts from './DiscountsGrid';
import SubscriptionsGrid from './SubscriptionsGrid';
import BillsGrid from './BillsGrid';
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';
import Modal from '../general/Modal';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ClientCredits from './ClientCreditPage';
import AdminCredits from './AdminCreditPage';
import { useIntl } from 'react-intl';



function Billing(props) {

    const intl = useIntl();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locked, setLocked] = useState(false);

    useEffect(() => {
        setLocked(props.locked ? props.locked : false);
    }, [props.locked])

    function loadUsers() {
        setLoading(true)
        let url = BASEURI + '/api/dashboard/user/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((clients) => {
                        setLoading(false);
                        let users = clients.map(client => client.user)
                        setUsers(users);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    const tabsRef = useRef(null);
    useEffect(() => {
        if (window.componentHandler)
            window.componentHandler.upgradeElement(tabsRef.current);
        loadUsers();

    }, [])

    function refresh() {
        loadUsers();
    }

    return <div className='mdl-grid'
        style={{
            flexDirection: 'column',
            alignItems: 'center'
        }}>
        <RefreshPortal>
            <RefreshButton loading={loading} onClick={refresh} />
        </RefreshPortal>
        <div className='breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
            {intl.formatMessage({ id: 'app.bills.billing.billing', defaultMessage: 'BILLING' })}
        </div>
        <div className="mdl-grid mdl-cell mdl-cell--12-col" style={{ alignItems: 'center', flexDirection: 'column' }}>
            <div ref={tabsRef} className="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
                <div className="mdl-tabs__tab-bar">
                    <a href="#bills-panel" className='mdl-tabs__tab is-active'>
                        {intl.formatMessage({ id: 'app.bills.billing.bills', defaultMessage: 'Bills' })}
                    </a>
                    <a href="#payments-panel" className='mdl-tabs__tab'>
                        {intl.formatMessage({ id: 'app.bills.billing.payments', defaultMessage: 'Payments' })}
                    </a>
                    {
                        props.isAdmin &&
                        <React.Fragment>
                            <a href="#subscriptions-panel" className='mdl-tabs__tab'>
                                {intl.formatMessage({ id: 'app.bills.billing.subscriptions', defaultMessage: 'Subscriptions' })}
                            </a>
                            <a href="#discount-panel" className='mdl-tabs__tab '>
                                {intl.formatMessage({ id: 'app.bills.billing.discounts', defaultMessage: 'Discounts' })}
                            </a>
                        </React.Fragment>
                    }
                    <a href="#account-panel" className='mdl-tabs__tab '>
                        {intl.formatMessage({ id: 'app.bills.billing.credits', defaultMessage: 'Credits' })}
                    </a>
                </div>
                <div className="mdl-tabs__panel is-active" id="bills-panel">
                    {locked && <CautionModal onClose={() => setLocked(false)} />}
                    <BillsGrid users={users} isAdmin={props.isAdmin} />
                </div>
                <div className="mdl-tabs__panel" id="payments-panel">
                    <PaymentsGrid users={users} />
                </div>
                {
                    props.isAdmin &&
                    <div>
                        <div className="mdl-tabs__panel" id="subscriptions-panel">
                            <SubscriptionsGrid users={users} />
                        </div>
                        <div className="mdl-tabs__panel" id="discount-panel">
                            <Discounts users={users} />
                        </div>
                    </div>
                }
                <div className="mdl-tabs__panel" id="account-panel">
                    {
                        props.isAdmin ?
                            <AdminCredits isAdmin={props.isAdmin} users={users} />
                            :
                            <ClientCredits isAdmin={props.isAdmin} users={users} />
                    }
                </div>
            </div>
        </div>

    </div>;
}

export default Billing;


function CautionModal(props) {
    const intl = useIntl();
    return (
        <Modal>
            <Dialog onClose={props.onClose} title=" " >
                <div className="mdl-grid">
                    <div className="mdl-cell mdl-cell--12-col" style={{ justifyContent: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <i className="material-icons" style={{ color: 'red', fontSize: '5em' }}>warning</i>
                            <p style={{ fontSize: '3em' }}>
                                {intl.formatMessage({ id: 'app.bills.billing.caution', defaultMessage: 'CAUTION' })}
                            </p>
                            {intl.formatMessage({ id: 'app.bills.billing.cautionMessage', defaultMessage: 'PLEASE PAY DUE BILLS TO REGAIN ACCESS TO YOUR ACCOUNT.' })}
                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>
                        {intl.formatMessage({ id: 'app.bills.billing.close', defaultMessage: 'Close' })}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    );

}