import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { FormattedMessage } from 'react-intl';



class TabDialog extends Component {

	allowSubmit() {
		if (!this.props.tab.name)
			return false
		else
			return true
	}


	render() {

		let actionBar;
		if (this.props.action === 'edit') {
			actionBar =
				<DialogActionsBar>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md delete"
						onClick={() => {
							// this.props.delete(this.props.tabId);
							this.props.showDeleteDialog();
							this.props.close();
						}}>
						{this.props.intl.formatMessage({ id: 'app.tab.tabDialog.delete', defaultMessage: 'Delete' })}
					</button>
					<button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>
						{this.props.intl.formatMessage({ id: 'app.tab.tabDialog.cancel', defaultMessage: 'Cancel' })}
					</button>
					<button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.create} disabled={!this.allowSubmit()}>
						{this.props.intl.formatMessage({ id: 'app.tab.tabDialog.update', defaultMessage: 'Update' })}
					</button>
				</DialogActionsBar>;
		} else {
			actionBar =
				<DialogActionsBar>
					<button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>
						{this.props.intl.formatMessage({ id: 'app.tab.tabDialog.cancel', defaultMessage: 'Cancel' })}
					</button>
					<button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.create} disabled={!this.allowSubmit()}>
						{this.props.intl.formatMessage({ id: 'app.tab.tabDialog.add', defaultMessage: 'Add' })}
					</button>
				</DialogActionsBar>;
		}

		return this.props.visible ? <Dialog
			title={this.props.action === 'edit' ?
				this.props.intl.formatMessage({ id: 'app.tab.tabDialog.editDash', defaultMessage: 'Edit dashboard' }) :
				this.props.intl.formatMessage({ id: 'app.tab.tabDialog.newDash', defaultMessage: 'New dashboard' })}
			onClose={this.props.close}
		// className='edit-chart-dialog'
		// style={{width: '400px', height: '200px'}}
		>

			<div style={{ justifyContent: 'center' }}>
				<div>
					<div className='modal-form-wrap'>
						<div className='modal-form-inputs-wrap'>
							<Input
								name='name'
								required={true}
								key='name-input'
								label={this.props.intl.formatMessage({ id: 'app.tab.tabDialog.tabName', defaultMessage: 'TAB NAME' })}
								value={this.props.tab.name}
								onChange={this.props.handleChange}
							/>
						</div>
					</div>
				</div>
			</div>

			{actionBar}

		</Dialog> : null;
	}
}


export default TabDialog;