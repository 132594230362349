// React
import React from "react"
import { useIntl } from 'react-intl';
// Project
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { generateYearList } from "../utils";


const textField = "name";

function Body(props){

    // return <div className="flex flex-col gap-1 p-3 w-full md:w-2/6 bg-white overflow-hidden rounded-md ">
    return <div className="col-span-12 order-first row-span-1 md:col-span-4 md:row-span-1 flex flex-col gap-1 p-3 bg-white overflow-hidden rounded-md shadow-lg">
            {props.children}
    </div>
}

function FarmDropDownList(props){
    const intl = useIntl();
    return <DropDownList
        style={{width: '100%'}}
        label={intl.formatMessage({id:'app.agscout.farm', defaultMessage:'FARM'})}
        // placeholder='SELECT FARM'
        data={props.data}
        value={props.value}
        textField={textField}
        dataItemKey='id'
        onChange={props.onChange}
    />
}

function YearSelect(props){
    const intl = useIntl();
    const data = generateYearList(2019)
    
    return <DropDownList
        label={intl.formatMessage({id:'app.agscout.year', defaultMessage:'YEAR'})}
        data={data}
        value={props.value}
        textField={'text'}
        dataItemKey={'value'}
        onChange={props.onChange}
    />
}

function ScoutTypeSelect(props){
    const {data} = props;
    const intl = useIntl();

    return <div className='flex flex-row flex-grow'>
        <DropDownList
            label={intl.formatMessage({id:'app.agscout.scoutType', defaultMessage:'SCOUT TYPE'})}
            data={data}
            value={props.value}
            style={{width: '100%'}}
            dataItemKey='id'
            textField='scout_type'
            onChange={props.onChange}
        />
    </div>
}


function Farms(props){

    return <Body>
        {props.children}
    </Body>
}

export default Farms
export {FarmDropDownList, ScoutTypeSelect, YearSelect}