import React, { useState, useRef, useEffect } from 'react'
import { formatDate } from '../utils';
import { Input } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';
function getPercentage(containerWidth, distanceMoved) {
    return (distanceMoved / containerWidth) * 100;
};

function limitNumberWithinRange(value, min, max) {
    return Math.min(Math.max(value, min), max);
};

function nearestN(N, number) {
    return Math.ceil(number / N) * N
};

function getTags(intl, values, initialDate, devDate, midDate, lateDate, plantDate) {

    return [
        {
            name: intl.formatMessage({id:'app.chart.etcRawForm.tagSection.initial', defaultMessage:'Initial'}),
            color: "#69AAF9",
            value: values.initialValue,
            date: initialDate,
            plantDate: plantDate,

        },
        {
            name: intl.formatMessage({id:'app.chart.etcRawForm.tagSection.development', defaultMessage:'Development'}),
            color: "#69AAF9",
            date: devDate,
            plantDate: "",
        },
        {
            name: intl.formatMessage({id:'app.chart.etcRawForm.tagSection.middle', defaultMessage:'Middle'}),
            color: "#69AAF9",
            value: values.midValue,
            date: midDate,
            plantDate: "",
        },
        {
            name: intl.formatMessage({id:'app.chart.etcRawForm.tagSection.late', defaultMessage:'Late'}),
            color: "#69AAF9",
            value: values.lateValue,
            date: lateDate,
            plantDate: "",
        }
    ];
}

const TagSection = ({ name, date, plantDate, value, color, width, onSliderSelect, setWidth, index, widths }) => {
    const [showInput, setShowInput] = useState(false);
    const [newWidth, setNewWidth] = useState(width);
    const intl = useIntl();
    useEffect(() => {
        setNewWidth(width);
    }, [width])

    function handleChange(event) {
        setNewWidth(event.target.value)
    }
    function handleSave() {
        setShowInput(false);
        setWidth(index, widths, Number(newWidth));
    }

    function handleEnterKey(e) {
        if (e.key === 'Enter') {
            handleSave();
        }
    }

    return (
        <div
            className="tag"
            style={{
                ...styles.tag,
                background: color,
                width: width + "%"
            }}
        >
            <span className="plant-date-span">{plantDate}</span>
            <span style={styles.tagText}>{name}</span>
            <span style={styles.tagText}>{value}</span>

            <span className="plant-days" style={{ ...styles.tagText, fontSize: 12 }}>
                {
                    showInput ?
                        <div>
                            <Input autoFocus={true} onKeyDown={handleEnterKey} onBlur={handleSave} className="plant-days-input" type="text" value={newWidth} onChange={handleChange} />
                        </div>
                        :
                        <p title="Click to edit" onClick={() => setShowInput(true)}>
                            <span>{width}</span> {intl.formatMessage({id:'app.chart.etcRawForm.tagSection.days', defaultMessage:'days'})}
                        </p>
                }
            </span>

            <div
                onPointerDown={onSliderSelect}
                className="slider-button"
            >
                {!isNaN(date.getTime()) && <span className="date-span">{formatDate(date).substring(0, 8)}</span>}
                <img alt="Adjust" src={"https://assets.codepen.io/576444/slider-arrows.svg"} height={"30%"} />
            </div>
        </div>
    );
};

export const TagSlider = (props) => {
    const intl = useIntl();
    const [widths, setWidths] = useState(
        [
            props.graph.variables.plant ? props.graph.variables.plant.initial_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.dev_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.mid_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.late_days : 25
        ]);


    function parseDate(s) {
        if (!s) return;
        let p = s.split("/");
        return new Date(p[2], (p[1] - 1), p[0]);
    }
    const [values, setValues] = useState({
        initialValue: props.graph.variables.plant ? props.graph.variables.plant.initial_value : 0,
        midValue: props.graph.variables.plant ? props.graph.variables.plant.mid_value : 0,
        lateValue: props.graph.variables.plant ? props.graph.variables.plant.late_value : 0,
    });

    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days)
        return result;
    }
    // let _tags = getTags(values,
    //     addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days : 0),
    //     addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days : 0),
    //     addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days : 0),
    //     addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days + props.graph.variables.plant.late_days : 0),
    //     props.graph.variables && props.graph.variables['plantdate'],
    // )

    useEffect(() => {
        let values = {
            initialValue: props.graph.variables.plant ? props.graph.variables.plant.initial_value : 0,
            midValue: props.graph.variables.plant ? props.graph.variables.plant.mid_value : 0,
            lateValue: props.graph.variables.plant ? props.graph.variables.plant.late_value : 0,
        }
        let widths = [
            props.graph.variables.plant ? props.graph.variables.plant.initial_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.dev_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.mid_days : 25,
            props.graph.variables.plant ? props.graph.variables.plant.late_days : 25
        ];

        setWidths(widths)
        setValues(values);

        setTags(
            getTags(intl, values,
                addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days : 0),
                addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days : 0),
                addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days : 0),
                addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days + props.graph.variables.plant.late_days : 0),
                props.graph.variables && props.graph.variables['plantdate'],
            )
        );
    }, [props.graph.variables]);


    const [tags, setTags] = useState(
        getTags(intl, values,
            addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days : 0),
            addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days : 0),
            addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days : 0),
            addDays(parseDate(props.graph.variables.plantdate), props.graph.variables.plant ? props.graph.variables.plant.initial_days + props.graph.variables.plant.dev_days + props.graph.variables.plant.mid_days + props.graph.variables.plant.late_days : 0),
            props.graph.variables && props.graph.variables['plantdate'],
        )
    );
    function setWidth(index, _widths, width) {
        _widths[index] = width;

        let periods = {
            initial: _widths[0],
            dev: _widths[1],
            mid: _widths[2],
            late: _widths[3],
        }
        props.setPlantDays(periods);
    }

    const TagSliderRef = useRef(null);

    return props.graph.variables.plant ? (
        <div>

            <div
                ref={TagSliderRef}
                style={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                }}
            >
                {tags.map((tag, index) => (
                    <TagSection
                        date={tag.date}
                        width={widths[index]}
                        key={index}
                        plantDate={tag.plantDate}
                        noSliderButton={index === tags.length - 1}
                        index={index}
                        setWidth={setWidth}
                        widths={widths}
                        value={tag.value}
                        name={tag.name}
                        onSliderSelect={(e) => {
                            // console.log(e)
                            e.preventDefault();
                            document.body.style.cursor = "ew-resize";
                            const startDragX = e.pageX;
                            const sliderWidth = TagSliderRef.current.offsetWidth;

                            const resize = (e) => {
                                e.preventDefault();
                                const endDragX = e.touches ? e.touches[0].pageX : e.pageX;
                                const distanceMoved = endDragX - startDragX;
                                const maxPercent = widths[index] + widths[index + 1];

                                const percentageMoved = nearestN(1, getPercentage(sliderWidth, distanceMoved))
                                // const percentageMoved = getPercentage(sliderWidth, distanceMoved);

                                const _widths = widths.slice();

                                const prevPercentage = _widths[index];

                                const newPercentage = prevPercentage + percentageMoved;
                                const currentSectionWidth = limitNumberWithinRange(
                                    newPercentage,
                                    0,
                                    maxPercent
                                );
                                _widths[index] = currentSectionWidth;

                                const nextSectionIndex = index + 1;

                                const nextSectionNewPercentage =
                                    _widths[nextSectionIndex] - percentageMoved;
                                const nextSectionWidth = limitNumberWithinRange(
                                    nextSectionNewPercentage,
                                    0,
                                    maxPercent
                                );
                                _widths[nextSectionIndex] = nextSectionWidth;

                                if (tags.length > 2) {
                                    if (_widths[index] === 0) {
                                        _widths[nextSectionIndex] = maxPercent;
                                        _widths.splice(index, 1);
                                        setTags(tags.filter((t, i) => i !== index));
                                        removeEventListener();
                                    }
                                    if (_widths[nextSectionIndex] === 0) {
                                        _widths[index] = maxPercent;
                                        _widths.splice(nextSectionIndex, 1);
                                        return;
                                        // setTags(tags.filter((t, i) => i !== nextSectionIndex));
                                        // removeEventListener();
                                    }
                                }
                                // console.log(_widths);
                                let periods = {
                                    initial: _widths[0],
                                    dev: _widths[1],
                                    mid: _widths[2],
                                    late: _widths[3],
                                }
                                props.setPlantDays(periods);

                                setWidths(_widths);
                            };
                            if(index !== 3){
                                window.addEventListener("pointermove", resize);
                                window.addEventListener("touchmove", resize);
                            }

                            const removeEventListener = () => {
                                window.removeEventListener("pointermove", resize);
                                window.removeEventListener("touchmove", resize);
                            };

                            const handleEventUp = (e) => {
                                e.preventDefault();
                                document.body.style.cursor = "initial";
                                removeEventListener();
                            };

                            window.addEventListener("touchend", handleEventUp);
                            window.addEventListener("pointerup", handleEventUp);
                        }}
                        color={tag.color}
                    />
                ))}
            </div>
        </div>
    ) : <div></div>;
};

// type StylesType = { [key: string]: React.CSSProperties };

const styles = {
    tag: {
        padding: 20,
        textAlign: "center",
        position: "relative",
        borderRightWidth: ".1em",
        borderRightStyle: "solid",
        borderRightColor: "white",
        boxSizing: "border-box",
        borderLeftWidth: ".1em",
        borderLeftStyle: "solid",
        borderLeftColor: "white"
    },
    tagText: {
        color: "white",
        fontWeight: 700,
        userSelect: "none",
        display: "block",
        overflow: "hidden",
        fontFamily: "serif"
    },
};
