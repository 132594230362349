import React from 'react'



function MapContainer(props){

    // return <div className="flex flex-col md:flex-row flex-wrap m-4 mt-0 gap-2 h-5/6" >
    return <div className="grid grid-cols-12 px-3 grid-rows-auto gap-2" >
        {props.children}
    </div>;
}


export default MapContainer;