import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useIntl } from 'react-intl';

export default function UserSelector(props) {
    const {
        users,
        handleSwitchUser,
        usersLength,
        handleFilterChange,
        currentUserId,
        username } = props;
    const [showUserSelector, setShowUserSelector] = useState(false);
    const intl = useIntl();


    return (
        <ClickAwayListener onClickAway={() => setShowUserSelector(false)}>

            <div className='relative text-gray-600'>
                <div
                    // Translate this
                    title='Select User'
                    onClick={() => setShowUserSelector(!showUserSelector)}
                    className="m-4 flex h-8 w-8 items-center justify-center rounded-full bg-green-200 font-bold text-green-600 ring-2 ring-white cursor-pointer uppercase">{username?.[0] || <i className='material-icons'>person</i>}</div>
                {showUserSelector &&

                    <div className="p-2 w-[280px] absolute top-[50px] right-[0px] rounded-md shadow-lg bg-white border border-gray-300">
                        {usersLength > 5 &&
                            <div className='w-full p-2'>
                                <input
                                    className="w-full text-left bg-white border-0 border-b-[1px] border-b-green-500 focus:outline-none"
                                    onChange={handleFilterChange}
                                    type="text"
                                    placeholder="Search"
                                />
                            </div>
                        }
                        <ul className="max-h-[250px] overflow-y-scroll mt-1 divide-y divide-gray-100">
                            {users.length > 0 ?
                                users.map((item) => (
                                    <li
                                        key={item.id}
                                        onClick={() => handleSwitchUser(item.user.id)}
                                        className="flex justify-between gap-x-6 py-2 cursor-pointer hover:bg-gray-100 rounded-md p-2">
                                        <div className="flex min-w-0 gap-x-4">
                                            <div className="min-w-0 flex-auto">

                                                <div
                                                    className="whitespace-nowrap flex flex-row"
                                                    title={item.name ? item.name : item.user.username}
                                                >
                                                    <div
                                                        className='text-ellipsis max-w-[170px] overflow-hidden text-sm font-semibold leading-6 text-gray-900'
                                                    >
                                                        {item.name ? item.name : item.user.username}
                                                    </div>
                                                    {(item.user.id === currentUserId) && <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 ml-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Signed in</span>}
                                                </div>


                                                <p className="whitespace-nowrap text-ellipsis w-[150px] overflow-hidden mt-1 truncate text-xs leading-5 text-gray-500" title={'@' + item?.user?.username}>@{item?.user?.username}</p>
                                            </div>
                                        </div>

                                        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                                            <p className="whitespace-nowrap text-ellipsis w-[80px] text-right overflow-hidden text-sm leading-6 text-gray-900 inline-block h-[21px]" title={item.country}>{item.country}</p>
                                            <p className="whitespace-nowrap text-ellipsis w-[80px] text-right overflow-hidden mt-1 text-xs leading-5 text-gray-500 h-[21px]" title={item.crop}>{item.crop}</p>
                                        </div>
                                    </li>
                                )
                                ) : <div className='font-bold text-slate-500 flex justify-center'>...</div>
                            }
                        </ul>
                    </div>}
            </div>
        </ClickAwayListener >
    )
}