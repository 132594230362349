import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { useIntl } from 'react-intl';

export default function ExcelExport({ excelData, fileName }) {
    const intl = useIntl()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <span
            className="config-btn uppercase"
            onClick={() => exportToExcel()} title={intl.formatMessage({ id: 'app.agscout.exportExcel', defaultMessage: 'Export Excel' })}>
            <span className="mr-2 align-middle material-icons">
                download
            </span>
            {intl.formatMessage({ id: 'app.admin.stationGrid.download', defaultMessage: 'Download' })}
        </span>
    )
}