import React, { useState, useEffect } from 'react'
import { Input, Switch } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';
import {getAuthToken, BASEURI} from '../utils';


function DGAUserACC(props){
    const intl = useIntl();

    useEffect(()=>{
        loadCredentials();
    }, [])

    function loadCredentials(){
        let url = `${BASEURI}/api/dga/credentials/`,
        options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201)
                return response.text().then(text => {throw new Error(text)});

                return response.json();
            })
            .then((credential) => {
                props.setUserAccCredentials(credential)
            })
            .catch(e => {
                console.error(e)
            });

        });
    }

    function handleChange(e){
        props.setUserAccCredentials({
            ...props.userAccCredentials,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <div class="modal-form-row">
                <Input
                    name='dga_acc_username'
                    label={intl.formatMessage({id:'app.dga.jobTable.username', defaultMessage:'USERNAME'})}
                    value={props.userAccCredentials.dga_acc_username}
                    onChange={handleChange}
                    required/>
                <Input
                    name='dga_acc_password'
                    label={intl.formatMessage({id:'app.dga.jobTable.password', defaultMessage:'PASSWORD'})}
                    value={props.userAccCredentials.dga_acc_password}
                    onChange={handleChange}
                    required/>
            </div>
        </div>
    )
}

export default DGAUserACC;