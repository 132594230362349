import { React, useContext } from 'react';
import { useIntl } from 'react-intl';
import { STATUS_COLORS } from './Scheduler';
import { toZonedTime } from 'date-fns-tz';
import { TimezoneContext } from './TabLayout';


function EventItem(props) {
    const intl = useIntl();
    const { item } = props;
    const color = STATUS_COLORS[item.status];
    const { timezone, setTimezone, timezones } = useContext(TimezoneContext);
    const deviceTimezone = item?.irrigation_task?.device?.details?.timezone

    return (
        <article className={`m-4 rounded-xl bg-white p-4 ring-1 sm:p-6 lg:p-8`} style={{ '--tw-ring-color': color.color }}>

            <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium sm:text-xl">
                    <a href="#" className="hover:underline"> {item.irrigation_task.device.name} [{item.irrigation_task.zone.name}] </a>
                </h3>

                <strong
                    className="rounded px-3 py-1.5 text-[10px] font-medium text-white"
                    style={{ ...color }}
                >
                    {props.irrigationStatus.find((item) => item.key === props.status).status}
                </strong>
            </div>
            <div className="flex items-start sm:gap-8">
                <div>
                    <p className="flex gap-3  mt-2 text-sm text-gray-700">
                        <span>Precip: {item?.precipitation?.toFixed(3)}{item?.irrigation_task?.device?.details?.precip_unit} </span>
                        <span aria-hidden="true">&middot;</span>
                        <span>Vol: {item?.volume?.toFixed(3)}{item?.irrigation_task?.device?.details?.volume_unit}</span>
                    </p>
                    <p className="flex gap-3 text-sm text-gray-700">
                        <span>{intl.formatMessage({ id: "app.scheduler.sent_to_device", defaultMessage: 'Sent To Station: ' })}</span>
                        {item?.sent_to_network ?
                            <i className="material-icons text-green-500 text-[17px]" role="presentation">check</i> :
                            <i className="material-icons text-red-500 text-[17px]" role="presentation">close</i>
                        }
                    </p>
                    <div className='flex items-center text-xs mt-1 font-medium items-center gap-1 text-gray-500'>
                        <span className='relative mr-1'>
                            <i className="material-icons text-[15px]" role="presentation">public</i>
                            <i className="material-icons absolute bottom-[5px] right-[0px] text-[8px] bg-white rounded-full" role="presentation">schedule</i>
                        </span>{timezone}</div>
                    <div className="flex items-center gap-1 text-gray-500">
                        <svg
                            className="h-4 w-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>

                        <span className="text-xs font-medium">
                            {intl.formatDate(toZonedTime(item.start, timezone), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                        </span>

                        <span aria-hidden="true">&mdash;</span>

                        <p className="text-xs font-medium sm:inline-block">
                            {intl.formatDate(toZonedTime(item.end, timezone), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                        </p>
                    </div>
                </div>
            </div>
        </article >

    )

}

export default EventItem;