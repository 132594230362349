import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from '../../utils';
import Highcharts from 'highcharts/highstock';
import ReportChart from './ReportChart';
import WeatherStations from './WeatherStations';
import TempReportChart from './TempReportChart';
import EToReportChart from './EToReportChart';
import ConfigModal from './ConfigModal';


function WeatherReport(props) {

    const [chillHours, setChillHours] = useState({ last: [], current: [] });
    const [chillPortions, setChillPortions] = useState({ last: [], current: [] });
    const [degreeDays, setDegreeDays] = useState({ last: [], current: [] });
    const [showConfig, setShowConfig] = useState(false);
    const [dailyETo, setDailyETo] = useState([]);
    const [weeklyETo, setWeeklyETo] = useState([]);
    const [dailyPrecip, setDailyPrecip] = useState([]);
    const [weeklyPrecip, setWeeklyPrecip] = useState([]);
    const [cherryGrowing, setCherryGrowing] = useState({ last: [], current: [] });
    const [temperature, setTemperature] = useState([]);
    const intl = useIntl();

    Highcharts.Point.prototype.highlight = function (event) {

        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        // this.series.chart.tooltip.refresh([this]); // Show the tooltip
        // this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    function handleConfigurationButton() {
        setShowConfig(!showConfig);
    }

    function syncExtremes(e) {
        var thisChart = this.chart;

        if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
            Highcharts.each(Highcharts.charts, function (chart) {
                // console.log('chart', chart);
                if (chart !== thisChart && chart !== undefined) {
                    if (chart.xAxis[0] && chart.xAxis[0].setExtremes) { // It is null while updating
                        chart.xAxis[0].setExtremes(
                            e.min,
                            e.max,
                            undefined,
                            false,
                            { trigger: 'syncExtremes' }
                        );
                    }
                }
            });
        }
    }


    function hoverEventListener(e) {

        // if (charts.length < 1)
        // return;

        let chart,
            point,
            i,
            event;

        console.log('charts length ', Highcharts.charts);

        for (i = 0; i <= Highcharts.charts.length; i = i + 1) {
            if (Highcharts.charts[i] === undefined)
                continue
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart.pointer.normalize(e);
            // Get the hovered point
            point = chart.series[0].searchPoint(event, true);

            if (point) {
                point.highlight(e);
            }
        }
    }

    // get chill hours report
    useEffect(() => {

        let url = BASEURI + '/api/reports/chill-hours/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setChillHours(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get chill portions report
    useEffect(() => {

        let url = BASEURI + '/api/reports/chill-portions/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setChillPortions(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get degree days report
    useEffect(() => {

        let url = BASEURI + '/api/reports/degree-days/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setDegreeDays(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get weekly eto report
    useEffect(() => {

        let url = BASEURI + '/api/reports/eto/7d/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setWeeklyETo(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get daily eto report
    useEffect(() => {

        let url = BASEURI + '/api/reports/eto/D/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setDailyETo(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get weekly precipitation report
    useEffect(() => {

        let url = BASEURI + '/api/reports/precip/7d/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setWeeklyPrecip(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get daily precipitation report
    useEffect(() => {

        let url = BASEURI + '/api/reports/precip/D/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setDailyPrecip(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get cherry growing report
    useEffect(() => {

        let url = BASEURI + '/api/reports/cherry-growing/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setCherryGrowing(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // get temperature report
    useEffect(() => {

        let url = BASEURI + '/api/reports/temperature/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((report) => {
                        setTemperature(report);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }, [props.refreshAll])

    // Removed to disable synced scrolling -- uncomment to enable
    //add event listeners
    // useEffect(() => {
    // 	['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
    // 		document.getElementById('chill_hours').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('chill_portions').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('degree_days').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('eto_daily').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('eto_weekly').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('cherry_growing').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 		document.getElementById('temperature').addEventListener(
    // 			eventType,
    // 			hoverEventListener
    // 		);
    // 	});
    // }, [])


    return <div className="mdl-grid" style={{ justifyContent: 'center' }}>
        <div className="mdl-grid mdl-cell mdl-cell--12-col">
            {showConfig && <ConfigModal onClose={handleConfigurationButton} stations={props.stations} />}

            <div className="flex flex-row flex-wrap-reverse content-center w-full p-4 justify-between  bg-white rounded-md">
                <div className='p-2'>
                    <WeatherStations
                        refreshAll={props.refreshAll}
                        setRefreshAll={props.setRefreshAll}
                        stations={props.stations}
                    />
                </div>
                <div className="config-btn h-11 " onClick={handleConfigurationButton}>
                    <i className="material-icons">settings</i> {intl.formatMessage({ id: 'app.admin.weather.config', defaultMessage: 'CONFIGURATIONS' })}
                </div>
            </div>


            <div className='mdl-grid mdl-cell mdl-cell--12-col' id='reports_container'>
                <ReportChart
                    reportID='chill_hours'
                    data={chillHours}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.chillHours', defaultMessage: 'CHILL HOURS' })}
                    colors={['#A0C2E5', '#2F97FF']}
                />
                <ReportChart
                    reportID='chill_portions'
                    data={chillPortions}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.chillPortions', defaultMessage: 'CHILL PORTIONS' })}
                    colors={['#666666', '#000000']}
                />
                <ReportChart
                    reportID='degree_days'
                    data={degreeDays}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.degreeDays', defaultMessage: 'DEGREE DAYS' })}
                    colors={['#D98686', '#C95353']}
                />
                <ReportChart
                    reportID='cherry_growing'
                    data={cherryGrowing}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.cherryGrowing', defaultMessage: 'CHERRY GROWING' })}
                    colors={['#A57FA5', '#4C004C']}
                />
                <EToReportChart
                    reportID='eto_daily'
                    data={{ eto: dailyETo, precip: dailyPrecip }}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.precipEtoH', defaultMessage: 'PRECIP & ETO (LAST 24H)' })}
                    colors={['#000045', '#2F97FF']}
                />
                <EToReportChart
                    reportID='eto_weekly'
                    data={{ eto: weeklyETo, precip: weeklyPrecip }}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.precipEtoW', defaultMessage: 'PRECIP & ETO (LAST WEEK)' })}
                    colors={['#000045', '#2F97FF']}
                />
                <TempReportChart
                    reportID='temperature'
                    data={temperature}
                    sync={syncExtremes}
                    title={intl.formatMessage({ id: 'app.admin.weather.temperature', defaultMessage: 'TEMPERATURE (24H)' })}
                    colors={['#A57FA5', '#4C004C']}
                />
            </div>
        </div>
    </div>;
}


export default WeatherReport;

