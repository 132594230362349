import React, { useEffect } from 'react';
import { useNavigate, Navigate, Outlet, useLocation } from 'react-router-dom';



export default function PrivateRoutes(props) {
    const navigate = useNavigate();
    const location = useLocation();

    if (props.lockedUser)
        navigate('billing')

    // if (!props.loggedin) {
    //     props.setNavigateTo(location.pathname)
    // }


    useEffect(() => {
        if (props.pageRefreshed & location.pathname !== '/') {
            props.setPageRefreshed(false)
            navigate('/')
        }
        props.setPageRefreshed(false)

    }, [location.pathname])

    if (props.navigateTo) {
        navigate(props.navigateTo)
        props.setNavigateTo(null)

    }


    return (
        props.loggedin ? <Outlet /> : <Navigate to='/login' />
    )
}