import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl';
import { Grid as KGrid, GridCell, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { BASEURI, getAuthToken } from '../utils';



export default function TransactionsGrid(props) {

    const [newTransaction, setNewTransaction] = useState({
        id: undefined,
        date: new Date(),
        amount: 0,
        reference: "",
        notes: "",
        inEdit: true,
    })
    const [Transaction] = useState({
        id: undefined,
        date: new Date(),
        amount: 0,
        reference: "",
        notes: "",
        inEdit: true,
    })

    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        if (props.isAdmin) {
            props.refresh();
        }

    }, [newTransaction])
    useEffect(() => {
        console.log(props.balance)
    }, [props.balance])

    function add() {
        props.setTransactions([newTransaction, ...props.transactions])
    }

    function transactionItemChange(event) {

        const data = props.transactions.map(tranc => {
            return tranc.inEdit === event.dataItem.inEdit ?
                { ...tranc, [event.field]: event.value } : tranc
        });
        props.setTransactions(data);
    }

    function cancel() {
        const data = props.transactions.filter(trans => trans.id && trans)
        props.setTransactions(data);

    }

    function save(dataItem) {
        delete dataItem.inEdit
        // save new transaction

        let url = BASEURI + `/api/billing/transaction/${props.userId}/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((transaction) => {
                        transaction.date = new Date(transaction.date)

                        let data = props.transactions.map(tr => {
                            return tr.id === undefined ? { ...transaction } : tr
                        })

                        props.setTransactions(data)
                        setNewTransaction(Transaction)
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }

    const Actions = ActionCell({
        save: save,
        cancel: cancel,
    })

    return (
        <div>
            <KGrid
                data={filterBy(orderBy(props.transactions, sort), filter)}
                sort={sort}
                onSortChange={e => setSort(e.sort)}
                filterable
                style={{ height: props.height ? props.height : 480 }}
                filter={filter}
                skip={props.skip}
                total={props.total}
                take={props.take}
                editField="inEdit"
                pageable
                onItemChange={transactionItemChange}
                onPageChange={props.onPageChange}
                onFilterChange={(e) => {
                    setFilter(e.filter);
                }}>
                <GridNoRecords>
                    {/* Use graph grid no data message */}
                    {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                </GridNoRecords>
                <GridToolbar>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={add} disabled={!props.isAdmin}>
                            {intl.formatMessage({ id: 'app.bills.transactionGrid.addFund', defaultMessage: 'Add Fund' })}</button>
                        <span><b>{intl.formatMessage({ id: 'app.bills.transactionGrid.balance', defaultMessage: 'Balance' })}: {intl.formatNumber(props.balance, { style: 'currency', currency: 'USD' })}</b></span>
                    </div>
                </GridToolbar>

                <Column field="date" title={intl.formatMessage({ id: 'app.bills.transactionGrid.date', defaultMessage: 'Date' })} format="{0:dd/MM/yy HH:mm}" filter="date" editable={false} />
                <Column field="amount" cell={AmountCell} title={intl.formatMessage({ id: 'app.bills.transactionGrid.amount', defaultMessage: 'Amount' })} filter="numeric" editor="numeric" />
                <Column field="reference" title={intl.formatMessage({ id: 'app.bills.transactionGrid.reference', defaultMessage: 'Reference' })} />
                <Column field="notes" title={intl.formatMessage({ id: 'app.bills.transactionGrid.notes', defaultMessage: 'Notes' })} />
                {
                    props.isAdmin &&
                    <Column cell={Actions} title="Actions" />
                }

            </KGrid>
        </div>
    );
}


export function AmountCell(props) {
    const { dataItem } = props;
    let color
    color = dataItem.amount > 0 ? color = 'green' : color = 'red';
    const intl = useIntl();

    function handleChange(e) {
        props.onChange({
            dataItem: dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value
        })
    }

    return (
        dataItem.inEdit ?
            <td colSpan="1" className="k-grid-edit-cell">
                <input name="amount" className="k-textbox" type={"number"} value={dataItem.amount} onChange={handleChange} style={{ width: '100%' }} />
            </td>
            :
            <td style={{ color: color }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <i style={{ color: color }} className="material-icons">
                        {color === 'green' ?
                            intl.formatMessage({ id: 'app.bills.transactionGrid.add', defaultMessage: 'add' }) :
                            intl.formatMessage({ id: 'app.bills.transactionGrid.remove', defaultMessage: 'remove' })}
                    </i>
                    ${dataItem.amount < 0 ?
                        intl.formatNumber(dataItem.amount * -1, { style: "currency", currency: 'USD' }) :
                        intl.formatNumber(dataItem.amount, { style: "currency", currency: 'USD' })}

                </div>
            </td>
    );

}


function ActionCell({ save, cancel }) {
    const intl = useIntl();
    return props => {

        // render() {
        const { dataItem } = props;

        return (
            <td>
                {
                    dataItem.inEdit &&
                    <>
                        <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={() => save(dataItem)}>
                            {intl.formatMessage({ id: 'app.bills.transactionGrid.save', defaultMessage: 'Save' })}</button>
                        <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={cancel}>
                            {intl.formatMessage({ id: 'app.bills.transactionGrid.cancel', defaultMessage: 'Cancel' })}
                        </button>
                    </>
                }
            </td>
        )
        // }
    }
}