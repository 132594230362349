// React
import React, { useEffect, useState } from 'react'

// Third-party
import Highcharts from 'highcharts/highstock';

// Project
import { SCOUTURI, SCOUTAPIKEY} from '../../utils'
import { buildParamStr } from '../utils';

function Chart(props){

    const [data, setData] = useState()

    function getData(){

        if (!props.farm || !props.scoutType )
        return

        let params = buildParamStr({
            year: props.year,
            type: props.scoutType?.id
        })

        let url = `${SCOUTURI}/api/v1.0/scoutcounts/${props.farm?.id}/${params}`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };
        const APIKEY = SCOUTAPIKEY

        options.headers.Authorization = `Api-Key ${APIKEY}`;
    
        fetch(url, options)
        .then((response) => {
            if(!response.ok){
                response.text().then(err => console.log(err));
                throw new Error(response.statusText, response.status);
            }
            return response.json();
        })
        .then((response) => {
            setData(response.data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    function initChart(){

        const seriesOpt = {
            type: 'column'
        }

        Highcharts.chart('scouts_comparison_chart', {
            chart: {
                // marginLeft: 50, // Keep all charts left aligned
                spacingTop: 50,
                spacingBottom: 10,
                spacingLeft: 10,
                height: '200px',
                // width: '100%'
            },
            title: {
                text: null,
			},
			plotOptions:{
				column:{
					dataLabels: {
						// rotation: props.data.eto.length > 5 ? -90 : 0,
						enabled: true,
						allowOverlap: true,
						// y: props.data.eto.length > 5 ? -20 : 0,
						style:{
							color: 'black',
							fontSize: '13px',
							fontWeight: 'bold',
							textOutline: null
						}
					}
				}
			},
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
			},
			xAxis: {
				type: 'category',
				min: 0,
			},
            yAxis: {
                offset: -10,
                title:{
                    text:  'conteo total/campo',
                },
            },
			tooltip: {
				shared: true,
                valueDecimals: 1
			},
            series : [
                {
                    name: data.type,
                    data: data.counts,
                    // color: '#F4C6D0',
                    // color: '#B2FFB2',
                    // color: '#A0E5A0',
                    color: '#A9E7A9',
                    ...seriesOpt
                }
            ]
        });

    }

    useEffect(() => {
        if(props.farm)
        getData()
    }, [props.farm, props.year, props.scoutType])

    useEffect(() => {
        if (data && data.counts)
        initChart()
    }, [data])

    return <div id='scouts_comparison_chart'>
    </div>
}


function ChartFrame(props){
    return <div className="flex-grow">    
        {props.children}
    </div>
}

function ScoutChart(props){
    return <ChartFrame>
            <Chart {...props}/>
        </ChartFrame>
}

export default ScoutChart;