import React, { useEffect } from "react";

import Highcharts from 'highcharts/highstock';

import Spinner from "../../general/Spinner";

export default function PlotStatChart(props){

    const { data, plotID } = props;
    const COLORS = {
        'Dardos post poda': '#7cb5ec',
        'Dardos pre poda': '#434348',
        'Frutos': '#90ed7d',
        'Plantas no injertadas': '#f7a35c',
        'Flores': '#8085e9',
        'default': '#f15c80',
    }

    function getColor(graphName){
        if(COLORS[graphName])
            return COLORS[graphName];
        else
            return COLORS.default;
    }

    function initChart(){

        const seriesOpt = {
            type: 'column',
            dataSorting:  {
                enabled: true,
                sortKey: 'name'
            }
        }

        const xAxisCategories = [],
              categories = {};

        data.map(item => {
            if (xAxisCategories.indexOf(item.scouted_date__year) === -1)
            xAxisCategories.push(item.scouted_date__year);
        })

        data.map(item => {
            const type = item.type_of_scout__scout_type;
            if (!categories.hasOwnProperty(type)){
                categories[type] = {
                    name: type,
                    color: getColor(type),
                    data: xAxisCategories.map(cat => ({
                        name: cat,
                        y: null
                    })),
                    ...seriesOpt
                }
            }

        })

        data.map(item => {
            const type = item.type_of_scout__scout_type;
            let dataPoint = categories[type].data.find(point => point.name === item.scouted_date__year)
            dataPoint.y = item.value;
        })

        let series = Object.getOwnPropertyNames(categories).map(item => categories[item])
        // let series = categories.map(category => ({
        //         name: category,
        //         data: data[category].map(dataPoint => {
        //             if (xAxisCategories.indexOf(dataPoint.scouted_date__year) === -1)
        //             xAxisCategories.push(dataPoint.scouted_date__year);
        //             return {
        //                 name: dataPoint.scouted_date__year,
        //                 y: dataPoint.tcpp_chart_data,
        //                 color: getColor(dataPoint.type_of_scout__scout_type)
        //             }
        //         }),
        //         ...seriesOpt
        //     }));
        // Sort x-axis values
        xAxisCategories.sort((a, b) => b - a)

        Highcharts.chart('stats_chart_plot' + plotID, {
            chart: {
                // marginLeft: 50, // Keep all charts left aligned
                spacingTop: 50,
                spacingBottom: 10,
                spacingLeft: 10,
                height: '250px',
                // width: '100%'
            },
            title: {
                text: null,
			},
            credits: {
                enabled: false
            },
            // legend: {
            //     enabled: false
			// },
			xAxis: {
				type: 'category',
                categories: xAxisCategories,
				// min: 0,
                reversed: true,
                // tickInterval: 0
			},
            yAxis: {
                title:{
                    text:  'totales/cuartel',
                },
                offset: -10,
                // opposite: true
            },
			tooltip: {
				shared: true,
                valueDecimals: 1
			},
            series : series
        });

    }

    useEffect(() => {
        if (data)
        initChart()
    }, [data])

    return (
        data ?
        <div id={'stats_chart_plot' + plotID}>
        </div>
        :
        <div className="flex-grow w-full">
            <Spinner
                color={'black'}
                show={true}
                backDrop={true}
                backDropColor={'white'}
                backDropOpacity={0.6}
                size={20}
            />
        </div>
    )

}