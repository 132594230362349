import React, { useState, useCallback, useEffect, createContext, useContext } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Popup } from "@progress/kendo-react-popup";
import { Fade } from '@progress/kendo-react-animation';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from '../utils';
import { FormWithCustomEditor } from './CustomForm';
import { useInternationalization } from '@progress/kendo-react-intl';
import { TimezoneContext } from './TabLayout';
import { toZonedTime } from 'date-fns-tz';
import AgendaViewSchedulerItem from './AgendaViewSchedulerItem'

import '@progress/kendo-date-math/tz/all'

import {
    Scheduler,
    SchedulerItem,
    SchedulerHeader,
    DayView,
    WeekView,
    MonthView,
    AgendaView,
} from '@progress/kendo-react-scheduler';
import TaskFormModal from './TaskFormModal';
import loadingIcon from '../loading_white.svg';

import SchedulerEditItemWrapper from './SchedulerEditItemWrapper';


// const customModelFields = {
//     id: 'id',
//     title: 'irrigation_task.title',
//     description: 'irrigation_task.description',
//     start: 'start',
//     end: 'end',
//     recurrenceRule: 'recurrence_rule',
//     isAllDay: 'is_all_day',
//     recurrenceId: 'recurrence_id',
//     recurrenceExceptions: 'recurrence_exceptions',
//     precip: 'precip',
//     duration: 'duration'
// };

const dateRangeMap = {
    agenda: AgendaView.defaultProps.dateRange,
    // timeline: TimelineView.defaultProps.dateRange,
    day: DayView.defaultProps.dateRange,
    week: WeekView.defaultProps.dateRange,
    month: MonthView.defaultProps.dateRange,
};

const numberOfDaysMap = {
    agenda: AgendaView.defaultProps.numberOfDays,
    // timeline: TimelineView.defaultProps.numberOfDays,
    day: DayView.defaultProps.numberOfDays,
    week: WeekView.defaultProps.numberOfDays,
    month: MonthView.defaultProps.numberOfDays,
};

export const STATUS_COLORS = {
    'Saved': { background: '#e6fff2', color: "#218838", detailsColor: '#02e316' }, // agviewer green
    'Running': { background: '#e6f0ff', color: "#0044cc", detailsColor: "#2b72ff" }, // blue
    'Executed OK': { background: '#e0f7fa', color: "#006064", detailsColor: "#04c4cc" }, //cyan
    'Sending': { background: '#ffeb99', color: "#b37800", detailsColor: "#ffab00" },

    // Not determined
    'Removing': { background: '#ffcccc', color: "#cc0000", detailsColor: "#fa0505" },
    'Sending irrigation has failed': { background: '#ffcccc', color: "#cc0000", detailsColor: "#fa0505" },
    'Executed with failure': { background: '#ffcccc', color: "#cc0000", detailsColor: "#fa0505" },
    'Stopped due to Manual Irrigation': { background: '#ffcccc', color: "#cc0000", detailsColor: "#fa0505" },
    'Stopped due to Communication issues': { background: '#ffcccc', color: "#cc0000", detailsColor: "#fa0505" },
}

const CustomItem = (props) => {
    const intl = useIntl();
    const { dataItem } = props;
    let color = STATUS_COLORS[dataItem?.status]; // Change this color according to irrigation status
    const { timezone, setTimezone, timezones } = useContext(TimezoneContext)
    const deviceTimezone = dataItem.placeholder ?
        dataItem.timezone :
        dataItem?.irrigation_task?.device?.details?.timezone;


    const irrigationStatus = [
        // translate the type part in any language
        { status: intl.formatMessage({ id: "app.scheduler.status_saved", defaultMessage: 'Saved' }), key: 'Saved' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending", defaultMessage: 'Sending' }), key: 'Sending' },
        { status: intl.formatMessage({ id: "app.scheduler.status_removing", defaultMessage: 'Removing' }), key: 'Removing' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending_failed", defaultMessage: 'Sending irrigation has failed' }), key: 'Sending irrigation has failed' },
        { status: intl.formatMessage({ id: "app.scheduler.status_running", defaultMessage: 'Running' }), key: 'Running' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_ok", defaultMessage: 'Executed OK' }), key: 'Executed OK' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_failure", defaultMessage: 'Executed with failure' }), key: 'Executed with failure' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_by_user", defaultMessage: 'Stopped by User' }), key: 'Stopped by User' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_manual_irrigation", defaultMessage: 'Stopped due to Manual Irrigation' }), key: 'Stopped due to Manual Irrigation' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_communication_issues", defaultMessage: 'Stopped due to Communication issues' }), key: 'Stopped due to Communication issues' }
    ]


    const ref = React.useRef(null);
    const [show, setShow] = useState(false);

    return (
        <>
            <SchedulerItem
                {...props}
                style={{
                    ...props.style,
                    borderRadius: '8px',
                    // margin: '2px',
                    color: '#006400',
                    ...color,
                    background: dataItem.placeholder ? "repeating-linear-gradient(45deg, #e6fff2, #e6fff2 10px, #9edbac 10px, #9edbac 20px)" : color?.background,
                    fontWeight: 'bold',
                    // '--tw-ring-color': dataItem?.irrigation_task?.color
                    borderColor: dataItem.placeholder ? dataItem.color : dataItem?.irrigation_task?.color,

                }}
                className='!border-l-4 leading-[1px]'
                ref={ref}

                onClick={(e) => {
                    // Prevent propagtion for remove button click
                    if ('k-icon k-i-close' !== e.syntheticEvent.target?.className)
                        setShow(!show)
                }
                }

                onRemoveClick={(event) => {
                    props.onRemoveClick(event);
                }}

                // Prevent editing on placeholders
                onDoubleClick={(event) => {
                    if (dataItem.placeholder)
                        return
                    props.onDoubleClick(event)
                }}
                // Prevent editing on placeholders
                onDrag={(event) => {
                    if (dataItem.placeholder)
                        return
                    props.onDrag(event)
                }}

                editable={{
                    remove: !Boolean(dataItem.placeholder), // let this
                    resize: !Boolean(dataItem.placeholder), // let this
                    select: !Boolean(dataItem.placeholder), // let this
                }}
            // onDoubleClick={() => null}
            // onDrag={() => null}
            // onResizeStartDrag={() => null}

            >
                {props.children}
                <span style={{ color: dataItem.placeholder ? '#218838' : color?.detailsColor, fontWeight: "normal" }}>
                    <span className='text-xs p-0 m-0 pl-2'>
                        {dataItem.placeholder ?
                            <>{dataItem?.device} [{dataItem?.zone}]</> :
                            <>{dataItem?.irrigation_task?.device?.name} [{dataItem?.irrigation_task?.zone?.name}]</>}
                    </span><br />
                    <span className='mt-[-10px] text-xs p-0 m-0 pl-2'>{dataItem?.precipitation?.toFixed(3)}{dataItem?.irrigation_task?.device?.details?.precip_unit}</span>
                </span>
            </SchedulerItem>

            <Popup
                show={show}
                anchorAlign={{
                    horizontal: "center",
                    vertical: "top",
                }}
                popupAlign={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                anchor={ref.current && ref.current.element}
                className='rounded-t-md'

            >
                <article className={`m-[-5px] rounded-xl bg-white p-4 ring min-w-[250px]`} style={{ '--tw-ring-color': dataItem.placeholder ? '#F5F5DC' : color.background }}>
                    <button className='flex justify-center items-center rounded-full hover:bg-red-50 w-[25px] h-[25px] text-red-500 absolute top-[1px] right-[1px] text-xsm' onClick={() => setShow(false)}>
                        <i className="material-icons" role="presentation">close</i>
                    </button>


                    <h3 className="text-lg font-medium sm:text-xl mt-4">
                        <a href="#" className="hover:underline">
                            {dataItem.placeholder ?
                                <>{dataItem?.device} [{dataItem?.zone}]</> :
                                <>{dataItem?.irrigation_task?.device?.name} [{dataItem?.irrigation_task?.zone?.name}]</>}</a>
                    </h3>
                    {dataItem.placeholder ?
                        <strong
                            className="rounded px-3 py-1.5 text-[10px] font-medium text-white"
                            style={{ color: '#4c926b', background: '#F5F5DC' }}
                        >
                            {intl.formatMessage({ id: "app.scheduler.plannedIrrigation", defaultMessage: 'Planned Irrigation' })}
                        </strong> :
                        <strong
                            className="rounded px-3 py-1.5 text-[10px] font-medium text-white"
                            style={{ ...color }}
                        >
                            {irrigationStatus.find((item) => item.key === dataItem?.status)?.status}
                        </strong>}

                    <div className="flex items-start sm:gap-8">

                        <div>
                            <p className="flex gap-3  mt-4 text-sm text-gray-700">
                                <span>Precip: {dataItem.placeholder ? 'N/A' : <>{dataItem?.precipitation?.toFixed(3)}{dataItem?.irrigation_task?.device?.details?.precip_unit} </>}</span>
                                <span aria-hidden="true">&middot;</span>
                                <span>Vol: {dataItem.placeholder ? 'N/A' : <>{dataItem?.volume?.toFixed(3)}{dataItem?.irrigation_task?.device?.details?.volume_unit}</>}</span>
                            </p>
                            <p className="flex gap-3 text-sm text-gray-700">
                                <span>{intl.formatMessage({ id: "app.scheduler.sent_to_device", defaultMessage: 'Sent To Station: ' })}</span>
                                {dataItem?.sent_to_network ?
                                    <i className="material-icons text-green-500 text-[17px]" role="presentation">check</i> :
                                    <i className="material-icons text-red-500 text-[17px]" role="presentation">close</i>
                                }
                            </p>

                            <div className='flex items-center text-xs mt-2 font-medium items-center gap-1 text-gray-500'>
                                <span className='relative mr-1'>
                                    <i className="material-icons text-[15px]" role="presentation">public</i>
                                    <i className="material-icons absolute bottom-[5px] right-[0px] text-[8px] bg-white rounded-full" role="presentation">schedule</i>
                                </span>
                                {deviceTimezone}
                            </div>

                            <div className={' flex items-center gap-1'}>
                                <svg
                                    className={`h-4 w-4 ${dataItem?.sent_to_network ? 'text-green-500' : 'text-gray-500'}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                </svg>

                                <span className="text-xs font-medium">
                                    {intl.formatDate(toZonedTime(dataItem.start, deviceTimezone), { hour: 'numeric', minute: 'numeric' })}
                                </span>

                                <span aria-hidden="true">&mdash;</span>

                                <p className="text-xs font-medium sm:inline-block">
                                    {intl.formatDate(toZonedTime(dataItem.end, deviceTimezone), { hour: 'numeric', minute: 'numeric' })}
                                </p>
                            </div>
                        </div>
                    </div>
                    {!dataItem.placeholder &&
                        <div className='mt-2'>
                            <div className='absolute bottom-[-4px] right-[1px]'>
                                <button
                                    onClick={(event) => { props.onDoubleClick(event); setShow(false) }}
                                    class="rounded-md px-1 text-green-700 hover:text-white transition duration-300 ease-in-out hover:bg-green-500 hover:shadow-lg">
                                    {intl.formatMessage({ id: "app.chart.moreMenu.edit", defaultMessage: 'Edit' })}
                                </button>
                            </div>
                        </div>}
                </article >
            </Popup >
        </>)
}

export const TaskCrudContext = createContext();

function IrrigationScheduler(props) {
    const intl = useIntl();
    const kendoIntl = useInternationalization();
    const [data, setData] = useState([]);
    const [view, setView] = useState('week');
    const [date, setDate] = useState(new Date());
    const [showDialog, setShowDialog] = useState(false);
    const [addingTask, setAddingTask] = useState(false);
    const [updatingTask, setUpdatingTask] = useState(false);
    const [deletingTask, setDeletingTask] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errors, setErrors] = useState([]);
    const [activeSectors, setActiveSectors] = useState([]);
    const [intervalId, setIntervalId] = useState(null);
    const numberOfDays = numberOfDaysMap[view];
    const dateRange = dateRangeMap[view]({ date, intl: kendoIntl, numberOfDays });
    const [placeholders, setPlaceholders] = useState([]);
    const { timezone, setTimezone, timezones } = useContext(TimezoneContext);



    useEffect(() => {
        if (errorText.length > 0)
            setErrors(errorText.split("##").map(item => { return { message: item, key: Math.floor(Math.random() * 50) } }));
    }, [errorText])

    const parseData = (dataItems) => {
        return dataItems.map((item) => {

            return {
                ...item,
                title: item?.irrigation_task?.title,
                duration: item?.managed_by_user ? item?.duration / 60 : item?.irrigation_task?.duration,
                precip: item?.irrigation_task?.precip,
                recurrence_rule: item?.irrigation_task?.recurrence_rule,
                device: item?.irrigation_task?.device,
                zone: item?.irrigation_task?.zone,
                description: item?.irrigation_task?.description,
                recurrence_exceptions: item?.recurrence_exceptions?.map((rec_date) => new Date(rec_date)),
                start: new Date(item.start),
                end: new Date(item.end),
                color: item?.irrigation_task?.color

            }
        });
    }

    const parsePlaceholders = (dataItems) => {
        return dataItems.map((item) => {

            return {
                ...item,
                recurrenceRule: item.recurrence_rule,
                start: new Date(item.start),
                end: new Date(item.end)

            }
        });
    }

    useEffect(() => {
        loadTasks();
        clearInterval(intervalId)
        setIntervalId(null)
    }, [view, date])

    useEffect(() => {
        loadPlaceholders();
    }, [])


    useEffect(() => {
        if (!intervalId)
            setIntervalId(setInterval(
                loadTasks,
                1000 * 60 * 10, // Fetch irrigation task events every 10 minutes
            ))
    }, [intervalId])


    const loadTasks = () => {
        const start_date = dateRange?.start?.toJSON(),
            end_date = dateRange?.end?.toJSON();


        let url = BASEURI + '/api/scheduler/irrigation-task-event/?'
            + new URLSearchParams({ start_date: start_date, end_date: end_date }).toString(),
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((tasks) => {
                        setData(parseData(tasks));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const loadPlaceholders = () => {


        let url = BASEURI + '/api/scheduler/tasks/placeholders/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((placeholders) => {
                        setPlaceholders(parsePlaceholders(placeholders));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const deleteTask = (task) => {
        setDeletingTask(true);

        // const body = {
        //     stop_active_irrigation: task.stop_active_irrigation
        // }


        let url = BASEURI + `/api/scheduler/tasks/${task.irrigation_task.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                // body: JSON.stringify(body)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {
                        if (!response.ok) {
                            const errors = await response.text()
                            throw new Error(`${errors}`)
                        }

                        return response.json();
                    })

                    .then((tasks) => {

                        // if (tasks.active_irrigation) {
                        //     if (window.confirm('Warning: Current sector have active irrigation deleting will stop current irrigation. \nDo you want to continue?')) {
                        //         deleteTask({ ...task, stop_active_irrigation: true })
                        //     }
                        //     setDeletingTask(false);


                        // } else {
                        //     setDeletingTask(false);
                        //     setData(parseData(tasks));
                        // }

                        setDeletingTask(false);
                        setData(parseData(tasks));
                        loadPlaceholders();
                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setDeletingTask(false);
                        setErrorText(error.message);
                    });
            });
    }

    const deleteTaskEvent = (task) => {
        setDeletingTask(true);

        // const body = {
        //     stop_active_irrigation: task.stop_active_irrigation
        // }


        let url = BASEURI + `/api/scheduler/irrigation-task-event/${task.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                // body: JSON.stringify(body)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {

                        if (!response.ok) {
                            const errors = await response.text()
                            throw new Error(`${errors}`)
                        }
                        return response.json();
                    })

                    .then((message) => {
                        setDeletingTask(false);
                        setData(data?.filter((item) => item.id !== task.id));
                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setErrorText(error.message);
                        setDeletingTask(false);
                    });
            });
    }


    const updateTask = (task) => {
        setUpdatingTask(true);

        // if (!task.stop_active_irrigation) {

        // used for agronic
        // task.sector = task?.sector?.id;
        // }


        task.device = task.device.serial;
        task.zone = task?.zone?.id;
        if (typeof task.irrigation_type === 'object')
            task.irrigation_type = task?.irrigation_type?.key;

        let url = BASEURI + `/api/scheduler/tasks/${task.irrigation_task.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(task)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {
                        if (!response.ok) {
                            throw Error(await response.text())
                        }
                        return response.json();
                    })

                    .then((tasks) => {

                        // Check if tasks have active irrigation
                        // if (tasks.active_irrigation) {
                        //     if (window.confirm('Warning: Current sector have active irrigation editing will stop current irrigation. \nDo you want to continue?')) {
                        //         updateTask({ ...task, stop_active_irrigation: true })
                        //     }
                        //     setAddingTask(false);
                        // } else {

                        setData(parseData(tasks));
                        setUpdatingTask(false);
                        loadPlaceholders();

                        // }
                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setUpdatingTask(false);
                        loadTasks();
                        setErrorText(error.message)
                    });
            });

    }


    const updateTaskEvent = (task) => {
        setUpdatingTask(true);

        task.zone_id = task?.zone?.id;
        if (typeof task.irrigation_type === 'object')
            task.irrigation_type = task?.irrigation_type?.key;

        let url = BASEURI + `/api/scheduler/irrigation-task-event/${task.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(task)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {
                        if (!response.ok) {

                            const errors = await response.text()

                            throw new Error(`${errors}`)
                        }

                        return response.json();
                    })

                    .then((newEvent) => {
                        // Check if task have active irrigation
                        const events = data?.filter((item) => item.id !== task.id);
                        events.push(parseData([newEvent])[0]);
                        setData(events)
                        setUpdatingTask(false);

                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setUpdatingTask(false);
                        setErrorText(error.message)
                    })
            });

    }

    const createTask = (task) => {
        setAddingTask(true);

        task.device = task?.device?.serial;
        // task.sector = task?.sector?.id; // agronic use
        task.zone = task?.zone?.id;
        task.irrigation_type = task?.irrigation_type?.key;

        let url = BASEURI + `/api/scheduler/tasks/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(task)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    // this.setState({loggedin:false});
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {

                        if (!response.ok) {
                            throw Error(await response.text())
                        }
                        return response.json();
                    })

                    .then((tasks) => {
                        setAddingTask(false);
                        setData(parseData(tasks));
                        loadPlaceholders();
                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        setAddingTask(false);
                        loadTasks();
                        setErrorText(error.message)
                    });
            });
    }

    const handlViewChange = useCallback((event) => {
        setView(event.value);
    }, [setView])

    const handleDateChange = useCallback(
        (event) => {
            setDate(event.value);
        },
        [setDate]
    );

    const handleDataChange = useCallback(
        ({ created, updated, deleted }) => {

            if (deleted.length) {
                // Delete operation is handled inside SchedulerRemoveDialog
                // deleteTaskEvent(deleted[0]);

            }

            if (updated.length) {
                if (updated[0]['editSeries'])
                    updateTask(updated[0]);
                else
                    updateTaskEvent(updated[0]);
            }

            if (created.length) {
                createTask(created[0]);
            }
        }
    )
    return (

        <div className="p-0 m-0">
            <div className='grid gap-1 grid-cols-12'>
                <div className='col-span-12 bg-white p-4 m-1 h-screen'>
                    <TaskCrudContext.Provider value={{ deleteTask, deleteTaskEvent, updateTask, updateTaskEvent }}>
                        <Scheduler
                            item={CustomItem}
                            onDataChange={handleDataChange}
                            data={[...data, ...placeholders]}
                            onViewChange={handlViewChange}
                            view={view}
                            onDateChange={handleDateChange}
                            date={date}
                            editable={true}
                            form={FormWithCustomEditor}
                            className='!h-full'
                            header={(props) => <SchedulerHeader
                                {...props}
                                children={[
                                    <div className='flex items-center justify-center p-2 text-slate-500 bg-white mr-4'>
                                        <span className='relative mr-1'>
                                            <i className="material-icons" role="presentation">public</i>
                                            <i className="material-icons absolute bottom-[5px] right-[0px] text-[12px] bg-white rounded-full" role="presentation">schedule</i>
                                        </span>
                                        <select defaultValue={timezone} onChange={(e) => setTimezone(e.target.value)}>
                                            {timezones.map(item => <option value={item}>{item}</option>)}
                                        </select>
                                    </div>,
                                    ...props.children,]} />}

                            editItem={(props) => {
                                return <SchedulerEditItemWrapper {...props} />
                            }}
                            timezone={timezone}
                        >
                            {/* <TimelineView /> */}
                            <WeekView showWorkHours={false} />
                            <DayView showWorkHours={false} />
                            <MonthView showWorkHours={false} />
                            <AgendaView
                                viewTask={AgendaViewSchedulerItem}
                                showWorkHours={false} />
                        </Scheduler>
                    </TaskCrudContext.Provider>
                </div>


                <span className="ag-add-widget-button">
                    <button
                        className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--accent"
                        onClick={() => setShowDialog(!showDialog)}
                    >
                        <i className="material-icons" role="presentation">add</i>
                        <span className="visuallyhidden">
                            'Add'
                        </span>
                    </button>
                </span>
                {
                    showDialog &&
                    <TaskFormModal
                        // stations={stationsCopy}
                        // handleStationFilterChange={handleStationFilterChange}
                        // sectors={sectors}
                        // setStation={setStation}
                        loadTasks={loadTasks}
                        onClose={setShowDialog}
                        setAddingTask={setAddingTask}
                        setErrorText={setErrorText}
                        setError={setError}
                        setErrors={setErrors}
                    />
                }
                <NotificationGroup
                    style={{
                        top: '65px',
                        left: '50%',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        transform: 'translateX(-50%)',
                        zIndex: 100,
                        position: 'fix',
                        // overflow: 'hidden'
                    }}>
                    <Fade>
                        {deletingTask &&
                            <Notification
                                type={{
                                    style: 'error',
                                    // icon: true

                                }}
                                closable={true}
                                style={{ padding: '5px' }}
                                onClose={() => setDeletingTask(false)}>
                                <img
                                    style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top', color: 'white' }}
                                    src={loadingIcon}
                                    alt='loading' />

                                <span style={{ color: 'white' }}>
                                    {intl.formatMessage({ id: 'app.scheduler.events.deleting', defaultMessage: 'Deleting...' })}
                                </span>
                            </Notification>}

                        {errors?.map((error) => {
                            return <Notification
                                key={error.zone}
                                type={{
                                    style: 'error',
                                    // icon: true
                                }}
                                closable={true}
                                style={{ padding: '5px', color: 'white' }}
                                onClose={() => setErrors((prevErrors) => {
                                    return prevErrors.filter((item) => item.key !== error.key)
                                })} >
                                <div>
                                    {error.message}
                                </div>
                            </Notification>
                        })}


                        {addingTask &&
                            <Notification
                                type={{
                                    style: 'success',
                                    // icon: true
                                }}
                                closable={true}
                                style={{ padding: '5px', background: '#16a34a', color: 'white' }}
                                onClose={() => setAddingTask(false)}>
                                <img
                                    style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top' }}
                                    src={loadingIcon}
                                    alt='loading' />

                                <span style={{ color: 'white' }}>
                                    {intl.formatMessage({ id: 'app.scheduler.events.adding', defaultMessage: 'Adding...' })}
                                </span>
                            </Notification>}
                        {updatingTask &&
                            <Notification
                                type={{
                                    style: 'success',
                                    // icon: true
                                }}
                                closable={true}
                                style={{ padding: '5px', background: '#16a34a', color: 'white' }}
                                onClose={() => setUpdatingTask(false)}>
                                <img
                                    style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top' }}
                                    src={loadingIcon}
                                    alt='loading' />

                                <span style={{ color: 'white' }}>
                                    {intl.formatMessage({ id: 'app.scheduler.events.updating', defaultMessage: 'Updating...' })}
                                </span>
                            </Notification>}
                    </Fade>
                </NotificationGroup>

            </div >
        </div >
    )
}

export default IrrigationScheduler;