import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighChartsMore from 'highcharts/highcharts-more';
import { useIntl } from 'react-intl';

function TempReportChart(props) {
    const intl = useIntl();

    function init() {

        HighChartsMore(Highcharts);

        Highcharts.seriesType('lowmedhigh', 'boxplot', {
            keys: ['low', 'median', 'high'],
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
                    `${intl.formatMessage({ id: "app.report.weatherReport.min", defaultMessage: 'Min' })} <b>{point.low}</b>
                    ${intl.formatMessage({ id: "app.report.weatherReport.avg", defaultMessage: 'Avg' })} <b>{point.median}</b> 
                    ${intl.formatMessage({ id: "app.report.weatherReport.max", defaultMessage: 'Max' })} <b>{point.high}</b><br/>`
            }
        }, {
            // Change point shape to a line with three crossing lines for low/median/high
            // Stroke width is hardcoded to 1 for simplicity
            drawPoints: function () {
                var series = this;
                Highcharts.each(this.points, function (point) {
                    var graphic = point.graphic,
                        verb = graphic ? 'animate' : 'attr',
                        shapeArgs = point.shapeArgs,
                        width = shapeArgs.width,
                        left = Math.floor(shapeArgs.x) + 0.5,
                        right = left + width,
                        crispX = left + Math.round(width / 2) + 0.5,
                        highPlot = Math.floor(point.highPlot) + 0.5,
                        medianPlot = Math.floor(point.medianPlot) + 0.5,
                        lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

                    if (point.isNull) {
                        return;
                    }

                    if (!graphic) {
                        point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
                    }

                    graphic.attr({
                        stroke: point.color || series.color,
                        "stroke-width": 1
                    });

                    graphic[verb]({
                        d: [
                            'M', left, highPlot,
                            'H', right,
                            'M', left, medianPlot,
                            'H', right,
                            'M', left, lowPlot,
                            'H', right,
                            'M', crispX, highPlot,
                            'V', lowPlot
                        ]
                    });
                });
            }
        });

        Highcharts.chart(props.reportID, {
            chart: {
                isSynced: true,
                type: 'lowmedhigh',
                marginLeft: 40, // Keep all charts left aligned
                spacingTop: 20,
                spacingBottom: 20,
                events: {
                    render: function () {
                        let series = this.series[0];
                        // console.log(series.data[0]);
                        if (!series.data.length > 0)
                            return;

                        if (this.customLabel) {
                            this.customLabel.destroy();
                            this.customLabel = undefined;
                        }
                        this.customLabel = this.renderer.g('customLabel').attr({
                            zIndex: 6
                        }).add();

                        for (let i in series.data) {
                            let distX = this.xAxis[0].toPixels(series.data[i].x),
                                distYMed = this.yAxis[0].toPixels(series.data[i].median),
                                distYLow = this.yAxis[0].toPixels(series.data[i].low),
                                distYHi = this.yAxis[0].toPixels(series.data[i].high);

                            this.renderer.label(series.data[i].low, distX, distYLow).add(this.customLabel);
                            this.renderer.label(series.data[i].median, distX, distYMed).add(this.customLabel);
                            this.renderer.label(series.data[i].high, distX, distYHi).add(this.customLabel);
                        }



                        // console.log('x, y ', distX, distY);

                        // this.renderer.label('text2', distX + 10, distY + 10).attr({}).add(this.customLabel);
                    }
                }
            },
            plotOptions: {
                boxplot: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            title: {
                text: null,
                // align: 'left',
                // margin: 0,
                // x: 30
            },
            credits: {
                enabled: false
            },
            legend: {
                verticalAlign: 'top',
                enabled: true
            },
            xAxis: {
                categories: props.data.map(i => i.name),
                crosshair: true,
                // Uncomment to enable synced scrolling
                // events: {
                //     setExtremes: props.sync
                // },
                min: 0,
                max: props.data.length < 20 ? props.data.length - 1 : 20,
                scrollbar: {
                    enabled: props.data.length >= 20,
                    barBackgroundColor: '#D2DBE8',
                    barBorderRadius: 7,
                    barBorderWidth: 0,
                    buttonBackgroundColor: '#D2DBE8',
                    buttonBorderWidth: 0,
                    buttonBorderRadius: 7,
                    trackBackgroundColor: 'none',
                    trackBorderWidth: 1,
                    trackBorderRadius: 8,
                    trackBorderColor: '#CCC'
                },
            },
            tooltip: {
                // positioner: function () {
                // 	return {
                // 		// right aligned
                // 		x: this.chart.chartWidth - this.label.width,
                // 		y: 10 // align to title
                // 	};
                // },
                // borderWidth: 0,
                // backgroundColor: 'none',
                // pointFormat: '{point.y}',
                // headerFormat: '',
                // shadow: false,
                // style: {
                // 	fontSize: '18px'
                // },
                shared: true
                // valueDecimals: dataset.valueDecimals
            },
            series: [{
                data: props.data.map(item => item.y),
                name: 'TEMP',
                // type: 'lowmedhigh',
                // color: Highcharts.getOptions().colors[parseInt(Math.random()*10)],
                fillOpacity: 0.3,
                // tooltip: {
                // 	valueSuffix: ' ' + dataset.unit
                // }
            }]
        });
    }

    useEffect(() => {
        if (props.data)
            init();
    }, [props.data]);

    return (
        <div style={{ width: '100%', height: '450px', marginTop: '25px' }}>
            <span style={{ fontSize: '1.4em', }}>{props.title}</span>
            <div id={props.reportID} style={{ width: '100%', height: '450px' }}>
            </div>
        </div>);
}


export default TempReportChart;