import React from "react";

import '../spinner.css'


function SpinnerBackdrop(props){
    return <div className='flex justify-center items-center w-full h-full spinner-curtain bg-white opacity-40'
        style={{backgroundColor: props.backDropColor, opacity: props.backDropOpacity}}
    >
        {props.children}
    </div>
}

function Spinner(props){

    if (!props.show)
    return null

    const style = {
        borderColor: props.color ? props.color : null,
        borderTopColor: props.backDropColor,
        width: props.size,
        height: props.size
    }

    if (props.backDrop)
    return <SpinnerBackdrop backDropColor={props.backDropColor} backDropOpacity={props.backDropOpacity}>
            <div className="spinner-rolling">
                <div className="spinner-box">
                    <div style={style}></div>
                </div>
        </div>
    </SpinnerBackdrop>
    else
    return <div className="spinner-rolling absolute z-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <div className="spinner-box">
            <div style={style}></div>
        </div>
    </div>
}


export default Spinner