import React, { useRef, useState, useEffect } from 'react';
import {getAuthToken, BASEURI} from '../utils';
import Modal from '../general/Modal';
import UploadJobDialog from './UploadJobDialog';
import JobTable, {JobItem} from './UploadJobTable';
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';
import { useIntl } from 'react-intl';
import UploadLogs from './UploadLogs';


function DGAUploadJobs (props){

    const [showDialog, setShowDialog] = useState(false);
    const [uploadJobs, setUploadJobs] = useState([]);
    const [viewedJob, setViewedJob] = useState({});
    const [graphs, setGraphs] = useState([]);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState('');
    const [isWorking, setIsworking] = useState({
        getUploadJobs: false,
        getUserGraphs: false
    });
    // prevent reload animation on first access
    const [notIntial, setNotInitial] = useState(false);
    const [loading, setLoading] = useState(false);
    const intl = useIntl();

    const uploadLogsRef = useRef({});

    useEffect(()=>{
        if((isWorking.getUserGraphs || isWorking.getUploadJobs) && notIntial){
            setLoading(true);
        }
        else { setLoading(false); }
    },[isWorking]);
    

    // get all upload jobs
    function getUploadJobs(){
        setIsworking({...isWorking, getUploadJobs: true})
        let url = `${BASEURI}/api/dga/job/`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                return response.json();
            })
            .then((jobs) => {
                !notIntial && setNotInitial(true);
                setIsworking({...isWorking, getUploadJobs: false})
                setUploadJobs(jobs);
            })
            .catch(error => console.error('Error:', error));

        });
    }

    function getUserGraphs(){
        setIsworking({...isWorking, getUserGraphs: true})
        let url = `${BASEURI}/api/allgraphs/`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
          };
    
        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {
    
          options.headers.Authorization = `Bearer  ${token}`;
    
          fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((userGraphs) => {
            setIsworking({...isWorking, getUserGraphs: false});
            setGraphs(userGraphs);
          })
          .catch(error => console.error('Error:', error));
    
        });
    
      }

    function viewJob(op = 'edit', jobId){
        
        setShowDialog(true);

        if (op === 'edit'){
            for (let i in uploadJobs)
            if(uploadJobs[i].id === jobId)
            setViewedJob(uploadJobs[i]);
        } else {
            setViewedJob({
                region_code: '',
                description: '',
                totalizer: null, 
                flow: null,
                well_water_level: null,
                enabled: false,
            });
        }
        console.log(jobId);
        
    }

    function saveJob(){
        
        setSaving(true);

        let url = viewedJob.id === undefined ? 
        `${BASEURI}/api/dga/job/`:
        `${BASEURI}/api/dga/job/${viewedJob.id}/`,
        options = {
            method: viewedJob.id === undefined ? 'POST' : 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(viewedJob)
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                console.log(response.status);
                if (response.status !== 200 && response.status !== 201)
                return response.text().then(text => {throw new Error(text)});

                return response.json();
            })
            .then((newJob) => {
                setSaving(false);
                setViewedJob(newJob);
                getUploadJobs();
            })
            .catch(e => {
                setError(e.toString());
                setSaving(false);
            });

        });
    }

    function deleteJob(){

        if(!window.confirm('Delete Upload Job ?'))
        return;
        
        setDeleting(true);

        let url = `${BASEURI}/api/dga/job/${viewedJob.id}`,
        options = {
            method:'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                console.log(response.status);
                if (response.status !== 204)
                return response.text().then(text => {throw Error(text)});

                setDeleting(false);
                //refresh list
                getUploadJobs();
                setShowDialog(false);
            })
            .catch(e => {
                setError(e.toString());
                setDeleting(false);
            });

        });
    }

    function handleChange(e){

        //clear error messages
        setError('');

        let job = viewedJob;

        job = {
            ...job,
            [e.target.name]: e.target.value.id ? e.target.value.id : e.target.value
        };

        setViewedJob(job);
    }

    function refresh(){
        getUploadJobs();
        getUserGraphs();
        uploadLogsRef.current.refresh();
    }

    useEffect(() => {
        getUploadJobs();
        getUserGraphs();
        // document.getElementById("refresh").addEventListener('click',refresh);
    }, [])

    // useEffect(() => {
    //     setError('');
    // });

    return <div className='mdl-grid' 
            style={{
                flexDirection: 'column',
                alignItems: 'center'
            }}>
        <div className='breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
        {intl.formatMessage({id:'app.dga.dgaUploadJob', defaultMessage:'DGA Upload Jobs'})}
        </div>
                
        <RefreshPortal>
            <RefreshButton onClick={refresh} loading={loading} />
        </RefreshPortal>

        <JobTable intl={intl}>
            {uploadJobs.map(job => <JobItem view={viewJob} key={job.id} {...job} />)}
        </JobTable>

        <UploadLogs
            uploadLogsRef={uploadLogsRef}
        />

        <Modal>
            <UploadJobDialog
                graphs={graphs}
                job={viewedJob}
                show={showDialog}
                toggle={() => setShowDialog(!showDialog)}
                onChange={handleChange}
                save={saveJob}
                delete={deleteJob}
                saving={saving}
                deleting={deleting}
                error={error}
            />
        </Modal>
        <button name='add_new_button' onClick={() => viewJob('new', null)} className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--primary ag-add-button">
            <i className="material-icons" role="presentation">add</i>
            <span className="visuallyhidden">{intl.formatMessage({id:'app.dga.add', defaultMessage:'Add'})}</span>
        </button>
    </div>
}


export default DGAUploadJobs;