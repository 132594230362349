import React, { Component, useEffect } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import ClickAwayListener from 'react-click-away-listener';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Modal from '../general/Modal';


class QuickDateMenu extends Component {
    anchor = null;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showCustomDateRangeDialog: false,
            dateRange: {
                start: null,
                end: null
            }
        };

        this.changeDate = this.changeDate.bind(this);
        this.closeCustomDateRangeDialog = this.closeCustomDateRangeDialog.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    }

    componentDidMount() {
        this.setState({ show: false });
    }

    popupAlign = { horizontal: 'right', vertical: 'bottom' };
    anchorAlign = { horizontal: 'center', vertical: 'top' };

    changeDate(e, range) {
        e.preventDefault();
        this.props.onClick(range);
        this.setState({ show: false });
        this.setState({ showCustomDateRangeDialog: false });
    }
    handleDateRangeChange(e) {

        this.setState({
            dateRange: {
                ...this.state.dateRange,
                [e.target.name]: e.value
            }
        });

    }
    render() {
        return (
            <div>
                <button id={this.props.menuId}
                    className=" mdl-button mdl-js-button mdl-button--icon text-slate-700"
                    onClick={this.close}
                    ref={(button) => {
                        this.anchor = button;
                    }}
                    title='Change date range'
                >
                    <i className='material-icons'>date_range</i>
                </button>
                <Popup
                    popupAlign={this.popupAlign}
                    anchorAlign={this.anchorAlign}
                    anchor={this.anchor}
                    show={this.state.show}
                    animate={false}
                    popupClass={'ag-chart-popup'}
                >
                    <ClickAwayListener onClickAway={this.close}>
                        <ul>
                            <li onClick={(e) => this.changeDate(e, '1D')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.today', defaultMessage: 'Today' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '2D')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.yesterday', defaultMessage: 'Yesterday' })} </a></li>
                            <li onClick={(e) => this.changeDate(e, '1W')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1week', defaultMessage: '1 Week' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '2W')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.2week', defaultMessage: '2 Weeks' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '1M')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1month', defaultMessage: '1 Month' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '2M')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.2months', defaultMessage: '2 Months' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '3M')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.3months', defaultMessage: '3 Months' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '6M')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.6months', defaultMessage: '6 Months' })}</a></li>
                            <li onClick={(e) => this.changeDate(e, '1Y')}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1year', defaultMessage: '1 Year' })}</a></li>
                            <li onClick={(e) => { this.setState({ showCustomDateRangeDialog: true }); this.setState({ show: false }) }}><a href="#dummy">{this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.custom', defaultMessage: 'Custom' })}</a></li>
                        </ul>
                    </ClickAwayListener>
                </Popup>
                <Modal>
                    {this.state.showCustomDateRangeDialog && <Dialog
                        title={this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.customDateRange', defaultMessage: 'Custom Date Range' })}
                        onClose={this.closeCustomDateRangeDialog}
                        className='edit-chart-dialog'
                        // please make this responsvie
                        width={window.innerWidth > 550 && 550}
                        height={window.innerWidth > 460 && 500}
                    >
                        <div className='flex justify-between'>
                            <div>
                                <DatePicker
                                    label={this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.start', defaultMessage: 'Start' })}
                                    name='start'
                                    calendarSettings={{ views: 1 }}
                                    onChange={this.handleDateRangeChange}
                                />
                            </div>
                            <div>
                                <DatePicker
                                    label={this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.end', defaultMessage: 'End' })}
                                    name='end'
                                    calendarSettings={{ views: 1 }}
                                    onChange={this.handleDateRangeChange}
                                />
                            </div>
                        </div>

                        <DialogActionsBar>
                            <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.closeCustomDateRangeDialog}>
                                {this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.cancel', defaultMessage: 'Cancel' })}
                            </button>
                            <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={
                                (e) => this.state.dateRange.start !== null ?
                                    this.changeDate(e, this.state.dateRange) :
                                    this.setState({ showCustomDateRangeDialog: false })
                            }>
                                {this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.update', defaultMessage: 'Update' })}
                            </button>
                        </DialogActionsBar>
                    </Dialog>}
                </Modal>
            </div>
        );
    }
    closeCustomDateRangeDialog = (e) => {
        this.setState({ showCustomDateRangeDialog: false })
    }

    close = (e) => {
        if (e)
            this.setState({ show: !this.state.show });
        else
            this.setState({ show: false });
    }
}

export default QuickDateMenu;