import React, { useState, useEffect } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Chart from '../../../charts/Chart';
import ModalForm from '../../../charts/ChartDialog';
import Modal from '../../../general/Modal';
import { BASEURI, getAuthToken } from '../../../utils';
import { useIntl } from 'react-intl';

function WellsReportChart(props) {
  const [loading, setLoading] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [loadingSensors, setLoadingSensors] = useState(false);
  const [changing, setChanging] = useState(null);
  const [stationSensors, setStationSensors] = useState([]);
  const [filteredSensors, setFilteredSensors] = useState([]);
  const [graphs, setGraphs] = useState([]);
  const [reRenderGraphs, setRerendergraphs] = useState(false);
  const intl = useIntl();
  // const [stationSensors, setStationSensors] = useState([]);
  useEffect(() => {
    setGraphs(props.chart.graphs);
    loadSensors(props.chart.name);
  }, [props.chart])

  useEffect(() => {
    setFilteredSensors(stationSensors);
  }, [stationSensors])

  function loadSensors(stationSerial) {
    let url = `${BASEURI}/api/stations/sensors/${stationSerial}/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((sensors) => {
            if (sensors.length) {
              setStationSensors(sensors)
            }
          })
          .catch(error => { console.error('Error:', error) });
      });
  }

  function closeEditingModal() {
    setShowConfig(!showConfig)
  }
  function addGraph(graph) {

    if (!graph.id) {
      alert(intl.formatMessage({ id: 'app.reports.smart_wells.addMessage', defaultMessage: 'Can not add graph!' }));
      return
    }

    setChanging(graph.id)

    let newChangedGraph = JSON.parse(JSON.stringify(graph));

    let sensors = newChangedGraph.sensors.map(sensor => sensor.id),
      extracts = newChangedGraph.extract.map(extract => extract.id);


    newChangedGraph.extract = extracts;
    newChangedGraph.sensors = sensors;

    if (!graph.variables)
      newChangedGraph.variables = {};

    let url = `${BASEURI}/api/graph/${graph.id}/`,
      options = {
        method: 'PUT',
        body: JSON.stringify(newChangedGraph),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((newGraph) => {
            let graphsList = graphs;
            let newSensors = [];
            if (stationSensors) {
              for (let i in stationSensors) {
                if (newGraph.sensors.indexOf(stationSensors[i].id) !== -1)
                  newSensors.push(stationSensors[i]);
              }
            }
            newGraph.sensors = newSensors;
            let extracts = [];
            for (let i in newSensors) {
              for (let j in newSensors[i].extract) {
                if (newGraph.extract.includes(newSensors[i].extract[j].id)) {
                  extracts.push(newSensors[i].extract[j])
                }
              }
            }

            newGraph.extract = extracts;
            let graphIndex;

            for (let i in graphsList) {
              if (graphsList[i].id === newGraph.id)
                graphIndex = i;
            }
            graphsList.splice(graphIndex, 1, newGraph);
            setGraphs(graphsList);
            setChanging(null);
            setRerendergraphs(!reRenderGraphs);
          })
          .catch(error => console.error('Error:', error));

      });
  }
  function removeGraph() {
    alert(intl.formatMessage({ id: 'app.reports.smart_wells.deleteMessage', defaultMessage: 'Can not delete graph!' }));
  }
  function handleFilterChange(event) {
    setFilteredSensors(stationSensors);
    let sensors = stationSensors.slice().filter((item) => {
      let filterText = event.filter.value,
        itemText = `${item.name.toLowerCase()} ${item.device.name.toLowerCase()} [${item.port}]`;

      if (itemText.indexOf(filterText) !== -1)
        return true
      else
        return false
    });

    setFilteredSensors(sensors);
  }

  return (

    <div className="mdl-grid mdl-cell mdl-cell--12-col" key={props.chart.id}>
      <Modal>
        <ModalForm
          intl={intl}
          close={closeEditingModal}
          graphs={graphs}
          stations={[]}
          loadingSensors={loadingSensors}
          chartId={props.chart.chartId}
          handleFilterChange={handleFilterChange}
          isFromWells={true}
          visible={showConfig}
          addGraph={addGraph}
          changing={changing}
          stationSerial={props.chart.name}
          removeGraph={removeGraph}
          chartTitle={props.chart.name}
          sensors={filteredSensors}
        />
      </Modal>
      <div className="mdl-grid m dl-cell mdl-cell--12-col">
        <div className="mdl-cell mdl-cell--6-col"></div>
        <div className="mdl-cell mdl-cell--6-col">
          <div className="conf-btn-ctn">
            <div></div>
            {/* <div className="config-btn" onClick={closeEditingModal}>
                             <i className="material-icons">settings</i>
                         </div> */}
          </div>
        </div>

      </div>
      <div className='mdl-grid mdl-cell mdl-cell--12-col' style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <i className="material-icons" onClick={closeEditingModal} style={{ marginRight: '9px', cursor: 'pointer' }}>settings</i>
        <Chart
          intl={intl}
          graphs={graphs}
          key={props.chart.id}
          name={props.chart.name}
          maximized={props.chart.maximized}
          chartId={props.chart.id}
          //Use forecast chart layout for wells charts
          isForecast={true}
          title={props.chart.name}
          //  use reRenderGraphs to make changes in Chart state to rerender
          reRenderGraphs={reRenderGraphs}
        />
      </div>
    </div>)
}
function WellsReportChartContainer(props) {

  const wellsCharts = props.charts.map((chart) => {
    return (<WellsReportChart
      chart={chart}
      key={chart.id}
    />)
  })

  return (
    <div className="mdl-grid" style={{ justifyContent: 'center' }}>
      {wellsCharts}
    </div>
  )
}
export default WellsReportChartContainer;