import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { getAuthToken, BASEURI } from '../utils';
import { MultiSelect, DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Modal from '../general/Modal';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { RecurrenceEditor, IrrigationTypeEditor, PrecipitationEditor } from "./Editors";
import { StationContext } from './TabLayout';
import { TimezoneContext } from './TabLayout';
import { fromZonedTime } from 'date-fns-tz';

const NEW_TASK = {
    device: null,
    sectors: [],
    start: new Date(),
    end: new Date(),
    duration: 0,
    recurrence_rule: '',
    precip: 0,
    irrigation_type: 'Date/Time'
}

export function validateRecurrenceRule(rule) {
    // Define valid values for FREQ and BYDAY
    const validFreqValues = ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'];
    const validDayValues = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

    // Split the rule into parts
    const parts = rule.split(';').filter(part => part);

    // Ensure the rule has at least one valid part (FREQ)
    if (parts.length < 1) {
        return false;
    }

    // Validate FREQ part
    const freqPart = parts.find(part => part.startsWith('FREQ='));
    if (!freqPart) {
        return false;
    }
    const freqValue = freqPart.split('=')[1];
    if (!validFreqValues.includes(freqValue)) {
        return false;
    }

    // Validate BYDAY part (if present)
    const byDayPart = parts.find(part => part.startsWith('BYDAY='));
    if (byDayPart) {
        const byDayValues = byDayPart.split('=')[1].split(',');
        if (!byDayValues.every(day => validDayValues.includes(day))) {
            return false;
        }
    }

    // Validate BYMONTHDAY part (if present)
    const byMonthDayPart = parts.find(part => part.startsWith('BYMONTHDAY='));
    if (byMonthDayPart) {
        const byMonthDayValues = byMonthDayPart.split('=')[1].split(',');
        if (!byMonthDayValues.every(day => !isNaN(day) && day >= 1 && day <= 31)) {
            return false;
        }
    }

    return true;
}


function TaskForm(props) {
    const { timezone } = useContext(TimezoneContext);
    const intl = useIntl();
    const [newTask, setNewTask] = useState(NEW_TASK);
    const [error, setError] = useState(false);
    const [station, setStation] = useState({});
    const [sectors, setSectors] = useState([]);
    const deviceTimezone = station?.details?.timezone;

    const {
        stations,
    } = useContext(StationContext);

    const [filteredStations, setFilteredStations] = useState(stations)


    useEffect(() => {
        loadDeviceSectors(station.serial, station.api === "Wiseconn" ? 'zones' : 'sectors');
    }, [station])


    function handleStationFilterChange(e) {
        let filtered = stations.filter(item => {
            let filterText = e.filter.value,
                itemText = `${item.name.toLowerCase()}`;

            for (let i in filterText.split(' ')) {
                let textSplit = filterText.split(' ')[i];

                if (itemText.indexOf(textSplit) !== -1)
                    return true
                else
                    return false
            }
            return true;
        });
        setFilteredStations(filtered)
    }

    // useEffect(() => {
    //     setNewTask({
    //         ...newTask,
    //         sectors: props.sectors
    //     })
    // }, [props.sectors])


    function handleIrrigationTypeChange({ value }) {
        setNewTask({
            ...newTask,
            irrigation_type: value
        })
    }

    function handlePrecipitationChange({ value }) {
        setNewTask({
            ...newTask,
            'precip': value
        })
    }


    function handleChange(e) {

        let name, value = '';
        if (e.name === 'recurrence_rule') {
            name = e.name;
            value = e.value;
        } else {
            name = e?.target?.name;
            value = e?.target?.value;
        }

        if (name === 'device') {
            setStation(value);
            setNewTask({
                ...newTask,
                device: value
            })
        } else {
            setNewTask({
                ...newTask,
                [name]: value
            })
        }

    }

    const loadDeviceSectors = (serial, type) => {

        let url = BASEURI + `/api/scheduler/${type}/${serial}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((sectors_list) => {
                        setSectors(sectors_list);
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }



    function validate(dataItem) {
        if (!dataItem.device | dataItem.sectors.length < 1) {
            setError(true)
            return false
        }
        if (dataItem.irrigation_type.key == 'Volume') {
            if (dataItem.precip <= 0) {
                {
                    setError(true)
                    return false
                }
            }

        }
        if (dataItem.irrigation_type.key == 'Duration') {
            if (dataItem.duration <= 0) {
                {
                    setError(true)
                    return false
                }
            }

        }
        if (dataItem.recurrence_rule) {
            if (!validateRecurrenceRule(dataItem.recurrence_rule)) {
                setError(true)
                return false
            }
        }

        setError(false);
        return true
    }

    function addTasks() {
        let dataItem = newTask;

        if (!validate(dataItem))
            return

        props.setAddingTask(true);
        props.onClose(false);
        dataItem.api = dataItem.device.api;
        dataItem.device = dataItem.device.serial;
        dataItem.sectors = dataItem.sectors.map(item => item.id);
        dataItem.start = fromZonedTime(dataItem.start, timezone);
        dataItem.end = fromZonedTime(dataItem.end, timezone);


        if (typeof dataItem.irrigation_type === 'object')
            dataItem.irrigation_type = dataItem.irrigation_type.key


        let url = BASEURI + `/api/scheduler/tasks/add-group-tasks/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(async (response) => {
                        if (!response.ok) {
                            throw Error(await response.text())
                        }
                        return response.json();

                    })

                    .then((messages) => {
                        setNewTask(NEW_TASK);
                        props.loadTasks();
                        props.setAddingTask(false);
                    })

                    .catch(error => {
                        console.error('Error:', error.message);
                        props.setError(true);
                        props.loadTasks();
                        props.setAddingTask(false);
                        props.setErrorText(error.message)
                    });
            });

    }
    function handleCloseButton() {
        props.onClose(false);
    }

    function itemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps?.dataItem?.device?.name} [{itemProps?.dataItem?.zone_id}]</div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (
        <div><Modal>
            <Dialog onClose={handleCloseButton} title={intl.formatMessage({ id: 'app.scheduler.taskModel.addIrrigation', defaultMessage: "Group Add Irrigation" })}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <div className='modal-form-inputs-wrap' >
                        <div className='modal-form-inputs-wrap-row' >
                            <DropDownList
                                name='device'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.station', defaultMessage: 'Station' })}
                                dataItemKey='serial'
                                textField='name'
                                valueField='serial'
                                data={filteredStations}
                                value={newTask.device}
                                onChange={handleChange}
                                required={true}
                                filterable={true}
                                onFilterChange={handleStationFilterChange}
                            />
                            <MultiSelect
                                name='sectors'
                                label={intl.formatMessage({ id: 'app.scheduler.taskModel.sectors', defaultMessage: 'Sectors' })}
                                data={sectors}
                                textField='name'
                                value={newTask.sectors}
                                onChange={handleChange}
                                required={true}
                                itemRender={itemRender}
                                tags={

                                    newTask.sectors.length > 0 ?

                                        newTask.sectors.length > 1 ?
                                            [
                                                {
                                                    text: `${newTask.sectors.length} ${intl.formatMessage({ id: 'app.admin', defaultMessage: 'Sectors selected' })}`,
                                                    data: [...sectors],
                                                },
                                            ] : [
                                                {
                                                    text: `${newTask.sectors[0]?.name}`,
                                                    data: [...sectors],
                                                },
                                            ]
                                        : []
                                }
                                style={{ height: 'auto' }}
                            />
                        </div>
                        <div className='modal-form-inputs-wrap-row' >
                            <IrrigationTypeEditor
                                onChange={handleIrrigationTypeChange}
                                value={newTask.irrigation_type}
                                startEditor={
                                    <DateTimePicker
                                        name='start'
                                        label={intl.formatMessage({ id: 'app.scheduler.taskModel.start', defaultMessage: 'Start ({timezone})' }, { 'timezone': deviceTimezone })}
                                        onChange={handleChange}
                                        value={newTask.start}
                                        required={true}
                                    />
                                }
                                endEditor={
                                    <DateTimePicker
                                        name='end'
                                        label={intl.formatMessage({ id: 'app.scheduler.taskModel.end', defaultMessage: 'End ({timezone})' }, { 'timezone': deviceTimezone })}
                                        onChange={handleChange}
                                        value={newTask.end}
                                        required={true}
                                        timezone={timezone}
                                    />
                                }
                                durationEditor={
                                    <NumericTextBox
                                        name='duration'
                                        label={intl.formatMessage({ id: 'app.scheduler.taskModel.duration', defaultMessage: 'Duration (H)' })}
                                        onChange={handleChange}
                                        value={newTask.duration}
                                        required={true}
                                    />

                                }

                                precipitationEditor={
                                    <PrecipitationEditor
                                        label={intl.formatMessage({ id: 'app.scheduler.taskModel.precip', defaultMessage: 'Precipitation ({unit})' }, { unit: 'in' })}
                                        onChange={handlePrecipitationChange}
                                        value={newTask.precip}
                                        required={true}
                                        unit={newTask?.device?.details?.precip_unit}
                                    />
                                }

                            />
                            <RecurrenceEditor
                                onChange={handleChange}
                                name='recurrence_rule'
                                value={newTask.recurrence_rule}
                            />

                        </div>
                        {error && <div className='text-red-500 mt-5 text-center'>{intl.formatMessage({ id: "app.scheduler.taskModel.message", defaultMessage: "Please fill required fields!" })}</div>}
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={handleCloseButton}>{intl.formatMessage({ id: 'app.scheduler.taskModel.close', defaultMessage: 'Close' })}</button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={addTasks}>
                        {intl.formatMessage({ id: 'app.scheduler.taskModel.add', defaultMessage: 'Add' })}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
            {/* <button onClick={addTasks} className='mdl-button mdl-js-button mdl-button--primary'>Add Task</button> */}
        </div>)
}

export default TaskForm;