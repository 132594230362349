
import React, { useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import bg3 from '../bg_3.jpg';
import { useIntl } from 'react-intl';
import { BASEURI } from '../utils';
import loadingWhite from '../loading_white.svg';
import { Input } from '@progress/kendo-react-inputs';

const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);
    if (newPassword1 !== newPassword2) {
      setErrorMessage(intl.formatMessage({ id: "app.password.resetPassSuccessMessage2", defaultMessage: "Passwords do not match" }));
      return;
    }
    else {
      setErrorMessage('');
    }

    try {
      const response = await fetch(BASEURI + `/api/dashboard/password-reset-confirm/${uidb64}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ new_password1: newPassword1, new_password2: newPassword2 }),
      });

      if (response.ok) {
        setMessage(intl.formatMessage({ id: "app.password.resetPassSuccessMessage", defaultMessage: "Password reset successful. Redirecting to login..." }));
        // Send user to login using
        setTimeout(() => navigate('/login'), 3000);
      } else {
        const errors = await response.json();
        if (errors.message)
          setErrorMessage(intl.formatMessage({ id: "app.password.resetPassErrorMessage", defaultMessage: "Invalid or expired token" }));
        else
          setErrorMessage(Object.entries(errors)
            .map(([key, value]) => `${value}`)
            .join(', '))

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(intl.formatMessage({ id: "app.password.resetPassErrorMessage1", defaultMessage: "Something went wrong." }));
      console.error(error)
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 bg-cover bg-center" style={{ backgroundImage: `url(${bg3})` }}>
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        {message ?
          <div className='text-center'>
            <i className='material-icons text-green-500 text-[70px]'>task_alt</i>
            <p className="mt-4 text-sm text-green-500">{message}</p>
          </div> :
          <form onSubmit={handleSubmit}>
            <h3 className="text-2xl font-semibold mb-6 text-gray-800">{intl.formatMessage({ id: "app.password.resetYourPass", defaultMessage: "Reset Your Password" })}</h3>

            <div className="mb-4">
              {/* <label className="block text-sm font-medium text-gray-700 mb-2">{intl.formatMessage({ id: "app.password.newPass", defaultMessage: "New Password" })}</label> */}
              <Input
                label={intl.formatMessage({ id: "app.password.newPass", defaultMessage: "New Password" })}
                type="password"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.target.value)}
                // placeholder={intl.formatMessage({ id: "app.password.newPass", defaultMessage: "New Password" })}
                required
              // className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <div className="mb-4">
              {/* <label className="block text-sm font-medium text-gray-700 mb-2">{intl.formatMessage({ id: "app.password.confirmPass", defaultMessage: "Confirm Password" })}</label> */}
              <Input
                label={intl.formatMessage({ id: "app.password.confirmPass", defaultMessage: "Confirm Password" })}
                type="password"
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                // placeholder={intl.formatMessage({ id: "app.password.confirmPass", defaultMessage: "Confirm Password" })}
                required
              // className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <button
              type="submit"
              className="flex justify-center w-full bg-green-600 text-white font-semibold py-2 rounded-lg shadow-md hover:bg-green-700 transition duration-300"
            >
              {loading ? <img style={{ width: '24px' }} src={loadingWhite} alt='loading' /> : intl.formatMessage({ id: "app.password.resetPass", defaultMessage: "Reset Password" })}
            </button>
          </form>
        }

        {errorMessage && <p className="mt-4 text-sm text-red-500">{errorMessage}</p>}

        <div className='flex justify-center text-green-500 text-sm mt-2 capitalize'>
          <NavLink to='/login'>{intl.formatMessage({ id: "app.login.login", defaultMessage: "Login" })}</NavLink>
          &nbsp;|&nbsp;
          <NavLink to='/password-reset-request'>{intl.formatMessage({ id: "app.password.request", defaultMessage: "Request Password Reset" })}</NavLink>
        </div>
      </div>
    </div>

  );
};

export default PasswordResetConfirm;
