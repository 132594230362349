import { BASEURI } from '../utils';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import bg3 from '../bg_3.jpg';
import { NavLink } from 'react-router-dom';
import loadingWhite from '../loading_white.svg';
import { Input } from '@progress/kendo-react-inputs';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !email)
      return

    setLoading(true);
    try {
      const response = await fetch(BASEURI + '/api/dashboard/password-reset/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, username }),
      });
      if (response.ok) {
        setMessage(intl.formatMessage({ id: "app.password.successMessage", defaultMessage: "Password reset link sent to your email." }));
        setLoading(false);
      } else {
        const errors = await response.json()
        if (errors.message)
          setErrorMessage(errors.message);
        else if (errors.email)
          setErrorMessage(intl.formatMessage({ id: "app.password.errorMessage2", defaultMessage: "This email is not registered." }));
        else if (errors.username)
          setErrorMessage(intl.formatMessage({ id: "app.password.errorMessage3", defaultMessage: "This username is not registered." }));
        else if (errors.non_field_errors)
          setErrorMessage(intl.formatMessage({ id: "app.password.errorMessage4", defaultMessage: "This email is not associated with this username." }));
        else
          setErrorMessage(intl.formatMessage({ id: "app.password.errorMessage", defaultMessage: "Error processing your request." }));

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(intl.formatMessage({ id: "app.password.errorMessage1", defaultMessage: "Something went wrong." }));
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 bg-cover bg-center" style={{ backgroundImage: `url(${bg3})` }}>

      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        {
          message ?
            <div className='text-center'>
              <i className='material-icons text-green-500 text-[70px]'>task_alt</i>
              <p className="mt-4 text-sm text-green-500">{message}</p>
            </div> :

            <form onSubmit={handleSubmit}>
              <h3 className="text-2xl font-semibold mb-6 text-gray-800">{intl.formatMessage({ id: "app.password.reset", defaultMessage: "Reset Password" })}</h3>

              <div className="mb-4">
                {/* <label className="block text-sm font-medium text-gray-700 mb-2">{intl.formatMessage({ id: "app.password.emailAdd", defaultMessage: "Email Address" })}</label> */}
                <Input
                  label={intl.formatMessage({ id: "app.password.usename", defaultMessage: "Username" })}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  // placeholder={intl.formatMessage({ id: "app.password.enterEmail", defaultMessage: "Enter your email" })}
                  required
                // className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
                />
              </div>
              <div className="mb-4">
                {/* <label className="block text-sm font-medium text-gray-700 mb-2">{intl.formatMessage({ id: "app.password.emailAdd", defaultMessage: "Email Address" })}</label> */}
                <Input
                  label={intl.formatMessage({ id: "app.password.emailAdd", defaultMessage: "Email Address" })}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // placeholder={intl.formatMessage({ id: "app.password.enterEmail", defaultMessage: "Enter your email" })}
                  required
                // className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
                />
              </div>

              <button
                type="submit"
                className="flex justify-center w-full bg-green-600 text-white font-semibold py-2 rounded-lg shadow-md hover:bg-green-700 transition duration-300"
              >

                {loading ? <img style={{ width: '24px' }} src={loadingWhite} alt='loading' /> : intl.formatMessage({ id: "app.password.sendResetLink", defaultMessage: "Send Reset Link" })}
              </button>
              {errorMessage && <p className="mt-4 text-sm text-red-500">{errorMessage}</p>}
            </form>

        }
        <div className='flex justify-center text-green-500 text-sm mt-2 capitalize'>
          <NavLink to='/login'>{intl.formatMessage({ id: "app.login.login", defaultMessage: "Login" })}</NavLink>
        </div>
      </div>
    </div>


  );
};

export default PasswordResetRequest;
