import React from "react";
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Modal from '../general/Modal';
import { logErrorToMyService } from './errorLog';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Modal>
                <Dialog>
                    <div className="text-red-500">
                        <i className="material-icons">error</i>
                        <h1 >
                            <FormattedMessage
                                id='app.errors.title'
                                defaultMessage={'Something went wrong.'}
                            />
                        </h1>
                    </div>
                    <p>
                        <FormattedMessage
                            id='app.errors.message'
                            defaultMessage={'If the error persist, please contact administrator.'}
                        />
                    </p>
                    <DialogActionsBar>
                        <button className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md' onClick={
                            () => {
                                console.log('please close the dialog')
                                this.setState({ hasError: false })
                            }
                        }>OK</button>
                    </DialogActionsBar>
                </Dialog>
            </Modal >;

        }

        return this.props.children;
    }
}

export default ErrorBoundary;