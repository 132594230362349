import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ClickAwayListener from 'react-click-away-listener';


export default function AnnotationTextbox(props) {
    const textInput = useRef(null);
    const intl = useIntl();

    useEffect(() => {
        if (textInput.current)
            textInput.current.focus()
    }, [])

    return <ClickAwayListener onClickAway={props.toggleAnnotationTextBox}>
        <div className='absolute z-50 w-[210px]'>
            <input
                placeholder={intl.formatMessage({ id: 'app.chart.annotationPlaceholder', defaultMessage: 'Annotation' })}
                className="w-[150px] shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={props.newAnnotation.annotation.text}
                onChange={props.handleAnnotationChange}
                ref={textInput}
            />
            <button id='an-btn' onClick={props.saveAnnotation} className='ml-[3px] bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'>OK</button>
        </div>
    </ClickAwayListener>
}