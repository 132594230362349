import React, { useState } from 'react';
import { SchedulerForm } from '@progress/kendo-react-scheduler';
import { CustomFormEditor } from './CustomFormEditor';
import { CustomDialog } from './CustomDialog';
import { useIntl } from 'react-intl';
import { validateRecurrenceRule } from './TaskFormModal';

export const FormWithCustomEditor = props => {
  const intl = useIntl();
  const [allowSubmit, setAllowSubmit] = useState(false);

  function formValidator(e) {

    let result = {};

    const errorMessage = intl.formatMessage({ id: 'app.scheduler.taskModel.errorMessage', defaultMessage: '* This field is required' });

    if (e?.title?.length > 0) {
      result['title'] = '';
      setAllowSubmit(true);
    }
    else {
      result['title'] = errorMessage;
      setAllowSubmit(false);
    }

    if (e?.device?.serial) {
      result['device'] = '';
      setAllowSubmit(true);
    }
    else {
      result['device'] = errorMessage;
      setAllowSubmit(false);
    }

    if (e?.irrigation_task?.device?.api == "Agronic") {
      if (e?.sector?.id) {
        result['sector'] = '';
        setAllowSubmit(true);
      }
      else {
        result['sector'] = errorMessage;
        setAllowSubmit(false);
      }
    }

    if (e?.irrigation_task?.device?.api == "Wiseconn") {
      if (e?.zone?.id) {
        result['zone'] = '';
        setAllowSubmit(true);
      }
      else {
        result['zone'] = errorMessage;
        setAllowSubmit(false);
      }
    }

    if (e?.duration || e?.precip || e?.precipitation) {
      result['duration'] = '';
      setAllowSubmit(true);
    }
    else {
      result['duration'] = errorMessage;
      setAllowSubmit(false);
    }

    if (e?.start) {
      result['start'] = '';
      setAllowSubmit(true);
    }
    else {
      result['start'] = errorMessage;
      setAllowSubmit(false);
    }

    if (e?.end) {
      result['end'] = '';
      setAllowSubmit(true);
    }
    else {
      result['end'] = errorMessage;
      setAllowSubmit(false);
    }
    if (e?.recurrence_rule) {
      if (!validateRecurrenceRule(e?.recurrence_rule)) {
        result['recurrence_rule'] = errorMessage;
        setAllowSubmit(false);
      }
    }

    return result;
  }


  return (
    <SchedulerForm
      {...props}
      dialog={CustomDialog}
      editor={CustomFormEditor}
      validator={formValidator}
      allowSubmit={allowSubmit}
    />
  );
};