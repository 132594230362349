import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import Modal from '../general/Modal';
import { getAuthToken, BASEURI } from '../utils';
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';

const gridCtnStyles = {
    flexDirection: 'column',
    alignItems: 'center',
}

class SimpleGraphChip extends React.Component {

    render() {
        return (
            <span className="mdl-chip" style={{ backgroundColor: '#e5e5e5' }}>
                <span className="mdl-chip__text">{this.props.text}</span>
            </span>
        );
    }
}

class AddEditAlertDialog extends React.Component {
    constructor(props) {
        super(props)

        this.categories = [
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.selectGraph', defaultMessage: 'Off (Select Graphs Manually)' }), value: null },
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.temperature', defaultMessage: 'Temperature' }), value: 'temp' },
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.paw', defaultMessage: 'PAW' }), value: 'paw' },
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.relativeHumidity', defaultMessage: 'Relative Humidity' }), value: 'relative_humidity' },
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.windSpeed', defaultMessage: 'Wind Speed' }), value: 'wind_speed' },
            { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.dewPoint', defaultMessage: 'Dew Point' }), value: 'dew_point' },
        ]

    }

    itemRender(li, itemProps) {

        const itemChildren = <div> {li.props.children} [{itemProps.dataItem.calculation}] <div style={{ color: "#00F" }}> {itemProps.dataItem.chart.name} </div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    render() {

        return this.props.show &&
            <Dialog title={this.props.addNew ?
                this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.addNew', defaultMessage: 'Add New' }) :
                this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.edit', defaultMessage: 'Edit' })} onClose={this.props.toggle} >
                {!this.props.allowSubmit &&
                    <span className='text-red-500'>
                        <sup>*</sup>
                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.errorMessage', defaultMessage: 'Please provide email and phone Number in order to add alerts!' })}
                    </span>
                }
                <div className='modal-wrap'>
                    <div className='modal-form-wrap'>
                        <div className='alert-modal-form-inputs-wrap'>

                            <div className='modal-form-row'>
                                <Input
                                    required={true}
                                    name='name'
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.name', defaultMessage: 'NAME' })}
                                    value={this.props.alert.name}
                                    onChange={this.props.onChange}
                                />
                                <DropDownList
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.autoAddGraphs', defaultMessage: 'AUTO-ADD GRAPHS' })}
                                    name='category'
                                    data={this.categories}
                                    textField='text'
                                    dataItemKey='value'
                                    value={this.props.alert.category}
                                    onChange={this.props.onChange}
                                />

                            </div>
                            <div className='modal-form-row'>
                                <MultiSelect
                                    key='graph-select'
                                    name='graphs'
                                    // required={true}
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.graphs', defaultMessage: 'GRAPH(S)' })}
                                    data={this.props.graphsList}
                                    value={this.props.alert.graphs}
                                    textField='label'
                                    dataItemKey='id'
                                    onChange={this.props.onChange}
                                    itemRender={this.itemRender}
                                    filterable={true}
                                    onFilterChange={this.props.handleGraphFilterChange}
                                    style={{ height: 'auto' }}
                                />
                            </div>

                            <div className='modal-form-row'>
                                <DropDownList
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.logic', defaultMessage: 'LOGIC' })}
                                    name='logic'
                                    data={[{ text: '< Less than', value: 'lt' }, { text: '> Greater than', value: 'gt' }]}
                                    textField='text'
                                    dataItemKey='value'
                                    value={this.props.alert.logic}
                                    onChange={this.props.onChange}
                                />
                                <Input
                                    required={true}
                                    type={'number'}
                                    name='threshold'
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.threshold', defaultMessage: 'THRESHOLD' })}
                                    value={this.props.alert.threshold}
                                    onChange={this.props.onChange}
                                />
                            </div>

                            <div className='modal-form-row'>
                                <Input
                                    required={true}
                                    type={'number'}
                                    name='min_time_beyond_threshold'
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.hoursBeyondThreshold', defaultMessage: 'HOURS BEYOND THRESHOLD' })}
                                    value={this.props.alert.min_time_beyond_threshold}
                                    onChange={this.props.onChange}
                                />
                                <DropDownList
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.alertType', defaultMessage: 'ALERT TYPE' })}
                                    name='_type'
                                    data={[
                                        { value: 'i', text: 'ℹ Info' },
                                        { value: 'w', text: '⚠ Warning' },
                                        { value: 'c', text: '⚠ Critical' }]}
                                    textField='text'
                                    dataItemKey='value'
                                    value={this.props.alert._type}
                                    onChange={this.props.onChange}
                                />
                            </div>
                            <div className='modal-form-row'>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.suppress', defaultMessage: 'SUPPRESS' })}
                                    </span>
                                    <Switch
                                        name='snooze'
                                        checked={this.props.alert.snooze}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                                <Input
                                    required={true}
                                    type={'number'}
                                    name='snooze_time'
                                    label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.suppressForHour', defaultMessage: 'SUPRESS FOR HOURS' })}
                                    value={this.props.alert.snooze_time}
                                    onChange={this.props.onChange}
                                />
                            </div>
                            <div className='modal-form-row'>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.emailAlert', defaultMessage: 'Email Alert' })}
                                    </span>
                                    <Switch
                                        name='email_alert'
                                        checked={this.props.alert.email_alert}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.smsAlert', defaultMessage: 'SMS Alert' })}
                                    </span>
                                    <Switch
                                        name='sms_alert'
                                        checked={this.props.alert.sms_alert}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.whatsAppAlert', defaultMessage: 'WhatsApp Alert' })}
                                    </span>
                                    <Switch
                                        name='whatsapp_alert'
                                        checked={this.props.alert.whatsapp_alert}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                            </div>
                            <div className='modal-form-row'>
                                <label className='k-form-field' style={{ marginTop: '20px' }}>
                                    <span>{this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.message', defaultMessage: 'Message' })}</span>
                                    <textarea
                                        className='border-b-2 border-b-gray-300 bg-gray-100 rounded-md'
                                        name='message'
                                        style={{ width: '100%' }}
                                        required={true}
                                        value={this.props.alert.message}
                                        onChange={this.props.onChange}
                                    >

                                    </textarea>
                                </label>
                            </div>
                            <div className='modal-form-row'>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.enabled', defaultMessage: 'Enabled' })}
                                    </span>
                                    <Switch
                                        name='on'
                                        checked={this.props.alert.on}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    {this.props.addNew ? null : <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md delete" onClick={this.props.delete}>{this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.delete', defaultMessage: 'Delete' })}</button>}
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.toggle}>{this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.done', defaultMessage: 'DONE' })}</button>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.save} disabled={!this.props.allowSubmit}>{this.props.addNew ?
                        this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.add', defaultMessage: 'Add' }) :
                        this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.update', defaultMessage: 'Update' })}</button>
                </DialogActionsBar>
            </Dialog>;
    }

}

class AlertConfig extends React.Component {

    render() {

        return (
            <div className='mdl-cell mdl-cell--8-col' >
                <form className='k-form' onSubmit={this.handleSubmit}>
                    <fieldset>
                        <legend>{this.props.intl.formatMessage({ id: 'app.alert.alertConfig.settings', defaultMessage: 'Settings' })}</legend>

                        <label className='k-form-field'>
                            <span>{this.props.intl.formatMessage({ id: 'app.alert.alertConfig.emailAddressComma', defaultMessage: 'Email addresses (comma separated)' })}</span>
                            <textarea
                                className='border-b-2 border-b-gray-300 bg-gray-100 rounded-md'
                                name='email_addresses'
                                style={{ width: '100%' }}
                                required={true}
                                onChange={this.props.onChange}
                                value={this.props.settings.email_addresses}
                            >

                            </textarea>
                            {this.props.emailValidMsg.length ? <p style={{ color: 'red' }}>{this.props.emailValidMsg}</p> : ""}
                        </label>

                        <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap' }}>
                            <div style={{ display: "block" }}>
                                <Input
                                    label={this.props.intl.formatMessage({ id: 'app.alert.alertConfig.phoneNo', defaultMessage: 'PHONE NUMBER' })}
                                    name='phone_number'
                                    required={true}
                                    onChange={this.props.onChange}
                                    value={this.props.settings.phone_number}
                                    valid={this.props.phoneValid}
                                    validationMessage={this.props.phoneValidMsg}
                                />
                                {this.props.phoneValidMsg.length ? <p style={{ color: 'red' }}>{this.props.phoneValidMsg}</p> : ""}
                            </div>
                            <button
                                type='submit'
                                className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary'
                                style={{ marginTop: '1em', height: 35 }}
                                value='Save'
                                required={true}
                                onClick={e => this.props.handleClick(e)}
                            >
                                {this.props.saving ?
                                    this.props.intl.formatMessage({ id: 'app.alert.alertConfig.saving', defaultMessage: 'Saving...' }) :
                                    this.props.intl.formatMessage({ id: 'app.alert.alertConfig.save', defaultMessage: 'Save' })}
                            </button>
                        </div>

                    </fieldset>
                </form>
            </div>
        );
    }

}

class AlertItem extends React.Component {

    render() {
        let graphs = this.props.alert.graphs.map(graph => <SimpleGraphChip key={graph.id} text={graph.label} />),
            type = {
                w: <span style={{ color: 'orange', fontSize: '1.5em' }} role="img"> ⚠ </span>,
                c: <span style={{ color: 'red', fontSize: '1.5em' }} role="img"> ⚠ </span>,
                i: <span style={{ color: 'skyblue', fontSize: '1.5em' }} role="img"> ℹ </span>
            },
            on = this.props.alert.on ? <span style={{ color: 'lightgreen' }} role="img">✓</span> : '❌';

        return (
            <tr name='alert_row' onClick={(e) => this.props.open(e, 'edit', this.props.id)} style={{ cursor: 'pointer' }}>
                <td className="mdl-data-table__cell--non-numeric">{this.props.alert.name}</td>
                <td className="mdl-data-table__cell--non-numeric">{type[this.props.alert._type]}</td>
                <td className="mdl-data-table__cell--non-numeric">{graphs}</td>
                <td className="mdl-data-table__cell--non-numeric">{on}</td>
            </tr>
        );
    }

}

class AlertTable extends React.Component {

    render() {
        return (
            <div className='mdl-cell mdl-cell--8-col'>
                <form className='k-form'>
                    <fieldset>
                        <legend>{this.props.intl.formatMessage({ id: 'app.alert.alertTable.alerts', defaultMessage: 'Alerts' })}</legend>
                        <table className="mdl-data-table mdl-js-data-table alerts-table" style={{ width: '100%', marginTop: '10px' }}>
                            <thead>
                                <tr >
                                    <th className="mdl-data-table__cell--non-numeric" style={{ textAlign: "left" }}>{this.props.intl.formatMessage({ id: 'app.alert.alertTable.alert', defaultMessage: 'Alert' })}</th>
                                    <th style={{ textAlign: "left" }}>{this.props.intl.formatMessage({ id: 'app.alert.alertTable.type', defaultMessage: 'Type' })}</th>
                                    <th style={{ textAlign: "left" }}>{this.props.intl.formatMessage({ id: 'app.alert.alertTable.graphs', defaultMessage: 'Graphs' })}</th>
                                    <th style={{ textAlign: "left" }}>{this.props.intl.formatMessage({ id: 'app.alert.alertTable.enabled', defaultMessage: 'Enabled' })}</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.props.children}

                            </tbody>
                        </table>
                    </fieldset>
                </form>
            </div>
        );
    }
}

class Alerts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            alerts: [],
            settings: {
                email_addresses: '',
                phone_number: ''
            },
            hasSettings: false,
            savingSettings: false,
            showDialog: false,
            addNew: false,
            newAlert: {

            },
            graphsList: [],
            notInitial: false,
            isWorking: {
                loadSettings: false,
                loadAlerts: false,
                loadGraphsList: false
            },
            phoneIsValid: true,
            emailIsValid: true,
            phoneValidMsg: "",
            emailValidMsg: "",
            loading: false,
        }

        this.loadSettings = this.loadSettings.bind(this);
        this.loadAlerts = this.loadAlerts.bind(this);
        this.handleSettingsChange = this.handleSettingsChange.bind(this);
        this.handleAlertTableChange = this.handleAlertTableChange.bind(this);
        this.handleSettingsSave = this.handleSettingsSave.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleAlertDialogChange = this.handleAlertDialogChange.bind(this);
        this.handleSettingsSave = this.handleSettingsSave.bind(this);
        this.loadGraphsList = this.loadGraphsList.bind(this);
        this.handleAlertSave = this.handleAlertSave.bind(this);
        this.normalizeAlert = this.normalizeAlert.bind(this);
        this.handleAlertDelete = this.handleAlertDelete.bind(this);
        this.handleGraphFilterChange = this.handleGraphFilterChange.bind(this);
    }

    handleGraphFilterChange(event) {
        // console.log('filter', this.state.graphsList);
        this.setState(() => {
            return {
                graphsList: this.graphsListSource.slice().filter(item => {
                    let filterText = event.filter.value,
                        itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

                    for (let i in filterText.split(' ')) {
                        let textSplit = filterText.split(' ')[i];
                        // if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
                        if (itemText.indexOf(textSplit) !== -1)
                            return true
                        else
                            return false
                    }
                    return true;
                })
            };
        });
    }

    loadSettings() {
        this.setState({ loading: true });

        let url = `${BASEURI}/api/monitor/conf/listcreate/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((settings) => {
                        if (!this.state.notInitial) {
                            this.setState({ notInitial: true });
                        }
                        if (settings.length > 0)
                            this.setState({
                                settings: settings[0],
                                hasSettings: true,
                                loading: false,
                            });
                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    loadAlerts() {
        this.setState({ loading: true });

        let url = `${BASEURI}/api/monitor/alerts/list/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((alerts) => {
                        this.setState({
                            loading: false,
                            alerts: alerts,
                        });
                    })
                    .catch(error => console.error('Error:', error));
            });

    }

    loadGraphsList() {
        this.setState({ loading: true });

        let url = `${BASEURI}/api/allgraphs/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((graphsList) => {
                        if (graphsList.length > 0)
                            this.setState({
                                graphsList: graphsList,
                                loading: false
                            });
                        this.graphsListSource = graphsList;
                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    componentDidMount() {
        this.loadSettings();
        this.loadAlerts();
        this.loadGraphsList();

        // document.getElementById('refresh').addEventListener('click', this.refresh);
    }

    componentWillUnmount() {
        // document.getElementById('refresh').removeEventListener('click', this.refresh);
    }

    refresh = () => {
        console.log('reloading alerts');
        this.loadSettings();
        this.loadAlerts();
        this.loadGraphsList();
    }



    handleSettingsChange(event) {

        const target = event.target;

        let settings = this.state.settings;
        settings[target.name] = target.value;

        const phoneNumberValidator = /^(\+\d{11,12}|00\d{11,12})?(\s)*(,?(\s)*\+\d{11,12}|,00\d{11,12})*$/;
        const emailValidator = /^([a-zA-Z0-9&_\\.-]{1,}@[a-z&_\\.-]{1,}\.[a-zA-Z0-9&_\\.-]{1,})?(,?(\s)*[a-zA-Z0-9&_\\.-\s]{1,}@[a-zA-Z0-9&_\\.-\s]{1,}\.[a-z&_\\.-\s]{1,})*$/g;
        if (target.name === 'phone_number') {
            let phoneValidResult = phoneNumberValidator.test(target.value);
            phoneValidResult ? this.setState({ phoneIsValid: true, phoneValidMsg: "" }) : this.setState({ phoneIsValid: false, phoneValidMsg: "PHONE NUMBER(S) IS/ARE NOT VALID!" });
        }
        else {
            let emailValidResult = emailValidator.test(target.value);
            emailValidResult ? this.setState({ emailIsValid: true }) : this.setState({ emailIsValid: false });
        }

        this.setState({ settings: settings });

    }

    componentDidUpdate() {
        // console.log(this.state.newAlert);    
    }

    handleSettingsSave(event) {

        this.setState({ savingSettings: true });

        event.preventDefault();
        // add transilation for phoneValidMsg
        if (this.state.settings.phone_number && !this.state.phoneIsValid) {
            // some translation here!!
            this.setState({
                savingSettings: false, emailValidMsg: "",
                phoneValidMsg: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.phoneNumber', defaultMessage: 'PHONE NUMBER(S) IS/ARE NOT VALID!' })
            });
            return
        }
        if (this.state.settings.email_addresses && !this.state.emailIsValid) {
            // translation here
            this.setState({
                savingSettings: false, phoneValidMsg: "",
                emailValidMsg: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.email', defaultMessage: 'EMAIL ADDRESS(ES) IS/ARE NOT VALID!' })
            });
            return
        }

        this.setState({ phoneValidMsg: "", emailValidMsg: "" });
        let url = this.state.hasSettings
            ? `${BASEURI}/api/monitor/conf/rud/${this.state.settings.user}/`
            : `${BASEURI}/api/monitor/conf/listcreate/`,
            options = {
                method: this.state.hasSettings ? 'PUT' : 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        options.body = JSON.stringify(this.state.settings);

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((settings) => {

                        this.setState({
                            settings: settings,
                            hasSettings: settings ? true : false,
                            savingSettings: false
                        });

                    })
                    .catch(error => console.log('Error:', error));

            });

    }

    handleAlertTableChange() {

    }

    toggleDialog(event, op, alertId = null) {

        if (op === 'edit') {
            // let alertId = event.target.id,
            let alertToEdit, alerts = this.state.alerts;
            //find the alert that is supposed to be edited
            for (let alert in alerts) {
                if (alerts[alert].id === alertId)
                    alertToEdit = alerts[alert];
            }

            this.setState(state => ({
                showDialog: !state.showDialog,
                //if alert is found set newAlert to it otherwise keep old value
                newAlert: alertToEdit !== undefined ? this.normalizeAlert(alertToEdit) : state.newAlert,
                addNew: false
            }));

        } else {

            this.setState(state => ({
                showDialog: !state.showDialog,
                addNew: true,
                newAlert: {
                    'on': true,
                    'snooze': false,
                    'email_alert': false,
                    'sms_alert': false,
                    'snooze_time': 4
                },
            }));
        }
    }

    handleAlertDialogChange(event) {

        let newAlert = this.state.newAlert;

        //the name attribute in KendoReact Switch element
        //must be accessed using props
        if (event.target.name !== undefined)
            newAlert[event.target.name] = event.target.value;
        else
            newAlert[event.target.props.name] = event.target.value;

        this.setState({ newAlert: newAlert });

    }

    handleAlertSave(event) {

        let alert = this.state.newAlert;

        alert.graphs = alert.graphs ? alert.graphs.map((graph) => graph.id) : [];
        alert.logic = alert.logic.value;
        alert._type = alert._type.value;
        alert.category = alert.category ? alert.category.value : null;

        let url = this.state.addNew
            ? `${BASEURI}/api/monitor/alerts/create/`
            : `${BASEURI}/api/monitor/alerts/rud/${this.state.newAlert.id}/`,
            options = {
                method: this.state.addNew ? 'POST' : 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        options.body = JSON.stringify(this.state.newAlert);

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((newAlert) => {

                        let normalizedAlert = this.normalizeAlert(newAlert);
                        newAlert.graphs = normalizedAlert.graphs;

                        this.setState(state => {

                            let alerts = state.alerts
                            let changedAlert;
                            for (let i in alerts) {
                                if (newAlert.id === alerts[i].id) {
                                    changedAlert = i;
                                    break;
                                }
                            }

                            if (changedAlert !== undefined)
                                alerts.splice(changedAlert, 1, newAlert);
                            else
                                alerts.push(newAlert);

                            return { alerts: alerts, newAlert: this.normalizeAlert(newAlert) };

                        });

                    })
                    .catch(error => console.log('Error:', error));

            });

    }

    normalizeAlert(alertToNormalize) {

        let alert = {};
        alert = Object.assign(alert, alertToNormalize);

        let logicValues = {
            'lt': { text: '< Less than', value: 'lt' },
            'gt': { text: '> Greater than', value: 'gt' }
        }

        let typeValues = {
            'i': { value: 'i', text: 'ℹ Info' },
            'w': { value: 'w', text: '⚠ Warning' },
            'c': { value: 'c', text: '⚠ Critical' }
        }
        let categoryValues = {
            null: { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.selectGraph', defaultMessage: 'Off (Select Graphs Manually)' }), value: null },
            'relative_humidity': { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.relativeHumidity', defaultMessage: 'Relative Humidity' }), value: 'relative_humidity' },
            'temp': { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.temperature', defaultMessage: 'Temperature' }), value: 'temp' },
            'paw': { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.paw', defaultMessage: 'PAW' }), value: 'paw' },
            'wind_speed': { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.windSpeed', defaultMessage: 'Wind Speed' }), value: 'wind_speed' },
            'dew_point': { text: this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.dewPoint', defaultMessage: 'Dew Point' }), value: 'dew_point' }

        }

        //only normalize graphs if not already nomalized
        if (alert.graphs.length > 0) {
            if (!alert.graphs[0].hasOwnProperty('label')) {
                let graphs = [],
                    graphsList = this.state.graphsList.slice();
                for (let i in graphsList) {
                    if (alert.graphs.indexOf(graphsList[i].id) !== -1) {
                        graphs.push(graphsList[i]);
                    }

                }
                alert.graphs = graphs;
            }
        }


        alert.logic = logicValues[alert.logic];
        alert._type = typeValues[alert._type];
        alert.category = categoryValues[alert.category]

        return alert;

    }

    handleAlertDelete() {

        if (!window.confirm(this.props.intl.formatMessage({ id: 'app.alert.confrimMessage', defaultMessage: 'Are you sure want to delete alert: [{name}]?' }, { name: this.state.newAlert.name })))
            return

        this.setState({ loading: true });

        let alertToDelete = this.state.newAlert.id;

        let url = `${BASEURI}/api/monitor/alerts/rud/${alertToDelete}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                }
            }

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(response => {
                        let alertList = this.state.alerts.slice();
                        let alertToDeleteIndex = null;
                        for (let i = 0; i < alertList.length; i++) {
                            if (alertList[i].id === alertToDelete) {
                                alertToDeleteIndex = i;
                                break;
                            }
                        }

                        alertList.splice(alertToDeleteIndex, 1);

                        this.setState({ alerts: alertList, showDialog: false });
                    });

            });

    }

    render() {
        let alerts = this.state.alerts.map(alert => <AlertItem open={this.toggleDialog} key={alert.id} id={alert.id} alert={alert} />);

        return (
            <div className='mdl-grid' style={gridCtnStyles}>
                <RefreshPortal>
                    <RefreshButton loading={this.state.loading} onClick={this.refresh} />
                </RefreshPortal>

                <div className='breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
                    {this.props.intl.formatMessage({ id: 'app.alert.alerts', defaultMessage: 'ALERTS' })}
                </div>
                <AlertConfig
                    intl={this.props.intl}
                    settings={this.state.settings}
                    onChange={this.handleSettingsChange}
                    handleClick={this.handleSettingsSave}
                    phoneValid={this.state.phoneIsValid}
                    emailValid={this.state.emailIsValid}
                    saving={this.state.savingSettings}
                    phoneValidMsg={this.state.phoneValidMsg}
                    emailValidMsg={this.state.emailValidMsg}
                />

                <AlertTable intl={this.props.intl}>
                    {alerts}
                </AlertTable>

                <Modal>
                    <AddEditAlertDialog
                        intl={this.props.intl}
                        onChange={this.handleAlertDialogChange}
                        alert={this.state.newAlert}
                        toggle={this.toggleDialog}
                        show={this.state.showDialog}
                        save={this.handleAlertSave}
                        addNew={this.state.addNew}
                        graphsList={this.state.graphsList}
                        delete={this.handleAlertDelete}
                        handleGraphFilterChange={this.handleGraphFilterChange}
                        allowSubmit={Boolean(this.state.settings.email_addresses) & Boolean(this.state.settings.phone_number)}
                    />
                </Modal>

                <button name='add_new_button' onClick={(e) => this.toggleDialog(e, 'new')} className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--primary ag-add-button">
                    <i className="material-icons" role="presentation">add_alerts</i>
                    <span className="visuallyhidden">{this.props.intl.formatMessage({ id: 'app.alert.Add', defaultMessage: 'Add' })}</span>
                </button>

            </div>
        )
    }

}

export default Alerts;