import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { useIntl } from 'react-intl';
import { Input, Switch } from '@progress/kendo-react-inputs';
import loadingIcon from '../loading.svg';
import DGAUserACC from './DGAUserAcc';
import { getAuthToken, BASEURI } from '../utils';


function UploadJobDialog(props) {

    const [graphList, setGraphList] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [userAccCredentials, setUserAccCredentials] = useState({});
    const intl = useIntl();

    const allowedGraphTypes = [
        'raw',
        'Raw',
        'custom',
        'Custom Formula'
    ]

    useEffect(() => {
        setGraphList(props.graphs.filter(g => allowedGraphTypes.indexOf(g.calculation) !== -1));
    }, [props.graphs]);

    function itemRender(li, itemProps) {

        const itemChildren = <div> {li.props.children} [{itemProps.dataItem.calculation}] <div style={{ color: "#00F" }}> {itemProps.dataItem.chart.name} </div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    function getGraph(id) {
        return graphList.find(g => g.id === id);
    }

    function saveCredential() {
        let url = `${BASEURI}/api/dga/credentials/${userAccCredentials.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(userAccCredentials)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (response.status !== 200 && response.status !== 201)
                            return response.text().then(text => { throw new Error(text) });

                        return response.json();
                    })
                    .then((credential) => {
                        setUserAccCredentials(credential)
                    })
                    .catch(e => {
                        console.error(e)
                    });

            });
    }

    return props.show &&
        <Dialog title={props.job.id ? 'Edit' : 'Add New'} onClose={props.toggle} >

            <div className='modal-wrap'>
                <div className='modal-form-wrap'>
                    <div className='alert-modal-form-inputs-wrap'>

                        <div className='modal-form-row'>
                            <Input
                                required={true}
                                name='region_code'
                                label={intl.formatMessage({ id: 'app.dga.uploadJobDialog.regionCode', defaultMessage: 'REGION CODE' })}
                                value={props.job.region_code}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className='modal-form-row'>
                            <DropDownList
                                required={true}
                                label={intl.formatMessage({ id: 'app.dga.uploadJobDialog.totalizerGraph', defaultMessage: 'TOTALIZER GRAPH' })}
                                name='totalizer'
                                data={graphList}
                                textField='label'
                                dataItemKey='id'
                                value={getGraph(props.job.totalizer)}
                                itemRender={itemRender}
                                onChange={props.onChange}
                            />
                            <Input
                                name='totalizer_initial_value'
                                label={intl.formatMessage({ id: 'app.dga.uploadJobDialog.totalizerInitValue', defaultMessage: 'TOTALIZER INITIAL VALUE' })}
                                value={props.job.totalizer_initial_value}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className='modal-form-row'>
                            <DropDownList
                                required={true}
                                label={intl.formatMessage({ id: 'app.dga.uploadJobDialog.flowGraph', defaultMessage: 'FLOW GRAPH' })}
                                name='flow'
                                data={graphList}
                                textField='label'
                                dataItemKey='id'
                                value={getGraph(props.job.flow)}
                                itemRender={itemRender}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className='modal-form-row'>
                            <DropDownList
                                required={true}
                                label={intl.formatMessage({ id: 'app.dga.uploadJobDialog.wellLevelGraph', defaultMessage: 'WELL LEVEL GRAPH' })}
                                name='well_water_level'
                                data={graphList}
                                textField='label'
                                dataItemKey='id'
                                value={getGraph(props.job.well_water_level)}
                                itemRender={itemRender}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className='modal-form-row'>
                            <label className='k-form-field' style={{ marginTop: '20px' }}>
                                <span>
                                    {intl.formatMessage({ id: 'app.dga.uploadJobDialog.descriptionNote', defaultMessage: 'DESCRIPTION / NOTES' })}
                                </span>
                                <textarea
                                    className='border-b-2 border-b-gray-300 bg-gray-100 rounded-md'
                                    name='description'
                                    style={{ width: '100%' }}
                                    value={props.job.description}
                                    onChange={props.onChange}
                                >
                                </textarea>
                            </label>
                        </div>
                        <div className='modal-form-row'>
                            <div style={{ marginTop: '20px', color: 'gray' }}>
                                <span style={{ paddingRight: '20px' }}>
                                    {intl.formatMessage({ id: 'app.dga.uploadJobDialog.enabled', defaultMessage: 'Enabled' })}
                                </span>
                                <Switch
                                    name='enabled'
                                    checked={props.job.enabled}
                                    onChange={props.onChange}
                                />
                            </div>
                        </div>
                        <fieldset>
                            <legend>{intl.formatMessage({ id: 'app.dga.jobTable.dgaAcc', defaultMessage: 'DGA Account Credentials' })}</legend>
                            <DGAUserACC
                                userAccCredentials={userAccCredentials}
                                setUserAccCredentials={setUserAccCredentials}
                            />
                        </fieldset>
                    </div>
                    {/* error text */}
                    <div style={{ color: 'red', marginTop: '20px' }}>
                        {props.error}
                    </div>
                </div>
            </div>
            <DialogActionsBar>
                {props.job.id &&
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md delete" onClick={props.delete}>
                        {props.deleting ? <img style={{ color: 'gray', width: '25px' }} src={loadingIcon} alt='loading'></img> :
                            intl.formatMessage({ id: 'app.dga.uploadJobDialog.delete', defaultMessage: 'DELETE' })}
                    </button>
                }
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.toggle}>
                    {intl.formatMessage({ id: 'app.dga.uploadJobDialog.done', defaultMessage: 'DONE' })}
                </button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={() => { props.save(); saveCredential() }}>
                    {props.saving ? <img style={{ color: 'gray', width: '25px' }} src={loadingIcon} alt='loading'></img> : props.job.id ?
                        intl.formatMessage({ id: 'app.dga.uploadJobDialog.update', defaultMessage: 'Update' }) :
                        intl.formatMessage({ id: 'app.dga.uploadJobDialog.add', defaultMessage: 'Add' })}
                </button>
            </DialogActionsBar>
        </Dialog>;
}


export default UploadJobDialog;