import red from '../images/red.png';
import redWithError from '../images/red_with_error.png';
import yellow from '../images/yellow.png';
import yellowWidthError from '../images/yellow_with_error.png';
import green from '../images/green.png';
import greenWithError from '../images/green_with_error.png';
import blue from '../images/blue.png';
import blueWithError from '../images/blue_with_error.png';
import gray from '../images/gray.png';
import grayWithError from '../images/gray_with_error.png';


function getAvgPAW(graphs) {
    let paw_value;

    try {
        //get average of paw graph values
        paw_value = graphs.map(g => g.last_value[1]).reduce((x, y) => x + y) / graphs.length;
        //round to 1 decimal place
        return Math.round(paw_value * 10) / 10;
    } catch {
        return null;
    }
}


function getIcon(marker) {

    const paw_value = marker.avg_paw;

    if (paw_value === null) {
        if (hasCoordinates(marker))
            return [gray, 'GRAY'];
        else
            return [grayWithError, 'GRAY'];
    }

    if (paw_value <= 30) {
        if (hasCoordinates(marker))
            return [red, 'RED'];
        else
            return [redWithError, 'RED'];
    }
    else if (paw_value <= 70) {
        if (hasCoordinates(marker))
            return [yellow, 'YELLOW'];
        else
            return [yellowWidthError, 'YELLOW'];
    }
    else if (paw_value <= 100) {
        if (hasCoordinates(marker))
            return [green, 'GREEN'];
        else
            return [greenWithError, 'GREEN'];
    }
    else {
        if (hasCoordinates(marker))
            return [blue, 'BLUE'];
        else
            return [blueWithError, 'BLUE'];
    }
}


function hasCoordinates(marker) {
    if (marker.use_custom_location && marker.lng && marker.lat) {
        return true
    }

    try {
        const { location } = marker.device.details;
        if (location.lng && location.lat)
            return true
        else
            return false;
    } catch {
        return false;
    }
}


function getCoordinates(marker, map) {
    if (marker.use_custom_location && marker.lng && marker.lat) {
        return { lng: marker.lng, lat: marker.lat };
    }

    try {
        const { location } = marker.device.details;
        if (location.lng && location.lat)
            return location;
        else
            return { lng: map.getCenter().lng(), lat: map.getCenter().lat() };
    } catch {
        return { lng: map.getCenter().lng(), lat: map.getCenter().lat() };
    }
}

export { getAvgPAW, getIcon, getCoordinates }