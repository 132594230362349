import React, { Component } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { getAuthToken, BASEURI } from '../utils';

class EToForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphName: '',
			sensors: [],
			extracts: [],
			extract: null,
			temp_sensor: [],
			temp_extract: [],
			humidity_sensor: [],
			humidity_extract: [],
			radiation_sensor: [],
			radiation_extract: [],
			wind_sensor: [],
			wind_extract: [],
			tempSensor: {},
			humiditySensor: {},
			radiationSensor: {},
			windSensor: {},
			latitude: null,
			altitude: null,
			color: '#000000'
		};

		this.setInitialValues = this.setInitialValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.itemRender = this.itemRender.bind(this);
		this.extractItemRender = this.extractItemRender.bind(this);
	}

	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	// componentDidMount(){
	// 	console.log('this graph in Eto form', this.props.graph);
	// }

	handleChange(event) {

		//if change event is removing a sensor
		//do nothing further.
		if (event.target.value.length <= 0)
			return

		let sensorTypes = ['temp', 'humidity', 'wind', 'radiation'];

		if (sensorTypes.indexOf(event.target.name.split('_')[0]) >= 0) {

			let sensorKind = event.target.name.split('_');

			switch (sensorKind[0]) {
				case 'temp':
					let tempSensor = this.state.tempSensor;

					if (sensorKind[1] === 'sensor') {
						if (event.target.value.length > 0)
							tempSensor['sensor'] = event.target.value[0].id;
						else
							tempSensor.hasOwnProperty('sensor') && delete tempSensor.sensor;
					} else {
						if (event.target.value.length > 0)
							tempSensor['extract'] = event.target.value[0].id;
						else
							tempSensor.hasOwnProperty('extract') && delete tempSensor.sensor;
					}

					this.setState({
						tempSensor: tempSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;

				case 'humidity':
					let humiditySensor = this.state.humiditySensor;

					if (sensorKind[1] === 'sensor')
						humiditySensor['sensor'] = event.target.value[0].id;
					else
						humiditySensor['extract'] = event.target.value[0].id;

					this.setState({
						humiditySensor: humiditySensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				case 'radiation':
					let radiationSensor = this.state.radiationSensor;

					if (sensorKind[1] === 'sensor')
						radiationSensor['sensor'] = event.target.value[0].id;
					else
						radiationSensor['extract'] = event.target.value[0].id;

					this.setState({
						radiationSensor: radiationSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				case 'wind':
					let windSensor = this.state.windSensor;

					if (sensorKind[1] === 'sensor')
						windSensor['sensor'] = event.target.value[0].id;
					else
						windSensor['extract'] = event.target.value[0].id;

					this.setState({
						windSensor: windSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				default:
			}

		} else {
			this.setState({
				[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
			});
		}

		// if target doesn't have a two-
		// part name then it is not a sensor input field
		if (event.target.name.split('_').length < 2)
			return;

		if (event.target.name.split('_')[1] === 'sensor') {

			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});

			}
		}
	}


	itemRender(li, itemProps) {
		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}
	extractItemRender(li, itemProps) {
		const itemChildren = <div> {itemProps.dataItem.description} [{itemProps.dataItem.unit && itemProps.dataItem.unit}] </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	render() {

		return (
			<div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						key='temp-select'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						name='temp_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.temp', defaultMessage: 'TEMP' })}
						data={this.props.sensors}
						value={this.props.graph.variables.temp.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='temp-extract-select'
						name='temp_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.tempExtract', defaultMessage: 'TEMP SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.temp.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}

					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='humidity-select'
						name='humidity_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidity', defaultMessage: 'HUMIDITY' })}
						data={this.props.sensors}
						value={this.props.graph.variables.humidity.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='humidity-extract-extract'
						name='humidity_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidityExtract', defaultMessage: 'HUMIDITY SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.humidity.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}
					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='radiation-select'
						name='radiation_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.solarRadiation', defaultMessage: 'SOLAR RADIATION' })}
						data={this.props.sensors}
						value={this.props.graph.variables.radiation.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<DropDownList
						key='radiation-extract-select'
						name='radiation_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.radiationExtract', defaultMessage: 'RADIATION SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.radiation.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='wind-select'
						name='wind_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.windSpeed', defaultMessage: 'WIND SPEED' })}
						data={this.props.sensors}
						value={this.props.graph.variables.wind.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<DropDownList
						key='wind-extract-select'
						name='wind_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.windExtract', defaultMessage: 'WIND SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.wind.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<NumericTextBox
						name='latitude'
						required={true}
						key='lat-input'
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.latitude', defaultMessage: 'LATITUDE' })}
						value={this.props.graph.variables.latitude}
						onChange={this.props.handleChange} />
					<NumericTextBox
						name='altitude'
						required={true}
						key='long-input'
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.altitude', defaultMessage: 'ALTITUDE' })}
						value={this.props.graph.variables.altitude}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
						name='_type'
						data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
						textField='text'
						dataItemKey='value'
						value={this.graphTypes[this.props.graph._type]}
						onChange={this.props.handleChange} />
					<Input
						type='color'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
						name='color'
						data={this.state.color}
						value={this.props.graph.color}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='label'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
						value={this.props.graph.label}
						onChange={this.props.handleChange} />
					<Input
						name='axis_min'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
						value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						name='axis_max'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
						value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
						onChange={this.props.handleChange} />
					<Input
						required={true}
						name='y_axis_legend'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
						value={this.props.graph.y_axis_legend}
						onChange={this.props.handleChange} />
				</div>
			</div>
		);
	}
}

export default EToForm;