import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import loading from '../loading.svg';

class StatForm extends Component {

  handleChange = (event) => {

    this.props.handleChange({
      [event.target.props.name]: event.target.value
    });
  }

  render() {

    return (
      <div className='modal-widget-dialog-inputs-wrap modal-stat-wrap'>

        <Input
          required={true}
          name='name'
          label={this.props.intl.formatMessage({ id: 'app.addStatWidgetDialog.name', defaultMessage: 'NAME' })}
          onChange={this.handleChange}
        />

      </div>
    );
  }
}

class AddStatDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(widget) {
    this.setState(widget);
  }

  render() {

    let dialog = this.props.visible &&
      <Dialog title={this.props.intl.formatMessage({ id: 'app.addStatWidgetDialog.addStatWidget', defaultMessage: 'Add Stat Widget' })} onClose={this.props.close} className='add-widget-dialog'>

        <div className='modal-wrap'>
          <div className='modal-form-wrap'>
            <StatForm intl={this.props.intl} handleChange={this.handleChange} />
          </div>
        </div>
        <DialogActionsBar>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>
            {this.props.intl.formatMessage({ id: 'app.addStatWidgetDialog.cancel', defaultMessage: 'Cancel' })}
          </button>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={() => { this.props.addWidget(this.state) }}>
            {this.props.saving ?
              <img src={loading} style={{ width: '20px', height: '20px', opacity: .5 }} alt='loading' />
              :
              this.props.intl.formatMessage({ id: 'app.addStatWidgetDialog.add', defaultMessage: 'Add' })
            }
          </button>
        </DialogActionsBar>
      </Dialog>;
    return (dialog);
  }
}



export default AddStatDialog;