import React, { useEffect, useState } from "react";

import { useIntl } from 'react-intl';

import PlotStatOptions from "./PlotStatOptions";
import PlotStat from "./PlotStat";
import PlotStatChart from "./PlotStatChart";
import { SCOUTURI, SCOUTAPIKEY } from "../../utils";


export default function PlotStatCard (props) {

    const DEFAULT_STAT_TYPE = 'avg_count';
    const {stats, onEdit} = props;
    const [statType, setStatType] = useState(DEFAULT_STAT_TYPE)
    const [chartData, setChartData] = useState(null)
    function onStatTypeChange(type){
        setStatType(type);
    }
    const intl = useIntl();

    useEffect(()=>{
        if (!stats) return;

        const APIKEY = SCOUTAPIKEY,
        url = `${SCOUTURI}/api/v1.0/plot/${stats.plot_id}/stats/byyear/`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };
        options.headers.Authorization = `Api-Key ${APIKEY}`;
        fetch(url, options)
        .then(response => response.json())
        .then(data => setChartData(data))
        .catch(error => console.log(error));

    }, [stats])

    return (
        <div className="flex flex-col col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3 rounded-lg bg-white p-4 shadow-lg">
            <div className="m-1 mb-3 flex flex-row justify-between">
                <div className="plot_card-title">
                    <span className="text-xl">{stats.plot_name}</span>
                    <span className="text-slate-500 text-lg"> ({intl.formatNumber(stats.plot_area)} Ha)</span>
                </div>
                <div 
                    className="plot_card-settings cursor-pointer"
                    onClick={() => onEdit(stats.plot_id)}
                >
                    <span className="material-icons text-slate-500">settings</span>
                </div>
            </div>

            <PlotStatOptions active={statType} onChange={onStatTypeChange} />
            <div className="mt-5 flex w-full flex-row gap-4 overflow-scroll px-2">
                {
                    stats[statType]
                    .sort(
                        (a, b) => a.type_of_scout__scout_type > b.type_of_scout__scout_type ? 1 : -1
                    )
                    .map(
                        stat => <PlotStat
                            key={stat.type_of_scout__scout_type} 
                            value={Math.round(stat.value * 10)/10}
                            title={stat.type_of_scout__scout_type}
                        />
                    )
                }
            </div>
            { chartData && 
                <PlotStatChart plotID={stats.plot_id} data={chartData[statType]}/>
            }
        </div>
    )
}