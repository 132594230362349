import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import { useIntl } from 'react-intl';


function ReportChart(props) {
	const intl = useIntl();
	function init() {

		Highcharts.chart(props.reportID, {
			chart: {
				isSynced: true,
				marginLeft: 40, // Keep all charts left aligned
				spacingTop: 20,
				spacingBottom: 20
			},
			title: {
				text: null,
				// align: 'left',
				// margin: 0,
				// x: 30
			},
			plotOptions: {
				column: {
					dataLabels: {
						rotation: props.data.current.length > 5 ? -90 : 0,
						enabled: true,
						allowOverlap: true,
						y: props.data.current.length > 5 ? -20 : 0,
						style: {
							color: 'black',
							fontSize: '13px',
							fontWeight: 'bold',
							textOutline: null
						}
					}
				}
			},
			credits: {
				enabled: false
			},
			legend: {
				verticalAlign: 'top',
				enabled: true
			},
			xAxis: {
				type: 'category',
				categories: props.data.current.map(i => i.name),
				crosshair: true,
				// Uncomment to sync scrolling accross charts
				// events: {
				// 	setExtremes: props.sync
				// },
				min: 0,
				max: props.data.current.length < 20 ? props.data.current.length - 1 : 20,
				scrollbar: {
					enabled: props.data.current.length >= 20,
					barBackgroundColor: '#D2DBE8',
					barBorderRadius: 7,
					barBorderWidth: 0,
					buttonBackgroundColor: '#D2DBE8',
					buttonBorderWidth: 0,
					buttonBorderRadius: 7,
					trackBackgroundColor: 'none',
					trackBorderWidth: 1,
					trackBorderRadius: 8,
					trackBorderColor: '#CCC'
				},
			},
			tooltip: {
				// positioner: function () {
				// 	return {
				// 		// right aligned
				// 		x: this.chart.chartWidth - this.label.width,
				// 		y: 10 // align to title
				// 	};
				// },
				// borderWidth: 0,
				// backgroundColor: 'none',
				// pointFormat: '{point.y}',
				// headerFormat: '',
				// shadow: false,
				// style: {
				// 	fontSize: '18px'
				// },
				shared: true
				// valueDecimals: dataset.valueDecimals
			},
			series: [{
				data: props.data.last,
				name: intl.formatMessage({ id: "app.report.weatherReport.lastYear", defaultMessage: 'LAST YEAR' }),
				type: 'column',
				color: props.colors[0],
				fillOpacity: 0.3,
				// tooltip: {
				// 	valueSuffix: ' ' + dataset.unit
				// }
			}, {
				data: props.data.current,
				name: intl.formatMessage({ id: "app.report.weatherReport.current", defaultMessage: 'CURRENT' }),
				type: 'column',
				color: props.colors[1],
				fillOpacity: 0.3,
				// tooltip: {
				// 	valueSuffix: ' ' + dataset.unit
				// }
			}]
		});
	}

	useEffect(() => {
		if (props.data)
			init();
	}, [props.data]);

	return (
		<div style={{ width: '100%', height: '450px', marginTop: '25px' }}>
			<span style={{ fontSize: '1.4em', }}>{props.title}</span>
			<div id={props.reportID}>
			</div>
		</div>);
}


export default ReportChart;