import React, { useState, useContext } from 'react';
import { getAuthToken, BASEURI } from '../utils';
import { useEffect } from 'react';
import { EditSeriesContext } from './SchedulerEditItemWrapper';
import { Label, Error } from "@progress/kendo-react-labels";
import { Input, TextArea, } from "@progress/kendo-react-inputs";
import { FormElement, Field } from "@progress/kendo-react-form";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { TimezoneContext } from './TabLayout';


import { StationContext } from './TabLayout';

import {
  TitleEditor,
  DeviceEditor,
  SectorEditor,
  RecurrenceEditor,
  DurationEditor,
  PrecipitationEditor,
  IrrigationTypeEditor
} from "./Editors";
import { useIntl } from 'react-intl';


export const CustomFormEditor = props => {
  const intl = useIntl();
  const [device, setDevice] = useState(null);
  const [sectors, setSectors] = useState([]);
  const { stations } = useContext(StationContext);
  const { editSeries = true, allowEditing = true } = useContext(EditSeriesContext) ?? {};
  const { timezone } = useContext(TimezoneContext);

  const disable = !allowEditing;

  useEffect(() => {

    if (device) {
      let url = BASEURI + `/api/scheduler/${device.api === "Wiseconn" ? "zones" : "sectors"}/${device.serial}`,
        options = {
          method: 'GET',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        };

      getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

          //no token exists or all tokens are expired
          if (token === false) {
            return false;
          }

          options.headers.Authorization = `Bearer  ${token}`;

          fetch(url, options)
            .then((response) => {
              return response.json();
            })

            .then((sectors_list) => {
              setSectors(sectors_list);
            })

            .catch(error => {
              console.error('Error:', error.code);
            });
        });
    }

  }, [device])

  return <FormElement >
    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.title", defaultMessage: 'Title' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"title"} component={TitleEditor} disabled={!editSeries} />
        {props.errors.title && <Error>{props.errors.title}</Error>}
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.station', defaultMessage: 'Station' })}</Label>
      <div className="k-form-field-wrap">
        <Field
          name="device"
          setDevice={setDevice}
          devices={stations}
          component={DeviceEditor}
          disabled={disable} />
      </div>
      {props.errors.device && <Error>{props.errors.device}</Error>}
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.events.filter.sector", defaultMessage: 'Sector' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"zone"} sectors={sectors} component={SectorEditor} disabled={disable} />
        {props.errors.sector && <Error>{props.errors.sector}</Error>}
      </div>
    </div>

    <div className="k-form-field">
      {/* Translate this to spanish */}
      <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.irrigation_type', defaultMessage: 'Irrigation Type' })}</Label>
      <Field
        name={"irrigation_type"}
        component={IrrigationTypeEditor}
        startEditor={<>
          <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.start', defaultMessage: 'Start ({timezone})' }, { timezone: timezone })}</Label>
          <Field
            name={"start"}
            component={props.startEditor || DatePicker}
            as={DateTimePicker}
            rows={1}
            width={"250px"}
          // format="t"

          />
          {props.errors.start && <Error>{props.errors.start}</Error>}
        </>}

        endEditor={<>
          <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.end', defaultMessage: 'End ({timezone})' }, { timezone: timezone })}</Label>
          <Field
            name={"end"}
            component={props.startEditor || DatePicker}
            as={DateTimePicker}
            rows={1}
            width={"250px"}
          // format="t"
          />
          {props.errors.end && <Error>{props.errors.end}</Error>}
        </>}

        durationEditor={<>
          <Label>{intl.formatMessage({ id: 'app.scheduler.taskModel.duration', defaultMessage: 'Duration (H)' })}</Label>
          <Field
            name={"duration"}
            component={DurationEditor}
          />
          {props.errors.duration && <Error>{props.errors.duration}</Error>}
        </>}

        precipitationEditor={<>
          <Field
            name={editSeries ? "precip" : 'precipitation'}
            unit={device?.details?.precip_unit}
            component={PrecipitationEditor}
          />
        </>}
      />

    </div>

    {
      editSeries &&
      <div className="k-form-field">
        <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.frequency", defaultMessage: 'Frequency' })}</Label>
        <div className="k-form-field-wrap">
          <Field name={"recurrence_rule"} component={RecurrenceEditor} rows={1} />
        </div>
      </div>
    }

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.color", defaultMessage: 'Color' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"color"} defaultValue='#5A9BD5' type='color' component={Input} disabled={!editSeries} />
      </div>
    </div>

    <div className="k-form-field">
      <Label>{intl.formatMessage({ id: "app.scheduler.taskModel.notes", defaultMessage: 'Note' })}</Label>
      <div className="k-form-field-wrap">
        <Field name={"description"} default=' ' component={TextArea} rows={1} disabled={disable} />
      </div>
    </div>
  </FormElement >
};