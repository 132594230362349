import React, { useState, useEffect } from 'react';
import { getAuthToken, BASEURI } from '../utils';
import { Grid as KGrid, GridCell, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { useIntl } from 'react-intl';
import Modal from '../general/Modal';
import PaymentDialog from './PaymentDialog';
import BillDetail from './BillDetail';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import loadingIcon from '../loading.svg';
import ViewBillModal from './ViewBillModal';
import { filterBy } from '@progress/kendo-data-query';
import ManualReferenceModal from './ManualReferenceDialog';


function Bills(props) {

    const [bills, setBills] = useState({
        all: [],
    });

    const [billsGridData, setBillsGridData] = useState([]);
    const [showApplyDialog, setShowApplyDialog] = useState(false);
    const [viewingBill, setViewingBill] = useState({});
    const [expand, setExpand] = useState(0);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [showBillDialog, setShowBillDialog] = useState(false);
    const [showManualRefModal, setShowManualRefModal] = useState(false);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [take, setTake] = useState(5);
    const [useFilter, setUseFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    const [filter, setFilter] = useState([]);
    const [dueBillsCount, setDueBillsCount] = useState(0)
    const intl = useIntl()

    function onPageChange(event) {

        setSkip(event.page.skip);
        setTake(event.page.take);
        const currPage = Math.floor(event.page.skip / event.page.take) + 1;

        let url = useFilter ?
            `${BASEURI}/api/billing/bill/${filterValue}/?page=${currPage}` //check if user clicked on filter then return filtered pages
            : BASEURI + `/api/billing/bill/?page=${currPage}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        setTotal(resp.count);
                        setBillsGridData(resp.results.data);
                        setBills({
                            all: resp.results.data,
                        });
                    })
                    .catch(error => console.error('Error:', error));

            });

    }
    function getBills() {
        let url = `${BASEURI}/api/billing/bill/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        setTotal(resp.count);
                        console.log(resp)
                        setBillsGridData(resp.results.data);
                        setDueBillsCount(resp.results.due)
                        setBills({
                            all: resp.results.data,
                        });
                    })
                    .catch(error => console.error('Error:', error));

            });
    }

    function expandChange(event) {
        event.dataItem.expanded = !event.dataItem.expanded;
        setExpand(expand + 1);
    }

    function StatusCell(props) {
        return <td>
            {props.dataItem.paid ?
                <span style={{ color: 'green' }}>PAID</span> :
                !props.dataItem.issued ? <span style={{ color: "orange" }} >OPEN</span> :
                    <span style={{ color: 'red' }}>DUE</span>}
        </td>;
    }

    function AmountCell(props) {
        return <td>
            ${props.dataItem.amount_payable}
        </td>;
    }

    function toggleDialog(e) {
        e.preventDefault();
        setShowPaymentDialog(!showPaymentDialog);
    }

    function applyFilter(e, value) {
        setUseFilter(true);
        setFilterValue(value);

        e.preventDefault();
        let url = `${BASEURI}/api/billing/bill/${value}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        setTotal(resp.count);
                        setBillsGridData(resp.results.data);
                        setBills({
                            all: resp.results.data,
                        });
                    })
                    .catch(error => console.error('Error:', error));

            });
    }

    function applyDiscount(dataItem) {
        setViewingBill(dataItem)
        setShowApplyDialog(true);
    }

    function toggleBillDialog(e = null) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setShowBillDialog(!showBillDialog);
    }

    useEffect(() => getBills(), []);

    const Commands = CommandCell({
        applyDiscount: applyDiscount,
        viewBill: viewBill,
    }, intl);
    const Users = UserCell({
        users: props.users,
    })

    function viewBill(dataItem) {
        if (!props.isAdmin) return;
        setViewingBill(dataItem)
        toggleBillDialog();
    }



    return <div className='mdl-cell mdl-cell--8-col'>
        <div className="mdl-grid">

            <div className="mdl-cell mdl-cel--6-col">
                {intl.formatMessage({ id: 'app.bills.bills', defaultMessage: 'BILLS' })}
            </div>
            <div className="mdl-cell mdl-cell-6-col"></div>

            <ApplyDiscountModal
                show={showApplyDialog}
                onClose={() => setShowApplyDialog(false)}
                bill={viewingBill}
                bills={bills}
                setBills={setBills} />

            <ViewBillModal
                bill={viewingBill}
                setBill={setViewingBill}
                showRefModal={setShowManualRefModal}
                users={props.users}
                show={showBillDialog}
                onClose={toggleBillDialog} />
            <ManualReferenceModal
                show={showManualRefModal}
                onClose={() => setShowManualRefModal(false)}
                bill={viewingBill}
                setBill={setViewingBill} />

            <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone'>
                <form className='k-form'>
                    <KGrid
                        skip={skip}
                        take={take}
                        total={total}
                        filter={filter}
                        pageable
                        onPageChange={onPageChange}
                        data={filterBy(billsGridData, filter)}
                        scrollable={'scrollable'}
                        detail={BillDetail}
                        expandField={'expanded'}
                        onExpandChange={expandChange}
                        className={'ag-billstable'}
                        filterable
                        onFilterChange={(e) => setFilter(e.filter)}>
                        <GridNoRecords>
                            {/* Use graph grid no data message */}
                            {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                        </GridNoRecords>

                        <GridToolbar>
                            <div className="mdl-grid mdl-grid--no-spacing">
                                <div className='mdl-cell mdl-cell--2-col ag-grid-title' >
                                    {
                                        !props.isAdmin &&
                                        <div >
                                            <button className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary' onClick={toggleDialog}>
                                                {intl.formatMessage({ id: 'app.bills.makePayment', defaultMessage: 'Make Payment' })}
                                            </button>
                                        </div>
                                    }
                                </div>
                                <span className="mdl-cell mdl-cell--4-col due-bills-msg">
                                    {dueBillsCount ? <b style={{ color: 'red' }}>
                                        {intl.formatMessage({ id: 'app.bills.youHave', defaultMessage: 'YOU HAVE' })}
                                        &nbsp;
                                        <label>{intl.formatNumber(dueBillsCount)}</label>
                                        &nbsp;
                                        {intl.formatMessage({ id: 'app.bills.dueBills', defaultMessage: 'DUE BILLS' })}</b> : ""}
                                </span>
                                <div className='ag-grid-link-button--ctn mdl-cell mdl-cell--6-col'>
                                    {
                                        props.isAdmin ?
                                            <React.Fragment>
                                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'all')}>
                                                    {intl.formatMessage({ id: 'app.bills.all', defaultMessage: 'All' })}
                                                </button>
                                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'issued')}>
                                                    {intl.formatMessage({ id: 'app.bills.issued', defaultMessage: 'Issued' })}
                                                </button>
                                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'open')}>
                                                    {intl.formatMessage({ id: 'app.bills.open', defaultMessage: 'Open' })}
                                                </button>
                                            </React.Fragment>
                                            :
                                            <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'allforUser')}>All</button>
                                    }
                                    <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'paid')}>
                                        {intl.formatMessage({ id: 'app.bills.paid', defaultMessage: 'Paid' })}
                                    </button>
                                    <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => applyFilter(e, 'due')}>
                                        {intl.formatMessage({ id: 'app.bills.due', defaultMessage: 'Due' })}
                                    </button>
                                </div>
                            </div>
                        </GridToolbar>
                        {
                            props.isAdmin &&
                            <Column title="User" field="user" style={{ fontWeight: 'bold' }} filterable={false} cell={Users} />
                        }
                        <Column title='Period' field='period' style={{ fontWeight: 'bold' }} />
                        <Column title='Status' field='paid' style={{ fontWeight: 'bold' }} cell={StatusCell} />
                        <Column title='Total' field='amount_payable' style={{ fontWeight: 'bold' }} cell={AmountCell} />
                        {
                            props.isAdmin &&
                            <Column cell={Commands} filterable={false} sortable={false} title="Actions" width="150px" />
                        }
                    </KGrid>
                </form>
            </div>
            <Modal>
                <PaymentDialog
                    show={showPaymentDialog}
                    // dueBills={bills.filter(b => !b.paid)}
                    dueBills={bills.all}
                    close={() => setShowPaymentDialog(false)}
                />
            </Modal>
        </div>
    </div>;
}

export default Bills;
export { BillDetail };


function CommandCell({ applyDiscount, viewBill }, intl) {
    return props => {
        // render() {
        const { dataItem } = props;

        return (
            <td className="k-command-cell">
                <button
                    className="k-primary k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => applyDiscount(dataItem)}>
                    {intl.formatMessage({ id: 'app.bills.applyDiscount', defaultMessage: 'APPLY DISCOUNT' })}
                </button>
                <button
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => viewBill(dataItem)}>
                    {intl.formatMessage({ id: 'app.bills.viewBill', defaultMessage: 'VIEW BILL' })}
                </button>
            </td>

        );
        // }
    }
};

export function UserCell({ users }) {
    return props => {
        // render() {
        const { dataItem } = props;
        const user = users.filter(user => user.id === dataItem.user)[0];
        return (
            <td className="k-command-cell">
                {user && (user.first_name ? user.first_name : user.username)}
            </td>

        );
        // }
    }
};


function ApplyDiscountModal(props) {
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const intl = useIntl();
    function apply() {
        if (!props.bill.id) return;
        setLoading(true);
        let url = `${BASEURI}/api/billing/bill/${props.bill.id}/apply-discount/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(discount)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((bill) => {
                        setLoading(false);
                        let bills = props.bills.slice();
                        let billToReplace = null;
                        for (let i in bills) {
                            if (bills[i].id === bill.id) {
                                billToReplace = i;
                                break;
                            }
                        }
                        bills.splice(billToReplace, 1, bill);
                        props.setBills(bills);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }


    return props.show && <Modal>
        <Dialog closeIcon onClose={props.onClose} height={150} width={300}>
            <div className='modal-form-inputs-wrap'>
                <div className="modal-form-inputs-wrap-row">
                    <Input
                        name='discount'
                        value={discount}
                        required={true}
                        onChange={(e) => setDiscount(e.value)}
                        label={intl.formatMessage({ id: 'app.bills.applyDiscount.discount', defaultMessage: 'DISCOUNT (%)' })}

                    />
                </div>

            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>
                    {intl.formatMessage({ id: 'app.bills.applyDiscount.close', defaultMessage: 'Close' })}
                </button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={apply}>{
                    loading ? <img src={loadingIcon} alt="loading" width={25} height={25} /> :
                        intl.formatMessage({ id: 'app.bills.applyDiscount.apply', defaultMessage: 'APPLY' })}
                </button>
            </DialogActionsBar>

        </Dialog>
    </Modal>;
}
