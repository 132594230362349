import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getAuthToken, BASEURI } from '../utils';

function BillItems(props) {
    const intl = useIntl()

    return <table className='ag-billitems'>
        <thead>
            <tr>
                <th>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.item', defaultMessage: 'Item' })}
                </th>
                <th>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.qty', defaultMessage: 'Qty' })}
                </th>
                <th>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.amount', defaultMessage: 'Amount' })}
                </th>
            </tr>
        </thead>
        <tbody>
            {
                props.items.map((i, k) => {
                    console.log('key', k);
                    return <tr key={k}>
                        <td>{i.subscription}</td><td>{intl.formatNumber(i.count)}</td><td>{intl.formatNumber(i.total, { style: 'currency', currency: 'USD' })}</td>
                    </tr>
                })
            }
        </tbody>
        <tfoot>
            <tr>
                <td colSpan={2}>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.total', defaultMessage: 'Total' })}
                </td>
                <td>{intl.formatNumber(props.bill.amount, { style: 'currency', currency: 'USD' })}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.discount', defaultMessage: 'Discount' })}
                </td>
                <td>{intl.formatNumber(props.bill.discount_amount, { style: 'currency', currency: 'USD' })}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.salesTax', defaultMessage: 'Sales Tax' })}
                </td>
                <td>{intl.formatNumber(props.bill.sales_tax, { style: 'currency', currency: 'USD' })}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {intl.formatMessage({ id: 'app.bills.paymentDialog.amountPayable', defaultMessage: 'Amount Payable' })}
                </td>
                <td>{intl.formatNumber(props.bill.amount_payable, { style: 'currency', currency: 'USD' })}</td>
            </tr>
        </tfoot>
    </table>;
}

function PaypalButton(props) {

    const btnWrap = useRef(null);
    const [paypalBtn, setPaypalBtn] = useState();

    // useEffect(() => {

    //     return () => {
    //         console.log('unmounting');
    //         paypalBtn.current.remove();
    //     };
    // }, [])

    useEffect(() => {

        if (props.bill === undefined || !props.scriptLoaded)
            return;

        if (paypalBtn !== undefined)
            paypalBtn.remove();

        const btn = document.createElement("div");
        btn.id = 'paypal-button';

        btnWrap.current.appendChild(btn);

        setPaypalBtn(btn);

        console.log('paypal button construction effect ran');

        // window.paypal.Button.render({
        //     // Configure environment
        //     env: 'sandbox',
        //     // client: {
        //     // sandbox: 'AWlXcItSatGcrTCvUZdZMMdkzanwLlDAx2r5a2KBMVJUcpOr_rOanOWWDQxP4F5Y_z5p8-uYmSGXGESF',
        //     // production: 'demo_production_client_id'
        //     // },
        //     // Customize button (optional)
        //     // locale: 'en_US',
        //     // style: {
        //     // size: 'small',
        //     // color: 'gold',
        //     // shape: 'pill',
        //     // },

        //     // Enable Pay Now checkout flow (optional)
        //     commit: true,

        //     // Set up a payment
        //     payment: function(data, actions) {
        //     // return actions.payment.create({
        //     //     transactions: [{
        //     //     amount: {
        //     //         total: props.bill.amount.toString(),
        //     //         currency: 'USD'
        //     //     }
        //     //     }]
        //     // });
        //         getAuthToken()
        //         .then(token => token)
        //         .catch(token => token)
        //         .then(token => {

        //             return actions.request.post(
        //                 `${BASEURI}/api/billing/bill/${props.bill.id}/setup-payment/`,
        //                 {},
        //                 {
        //                     headers:{
        //                         Authorization: `Bearer  ${token}`
        //                     }
        //                 }
        //             )
        //             .then(res => res.id);

        //         });

        //     },
        //     // Execute the payment
        //     onAuthorize: function(data, actions) {
        //     // return actions.payment.execute().then(function() {
        //     //     // Show a confirmation message to the buyer
        //     //     window.alert('Thank you for your purchase!');
        //     // });
        //         return actions.request.post(`${BASEURI}/api/billing/bill/${props.bill.id}/execute-payment/`, {
        //             paymentID: data.paymentID,
        //             payerID: data.payerID
        //         })
        //         .then(res => window.alert('payment received.'));
        //     }
        //}, '#paypal-button');
        window.paypal.Buttons({
            style: {
                color: 'silver',
                label: 'pay',
                height: 40
            },
            createOrder: function (data, actions) {
                // return actions.order.create({
                //    purchase_units: [{
                //        amount: {
                //            value: props.bill.amount.toString()
                //        }
                //    }] 
                // });
                return getAuthToken()
                    .then(token => token)
                    .catch(token => token)
                    .then(token => {

                        return fetch(
                            `${BASEURI}/api/billing/bill/${props.bill.id}/setup-payment/`,
                            {
                                method: 'post',
                                headers: {
                                    "Content-Type": "application/json; charset=utf-8",
                                    Authorization: `Bearer  ${token}`
                                }
                            }
                        )
                            .then(response => response.json())
                            .then(result => result.id);

                    });
            },
            onApprove: function (data) {
                // return actions.order.capture()
                // .then(function(details){
                //     window.alert('Transaction completed by ' + details.payer.name.given_name);
                // })

                getAuthToken()
                    .then(token => token)
                    .catch(token => token)
                    .then(token => {

                        fetch(
                            `${BASEURI}/api/billing/bill/${props.bill.id}/execute-payment/`,
                            {
                                method: 'post',
                                headers: {
                                    "Content-Type": "application/json; charset=utf-8",
                                    Authorization: `Bearer  ${token}`
                                }
                            }
                        )
                            .then(response => response.json())
                            .then(() => window.alert('Transaction completed'));

                    });
            }
        }).render('#paypal-button');
        console.log('paypal button rendered');
        // setPaypalBtnRendered(true);
        // return paypalBtn.current.remove();
    }, [props.bill, props.scriptLoaded]);

    return <div ref={btnWrap} style={{ marginTop: '15px' }}>
    </div>;
}

function PaymentDialog(props) {

    const [bill, setBill] = useState();
    const [items, setItems] = useState([]);
    const [paypalScriptLoaded, setPaypalScriptLoaded] = useState(false);
    const intl = useIntl();

    function billChange(e) {
        setBill(e.target.value);
    }

    useEffect(() => {

        if (bill === undefined)
            return;

        let url = `${BASEURI}/api/billing/bill/${bill.id}/items/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((billItems) => {
                        setItems(billItems);
                    })
                    .catch(error => console.error('Error:', error));

            });
    }, [bill]);

    //load paypal script
    useEffect(() => {
        let CLIENT_ID = 'AV3l4CrwhNqtwJTkrFRtB5TjC-I0FaX8nC2lseVTAsOINfMitcjuVDsSzkPeHK3gF1ZWosxri9T8i3Gv';
        const scriptEl = document.createElement("script");
        scriptEl.id = 'paypalscript';
        scriptEl.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`;

        scriptEl.onload = e => {
            setPaypalScriptLoaded(true);
        };
        document.body.appendChild(scriptEl);

        return () => scriptEl.remove();

    }, []);

    return props.show && <Dialog title='Make Payment' onClose={props.close}>

        <div className='modal-wrap'>
            <div className='modal-form-wrap'>
                <div className='alert-modal-form-inputs-wrap payment-dialog'>
                    <div className='modal-form-row'>
                        <DropDownList
                            label={intl.formatMessage({ id: 'app.bills.paymentDialog.selectBill', defaultMessage: 'SELECT BILL' })}
                            name='bill'
                            data={props.dueBills}
                            textField='period'
                            dataItemKey='id'
                            value={bill}
                            onChange={billChange}
                        />
                        <div></div>
                    </div>
                    <div className='modal-form-row'>
                        <div style={{ marginTop: '15px' }}>
                            {bill && <BillItems bill={bill} items={items} />}
                        </div>
                    </div>
                </div>
                <div className='alert-modal-form-inputs-wrap payment-dialog'>
                    <div className='model-form-row'>
                        <PaypalButton bill={bill} scriptLoaded={paypalScriptLoaded} />
                    </div>
                </div>
            </div>
        </div>

        <DialogActionsBar>
            <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.close}>Done</button>
        </DialogActionsBar>
    </Dialog>;
}

export default PaymentDialog;