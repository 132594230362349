import React, { useState, useEffect } from 'react';
import { BASEURI, getAuthToken } from '../utils';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import loading from '../loading.svg';
import { useIntl } from 'react-intl';


function CopyChart(props) {

    const [working, setWorking] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [srcUser, setSrcUser] = useState();
    const [chart, setChart] = useState();
    const [desUser, setDesUser] = useState();
    const [desDashboard, setDesDashboard] = useState();
    const [chartListSource, setChartListSource] = useState([]);
    const [dashboardListSource, setDashboardListSource] = useState([]);
    const [chartList, setChartList] = useState([]);
    const [dashboardList, setDashboardList] = useState([]);
    const [srcUserList, setSrcUserList] = useState([]);
    const [desUserList, setDesUserList] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        setSrcUserList(props.users);
        setDesUserList(props.users);
    }, [props.users]);

    useEffect(() => {
        setChartList(chartListSource);
    }, [chartListSource]);

    useEffect(() => {
        setDashboardList(dashboardListSource);
    }, [dashboardListSource])

    useEffect(() => {
        if (props.isRegularUser) {
            if (props.users[0]) {
                setSrcUser(props.users[0]);
                setDesUser(props.users[0]);
                loadCharts(props.users[0].user.id);
                loadTabs(props.users[0].user.id);
            }
        }
    }, [props.users])

    function loadCharts(userID) {
        let url = `${BASEURI}/api/dashboard/charts/${userID}/`,

            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((charts) => {
                        setChartListSource(charts);
                    })
                    .catch(error => setErrorMsg(error));
            });
    }

    function handleSrcUserChange(e) {
        setSrcUser(e.value);
        loadCharts(e.value.user.id);

    }

    function loadTabs(userId) {
        let url = `${BASEURI}/api/dashboard/tabs/${userId}/`,

            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((tabs) => {
                        setDashboardListSource(tabs);
                    })
                    .catch(error => setErrorMsg(error));
            });

    }


    function handleDesUserChange(e) {
        setDesUser(e.value);
        loadTabs(e.value.user.id)
    }


    function copy() {

        setWorking(true);
        setErrorMsg('');

        let params = {
            chart_id: chart.id,
            tab_id: desDashboard.id
        }

        let url = `${BASEURI}/api/tools/chartadmin/`,
            options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            response.text().then(text => {
                                setSuccess(true);
                                setErrorMsg(text);
                                setWorking(false);
                            });
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then((res) => {
                        setSuccess(true);
                        setErrorMsg(res.message);
                        setWorking(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setWorking(false);
                    });

            });

    }

    function handleFilterChange(e) {

        switch (e.target.name) {
            case 'src_user':
                setSrcUserList(props.users.filter(client =>
                    client.user.username.indexOf(e.filter.value) !== -1));
                break;
            case 'des_user':
                setDesUserList(props.users.filter(client =>
                    client.user.username.indexOf(e.filter.value) !== -1));
                break;
            case 'src_chart':
                setChartList(chartListSource.filter(chart =>
                    chart.name.toLowerCase().indexOf(e.filter.value) !== -1));
                break;
            case 'des_dashboard':
                setDashboardList(dashboardListSource.filter(dash =>
                    dash.name.toLowerCase().indexOf(e.filter.value) !== -1));
                break;
            default:
                break;
        }
    }

    console.log('the source users list:', srcUserList)

    return <div className="mdl-grid" style={{ justifyContent: 'center' }}>

        <div className="mdl-cell mdl-cell--8-col">
            <div className='mdl-grid copy-chart-ctn'>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='src_user'
                        label={intl.formatMessage({ id: 'app.tools.copyChart.srcUser', defaultMessage: 'SRC USER' })}
                        data={srcUserList}
                        value={srcUser}
                        textField='user.username'
                        dataItemKey='user.id'
                        onChange={handleSrcUserChange}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                        disabled={props.isRegularUser}
                    />
                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='src_chart'
                        label={intl.formatMessage({ id: 'app.tools.copyChart.chart', defaultMessage: 'CHART' })}
                        data={chartList}
                        value={chart}
                        textField='name'
                        dataItemKey='id'
                        onChange={(e) => setChart(e.value)}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />
                </div>
                {/* <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <Checkbox
                        name='is_new_user'
                        label='NEW USER'
                        value={isNewUser}
                        onChange={(e) => setIsNewUser(e.value)}
                    />
                    
                </div> */}
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    {/* { isNewUser ?
                        <Input
                            name='username'
                            label='USERNAME'
                            value={username}
                            onChange={(e) => setUsername(e.value)}
                        />
                        : */}
                    <DropDownList
                        name='des_user'
                        label={intl.formatMessage({ id: 'app.tools.copyChart.desUser', defaultMessage: 'DES USER' })}
                        data={desUserList}
                        value={desUser}
                        textField='user.username'
                        dataItemKey='user.id'
                        onChange={handleDesUserChange}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                        disabled={props.isRegularUser}
                    />
                    {/* } */}

                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='des_dashboard'
                        label={intl.formatMessage({ id: 'app.tools.copyChart.desDashboard', defaultMessage: 'DES DASHBOARD' })}
                        data={dashboardList}
                        value={desDashboard}
                        textField='name'
                        dataItemKey='id'
                        onChange={(e) => setDesDashboard(e.value)}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />
                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'></div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <button
                        style={{ marginTop: '15px', width: '150px' }}
                        className="mdl-button mdl-js-button mdl-button--raised"
                        onClick={copy}
                    >
                        {working ? <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading' /> :
                            intl.formatMessage({ id: 'app.tools.copyChart.copy', defaultMessage: 'COPY' })}

                    </button>
                </div>
                <div className='mdl-cell mdl-cell--12-col' style={{ padding: '10px', color: success ? 'green' : 'red' }}>
                    {errorMsg}
                </div>
            </div>
        </div>

    </div>;
}


export default CopyChart;

