import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { BASEURI, getAuthToken } from '../utils';
import TransactionsGrid from './TransactionsGrid'






function ClientCredits(props) {
    
    const intl = useIntl();
    const [account, setAccount] = useState({ balance: 0 });
    const [transactions, setTransactions] = useState([]);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [take, setTake] = useState(5);


    useEffect(() => {
        loadAccount();
        loadTransactions();
    }, [])

    function loadAccount() {
        let url = BASEURI + '/api/billing/account/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((account) => {
                        setAccount(account);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    function loadTransactions() {
        let url = BASEURI + '/api/billing/transaction/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        let transactions = resp.results.map(tr => tr.id && { ...tr, date: new Date(tr.date) })
                        setTotal(resp.count);
                        setTransactions(transactions);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    function onPageChange(event) {

        setSkip(event.page.skip);
        setTake(event.page.take);
        const currPage = Math.floor(event.page.skip / event.page.take) + 1;
        let url = `${BASEURI}/api/billing/transaction/?page=${currPage}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        let transactions = resp.results.map(tr => tr.id && { ...tr, date: new Date(tr.date) })
                        setTotal(resp.count);
                        setTransactions(transactions);
                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    return (
        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid'>
                    <div className="mdl-cell mdl-cel--6-col">
                    {intl.formatMessage({id:'app.bills.clientCreditPage.creditStatus', defaultMessage:'CREDIT STATUS'})}
                    </div>
                    <div className="mdl-cell mdl-cell-6-col"></div>
                    <div className='mdl-grid--no-spacing mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone discount-grid-ctn'>
                        <TransactionsGrid
                            transactions={transactions}
                            onPageChange={onPageChange}
                            balance={account.balance}
                            skip={skip}
                            total={total}
                            take={take}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientCredits


