import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Grid as KGrid, GridCell, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { BASEURI, getAuthToken } from '../utils';
import DiscountModal from './DiscountModal';





function CommandCell({ editField, edit, deleteDiscount }) {
    const intl = useIntl();
    return props => {
        // render() {
        const { dataItem } = props;
        return (
            <td className="k-command-cell">
                <button
                    className="k-primary k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => edit(dataItem)}
                >
                    {intl.formatMessage({ id: 'app.bills.discountsGrid.edit', defaultMessage: 'EDIT' })}
                </button>
                <button
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-delete-command"
                    onClick={() => deleteDiscount(dataItem)}>
                    {intl.formatMessage({ id: 'app.bills.discountsGrid.delete', defaultMessage: 'DELETE' })}
                </button>
            </td>

        );
        // }
    }
};





function DiscountsGrid(props) {
    const [filter, setFilter] = useState([]);
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const [sort, setSort] = useState([]);
    const [viewingDiscount, setViewingDiscount] = useState({});
    const intl = useIntl();


    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        loadDiscounts();
    }, [props.users])

    function loadDiscounts() {
        let url = BASEURI + '/api/billing/discount/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((allDiscounts) => {
                        setDiscounts(allDiscounts);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    function edit(dataItem) {
        let discount = normalizeDiscount(dataItem);
        setViewingDiscount(discount);
        setShowDiscountDialog(true);
    }


    function deleteDiscount(dataItem) {
        let filtered = discounts.filter(disc => disc.id !== dataItem.id);
        setDiscounts(filtered);
        let url = BASEURI + `/api/billing/discount/${dataItem.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    function normalizeDiscount(discountToNormalize) {
        let discount = {};
        discount = Object.assign(discount, discountToNormalize);

        if (discount.subject_user_group === 'normal') {
            discount.organization = false
            discount.normal = true
        } else if (discount.subject_user_group === 'organization') {
            discount.organization = true
            discount.normal = false
        } else {
            discount.organization = false
            discount.normal = false
        }
        return discount;

    }


    const Commands = CommandCell({
        edit: edit,
        deleteDiscount: deleteDiscount,
        editField: "inEdit",

    });

    return (
        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            <DiscountModal
                show={showDiscountDialog}
                users={props.users}
                discounts={discounts}
                viewingDiscount={viewingDiscount}
                setDiscounts={setDiscounts}
                onClose={() => setShowDiscountDialog(false)} />

            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid'>
                    <div className="mdl-cell mdl-cel--6-col">
                        {intl.formatMessage({ id: 'app.bills.discountsGrid.discounts', defaultMessage: 'DISCOUNTS' })}
                    </div>
                    <div className="mdl-cell mdl-cell-6-col"></div>

                    <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone discount-grid-ctn'>
                        <KGrid
                            data={filterBy(orderBy(discounts.length ? discounts : [], sort), filter)}
                            sort={sort}
                            onSortChange={e => setSort(e.sort)}
                            filterable
                            filter={filter}
                            editField="inEdit"
                            onFilterChange={(e) => {
                                setFilter(e.filter);
                            }}
                        >
                            <GridNoRecords>
                                {/* Use graph grid no data message */}
                                {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                            </GridNoRecords>
                            <GridToolbar >
                                <button className="mdl-button mdl-js-button mdl-js-ripple-effect" onClick={() => setShowDiscountDialog(true)}>
                                    {intl.formatMessage({ id: 'app.bills.discountsGrid.addDiscount', defaultMessage: 'Add Discount' })}
                                </button>
                            </GridToolbar>
                            <Column field="name" title={intl.formatMessage({ id: 'app.bills.discountsGrid.name', defaultMessage: 'Name' })} />
                            <Column field="subject_user_group" cell={UserGroupCell} title={intl.formatMessage({ id: 'app.bills.discountsGrid.userGroup', defaultMessage: 'User Group' })} />
                            <Column field="users" cell={UsersCell} title={intl.formatMessage({ id: 'app.bills.discountsGrid.users', defaultMessage: 'Users' })} />
                            <Column field="condition" title={intl.formatMessage({ id: 'app.bills.discountsGrid.condition', defaultMessage: 'Condition' })} />
                            <Column field="discount" title={intl.formatMessage({ id: 'app.bills.discountsGrid.discount', defaultMessage: 'Discount(%)' })} />
                            <Column cell={Commands} filterable={false} sortable={false} />

                        </KGrid>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default DiscountsGrid

function UsersCell(props) {
    const { dataItem } = props;
    return (
        <td>
            {
                (dataItem && dataItem.users) ? dataItem.users.map(user =>
                    <span key={user.id} className="mdl-chip">
                        <span className="mdl-chip__text">{user.username}</span>
                    </span>
                ) : ""
            }
        </td>
    );

}



function UserGroupCell(props) {
    return <td style={{ textTransform: 'capitalize' }}>
        {props.dataItem && props.dataItem.subject_user_group}
    </td>

}