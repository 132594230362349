import React, { useRef, useState, useEffect } from 'react'
import { Input, Switch } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';
import {getAuthToken, BASEURI} from '../utils';
import LogTable, {LogItem} from './UploadLogsTable';
import loading from '../loading.svg';


function UploadLogs({ uploadLogsRef }){
    const intl = useIntl();
    const [logs, setLogs] = useState([]);
    const [load, setLoading] = useState(false);
    const [loadingNextPage, setLoadingNextPage] = useState(false);
    const [loadingPrevPage, setLoadingPrevPage] = useState(false);

    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);

    useEffect(()=>{
        uploadLogsRef.current.refresh = loadLogs;
    }, [])

    useEffect(()=>{
        loadLogs();
    }, [])

    function loadLogs(url=`${BASEURI}/api/dga/upload/logs/`){

        setLoading(true);
        
        let options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201)
                return response.text().then(text => {throw new Error(text)});

                return response.json();
            })
            .then((upload_logs) => {
                setLogs(upload_logs.results);
                setNextPage(upload_logs.next)
                setPrevPage(upload_logs.previous)
                setLoading(false);
                setLoadingNextPage(false);
                setLoadingPrevPage(false);
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
                setLoadingNextPage(false);
                setLoadingPrevPage(false);
            });

        });
    }
    
    function handleNextPage(){
        if(!nextPage)
        return
        setLoadingNextPage(true);
        loadLogs(nextPage);
    }

    function handlePreviousPage(){
        if(!prevPage)
        return
        setLoadingPrevPage(true);
        loadLogs(prevPage);
    }

    return (
        <React.Fragment>
            <LogTable>
                {logs.map(item => <LogItem {...item} key={item.id}/>)}
            </LogTable>
            <div class="inline-flex">
                <button onClick={handlePreviousPage} class={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${!prevPage && 'cursor-not-allowed'}`}>
                    {
                        loadingPrevPage ?
                        <img style={{width:'20px'}} src={loading} alt='loading'/> :
                        intl.formatMessage({id:'app.dga.logTable.prev', defaultMessage:'Prev'})
                    }
                </button>
                <button onClick={handleNextPage} class={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${!nextPage && 'cursor-not-allowed'}`}>
                    {
                        loadingNextPage ?
                        <img style={{width:'20px'}} src={loading} alt='loading'/> :
                        intl.formatMessage({id:'app.dga.logTable.next', defaultMessage:'Next'})
                    }
                </button>
            </div>
        </React.Fragment>
    )
}

export default UploadLogs;