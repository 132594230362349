import React from 'react'
import { useIntl } from 'react-intl';

export default function StatRow(props){

    const intl = useIntl()

    return <div className="row flex flex-col justify-between mb-1">
        <span className="value text-2xl text-slate-600 font-medium">
            {intl.formatNumber(props.value)}
        </span>
        <span className="title text-sm text-slate-600 font-light">
            {props.title}
        </span>
        
    </div>
}