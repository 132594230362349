import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from "../utils";
import loading from '../loading.svg';


function CopyDashboard(props) {
    const [working, setWorking] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [srcUser, setSrcUser] = useState();
    const [desUser, setDesUser] = useState();
    const [srcDashoard, setSrcDashboard] = useState();
    const [dashboardListSource, setDashboardListSource] = useState([]);
    const [srcDashboardList, setSrcDashboardList] = useState([]);
    const [srcUserList, setSrcUserList] = useState([]);
    const [desUserList, setDesUserList] = useState([]);
    const intl = useIntl();


    useEffect(() => {
        setSrcUserList(props.users);
        setDesUserList(props.users);
    }, [props.users]);

    useEffect(() => {
        setSrcDashboardList(dashboardListSource);
    }, [dashboardListSource])


    function handleSrcUserChange(e) {

        setSrcUser(e.value);

        let url = `${BASEURI}/api/dashboard/tabs/${e.value.user.id}/`,

            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((tabs) => {
                        setDashboardListSource(tabs);
                    })
                    .catch(error => setErrorMsg(error));
            });
    }

    function handleDesUserChange(e) {
        setDesUser(e.value);
    }

    function copy() {

        setWorking(true);
        setErrorMsg('');

        let params = {
            tab_id: srcDashoard.id,
            des_user_id: desUser.user.id,
        }

        let url = `${BASEURI}/api/tools/dashadmin/`,
            options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            response.text().then(text => {
                                setSuccess(true);
                                setErrorMsg(text);
                                setWorking(false);
                            });
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then((res) => {
                        setSuccess(true);
                        setErrorMsg(res.message);
                        setWorking(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setWorking(false);
                    });

            });

    }

    function handleFilterChange(e) {

        switch (e.target.name) {
            case 'src_user':
                setSrcUserList(props.users.filter(client =>
                    client.user.username.indexOf(e.filter.value) !== -1));
                break;
            case 'des_user':
                setDesUserList(props.users.filter(client =>
                    client.user.username.indexOf(e.filter.value) !== -1));
                break;
            case 'src_dashboard':
                setSrcDashboardList(dashboardListSource.filter(dash =>
                    dash.name.toLowerCase().indexOf(e.filter.value) !== -1));
                break;
            default:
                break;
        }
    }


    return <div className="mdl-grid" style={{ justifyContent: 'center' }}>

        <div className="mdl-cell mdl-cell--8-col">
            <div className='mdl-grid copy-dash-ctn'>
                {/* <div className="mdl-cell mdl-cel--6-col">
                    Copy Dashboard
                </div> */}
                {/* <div className="mdl-cell mdl-cell-6-col"></div> */}

                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='src_user'
                        label={intl.formatMessage({id:'app.tools.copyDash.srcUser', defaultMessage:'SRC USER'})}
                        data={srcUserList}
                        value={srcUser}
                        textField='user.username'
                        dataItemKey='user.id'
                        onChange={handleSrcUserChange}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />
                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='src_dashboard'
                        label={intl.formatMessage({id:'app.tools.copyDash.srcDash', defaultMessage:'SRC DASHBOARD'})}
                        data={srcDashboardList}
                        value={srcDashoard}
                        textField='name'
                        dataItemKey='id'
                        onChange={(e) => {
                            console.log(e.value)
                            setSrcDashboard(e.value)
                        }}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />
                </div>

                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <DropDownList
                        name='des_user'
                        label={intl.formatMessage({id:'app.tools.copyDash.desUser', defaultMessage:'DES USER'})}
                        data={desUserList}
                        value={desUser}
                        textField='user.username'
                        dataItemKey='user.id'
                        onChange={handleDesUserChange}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />
                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>

                </div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'></div>
                <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                    <button
                        style={{ marginTop: '15px', width: '150px' }}
                        className="mdl-button mdl-js-button mdl-button--raised"
                        onClick={copy}
                    >
                        {working ? <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading' /> :
                        intl.formatMessage({id:'app.tools.copyDash.copy', defaultMessage:'COPY'})}

                    </button>
                </div>
                <div className='mdl-cell mdl-cell--12-col' style={{ padding: '10px', color: success ? 'green' : 'red' }}>
                    {errorMsg}
                </div>
            </div>
        </div>

    </div>;
}
export default CopyDashboard;