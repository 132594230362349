import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import { useIntl } from 'react-intl';
import { formater } from './IrrigationReport';


function ReportChart(props) {
	const intl = useIntl();
	function init() {

		Highcharts.chart(props.reportID, {
			chart: {
				isSynced: true,
				marginLeft: 40, // Keep all charts left aligned
				spacingTop: 20,
				spacingBottom: 20
			},
			title: {
				text: null,
				// align: 'left',
				// margin: 0,
				// x: 30
			},
			plotOptions: {
				column: {
					dataLabels: {
						rotation: props.data.length > 4 ? -90 : 0,
						enabled: true,
						allowOverlap: true,
						y: props.data.length > 4 ? -20 : 0,
						style: {
							color: 'black',
							fontSize: '13px',
							fontWeight: 'bold',
							textOutline: null
						}
					}
				}
			},
			credits: {
				enabled: false
			},
			legend: {
				verticalAlign: 'top',
				enabled: true,
				labelFormatter: function () {
					return `
					  <span '>
						<span>${this.name}</span>
						${this.options.totalValue !== null ?
							`<span class="font-normal">(${this.options.totalValue})</span>` :
							''
						}
					  </span>`
				}
			},

			yAxis: [
				{
					offset: false,
					title: {
						//   offset: this.getYAxisTitleOffset(),
						text: 'hour',
						// text: graphs[i].y_axis_legend,
						style: {
							color: '#4DB6AC'
						}
					},
					labels: {

						x: -5,
						style: {
							color: '#4DB6AC'
						}
					},
					// min: axis_min,
					// max: axis_max,
					opposite: false,

				},
				{
					title: {
						//   offset: this.getYAxisTitleOffset(),
						text: props.precip_unit,
						// text: graphs[i].y_axis_legend,
						style: {
							color: '#FFA726'
						}
					},
					labels: {
						x: 10,
						style: {
							color: '#FFA726'
						}
					},
					// min: axis_min,
					// max: axis_max,
					opposite: true,
				},
				{
					title: {
						//   offset: this.getYAxisTitleOffset(),
						text: props.volume_unit,
						// text: graphs[i].y_axis_legend,
						style: {
							color: '#AB47BC'
						}
					},
					labels: {
						x: 10,
						style: {
							color: '#AB47BC'
						}
					},
					// min: axis_min,
					// max: axis_max,
					opposite: true,
				}

			],

			xAxis: {
				type: 'category',
				categories: props.data.map(i => i.irrigation_task__zone__name),
				crosshair: true,
				// Uncomment to sync scrolling accross charts
				// events: {
				// 	setExtremes: props.sync
				// },
				min: 0,
				max: props.data.length < 20 ? props.data.length - 1 : 20,
				scrollbar: {
					enabled: props.data.length >= 20,
					barBackgroundColor: '#D2DBE8',
					barBorderRadius: 7,
					barBorderWidth: 0,
					buttonBackgroundColor: '#D2DBE8',
					buttonBorderWidth: 0,
					buttonBorderRadius: 7,
					trackBackgroundColor: 'none',
					trackBorderWidth: 1,
					trackBorderRadius: 8,
					trackBorderColor: '#CCC'
				},
			},
			tooltip: {
				// positioner: function () {
				// 	return {
				// 		// right aligned
				// 		x: this.chart.chartWidth - this.label.width,
				// 		y: 10 // align to title
				// 	};
				// },
				// borderWidth: 0,
				// backgroundColor: 'none',
				// pointFormat: '{point.y}',
				// headerFormat: '',
				// shadow: false,
				// style: {
				// 	fontSize: '18px'
				// },
				shared: true
				// valueDecimals: dataset.valueDecimals
			},

			series: [
				{
					id: 'hour',
					totalValue: formater(props.totalDeviceDuration),
					data: props.data.map(item => parseFloat(item.total_sector_duration.toFixed(2))),
					name: intl.formatMessage({ id: 'app.scheduler.layout.irrigation_hours', defaultMessage: 'Irrigation Hours' }),
					type: 'column',
					color: '#4DB6AC',
					fillOpacity: 0.3,
					// tooltip: {
					// 	valueSuffix: ' ' + dataset.unit
					// }
					yAxis: 0
				},
				{
					id: props.precip_unit,
					totalValue: formater(props.totalDevicePrecipitation),
					data: props.data.map(item => parseFloat(item.total_sector_precipitation?.toFixed(3))),
					name: intl.formatMessage({ id: 'app.scheduler.layout.precipitation', defaultMessage: 'Precipitation' }),
					type: 'column',
					color: '#FFA726',
					fillOpacity: 0.3,
					// tooltip: {
					// 	valueSuffix: ' ' + dataset.unit
					// }
					yAxis: 1
				},
				{
					id: props.volume_unit,
					totalValue: formater(props.totalDeviceVolume),
					data: props.data.map(item => parseFloat(item.total_sector_volume.toFixed(2))),
					name: intl.formatMessage({ id: 'app.scheduler.layout.volume', defaultMessage: 'Volume' }),
					type: 'column',
					color: '#AB47BC',
					fillOpacity: 0.3,
					// tooltip: {
					// 	valueSuffix: ' ' + dataset.unit
					// }
					yAxis: 2
				}
			]


		});
	}

	useEffect(() => {
		if (props.data)
			init();
	}, [props.data]);

	return (
		<div style={{ width: '100%', height: '450px', marginTop: '25px' }}>
			<span style={{ fontSize: '1.4em', }}>{props.title}</span>
			<div id={props.reportID}>
			</div>
		</div>);
}


export default ReportChart;