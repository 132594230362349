import React, { useState, useEffect } from "react";
import loadingWhite from '../loading_white.svg';
import { BASEURI, getAuthToken } from "../utils";
import { useIntl } from 'react-intl';
import { Input } from '@progress/kendo-react-inputs';

const ProfilePage = ({ client, message, saving, updateClient, isValid, changePassword, successMessage }) => {
    const [formData, setFormData] = useState({});
    const [password, setPassword] = useState('');
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
    const intl = useIntl();

    useEffect(() => { setFormData(client) }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((preState) => { return { ...preState, [name]: value } });
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        if (!password)
            return
        changePassword(password);
        setPassword('');

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateClient(formData);
    };

    return (
        <div className="flex justify-center">
            <div className="m-4 w-screen max-w-[900px] rounded-md ring-1 ring-gray-100 p-4 shadow-md">
                <form>
                    <div className="border-b border-gray-900/10 pb-12">
                        <div className="flex items-center justify-between">
                            <h1 className="text-base text-slate-500 font-semibold leading-7  flex items-center ">
                                <i className="material-icons">person</i>@{formData?.user?.username}
                            </h1>
                            <h3 className="text-green-500 cursor-pointer " onClick={() => {
                                setShowChangePasswordForm(!showChangePasswordForm);
                                setPassword('');
                            }}
                            >
                                {showChangePasswordForm ?
                                    intl.formatMessage({ id: "app.profile", defaultMessage: "Profile" }) :
                                    intl.formatMessage({ id: "app.profile.changePassword", defaultMessage: "Change Password" })}</h3>
                        </div>

                        <div className="mt-4">
                            <div className="block text-sm font-medium leading-6 text-gray-500">
                                {intl.formatMessage({ id: "app.profile.org", defaultMessage: "Organization" })}
                            </div>
                            <div className="mt-2 text-slate-500 font-bold capitalize">
                                {formData.org_name || 'N/A'}
                            </div>
                        </div>

                        {showChangePasswordForm ?
                            <><div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
                                {/* New Password */}
                                <div className="col-span-1">
                                    <div className="mt-2">
                                        <Input
                                            label={intl.formatMessage({ id: "app.profile.newPassword", defaultMessage: "New Password" })}
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            type="text"
                                            name="password"
                                        // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>

                            </> : <>

                                <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
                                    {/* First Name */}
                                    <div className="col-span-1">
                                        <div className="mt-2">
                                            <Input
                                                label={intl.formatMessage({ id: "app.profile.name", defaultMessage: "Full Name" })}
                                                onChange={handleChange}
                                                value={formData?.name}
                                                type="text"
                                                name="name"
                                                id="first-name"
                                                autoComplete="given-name"
                                            // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Email Address */}
                                    <div className="col-span-1">
                                        <div className="mt-2">
                                            <Input
                                                valid={isValid}
                                                label={intl.formatMessage({ id: "app.profile.emailAdd", defaultMessage: "Email address" })}
                                                onChange={handleChange}
                                                value={formData.email}
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                            // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Country */}
                                    <div className="col-span-1">
                                        <div className="mt-2">
                                            <Input
                                                label={intl.formatMessage({ id: "app.profile.country", defaultMessage: "Country" })}
                                                onChange={handleChange}
                                                value={formData.country}
                                                type="text"
                                                id="country"
                                                name="country"
                                                autoComplete="country-name"
                                            // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>


                                    <div className="col-span-1">
                                        <div className="mt-2">
                                            <Input
                                                label={intl.formatMessage({ id: "app.profile.region", defaultMessage: "Region" })}
                                                onChange={handleChange}
                                                value={formData.region}
                                                type="text"
                                                id="region"
                                                name="region"
                                                autoComplete="region-name"
                                            // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
                                    {/* phone */}
                                    <div className="col-span-1">
                                        <div className="mt-2">
                                            <Input
                                                label={intl.formatMessage({ id: "app.profile.phone", defaultMessage: "Phone" })}
                                                onChange={handleChange}
                                                value={formData.phone}
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                autoComplete="phone-number"
                                            // className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                    <div className="text-red-500 mt-4">{message}</div>
                    <div className="text-green-500 mt-4">{successMessage}</div>
                    {/* Action Buttons */}
                    <div className="mt-6 flex justify-end gap-x-6">
                        <button
                            onClick={(e) => {
                                if (showChangePasswordForm)
                                    handlePasswordChange(e);
                                else
                                    handleSubmit(e);

                            }}
                            className="min-w-24 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            {saving ?
                                <img style={{ width: '15px' }} src={loadingWhite} alt='loading' /> :
                                showChangePasswordForm ?
                                    intl.formatMessage({ id: "app.profile.changePassword", defaultMessage: "Change Password" }) :
                                    intl.formatMessage({ id: "app.profile.save", defaultMessage: 'Save' })}
                        </button>
                    </div>

                </form>
            </div >
        </div >
    );
};

export default ProfilePage;
