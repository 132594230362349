// React
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

// Third-party
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid as KGrid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import Modal from '../../general/Modal'

// Project
import { getAuthToken, BASEURI } from '../../utils';
import { Input } from '@progress/kendo-react-inputs';

import ConfirmDeleteDialog from '../../general/ConfirmDeleteDialog';


function UserCommands(props) {
    const intl = useIntl();

    const { dataItem } = props;

    return (
        dataItem.inEdit ?
            <td>

                <button onClick={() => { props.save(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary">
                    {props.working ?
                        intl.formatMessage({ id: 'app.agscout.saving', defaultMessage: 'Saving...' }) :
                        intl.formatMessage({ id: 'app.agscout.save', defaultMessage: 'Save' })}
                </button>
                <button onClick={() => { props.cancel(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    {intl.formatMessage({ id: 'app.agscout.cancel', defaultMessage: 'Cancel' })}
                </button>
            </td>
            :
            <td>
                <button onClick={() => { props.del(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-warning delete">
                    {intl.formatMessage({ id: 'app.agscout.delete', defaultMessage: 'Delete' })}
                </button>
            </td>
    )
}


function PasswordCell(props) {

    const { dataItem } = props
    const field = props.field || ""
    const value = dataItem[field] !== null ? dataItem[field] : ""

    function onChange(event) {

        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: props.syntheticEvent,
                value: event.target.value
            })
        }
    }

    return (dataItem.inEdit ?
        <td>
            <Input name='password' value={value} type='password' onChange={onChange} />
        </td>
        :
        <td>******</td>
    )
}

function OrganizationCell(props) {

    const { dataItem } = props
    const field = props.field || ""
    const value = dataItem[field] !== null ? dataItem[field] : ""

    return (dataItem.inEdit ?
        <td>
        </td>
        :
        <td>{value}</td>
    )
}


function RetryButton(props) {
    const intl = useIntl();
    return <button className='underline text-red-600' onClick={props.reload}>
        {intl.formatMessage({ id: 'app.agscout.retry', defaultMessage: 'Retry' })}
    </button>
}


function UserModal(props) {

    const intl = useIntl();
    const [statusText, setStatusText] = useState('')
    const SUCCESS_TEXT_COLOR_CLASS = 'bg-green-100 text-green-600'
    const ERROR_TEXT_COLOR_CLASS = 'bg-red-100 text-red-600'
    const NETWORK_FAILURE_TEXT = intl.formatMessage({ id: 'app.agscout.failedToLoadUsers', defaultMessage: 'Failed to load list of AgScout users.' })
    const [statusColor, setStatusColor] = useState(SUCCESS_TEXT_COLOR_CLASS)
    const [data, setData] = useState(props.users)
    const [working, setWorking] = useState(false)
    const [itemToBeDeleted, setItemToBeDeleted] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [currentRecord] = useState({
        id: null,
        phone_number: null,
        password: null,
        org_name: null,
        inEdit: true,
    });

    function handleDeletion(dataItem) {
        setItemToBeDeleted(dataItem)
        setShowDeleteDialog(true)
    }

    function removeUser(dataItem) {

        let url = `${BASEURI}/api/agscout/user/`,
            options = {
                method: 'DELETE',
                body: JSON.stringify({ phone_number: dataItem.phone_number }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;

                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(response.statusText, response.status);
                        }
                    })
                    .then(_ => {
                        setData(data.filter(user => user.id !== dataItem.id))
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });
    }

    function addUser() {
        setData([currentRecord, ...data])
    }

    function saveUser(dataItem) {

        setWorking(true)

        let url = `${BASEURI}/api/agscout/user/`,
            options = {
                method: 'POST',
                body: JSON.stringify({
                    'phone_number': dataItem.phone_number,
                    'password': dataItem.password
                }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;

                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        setWorking(false)
                        if (!response.ok) {
                            setStatusColor(ERROR_TEXT_COLOR_CLASS)
                            response.json().then(err => setStatusText(err.message));
                            throw new Error(response.statusText, response.status);
                        }
                        return response.json();
                    })
                    .then((response) => {
                        setStatusColor(SUCCESS_TEXT_COLOR_CLASS)
                        setStatusText(response.message)
                        props.getUsers()
                    })
                    .catch(error => {
                        setWorking(false)
                        console.error(error);
                    });
            });
    }

    function cancel() {
        setData(data.filter(user => user.id !== null))
    }

    function onItemChange(event) {
        setStatusText('')
        const userList = data.map(user => user.inEdit === event.dataItem.inEdit ? { ...user, [event.field]: event.value } : user)
        setData(userList);
    }

    function Commands(props) {
        return <UserCommands
            {...props}
            save={saveUser}
            del={handleDeletion}
            cancel={cancel}
            working={working}
        />
    }

    useEffect(() => {
        if (props.users) {
            setData(props.users)
            // setStatusText('')
        }
    }, [props.users])

    useEffect(() => {
        if (props.retry) {
            setData(props.users)
            setStatusColor(ERROR_TEXT_COLOR_CLASS)
            setStatusText(NETWORK_FAILURE_TEXT)
        } else {
            setStatusText('')
        }
    }, [props.retry])

    return (
        <>{
            showDeleteDialog &&
            <ConfirmDeleteDialog
                message={<p> <b>{itemToBeDeleted.phone_number} </b> {intl.formatMessage({ id: 'app.chart.deleteChart.agscoutAcc.deleteMessage', defaultMessage: "will be deleted. Type {delete} below to confirm." }, { delete: <span style={{ color: 'red' }}>DELETE</span> })}</p>}
                onClose={() => setShowDeleteDialog(false)}
                remove={() => { removeUser(itemToBeDeleted); setShowDeleteDialog(false) }}
            />
        }
            <Modal>
                <Dialog title={intl.formatMessage({ id: 'app.agscout.agScoutUsers', defaultMessage: 'AgScout Users' })} closeIcon={true} onClose={props.close}>
                    <div className='scout-user_grid_ctn flex flex-col h-full w-full overflow-scroll'>
                        <KGrid
                            data={data}
                            editField='inEdit'
                            onItemChange={onItemChange}
                            scrollable='scrollable'
                            style={{ maxHeight: 'inherit' }}
                        >
                            <GridToolbar>
                                <button
                                    className="mdl-button mdl-button--colored"
                                    onClick={addUser}
                                >
                                    {intl.formatMessage({ id: 'app.agscout.addUser', defaultMessage: 'Add User' })}
                                </button>
                                {statusText &&
                                    <div className={`scout-user-grid-errors px-3 py-1 rounded-sm ${statusColor} text-center`}>
                                        {statusText}
                                    </div>
                                }
                                {props.retry && <RetryButton reload={props.getUsers} />}
                            </GridToolbar>
                            <Column field='org_name' title={intl.formatMessage({ id: 'app.agscout.organization', defaultMessage: 'Organization' })} editable={true} cell={OrganizationCell} />
                            <Column field='phone_number' title={intl.formatMessage({ id: 'app.agscout.phoneNo', defaultMessage: 'Phone No.' })} editable={true} editor='text' />
                            <Column field='password' title={intl.formatMessage({ id: 'app.agscout.password', defaultMessage: 'Password' })} editable={true} cell={PasswordCell} />
                            <Column cell={Commands} filterable={false} sortable={false} />
                        </KGrid>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.close} >
                            {intl.formatMessage({ id: 'app.agscout.done', defaultMessage: 'DONE' })}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            </Modal>
        </>)
}

export default UserModal