import React from "react";


export default function PlotStatOptions (props){

  const {active} = props;
  const activeStyle = 'text-white rounded-full bg-emerald-500';
  const inactiveStyle = 'text-emerald-600';

  return (
      <div className="flex max-w-fit rounded-full bg-green-50 p-1">
        <span 
          className={`cursor-pointer py-1.5 px-1.5 text-sm font-light ${active === 'avg_count' ? activeStyle : inactiveStyle}`}
          onClick={() => props.onChange('avg_count')}
        >
          Promedio
        </span>
        <span
          className={`cursor-pointer py-1.5 px-1.5 text-sm font-light ${active === 'counts_per_hectare' ? activeStyle : inactiveStyle}`}
          onClick={() => props.onChange('counts_per_hectare')}
        >
          Conteo/Ha
        </span>
        <span
          className={`cursor-pointer py-1.5 px-1.5 text-sm font-light ${active === 'total_count_per_plot' ? activeStyle : inactiveStyle}`}
          onClick={() => props.onChange('total_count_per_plot')}
        >
          Conteo/Cuartel
        </span>
        <span
          className={`cursor-pointer py-1.5 px-1.5 text-sm font-light ${active === 'kg_of_yield_total' ? activeStyle : inactiveStyle}`}
          onClick={() => props.onChange('kg_of_yield_total')}
        >
          Kg/Cuartel
        </span>
        <span
          className={`cursor-pointer py-1.5 px-1.5 text-sm font-light ${active === 'kg_of_yield_per_ha' ? activeStyle : inactiveStyle}`}
          onClick={() => props.onChange('kg_of_yield_per_ha')}
        >
          Kg/Ha
        </span>
      </div>
  )
}