import React from 'react';

import { useIntl } from 'react-intl';

export default function PlotStat (props) {

    const intl = useIntl()
    const {value, title} = props;

    return (
        <span className="inline-flex flex-col">
            <span className="text-lg"> {intl.formatNumber(value)} </span>
            <span className="text-xs text-slate-400"> {title} </span>
        </span>
    )
}