import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, Switch, NumericTextBox, Checkbox } from '@progress/kendo-react-inputs';
import { DropDownList, } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import loading from '../loading.svg';


class StationForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fixedStart: Boolean(props.fixed_start_date),
      ranges: [
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.today', defaultMessage: 'Today' }),
          value: 1,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.yesterday', defaultMessage: 'Yesterday' }),
          value: 2,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1week', defaultMessage: '1 Week' }),
          value: 7,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.2week', defaultMessage: '2 Weeks' }),
          value: 14,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1month', defaultMessage: '1 Month' }),
          value: 30,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.2months', defaultMessage: '2 Months' }),
          value: 60,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.3months', defaultMessage: '3 Months' }),
          value: 90,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.6months', defaultMessage: '6 Months' }),
          value: 180,
        },
        {
          range: this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.1year', defaultMessage: '1 Year' }),
          value: 360,
        }
      ]
    }

    this.handleFixedStartChange = this.handleFixedStartChange.bind(this);
  }

  handleFixedStartChange(e) {

    this.setState(state => ({ fixedStart: !state.fixedStart }));

    e.target.name = 'fixed_start_date';
    e.target.value = null;

    this.props.handleChange(e);
  }

  render() {

    return (<div className='modal-widget-dialog-inputs-wrap'>
      <Input
        required={true}
        name='name'
        label={this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.name', defaultMessage: 'NAME' })}
        value={this.props.name}
        onChange={this.props.handleChange} />

      <NumericTextBox
        required={true}
        name='index'
        label={this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.index', defaultMessage: 'INDEX' })}
        value={this.props.index}
        onChange={this.props.handleChange} />

      <div className='mdl-grid mdl-grid--no-spacing' style={{ margin: 0 }}>
        <div className='mdl-cell mdl-cell--6-col'>
          <div>
            <label id='fixed_start_date' style={{ color: 'gray', fontSize: '.8em' }}>
              {this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.fixStartDate', defaultMessage: 'FIXED START DATE' })}
            </label>
          </div>
          <DatePicker
            name='fixed_start_date'
            areaLabeledBy='fixed_start_date'
            defaultValue={this.props.min_date}
            value={this.props.fixed_start_date && new Date(this.props.fixed_start_date)}
            disabled={!this.state.fixedStart}
            calendarSettings={{ views: 1 }}
            popupSettings={this.popupSettings}
            onChange={this.props.handleChange}
          />
          <Checkbox
            name='maximized'
            checked={this.state.fixedStart}
            onChange={this.handleFixedStartChange}
          />
        </div>
        <div className='mdl-cell mdl-cell--6-col'>
          <DropDownList
            style={{ width: "100%" }}
            name='default_range'
            label={this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.defaultRange', defaultMessage: 'DEFAULT RANGE' })}
            data={this.state.ranges}
            value={this.state.ranges.filter(range => range.value === this.props.default_range)[0]}
            textField='range'
            dataItemKey='value'
            disabled={this.state.fixedStart}
            onChange={this.props.handleChange} />
        </div>
      </div>
      <div>
        <label
          style={{
            color: 'gray',
            fontSize: '.9em',
            display: 'block',
            margin: '10px 0 5px 0'
          }}
        >
          {this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.maximized', defaultMessage: 'MAXIMIZED' })}
        </label>
        <Switch
          name='maximized'
          checked={this.props.maximized}
          onChange={this.props.handleChange}
        />
      </div>
    </div>
    );
  }
}

class WidgetDialog extends Component {

  render() {

    let dialog = this.props.visible &&
      <Dialog title={this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.addChart', defaultMessage: 'Add Chart' })} onClose={this.props.close} className='add-widget-dialog'>

        <div className='modal-wrap'>
          <div className='modal-form-wrap'>
            <StationForm intl={this.props.intl} handleChange={this.props.handleChange} {...this.props.chart} />
          </div>
        </div>
        <DialogActionsBar>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>
            {this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.cancel', defaultMessage: 'Cancel' })}
          </button>
          {this.props.saving ?
            <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
              <img src={loading} style={{ width: '20px', height: '20px', opacity: .5 }} alt='loading' />
            </button> :
            <>{this.props.chart.id ?
              <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={(e) => this.props.updateChart(e, undefined)}>
                {this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.update', defaultMessage: 'Update' })}
              </button> :
              <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.addChart}>
                {this.props.intl.formatMessage({ id: 'app.chart.widgetDialog.add', defaultMessage: 'Add' })}
              </button>}
            </>
          }

        </DialogActionsBar>
      </Dialog>;
    return (dialog);
  }
}



export default WidgetDialog;