import {getAuthToken, BASEURI} from '../utils';
import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

function BillDetail(props){

    const intl = useIntl();

    const [detail, setDetail] = useState({
        content: ''
    });
    const [billItems, setBillItems] = useState([]);

    useEffect(() => {
        let url = `${BASEURI}/api/billing/bill/${props.dataItem.id}/items/`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };

        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                return response.json();
            })
            .then((items) => {
                setBillItems(items);
            })
            .catch(error => console.error('Error:', error));

        });
    }, [props.dataItem]);

    return <div className='ag-billdetail'>
            <table className='mdl-data-table mdl-js-data-table'
                style={{width: '100%', marginTop:'10px'}}
            >
                <thead>
                    <tr>
                        <th>{intl.formatMessage({id:'app.bills.billDetail.item', defaultMessage:'Item'})}</th>
                        <th>{intl.formatMessage({id:'app.bills.billDetail.qty', defaultMessage:'Qty'})}</th>
                        <th>{intl.formatMessage({id:'app.bills.billDetail.amount', defaultMessage:'Amount'})}</th>
                    </tr>
                </thead>
                <tbody>
                {billItems.map(item => <tr>
                    <td key={item.id}>{item.subscription}</td>
                    <td>{item.count}</td>
                    <td>${item.total}</td>
                </tr>)}
                </tbody>
            </table>
        </div>;
}

export default BillDetail;