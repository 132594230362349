import PasswordResetConfirm from './passwordResetComponents/PasswordResetConfirmationForm';
import PasswordResetRequest from './passwordResetComponents/PasswordRequestResetForm';
import TabLayout from './scheduler/TabLayout';
import '@babel/polyfill';
import React, { Component } from 'react';
import ErrorBoundary from './errorBoundaries/errorBoundary';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Outlet,
  Navigate
} from "react-router-dom";
import './App.css';
import './chart.css';
import '@progress/kendo-theme-material/dist/all.css';
import { FormattedMessage } from 'react-intl';
import Layout from './general/Layout';
import DashboardWraper from './general/Dashboard';
import Stations from './stations/Stations';
import Alerts from './alerts/Alerts';
import Login from './general/Login';
import { getAuthToken, BASEURI, retrieveToken, storeToken } from './utils';
import TabDialog from './general/TabDialog';
import Modal from './general/Modal';
import Admin from './admin/Admin';
import DGAUploadJobs from './dga/DGAUploadJobs';
import ConfirmDeleteDialog from './general/ConfirmDeleteDialog';
import Tools from './tools/Tools';
import Billing from './billing/Billing';
import ScoutPage from './scout/ScoutPage';
import NotFound from './routes/NotFound';
import PrivateRoutes from './routes/PrivateRoutes';
import AgScoutPrivacyPolicy from './privacyPolicy/AgScoutPrivacyPolicy';
import AgViewerPrivacyPolicy from './privacyPolicy/AgViewerPrivacyPolicy';
import ProfilePage from './general/ProfilePage';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs'
import loadingWhite from './loading_white.svg';

class LoginCtn extends Component {

  render() {
    return (
      <div>
        <Outlet />
      </div>);
  }
}


class LayoutCtn extends Component {

  render() {
    return <div> {this.props.children} </div>;
  }
}


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      showEmailDialog: false,
      saving: false,
      message: '',
      successMessage: '',
      client: {},
      currentUserId: null,
      users: [],
      usersCopy: [],
      updateComponent: false,
      loggingIn: false,
      notInitial: false,
      stations: [],
      allStations: [],
      markers: [],
      markersCopy: [],
      weatherVariables: [],
      loadingWeatherVariables: false,
      loadingMarkers: false,
      loading: false,
      page: 'admin',
      navigateTo: null,
      tabs: [],
      loggedin: localStorage.getItem('token') ? true : false,
      newTab: {},
      tabDialogAction: 'new',
      showTabDialog: false,
      errorMsg: "",
      username: null,
      showDeleteDialog: false,
      isAdmin: false,
      isOrganization: false,
      isLockedUser: false,
      editWidgets: true,
      pageRefreshed: true,
    }

    this.addTab = this.addTab.bind(this);
    this.setNavigateTo = this.setNavigateTo.bind(this);
    this.setPageRefreshed = this.setPageRefreshed.bind(this);
    this.loadTabs = this.loadTabs.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleTabFormChange = this.handleTabFormChange.bind(this);
    this.openTabDialog = this.openTabDialog.bind(this);
    this.closeTabDialog = this.closeTabDialog.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.loadStations = this.loadStations.bind(this);
    this.handleMarkersFilterChange = this.handleMarkersFilterChange.bind(this);
    this.loadWeatherVariables = this.loadWeatherVariables.bind(this);
    this.loadMarkers = this.loadMarkers.bind(this);
    this.setStations = this.setStations.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
  }


  handleFilterChange = (e) => {
    const { value } = e.target

    this.setState({
      users: this.state.usersCopy.filter(item => {
        const itemText = `${item.name} ${item.user.username} ${item.country} ${item.crop}`.toLocaleUpperCase();
        return itemText.indexOf(value.toLocaleUpperCase()) !== -1
      }
      )
    })
  }

  loadUsers = () => {
    let url = BASEURI + "/api/dashboard/user/?include=1",
      options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };


    // Get admin or organization token
    const isImpersonated = Boolean(retrieveToken('adminOrOrgToken'));

    getAuthToken(isImpersonated ? 'adminOrOrgToken' : 'token')
      .then((token) => token)
      .catch((token) => token)
      .then((token) => {
        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (!response.ok)
              throw Error('Something went Wrong!')

            return response.json();
          })
          .then((allUsers) => {
            this.setState({ users: allUsers, usersCopy: allUsers });
          })
          .catch((error) => {
            console.error("Error:", error.code);
          });
      });
  };

  startImpersonation = (id) => {
    let url = BASEURI + `/api/dashboard/start-impersonation/${id}/`,
      options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    // check if and a user is imporsonated
    // if so use admin or org token else use token
    const isImpersonated = Boolean(retrieveToken('adminOrOrgToken'))

    getAuthToken(isImpersonated ? 'adminOrOrgToken' : 'token')
      .then((token) => token)
      .catch((token) => token)
      .then((token) => {
        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        if (!isImpersonated) {
          storeToken('adminOrOrgToken', retrieveToken('token'));
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((token) => {
            storeToken('token', JSON.stringify(token));
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error:", error.code);
          });
      });
  };

  handleSwitchUser = (id) => {
    this.startImpersonation(id);
  }

  changePassword = (newPassword) => {
    this.setState({ saving: true })
    let url = BASEURI + "/api/dashboard/change-password/",
      options = {
        method: "POST",
        body: JSON.stringify({ password: newPassword }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then((token) => token)
      .catch((token) => token)
      .then((token) => {
        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(async (response) => {
            if (!response.ok) {
              const errors = await response.json()
              this.setState({
                message: errors
              });
              throw Error(errors);
            }

            return response.json();
          })
          .then((message) => {
            this.setState({
              successMessage: this.props.intl.formatMessage({ id: "app.profile.successMessage", defaultMessage: 'The password has been changed successfully.' }),
              saving: false
            });

            setTimeout(() => {
              this.setState({ successMessage: '' });
            }, 5000);
          })
          .catch((error) => {
            this.setState({ saving: false })
            console.error("Error:", error.code);
          });
      });

  }
  updateClient = (dataItem) => {


    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(dataItem.email)) {
      this.setState({
        isValid: false
      })
      return
    }
    this.setState({ isValid: true, saving: true })

    let url = BASEURI + `/api/dashboard/user/${this.state.client.id}/`,
      options = {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;
        options.body = JSON.stringify({
          name: dataItem.name || null,
          email: dataItem.email || null,
          country: dataItem.country || null,
          region: dataItem.region || null,
          phone: dataItem.phone || null,
        })

        fetch(url, options)
          .then(async (response) => {
            if (!response.ok) {
              const errors = await response.json()
              this.setState({
                message: Object.entries(errors)
                  .map(([key, value]) => `${value}`)
                  .join(', ')
              });
              throw Error(errors);
            }
            return response.json();
          })
          .then((client_obj) => {
            this.setClient(client_obj);
            this.setState({ saving: false, showEmailDialog: false });
          })
          .catch(error => {
            console.error(error)
            this.setState({ saving: false, showEmailDialog: false });
          });
      });

  }
  setClient = (client) => {
    this.setState({ client: client })
  }


  loadUserData() {

    this.loadMarkers();
    this.loadWeatherVariables();
    this.loadStations();
    this.loadTabs();

    let url = BASEURI + '/api/dashboard/superuser/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((client) => {
            this.setState({
              username: !client.name ? client.user.username : client.name,
              isAdmin: client.user.is_superuser,
              isOrganization: client.is_organization,
              isLockedUser: client.locked,
              editWidgets: client.edit_widgets,
              currentUserId: client.user.id,
              client: client,
              showEmailDialog: Boolean(!client.email)
            })
          })
          .catch(error => {
            console.error('Error:', error.code);
          });
      });

  }

  componentDidMount() {

    setTimeout(() => { this.setState({ notInitial: true }) }, 1000);
    this.loadUserData();
    this.loadUsers();

  }

  componentDidUpdate() {
    if (this.state.updateComponent) {
      this.loadUserData();
      this.setState({ updateComponent: false })
    }
    window.componentHandler.upgradeDom();

  }

  setStations(stations) {
    this.setState({ stations: stations });
  }


  loadStations() {
    if (this.state.notInitial) {
      this.setState({ loading: true });
    }
    let url = `${BASEURI}/api/stations/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((stations) => {
            if (!this.state.notInitial) {
              this.setState({ notInitial: true });
            }
            this.setState({
              loading: false,
              stations: stations,
              allStations: stations,
            });
          })
          .catch(error => console.error('Error:', error));

      });
  }

  loadMarkers() {
    this.setState({ loadingMarkers: true });
    let url = BASEURI + `/api/dashboard/marker/primary/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((markers) => {
            if (Array.isArray(markers))
              this.setState({
                markers: markers,
                markersCopy: markers,
                loadingMarkers: false,
              })
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({ loadingMarkers: false });
          });
      });

  }

  loadWeatherVariables() {
    this.setState({ loadingWeatherVariables: true });
    let url = BASEURI + `/api/dashboard/weathervariable/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((weatherVariables) => {
            if (weatherVariables)
              this.setState({
                weatherVariables: weatherVariables,
                loadingWeatherVariables: false,
              });
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({
              loadingWeatherVariables: false,
            })
          });
      });
  }

  handleMarkersFilterChange(event) {

    let filterText = event.filter.value.toLowerCase();
    let filtered = this.state.markersCopy.slice().filter(item => {
      let domain = `${item.device && item.device.name + ' ' + item.device.serial} ${item.location_name}`
        .toLowerCase();

      if (domain.indexOf(filterText) !== -1)
        return true;
      else
        return false;
    });

    this.setState({
      markers: filtered
    });

  }

  login(credentials) {
    this.setState({ loggingIn: true });

    const url = BASEURI + '/api/token/';
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then(response => response.json())
      .then((token) => {
        if (token.refresh) {

          localStorage.setItem('token', JSON.stringify(token));


          //what can be done here ?
          window.location.reload();

          // update is used to load material design lite cdns and scripts
          // this.setState({ loggedin: true, updateComponent: true });


          // this.forceUpdate();
        } else {
          // alert("Invalid Credentails")
          this.setState({
            loggingIn: false,
            errorMsg: <FormattedMessage
              id='app.login.error'
              defaultMessage="Invalid username or password."
              description='User login error'
            />
          })
        }


      })
      .catch(error => {
        this.setState({ loggingIn: false });
        console.error('Error:', error)
      });
    // .finally(()=>{
    //   this.forceUpdate();
    // });

  }



  loadTabs() {

    var token = localStorage.getItem('token');
    if (!token)
      this.setState({ loggedin: false });

    let url = BASEURI + '/api/tabs/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((tabs) => {
            if (Array.isArray(tabs)) {
              this.setState({
                tabs: tabs,
                loggedin: true,
              });
            }
          })
          .catch(error => {
            console.error('Error:', error.code);
          });

      });

  }


  addTab(e) {
    e.preventDefault();

    let url = this.state.tabDialogAction === 'edit'
      ? `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`
      : `${BASEURI}/api/tabs/`,
      options = {
        method: this.state.tabDialogAction === 'edit' ? 'PUT' : 'POST',
        body: JSON.stringify(this.state.newTab),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((tab) => {
            let tabs = this.state.tabs.slice(),
              index;

            if (this.state.tabDialogAction === 'new')
              tabs.push(tab);
            else {
              for (let i in this.state.tabs)
                if (tab.id === this.state.tabs[i].id)
                  index = i;

              tabs.splice(index, 1, tab);
            }

            this.setState({
              tabs: tabs,
              navigateTo: "dashboard/" + tab.id
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  openTabDialog(e, action, tabId) {
    let newTab = { id: tabId };
    if (action === "edit") {
      for (let i in this.state.tabs) {
        if (tabId === this.state.tabs[i].id)
          newTab.name = this.state.tabs[i].name;
      }

    }

    this.setState({ showTabDialog: true, tabDialogAction: action, newTab: newTab });

  }

  closeTabDialog(e, tabId) {

    this.setState({ showTabDialog: false });

  }

  deleteTab() {
    // if (!window.confirm(`Are you sure you want to delete tab '${this.state.newTab.name}' and the charts in it ?`))
    //   return;

    let url = `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(() => {
            let tabs = this.state.tabs.slice(),
              index;

            for (let i in this.state.tabs)
              if (this.state.newTab.id === this.state.tabs[i].id)
                index = i;

            tabs.splice(index, 1);

            this.setState({
              tabs: tabs,
              navigateTo: "dashboard/" + this.state.tabs[0].id,
              showTabDialog: false
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  setNavigateTo(navigateTo) {
    this.setState({
      navigateTo: navigateTo,
    })
  }

  setPageRefreshed(value) {
    this.setState({
      pageRefreshed: value,
    })
  }

  handleTabFormChange(event) {
    let id = this.state.newTab.id;
    this.setState({ newTab: { name: event.target.value, id: id } });
  }

  logout(e) {

    localStorage.removeItem('token');
    localStorage.removeItem('adminOrOrgToken');
    this.setState({
      loggedin: localStorage.getItem('token') ? true : false
    });

    //reloading page to fix half loading
    window.location.reload()
  }


  render() {
    const router = createBrowserRouter(
      createRoutesFromElements(
        <Route>
          <Route element={
            <PrivateRoutes
              lockedUser={this.state.isLockedUser}
              loggedin={this.state.loggedin}
              navigateTo={this.state.navigateTo}
              setNavigateTo={this.setNavigateTo}
              pageRefreshed={this.state.pageRefreshed}
              setPageRefreshed={this.setPageRefreshed}

            />}>
            <Route path='/' element={
              <LayoutCtn key='layoutctn' >
                <Layout key={'layout'}
                  handleFilterChange={this.handleFilterChange}
                  users={this.state.users}
                  usersLength={this.state.usersCopy.length}
                  currentUserId={this.state.currentUserId}
                  handleSwitchUser={this.handleSwitchUser}
                  handleLocaleChange={this.props.handleLocaleChange}
                  locale={this.props.locale}
                  username={this.state.username}
                  isLockedUser={this.state.isLockedUser}
                  logout={this.logout}
                  tabList={this.state.tabs}
                  open={this.openTabDialog}
                  isAdmin={this.state.isAdmin}
                  isOrganization={this.state.isOrganization}
                />
              </LayoutCtn>}>
              <Route index element={<Admin
                isAdmin={this.state.isAdmin}
                isOrganization={this.state.isOrganization}
                logout={this.logout}
                key='admin'
                markers={this.state.markers}
              />} />
              <Route path='stations' element={<Stations intl={this.props.intl} key='stations'
                tabs={this.state.tabs} />} />
              <Route path='alerts' element={<Alerts intl={this.props.intl} key='alerts' />} />
              <Route path='dga' element={<DGAUploadJobs key='dga' />} />
              <Route path='tools' element={<Tools
                key='copy'
                stations={this.state.stations}
                allStations={this.state.allStations}
                isRegularUser={!(this.state.isAdmin || this.state.isOrganization)}
              />} />
              <Route path='billing' element={<Billing isAdmin={this.state.isAdmin} key='billing' locked={this.state.isLockedUser} />} />
              <Route path='schedule' element={<TabLayout key='layout' />} />
              <Route path='profile' element={<ProfilePage
                key='profile'
                isValid={this.state.isValid}
                client={this.state.client}
                setClient={this.setClient}
                message={this.state.message}
                updateClient={this.updateClient}
                saving={this.state.saving}
                changePassword={this.changePassword}
                successMessage={this.state.successMessage}
              />} />


              <Route path='agscout' element={<ScoutPage />} />

              <Route path='/dashboard'>
                <Route path=':id'
                  element={
                    <ErrorBoundary>
                      <DashboardWraper
                        tabs={this.state.tabs}
                        intl={this.props.intl}
                        setStations={this.setStations}
                        loadStations={this.loadStations}
                        stations={this.state.stations}
                        allStations={this.state.allStations}
                        handleMarkersFilterChange={this.handleMarkersFilterChange}
                        loadingWeatherVariables={this.state.loadingWeatherVariables}
                        weatherVariables={this.state.weatherVariables}
                        loadingMarkers={this.state.loadingMarkers}
                        markers={this.state.markers}
                        isActive={true}
                        addTab={this.addTab}
                        handleTabFormChange={this.handleTabFormChange}
                        openTabDialog={this.openTabDialog}
                        closeTabDialog={this.closeTabDialog}
                        editWidgets={this.state.editWidgets}
                        locale={this.props.locale.localeId}
                      />
                    </ErrorBoundary>}
                >
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<LoginCtn key='loginctn' />}>
            <Route path='/login' element={
              <Login
                key='login'
                navigateTo={this.state.navigateTo}
                errorMsg={this.state.errorMsg}
                login={this.login}
                loggedin={this.state.loggedin}
                loggingIn={this.state.loggingIn} />} />
          </Route>
          <Route path='/frontend' element={<Navigate to='/' />} />
          <Route path="password-reset-request" element={<PasswordResetRequest />} />
          <Route path="password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />
          <Route path='privacy-policy'>
            <Route path='agscout' element={<AgScoutPrivacyPolicy />} />
            <Route path='agviewer' element={<AgViewerPrivacyPolicy />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route >
      )
    )

    return (
      <>
        <RouterProvider router={router} />
        {this.state.showEmailDialog &&
          <Modal>
            <Dialog onClose={() => this.setState({ showEmailDialog: false })}>
              <div class="text-center">
                <h2 class="text-lg font-semibold text-gray-800">
                  {this.props.intl.formatMessage({
                    id: "app.recovery.message",
                    defaultMessage: "It looks like you don't have a registered email address in our system. Please provide one to use as a recovery option for your account."
                  })}
                </h2>
              </div>
              <Input
                valid={this.state.isValid}
                label={this.props.intl.formatMessage({ id: "app.profile.emailAdd", defaultMessage: "Email address" })}
                required
                onChange={(e) => {
                  this.setState({ client: { ...this.state.client, email: e.target.value } })
                }}
              />
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                  onClick={() => this.setState({ showEmailDialog: false })} >
                  {this.props.intl.formatMessage({ id: "app.recovery.reminde", defaultMessage: 'Reminde me later' })}
                </button>
                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                  onClick={() => {
                    this.updateClient(this.state.client)
                  }} >
                  {this.state.saving ?
                    <img style={{ width: '15px' }} src={loadingWhite} alt='loading' /> :
                    this.props.intl.formatMessage({ id: "app.profile.save", defaultMessage: 'Save' })}
                </button>
              </DialogActionsBar>
            </Dialog>
          </Modal>}

        <Modal>
          <TabDialog
            intl={this.props.intl}
            visible={this.state.showTabDialog}
            close={this.closeTabDialog}
            create={(e) => { this.addTab(e); this.closeTabDialog() }}
            delete={this.deleteTab}
            handleChange={this.handleTabFormChange}
            tab={this.state.newTab}
            action={this.state.tabDialogAction}
            showDeleteDialog={() => this.setState({ showDeleteDialog: true })}
          />
          {
            this.state.showDeleteDialog &&
            <ConfirmDeleteDialog
              message={<p> <b>{this.state.newTab.name} </b> <FormattedMessage
                id='app.chart.deleteChart.deleteMessage'
                defaultMessage="will be deleted along with all of it's data. Type {delete} below to confirm."
              /></p>}
              onClose={() => this.setState({ showDeleteDialog: false })}
              remove={() => { this.deleteTab(); this.setState({ showDeleteDialog: false }) }} />
          }
        </Modal>
      </>
    )


  }
}

export default App;
