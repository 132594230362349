import React from 'react';
import { useIntl } from 'react-intl';
import { STATUS_COLORS } from './Scheduler';
import { toZonedTime } from 'date-fns-tz';

export default function AgendaViewSchedulerItem(props) {
    const intl = useIntl();
    const { dataItem } = props;
    const color = STATUS_COLORS[dataItem.status];

    const deviceTimezone = dataItem.placeholder ?
        dataItem.timezone :
        dataItem?.irrigation_task?.device?.details?.timezone;


    const irrigationStatus = [
        // translate the type part in any language
        { status: intl.formatMessage({ id: "app.scheduler.status_saved", defaultMessage: 'Saved' }), key: 'Saved' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending", defaultMessage: 'Sending' }), key: 'Sending' },
        { status: intl.formatMessage({ id: "app.scheduler.status_removing", defaultMessage: 'Removing' }), key: 'Removing' },
        { status: intl.formatMessage({ id: "app.scheduler.status_sending_failed", defaultMessage: 'Sending irrigation has failed' }), key: 'Sending irrigation has failed' },
        { status: intl.formatMessage({ id: "app.scheduler.status_running", defaultMessage: 'Running' }), key: 'Running' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_ok", defaultMessage: 'Executed OK' }), key: 'Executed OK' },
        { status: intl.formatMessage({ id: "app.scheduler.status_executed_failure", defaultMessage: 'Executed with failure' }), key: 'Executed with failure' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_by_user", defaultMessage: 'Stopped by User' }), key: 'Stopped by User' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_manual_irrigation", defaultMessage: 'Stopped due to Manual Irrigation' }), key: 'Stopped due to Manual Irrigation' },
        { status: intl.formatMessage({ id: "app.scheduler.status_stopped_due_to_communication_issues", defaultMessage: 'Stopped due to Communication issues' }), key: 'Stopped due to Communication issues' }
    ]

    return <article className={`relative m-[-5px] rounded-xl bg-white p-4 !w-full ring`} style={{ '--tw-ring-color': dataItem.placeholder ? '#F5F5DC' : color.background }}>
        {/* <button className='flex justify-center items-center rounded-full hover:bg-red-50 w-[25px] h-[25px] text-red-500 absolute top-[1px] right-[1px] text-xsm' onClick={(event) => props.onRemoveClick(event)}>
            <i className="material-icons" role="presentation">close</i>
        </button> */}

        <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-medium sm:text-xl mt-4">
                <a href="#" className="hover:underline">
                    {dataItem.placeholder ?
                        <>{dataItem?.device} [{dataItem?.zone}]</> :
                        <>{dataItem?.irrigation_task?.device?.name} [{dataItem?.irrigation_task?.zone?.name}]</>}</a>
            </h3>

            {dataItem.placeholder ?
                <strong
                    className="rounded px-3 py-1.5 text-[10px] font-medium text-white"
                    style={{ color: '#4c926b', background: '#F5F5DC' }}
                >
                    {intl.formatMessage({ id: "app.scheduler.plannedIrrigation", defaultMessage: 'Planned Irrigation' })}
                </strong> :
                <strong
                    className="rounded px-3 py-1.5 text-[10px] font-medium text-white"
                    style={{ ...color }}
                >
                    {irrigationStatus.find((item) => item.key === dataItem?.status)?.status}
                </strong>}
        </div>
        <div className="flex items-start sm:gap-8">

            <div>
                <p className="flex gap-3  mt-4 text-sm text-gray-700">
                    <span>Precip: {dataItem.placeholder ? 'N/A' : <>{dataItem?.precipitation?.toFixed(3)}{dataItem?.irrigation_task?.device?.details?.precip_unit} </>}</span>
                    <span aria-hidden="true">&middot;</span>
                    <span>Vol: {dataItem.placeholder ? 'N/A' : <>{dataItem?.volume?.toFixed(3)}{dataItem?.irrigation_task?.device?.details?.volume_unit}</>}</span>
                </p>
                <p className="flex gap-3 text-sm text-gray-700">
                    <span>{intl.formatMessage({ id: "app.scheduler.sent_to_device", defaultMessage: 'Sent To Station: ' })}</span>
                    {dataItem?.sent_to_network ?
                        <i className="material-icons text-green-500 text-[17px]" role="presentation">check</i> :
                        <i className="material-icons text-red-500 text-[17px]" role="presentation">close</i>
                    }
                </p>

                <div className='flex items-center text-xs mt-2 font-medium items-center gap-1 text-gray-500'>
                    <span className='relative mr-1'>
                        <i className="material-icons text-[15px]" role="presentation">public</i>
                        <i className="material-icons absolute bottom-[5px] right-[0px] text-[8px] bg-white rounded-full" role="presentation">schedule</i>
                    </span>
                    {deviceTimezone}
                </div>

                <div className={' flex items-center gap-1'}>
                    <svg
                        className={`h-4 w-4 ${dataItem?.sent_to_network ? 'text-green-500' : 'text-gray-500'}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>

                    <span className="text-xs font-medium">
                        {intl.formatDate(toZonedTime(dataItem.start, deviceTimezone), { hour: 'numeric', minute: 'numeric' })}
                    </span>

                    <span aria-hidden="true">&mdash;</span>

                    <p className="text-xs font-medium sm:inline-block">
                        {intl.formatDate(toZonedTime(dataItem.end, deviceTimezone), { hour: 'numeric', minute: 'numeric' })}
                    </p>
                </div>
            </div>
        </div>


        {/* {!dataItem.placeholder &&
            <div className='mt-2'>
                <div className='absolute bottom-[-4px] right-[1px]'>
                    <button
                        // onClick={(event) => { props.onDoubleClick(event); setShow(false) }}
                        class="rounded-md px-1 text-green-700 hover:text-white transition duration-300 ease-in-out hover:bg-green-500 hover:shadow-lg">
                        {intl.formatMessage({ id: "app.chart.moreMenu.edit", defaultMessage: 'Edit' })}
                    </button>
                </div>
            </div>} */}
    </article >
}