import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import languages from '../lang/languages.json';
import ViewAlertEvents from '../alerts/AlertEvents';
import logo from '../logo.png';
import agscoutLogo from '../images/agscout_small.png'
import { NavLink, Outlet } from 'react-router-dom';
import UserSelector from './UserSelector';

import { retrieveToken } from '../utils';

export class RefreshPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const refreshContainer = document.getElementById("refresh-container");
    refreshContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    const refreshContainer = document.getElementById("refresh-container");
    refreshContainer.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'AgViewer',
      showFilter: false,
    };
  }

  render() {

    return (

      <div
        className="mdl-layout mdl-js-layout mdl-layout--fixed-header"
      >

        <header className="mdl-layout__header">

          <div className="mdl-layout-icon">kk</div>

          <div className="mdl-layout__header-row flex justify-between items-center">

            <span className="mdl-layout-title">
              {/* <img className="ag_logo" src={logo} alt="AGVIEWER"></img> */}
              <img className="w-[50px] sm:w-[100px] " src={logo} alt="AGVIEWER"></img>
            </span>

            <div className='flex justify-between items-center'>
              <div className='hidden sm:!block'>
                <span style={{ marginRight: "20px", fontFamily: "inherit" }}>
                  <FormattedMessage id='app.layout' defaultMessage='Welcome' />, {this.props.username}
                </span>
              </div>
              <ViewAlertEvents />
              <div id="refresh-container"></div>

              <div id='header-locale'>
                <DropDownList
                  style={{ color: 'white', width: '100px', border: 'none' }}
                  textField='locale'
                  dataItemKey="localeId"
                  data={languages}
                  value={this.props.locale}
                  onChange={this.props.handleLocaleChange}
                />
              </div>
              {(this.props.isAdmin || this.props.isOrganization || Boolean(retrieveToken('adminOrOrgToken'))) &&

                <UserSelector
                  users={this.props.users}
                  handleSwitchUser={this.props.handleSwitchUser}
                  usersLength={this.props.usersLength}
                  handleFilterChange={this.props.handleFilterChange}
                  currentUserId={this.props.currentUserId}
                  username={this.props.username}
                  country={this.props.country}
                />
              }
            </div>



          </div>
          {/* 
              <div className="mdl-layout__tab-bar mdl-js-ripple-effect">
                {this.props.tabs}
                <a 
                  href="#dummy"
                  className="add-tab-button"
                  onClick={(e) => {this.props.addTab(e)}}

                >
                  <i className="material-icons ">add</i>
                </a>
                
              </div> */}

        </header >

        <div className="mdl-layout__drawer">

          <span className="mdl-layout-title rounded-full">
            AGVIEWER
            {/* <img className="ag_logo w" src={logo} alt="AGVIEWER" /> */}
          </span>

          <nav className="mdl-navigation">
            {/* <a 
                className="mdl-navigation__link"
              href='/profile/'>
                Profile
              </a> */}
            {/* <a  
                className="mdl-navigation__link"
              href='/profile/'>
                Settings
              </a> */}

            <div id='nav-locale'>
              <DropDownList
                style={{ color: 'white', width: '100px', border: 'none' }}
                textField='locale'
                dataItemKey="localeId"
                data={languages}
                value={this.props.locale}
                onChange={this.props.handleLocaleChange}
              />
            </div>
            <a
              className="mdl-navigation__link"
              href='/dashboard/'>
              <i className="material-icons">dashboard</i>   <FormattedMessage id='app.dash.nav' defaultMessage='Dashboards' />
            </a>
          </nav>
          <div className="ag-dashboards-ctn">
            {this.props.tabList.map(tab => {

              return (
                <div key={tab.id} className={"dashboard-item"}>
                  <NavLink
                    to={`dashboard/${tab.id}`}>
                    <span key={tab.id}>
                      {tab.name}
                    </span>
                  </NavLink>
                  {
                    !this.props.isLockedUser &&
                    <i
                      className="material-icons"
                      style={{ fontSize: '1.3em' }}
                      onClick={(e) => this.props.open(e, 'edit', tab.id)}
                    >
                      more_vert
                    </i>
                  }

                </div>
              )

            })}

          </div>
          <nav className="mdl-navigation">
            {
              !this.props.isLockedUser &&
              <>
                <NavLink className="mdl-navigation__link" to='stations'>
                  <i className="material-icons">router</i>  <FormattedMessage id='app.layout.station' defaultMessage='Stations' />
                </NavLink>
                <NavLink className="mdl-navigation__link" to='alerts'>
                  <i className="material-icons">warning</i>  <FormattedMessage id='app.layout.alert' defaultMessage='Alerts' />
                </NavLink>
                <NavLink
                  className="mdl-navigation__link" to='/'>
                  <i className="material-icons">pie_chart</i> <FormattedMessage id='app.layout.report' defaultMessage='Reports' />
                </NavLink>
                <NavLink className="mdl-navigation__link" to='schedule'>
                  <i className="material-icons">water_drop</i> <FormattedMessage id='app.layout.schedule' defaultMessage='Irrigation' />
                </NavLink>
                <NavLink className="mdl-navigation__link" to='agscout'>
                  <img
                    src={agscoutLogo}
                    alt='AgScout Logo'
                    width='50px'
                    style={{
                      display: 'inline-block',
                      marginLeft: '-3px',
                      marginRight: '9px',
                      width: '31px'
                    }}
                  />
                  <FormattedMessage id='app.layout.AgScout' defaultMessage='AgScout' />
                </NavLink>
                <NavLink className="mdl-navigation__link" to='dga'>
                  <i className="material-icons">cloud_upload</i>   <FormattedMessage id='app.layout.DGA' defaultMessage='DGA ' />
                </NavLink>
              </>
            }
            {
              !this.props.isLockedUser &&
              <NavLink className="mdl-navigation__link" to='tools'>
                <i className="material-icons">build_circle</i>    <FormattedMessage id='app.layout.tools' defaultMessage='Tools ' />
              </NavLink>
            }
            <NavLink className="mdl-navigation__link" to='billing'>
              <i className="material-icons">credit_card</i>     <FormattedMessage id='app.layout.billing' defaultMessage='Billing ' />
            </NavLink>
            <NavLink className="mdl-navigation__link" to='profile'>
              <i className="material-icons">person</i> <FormattedMessage id='app.profile' defaultMessage='Profile ' />
            </NavLink>
          </nav>
          <div className="ag-logout-ctn">
            <a
              className="mdl-navigation__link ag-logout"
              onClick={(e) => { this.props.logout(e) }}
              to="/login"
            >
              <i className="material-icons">exit_to_app</i><FormattedMessage id='app.layout.logout' defaultMessage='Logout ' />
            </a>
          </div>
        </div>

        <main className="mdl-layout__content">
          <Outlet />
        </main>
      </div>
    );
  }
}


export default Layout;