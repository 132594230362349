import React from 'react';
import { useIntl } from 'react-intl';

function AccountsList(props) {
    const intl = useIntl();


    return (
        <ul className="pt-4">
            <li className="flex flex-row justify-between border-[1px] p-2  text-gray-600 first:rounded-t-lg last:rounded-b-lg uppercase">
                {intl.formatMessage({ id: 'app.stations.stationForm.linkedAcc', defaultMessage: 'Linked Accounts of {api}' }, { api: props.api })}
            </li>
            {props.accounts.length > 0 ?
                props.accounts.map((item) => <li key={item.id} className="flex flex-row justify-between border-[1px] p-2  text-gray-600 first:rounded-t-lg last:rounded-b-lg">
                    <span>{item.name ? item.name : item.username}</span> <button className="text-red-600" onClick={() => props.deleteAccount(item.id, item.name ? item.name : item.username)}>{intl.formatMessage({ id: 'app.chart.deleteChart.delete', defaultMessage: 'DELETE' })}</button></li>) :
                <li className="flex justify-center border-[1px] p-2 font-light text-gray-600 first:rounded-t-lg last:rounded-b-lg">{intl.formatMessage({ id: 'app.stations.stationForm.notLinked', defaultMessage: 'No Account linked yet!' })}</li>
            }
            {
                props.accounts.length > 0 && <li className="flex justify-center border-[1px] p-2 font-light text-sm text-gray-600 first:rounded-t-lg last:rounded-b-lg">{intl.formatMessage({ id: 'app.stations.stationForm.accLinked', defaultMessage: '{accounts} Accounts linked' }, { accounts: props.accounts.length })}</li>
            }
        </ul>
    )
}

export default AccountsList;