//React
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';

// Project
import { SCOUTURI, SCOUTAPIKEY} from '../../../utils'
import StatRow from './StatRow'
import {buildParamStr} from '../../utils';


const activeStyle = 'text-slate-900',
inactiveStyle = 'text-white bg-slate-700';

export default function MapStats(props){
    const intl = useIntl();
    const [stats, setStats] = useState({})
    const [displayType, setDisplayType] = useState('counts')


    function getStats(){

        if (!props.farm || !props.scoutType)
        return

        let params = buildParamStr({
            year: props.year,
            type: props.scoutType?.id
        })

        if (!props.farm)
        return

        let url = `${SCOUTURI}/api/v1.0/farm/${props.farm.id}/stats/` + params,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };
    
        const APIKEY = SCOUTAPIKEY
        options.headers.Authorization = `Api-Key ${APIKEY}`;
        fetch(url, options)
        .then((response) => {
            if(!response.ok){
                throw new Error(response.statusText, response.status);
            }
            return response.json();
        })
        .then((response) => {
            setStats(response.data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    useEffect(() => getStats(), [props])

    return  <div className="box flex flex-col justify-around p-3 w-44 bg-white rounded">
        <div className="flex gap-1.5 rounded w-full bg-slate-300 p-1 -ml-1 mb-2">
            <span 
                className={`cursor-pointer py-1.5 px-2 text-sm font-light rounded ${displayType !== 'counts' ? activeStyle : inactiveStyle}`}
                onClick={() => setDisplayType('counts')}
            >
                    Conteo
            </span>
            <span
                className={`cursor-pointer py-1.5 px-2 text-sm font-light rounded ${displayType !== 'kgYield' ? activeStyle : inactiveStyle}`}
                onClick={() => setDisplayType('kgYield')}
            >
                Kg Prod
            </span>
        </div>
        <div className='text-slate-600 text-2xl font-medium'>
            {intl.formatNumber(stats.farm_area)} <span className='text-xl'>Ha</span>
        </div>
        <div className='text-slate-600 text-sm font-light mb-1'>
            Área Campo
        </div>
        { displayType === 'counts' ?
            <>
                <StatRow 
                    title={intl.formatMessage({id:'app.agscout.countsPerHectar', defaultMessage:'Counts/Ha'})}
                    value={stats.counts_per_ha}
                />
                <StatRow
                    title={intl.formatMessage({id:'app.agscout.totalCountsPerPlot', defaultMessage:'Total Counts/Farm'})}
                    value={stats.counts_per_plot}
                />
            </>
            :
            <>
                <StatRow 
                    title={intl.formatMessage({id:'app.agscout.kgYieldPerHa', defaultMessage:'Kg/Ha'})}
                    value={stats.kg_of_yield_per_ha}
                />
                <StatRow
                    title={intl.formatMessage({id:'app.agscout.kgYieldTotal', defaultMessage:'Kg Total/Farm'})}
                    value={stats.kg_of_yield_total}
                />
            </>
        }
  </div>
}
