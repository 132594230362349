import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Grid as KGrid, GridCell, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { BASEURI, getAuthToken } from '../utils';
import ViewTransactionModal from './ViewTransactionModal';


function AdminCredits(props) {
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState([]);
    const [skip, setSkip] = useState(0);
    const [pageSize] = useState(6);
    const [accounts, setAccounts] = useState([]);
    const [showaddFundsDialog, setShowaddFundsDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const intl = useIntl()


    useEffect(() => {
        loadAccounts();
    }, [props.users])

    function loadAccounts() {
        let url = BASEURI + '/api/billing/account/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((accounts) => {
                        setAccounts(accounts);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    function addFunds(dataItem) {
        console.log('dataitem', dataItem);
        setShowaddFundsDialog(true);
        setSelectedUser(dataItem)
    }

    function pageChange(e) {
        setSkip(e.page.skip);
    }

    const Acc = BalanceCell({
        accounts: accounts
    });
    const Actions = ActionCell({
        addFunds: addFunds,
    });
    return (
        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            <ViewTransactionModal
                loadAccounts={loadAccounts}
                show={showaddFundsDialog}
                user={selectedUser && selectedUser}
                onClose={() => setShowaddFundsDialog(false)}
                isAdmin={props.isAdmin}
            />
            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid'>
                    <div className="mdl-cell mdl-cel--6-col">
                        {intl.formatMessage({ id: 'app.bills.adminCreditPage.userCredits', defaultMessage: 'USER CREDITS' })}
                    </div>
                    <div className="mdl-cell mdl-cell-6-col"></div>
                    <div className='mdl-grid--no-spacing mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--8-col-phone discount-grid-ctn'>
                        <KGrid data={filterBy(orderBy(props.users, sort), filter).slice(skip, skip + pageSize)} scrollable={'virtual'} style={{ height: '400px' }}
                            sortable
                            sort={sort}
                            onSortChange={e => setSort(e.sort)}
                            filterable
                            filter={filter}
                            onFilterChange={e => {
                                setFilter(e.filter);
                                setSkip(0);
                            }}
                            editField='inEdit'
                            skip={skip}
                            total={props.users.length}
                            pageSize={pageSize}
                            onPageChange={pageChange}
                            rowHeight={40}
                        >
                            <Column field="username" title={intl.formatMessage({ id: 'app.bills.adminCreditPage.username', defaultMessage: 'Username' })} />
                            <Column field="first_name" title={intl.formatMessage({ id: 'app.bills.adminCreditPage.firstName', defaultMessage: 'First Name' })} />
                            <Column title={intl.formatMessage({ id: 'app.bills.adminCreditPage.balance', defaultMessage: 'Balance' })} cell={Acc} filterable={false} />
                            <Column cell={Actions} title={intl.formatMessage({ id: 'app.bills.adminCreditPage.actions', defaultMessage: 'Actions' })} />
                        </KGrid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminCredits;






function BalanceCell({ accounts }) {
    return props => {
        // render() {
        let account = accounts.filter(
            acc => acc.user.username === props.dataItem.username)[0]
        return (
            <td>
                <b> ${account && account.balance ? account.balance : 0} </b>
            </td>
        )
        // }
    }
}


function ActionCell({ addFunds }) {
    const intl = useIntl()
    return props => {

        // render() {
        const { dataItem } = props;
        return (
            <td>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={() => addFunds(dataItem)}>
                    {intl.formatMessage({ id: 'app.bills.adminCreditPage.transactions', defaultMessage: 'Transactions' })}</button>
            </td>
        )
        // }
    }
}