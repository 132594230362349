import React, { useEffect, useState } from 'react';
import Modal from '../general/Modal';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch, TextArea } from '@progress/kendo-react-inputs';
import { useIntl } from 'react-intl';
// import { BASEURI, getAuthToken } from '../utils';
import loadingIcon from '../loading_white.svg';
import ConfirmDeleteDialog from '../general/ConfirmDeleteDialog';


function EditUserModal(props) {
    const intl = useIntl()
    const [user, setUser] = useState({
        id: undefined,
        user: { username: "" },
        organization: undefined,
        is_organization: false,
        name: "",
        region: undefined,
        email: undefined,
        phone: undefined,
        agents: [],
        notes: undefined,
        sales_tax: undefined,
        locked: false,
        email_report: false,
        edit_widgets: true,
    });
    const [newUser] = useState({
        id: undefined,
        user: { username: "" },
        organization: undefined,
        name: "",
        is_organization: false,
        region: undefined,
        email: undefined,
        phone: undefined,
        agents: [],
        notes: undefined,
        sales_tax: undefined,
        locked: false,
        email_report: false
    });

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    // useEffect(() => {
    //     setUser(newUser);
    //     console.log(newUser)
    // }, [props])


    useEffect(() => {
        props.viewingUser && setUser(props.viewingUser)
    }, [props.viewingUser, props.users])


    function handleChange(e) {
        let target = e.target;
        if (target.name === 'is_organization') {
            setUser({
                ...user, [target.name]: target.value === 'ORG' ? true : false,
            })
        } else if (target.name === 'user') {
            setUser({
                ...user,
                user: { username: target.value }
            });
        } else {
            setUser({
                ...user,
                [target.name]: target.value
            });

        }
    }

    function edit() {

        props.update(user);

    }

    function save() {
        if (!user.user || !user.name) {
            alert("username and name fields are required!");
            return
        }
        // create new user 
        let toSaveUser = Object.assign({}, user);
        toSaveUser.username = user.user.username
        delete toSaveUser.user

        props.save(toSaveUser);
    }

    function handleCloseButton() {
        props.onClose();
        setUser(newUser);
        props.setViewingUser({});
        setShowConfirmDialog(false);
    }

    function deleteClient() {
        props.delete(user, newUser);
        setShowConfirmDialog(false);
    }
    return props.show && <Modal>
        {showConfirmDialog && <ConfirmDeleteDialog
            onClose={() => setShowConfirmDialog(false)}
            message={<p> <b>{user.user && user.user.username} </b> {intl.formatMessage({ id: 'app.chart.deleteChart.deleteMessage', defaultMessage: "will be deleted along with all of it's data. Type {delete} below to confirm." }, { delete: <span style={{ color: 'red' }}>DELETE</span> })}</p>}
            remove={deleteClient}
            deleting={props.deleting}
        />}
        <Dialog onClose={handleCloseButton} title={user.id ?
            intl.formatMessage({ id: 'app.userGrid.userModel.editUser', defaultMessage: 'EDIT USER' }) :
            intl.formatMessage({ id: 'app.userGrid.userModel.createNewUser', defaultMessage: 'CREATE NEW USER' })}>
            <div className='mdl-grid' style={{ justifyContent: 'center' }}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <form>
                        <div className='modal-form-inputs-wrap' >
                            <div className='modal-form-inputs-wrap-row' >
                                <Input
                                    name='user'
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.username', defaultMessage: 'USERNAME' })}
                                    required
                                    onChange={handleChange}
                                    value={user.user && user.user.username}
                                    disabled={user.id ? true : false}
                                />
                                <Input
                                    name='name'
                                    required
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.name', defaultMessage: 'NAME' })}
                                    onChange={handleChange}
                                    value={user.name}
                                />

                            </div>

                            {
                                props.isAdmin &&
                                <div className="modal-form-inputs-wrap-row">
                                    <DropDownList
                                        onChange={handleChange}
                                        value={user.is_organization ? 'ORG' : "REG"}
                                        name='is_organization'
                                        label={intl.formatMessage({ id: 'app.userGrid.userModel.accountType', defaultMessage: 'ACCOUNT TYPE' })}
                                        data={['ORG', 'REG']}
                                    />
                                    <DropDownList
                                        name='organization'
                                        label={intl.formatMessage({ id: 'app.userGrid.userModel.organization', defaultMessage: 'ORGANIZATION' })}
                                        onChange={handleChange}
                                        value={user.organization}
                                        data={props.organizationUsers}
                                        textField="user.username"
                                    />
                                </div>
                            }
                            <div className="modal-form-inputs-wrap-row">
                                <Input
                                    onChange={handleChange}
                                    value={user.country}
                                    name='country'
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.country', defaultMessage: 'COUNTRY' })}
                                />
                                <Input
                                    onChange={handleChange}
                                    value={user.region}
                                    name='region'
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.reqion', defaultMessage: 'REGION' })}
                                />
                            </div>
                            {
                                props.isAdmin &&
                                <div className="modal-form-inputs-wrap-row">
                                    <Input
                                        onChange={handleChange}
                                        value={user.sales_tax}
                                        name='sales_tax'
                                        label={intl.formatMessage({ id: 'app.userGrid.userModel.salesTax', defaultMessage: 'SALES TAX (%)' })}
                                    />
                                    <MultiSelect
                                        name='agents'
                                        label={intl.formatMessage({ id: 'app.userGrid.userModel.agents', defaultMessage: 'AGENTS' })}
                                        textField="name"
                                        onChange={handleChange}
                                        value={user.agents}
                                        data={props.agents.length ? props.agents : []}
                                        style={{ height: 'auto' }}

                                    />
                                </div>}
                            <div className="modal-form-inputs-wrap-row">
                                <Input
                                    name='email'
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.email', defaultMessage: 'EMAIL' })}
                                    type="email"
                                    onChange={handleChange}
                                    value={user.email}
                                />
                                <Input
                                    onChange={handleChange}
                                    value={user.phone}
                                    name='phone'
                                    label={intl.formatMessage({ id: 'app.userGrid.userModel.phone', defaultMessage: 'PHONE' })}
                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {intl.formatMessage({ id: 'app.userGrid.userModel.locked', defaultMessage: 'LOCKED' })}
                                    </span>
                                    <Switch
                                        name='locked'
                                        checked={user.locked}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }}>
                                        {intl.formatMessage({ id: 'app.userGrid.userModel.emailReport', defaultMessage: 'EMAIL REPORT' })}
                                    </span>
                                    <Switch
                                        name='email_report'
                                        checked={user.email_report}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <div style={{ marginTop: '20px', color: 'gray' }}>
                                    <span style={{ paddingRight: '20px' }} title={intl.formatMessage({ id: 'app.userGrid.userModel.titleMessage', defaultMessage: 'Allow user to edit charts and stats.' })}>
                                        {intl.formatMessage({ id: 'app.userGrid.userModel.enableEditing', defaultMessage: 'ENABLE EDITING' })}
                                    </span>
                                    <Switch
                                        name='edit_widgets'
                                        checked={user.edit_widgets}
                                        defaultChecked
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <label className='k-form-field' style={{ marginTop: '20px' }}>
                                    <span>{intl.formatMessage({ id: 'app.userGrid.userModel.notes', defaultMessage: 'NOTES' })}</span>
                                    <textarea
                                        className='border-b-2 border-b-gray-300 bg-gray-100 rounded-md'
                                        name='notes'
                                        style={{ width: '100%' }}
                                        required={true}
                                        value={user.notes ? user.notes : ' '}
                                        onChange={handleChange}
                                    >
                                    </textarea>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={handleCloseButton}>{intl.formatMessage({ id: 'app.userGrid.userModel.close', defaultMessage: 'Close' })}</button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" style={{ color: 'red' }} onClick={() => setShowConfirmDialog(true)} disabled={props.deleting || !user.id}>
                    {props.deleting ? <img src={loadingIcon} alt="loading" width={25} height={25} /> : intl.formatMessage({ id: 'app.userGrid.userModel.delete', defaultMessage: 'Delete' })}
                </button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={user.id ? edit : save} disabled={props.deleting}>
                    {props.loading ?
                        <img src={loadingIcon} alt="loading" width={25} height={25} /> :
                        user.id ?
                            intl.formatMessage({ id: 'app.userGrid.userModel.update', defaultMessage: 'Update' }) :
                            intl.formatMessage({ id: 'app.userGrid.userModel.add', defaultMessage: 'Add' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>;
}




export default EditUserModal;