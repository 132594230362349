import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import Modal from '../general/Modal';
import { getAuthToken, BASEURI } from '../utils';
import TransactionsGrid from './TransactionsGrid';


function ViewTransactionModal(props) {
    const [transactions, setTransactions] = useState([]);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [take, setTake] = useState(5);
    const [balance, setBalance] = useState(0);
    const [account, setAccount] = useState({ balance: 0 });
    const intl = useIntl();

    useEffect(() => {
        loadTransactions();
        loadAccount();

    }, [props.user])

    useEffect(() => {
        setBalance(account.balance)
    }, [account])

    function refresh() {
        loadTransactions();
        loadAccount()
        props.loadAccounts();
    }

    function onPageChange(event) {

        setSkip(event.page.skip);
        setTake(event.page.take);
        const currPage = Math.floor(event.page.skip / event.page.take) + 1;
        let url = `${BASEURI}/api/billing/transaction/${props.user.id}/?page=${currPage}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        let transactions = resp.results.map(tr => tr.id && { ...tr, date: new Date(tr.date) })
                        setTotal(resp.count);
                        setTransactions(transactions);
                    })
                    .catch(error => console.error('Error:', error));

            });

    }


    function loadTransactions() {
        let url = BASEURI + `/api/billing/transaction/${props.user.id}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((resp) => {
                        let transactions = resp.results.map(tr => tr.id && { ...tr, date: new Date(tr.date) })
                        setTotal(resp.count);
                        setTransactions(transactions);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }
    function loadAccount() {
        let url = BASEURI + `/api/billing/account/${props.user.id}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((account) => {
                        console.log(account)
                        setAccount(account);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }




    return props.show && <Modal>
        <Dialog onClose={props.onClose} title="Transactions" height={600}>
            <div className='mdl-grid' style={{ justifyContent: 'center' }}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <TransactionsGrid
                        refresh={refresh}
                        loadTransactions={loadTransactions}
                        balance={balance}
                        height={450}
                        transactions={transactions}
                        setTransactions={setTransactions}
                        isAdmin={props.isAdmin}
                        skip={skip}
                        total={total}
                        take={take}
                        onPageChange={onPageChange}
                        userId={props.user.id}
                    />
                </div>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.onClose}>
                    {intl.formatMessage({ id: 'app.bills.viewTransaction.close' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>;
}




export default ViewTransactionModal
