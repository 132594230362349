//React
import React from 'react'

// Project
import MapStats from './MapStats'
import Ranges from './Ranges'


export default function MapVisuals(props){

    return <div className='flex flex-col gap-3 absolute left-0 bottom-7 m-3 z-10'>
        <MapStats {...props} />
        <Ranges {...props} />
    </div>
}