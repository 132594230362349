import react, { useState, createContext, useEffect, useContext } from 'react';
import { SchedulerEditItem, SchedulerOccurrenceDialog, SchedulerRemoveDialog, SchedulerEditItemRemoveItemContext } from '@progress/kendo-react-scheduler';
import { TaskCrudContext } from './Scheduler';
export const EditSeriesContext = createContext();

export default function SchedulerEditItemWrapper(props) {
    const [editSeries, setEditSeries] = useState(true);
    const [recurring, setRecurring] = useState(true);
    const { deleteTaskEvent, deleteTask, updateTask, updateTaskEvent } = useContext(TaskCrudContext);
    const [dataItem, setDataItem] = useState(props.dataItem)
    const [dragOrResize, setDragOrResize] = useState(null);
    const [allowEditing, setAllowEditing] = useState(!Boolean(dataItem?.id));


    useEffect(() => {
        let isRecurring = true;
        if (props?.dataItem.managed_by_user) {
            isRecurring = false;


        } else if (props?.dataItem?.placeholder) {
            isRecurring = true
        } else {
            isRecurring = Boolean(props?.dataItem?.irrigation_task?.recurrence_rule);
        }
        setEditSeries(isRecurring);
        setRecurring(isRecurring);
        setAllowEditing(!Boolean(dataItem?.id));
    }, [props.dataItem])

    return (
        <EditSeriesContext.Provider value={{ editSeries: editSeries, allowEditing: allowEditing }}>
            <SchedulerEditItem
                {...props}

                onDrag={() => setDragOrResize(true)}
                onResizeEndDrag={() => setDragOrResize(true)}
                onResizeStartDrag={() => setDragOrResize(true)}

                onResizeItemChange={(event) => {
                    setDataItem(event.value);
                }}

                onDragItemChange={(event) => {
                    setDataItem(event.value);
                }}

                dataItem={{
                    ...props.dataItem,
                    editSeries: editSeries,
                    irrigation_type: editSeries ? props.dataItem['irrigation_type'] : 'Date/Time'
                }}

                removeDialog={(props) => {

                    return <SchedulerRemoveDialog
                        {...props}
                        onConfirm={(event) => {
                            if (editSeries) {
                                deleteTask(props.dataItem)
                            }
                            else {
                                deleteTaskEvent(props.dataItem)
                            }
                            props.onConfirm(event)
                        }}
                    />
                }}

                occurrenceDialog={(props) => {

                    return <SchedulerOccurrenceDialog
                        {...props}
                        onOccurrenceClick={(event) => {
                            setEditSeries(false);
                            console.log(dragOrResize)
                            if (dragOrResize) {
                                updateTaskEvent({ ...dataItem, irrigation_type: 'Date/Time' });
                                props.onClose()
                                setDragOrResize(false);
                                return
                            }
                            props.onOccurrenceClick(event)
                            console.log('Editing single occurence');

                        }}

                        onSeriesClick={(event) => {
                            setEditSeries(true)
                            console.log(dragOrResize)
                            if (dragOrResize) {
                                updateTask({ ...dataItem, irrigation_type: 'Date/Time' });
                                props.onClose()
                                setDragOrResize(false);
                                return
                            }
                            props.onOccurrenceClick(event)
                            console.log('Editing Series')

                        }}
                        onClose={(event) => {
                            setDragOrResize(false);
                            props.onClose(event)
                        }}
                    />
                }}

                isRecurring={recurring}
            />
        </EditSeriesContext.Provider>
    )
}
