import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useIntl } from 'react-intl';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react'
import Modal from '../general/Modal';
import { getAuthToken, BASEURI } from '../utils';
import loadingIcon from '../loading.svg';


function DiscountModal(props) {
    const intl = useIntl();
    const [discount, setDiscount] = useState({
        id: undefined,
        name: "",
        normal: false,
        organization: false,
        users: [],
        condition: "",
        discount: 0,

    });
    const [newDiscount] = useState({
        id: undefined,
        name: "",
        normal: false,
        organization: false,
        users: [],
        condition: "",
        discount: 0,

    });
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        props.viewingDiscount && setDiscount(props.viewingDiscount)
    }, [props.viewingDiscount])


    function handleChange(e) {
        let target = e.target;
        setDiscount({
            ...discount,
            [target.name]: target.value
        });
    }

    function handleCheckboxChange(e) {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        setDiscount({ ...discount, [e.target.name]: value })
    }
    function edit() {
        setLoading(true);
        let params = {
            ...discount,
            users: discount.users.map(user => user.id)
        }
        let url = `${BASEURI}/api/billing/discount/${discount.id}/`;
        let options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(params)
        };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((dis) => {
                        let discounts = props.discounts.slice();
                        let disToReplace = null;
                        for (let i in discounts) {
                            if (discounts[i].id === dis.id) {
                                disToReplace = i;
                                break;
                            }
                        }
                        discounts.splice(disToReplace, 1, dis);
                        setLoading(false);
                        props.setDiscounts(discounts);
                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    function save() {
        let params;

        try {
            params = {
                ...discount,
                users: discount.users.map(user => user.id),
            }
        } catch (error) {
            console.error(error);
            return;
        }

        let url = `${BASEURI}/api/billing/discount/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(params)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((discount) => {
                        props.setDiscounts([...props.discounts, discount])
                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    function itemRender(li, itemProps) {
        const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.username} {itemProps.dataItem.first_name && <span>[{itemProps.dataItem.first_name}]</span>} </div> </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }





    return props.show && <Modal>
        <Dialog onClose={props.onClose} title="CREATE DISCOUNT">
            <div className='mdl-grid' style={{ justifyContent: 'center' }}>
                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'>
                    <form>
                        <div className='modal-form-inputs-wrap' >
                            <div className='modal-form-inputs-wrap-row' >
                                <Input
                                    name='name'
                                    label={intl.formatMessage({ id: 'app.bills.discountModal.name', defaultMessage: 'NAME' })}
                                    onChange={handleChange}
                                    value={discount.name}

                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <h6>{intl.formatMessage({ id: 'app.bills.discountModal.subjectUserGroup', defaultMessage: 'SUBJECT USER GROUP' })}</h6>
                                <div></div>
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <CheckBox onChange={handleCheckboxChange} id="nor" name="normal" value={discount.normal} label={intl.formatMessage({ id: 'app.bills.discountModal.normalUsers', defaultMessage: 'Normal Users' })} />
                                <CheckBox onChange={handleCheckboxChange} id={'org'} name="organization" value={discount.organization} label={intl.formatMessage({ id: 'app.bills.discountModal.organizations', defaultMessage: 'Organizations' })} />
                            </div>

                            <div className="modal-form-inputs-wrap-row">
                                <MultiSelect
                                    onChange={handleChange}
                                    name='users'
                                    dataItemKey="id"
                                    textField="username"
                                    value={discount.users}
                                    label={intl.formatMessage({ id: 'app.bills.discountModal.selectedUsersOnly', defaultMessage: 'SELECTED USERS ONLY (applies to all users if none selected)' })}
                                    data={props.users}
                                    itemRender={itemRender}
                                    style={{ height: 'auto' }}

                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <Input
                                    name='condition'
                                    label={intl.formatMessage({ id: 'app.bills.discountModal.condtion', defaultMessage: 'CONDITION (i.e device > 10)' })}
                                    onChange={handleChange}
                                    value={discount.condition}

                                />
                            </div>
                            <div className="modal-form-inputs-wrap-row">
                                <Input
                                    onChange={handleChange}
                                    value={discount.discount}
                                    name='discount'
                                    label={intl.formatMessage({ id: 'app.bills.discountModal.discount', defaultMessage: 'DISCOUNT (%)' })}

                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={() => { props.onClose(); setDiscount(newDiscount); }}>
                    {intl.formatMessage({ id: 'app.bills.discountModal.close', defaultMessage: 'Close' })}
                </button>
                <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={discount.id ? edit : save}>
                    {loading ? <img src={loadingIcon} alt="loading" width={25} height={25} /> : discount.id ?
                        intl.formatMessage({ id: 'app.bills.discountModal.edit', defaultMessage: 'Edit' }) :
                        intl.formatMessage({ id: 'app.bills.discountModal.add', defaultMessage: 'Add' })}
                </button>
            </DialogActionsBar>
        </Dialog>
    </Modal>;
}




export default DiscountModal



function CheckBox(props) {


    return (
        <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor={props.name}>
            <input onChange={props.onChange} type="checkbox" id={props.id} name={props.name} className="mdl-checkbox__input" value={props.value} checked={props.value} />
            <span className="mdl-checkbox__label" >{props.label}</span>
        </label>
    );
}