import React, { Component, useEffect, useState } from 'react'
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { BASEURI, getAuthToken } from '../utils';
import { TagSlider } from './Slider';
import ETcRawSensorForm from './ETcRawForm';

class ETcForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphName: '',
			sensors: [],
			extracts: [],
			extract: null,
			temp_sensor: [],
			temp_extract: [],
			humidity_sensor: [],
			humidity_extract: [],
			radiation_sensor: [],
			radiation_extract: [],
			wind_sensor: [],
			wind_extract: [],
			tempSensor: {},
			humiditySensor: {},
			radiationSensor: {},
			windSensor: {},
			latitude: null,
			altitude: null,
			color: '#000000',
		};

		this.setInitialValues = this.setInitialValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.itemRender = this.itemRender.bind(this);
	}

	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	handleChange(event) {

		//if change event is removing a sensor
		//do nothing further.
		if (event.target.value.length <= 0)
			return

		let sensorTypes = ['temp', 'humidity', 'wind', 'radiation'];

		if (sensorTypes.indexOf(event.target.name.split('_')[0]) >= 0) {

			let sensorKind = event.target.name.split('_');

			switch (sensorKind[0]) {
				case 'temp':
					let tempSensor = this.state.tempSensor;

					if (sensorKind[1] === 'sensor') {
						if (event.target.value.length > 0)
							tempSensor['sensor'] = event.target.value[0].id;
						else
							tempSensor.hasOwnProperty('sensor') && delete tempSensor.sensor;
					} else {
						if (event.target.value.length > 0)
							tempSensor['extract'] = event.target.value[0].id;
						else
							tempSensor.hasOwnProperty('extract') && delete tempSensor.sensor;
					}

					this.setState({
						tempSensor: tempSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;

				case 'humidity':
					let humiditySensor = this.state.humiditySensor;

					if (sensorKind[1] === 'sensor')
						humiditySensor['sensor'] = event.target.value[0].id;
					else
						humiditySensor['extract'] = event.target.value[0].id;

					this.setState({
						humiditySensor: humiditySensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				case 'radiation':
					let radiationSensor = this.state.radiationSensor;

					if (sensorKind[1] === 'sensor')
						radiationSensor['sensor'] = event.target.value[0].id;
					else
						radiationSensor['extract'] = event.target.value[0].id;

					this.setState({
						radiationSensor: radiationSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				case 'wind':
					let windSensor = this.state.windSensor;

					if (sensorKind[1] === 'sensor')
						windSensor['sensor'] = event.target.value[0].id;
					else
						windSensor['extract'] = event.target.value[0].id;

					this.setState({
						windSensor: windSensor,
						[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
					});
					break;
				default:
			}

		} else {
			this.setState({
				[event.target.name]: event.target.name === 'graphType' ? event.target.value.value : event.target.value
			});
		}

		// if target doesn't have a two-
		// part name then it is not a sensor input field
		if (event.target.name.split('_').length < 2)
			return;

		if (event.target.name.split('_')[1] === 'sensor') {

			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});

			}
		}
	}


	itemRender(li, itemProps) {
		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	resampleFnValues = {
		sum: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.sum', defaultMessage: 'Sum' }), value: 'sum' },
		//'avg' should be changed to 'mean' after
		// all graph with 'avg' in db are changed to 'mean'
		avg: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.Mean', defaultMessage: 'Mean' }), value: 'avg' },
		min: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.min', defaultMessage: 'Min' }), value: 'min' },
		max: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.max', defaultMessage: 'Max' }), value: 'max' },
		diff: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.diff', defaultMessage: 'Diff' }), value: 'diff' }
	}

	resampleValues = {
		null: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.doNotResample', defaultMessage: 'Do not resample' }), value: null },
		H: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' }), value: 'H' },
		D: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' }), value: 'D' },
		'7D': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' }), value: '7D' },
		W: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' }), value: 'W' },
		'15D': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' }), value: '15D' },
		'2W': { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' }), value: '2W' },
		M: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' }), value: 'M' },
		Y: { text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.yearly', defaultMessage: 'Yearly' }), value: 'Y' }
	}


	render() {

		return (
			<div>
				<div style={{ marginTop: 10 }} className="modal-form-inputs-wrap-row">
					<div style={{ marginTop: '10px', color: 'gray' }}>
						<span style={{ paddingRight: '20px' }}>
							{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.preCalculatedEto', defaultMessage: 'PRE-CALCULATED ETO' })}
						</span>
						<Switch
							name='calculateEtc'
							checked={this.props.preCalculatedEto}
							onChange={this.props.setPrecalculatedEto}
						/>
					</div>
					<div></div>
				</div>
				{!this.props.preCalculatedEto ?
					<div className='modal-form-inputs-wrap'>
						<div className="modal-form-inputs-wrap-row">
							<MultiSelect
								key='temp-select'
								listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
								name='temp_sensor'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.temp', defaultMessage: 'TEMP' })}
								data={this.props.sensors}
								value={this.props.graph.variables.temp.sensor}
								textField='name'
								dataItemKey='id'
								itemRender={this.itemRender}
								onChange={this.props.handleChange}
								filterable={true}
								onFilterChange={this.props.handleFilterChange}
								style={{ height: 'auto' }}
							/>
							<DropDownList
								key='temp-extract-select'
								name='temp_extract'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.tempExtract', defaultMessage: 'TEMP SENSOR EXTRACT' })}
								data={this.props.extracts}
								value={this.props.graph.variables.temp.extract}
								textField='description'
								dataItemKey='id'
								onChange={this.props.handleChange}
								filterable
								onFilterChange={this.props.handleExtractFilterChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<MultiSelect
								listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
								key='humidity-select'
								name='humidity_sensor'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidity', defaultMessage: 'HUMIDITY' })}
								data={this.props.sensors}
								value={this.props.graph.variables.humidity.sensor}
								textField='name'
								dataItemKey='id'
								itemRender={this.itemRender}
								onChange={this.props.handleChange}
								filterable={true}
								onFilterChange={this.props.handleFilterChange}
								style={{ height: 'auto' }}
							/>
							<DropDownList
								key='humidity-extract-extract'
								name='humidity_extract'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.humidityExtract', defaultMessage: 'HUMIDITY SENSOR EXTRACT' })}
								data={this.props.extracts}
								value={this.props.graph.variables.humidity.extract}
								textField='description'
								dataItemKey='id'
								onChange={this.props.handleChange}
								filterable
								onFilterChange={this.props.handleExtractFilterChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<MultiSelect
								listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
								key='radiation-select'
								name='radiation_sensor'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.solarRadiation', defaultMessage: 'SOLAR RADIATION' })}
								data={this.props.sensors}
								value={this.props.graph.variables.radiation.sensor}
								textField='name'
								dataItemKey='id'
								itemRender={this.itemRender}
								onChange={this.props.handleChange}
								filterable={true}
								onFilterChange={this.props.handleFilterChange}
								style={{ height: 'auto' }} />
							<DropDownList
								key='radiation-extract-select'
								name='radiation_extract'
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.radiationExtract', defaultMessage: 'RADIATION SENSOR EXTRACT' })}
								data={this.props.extracts}
								value={this.props.graph.variables.radiation.extract}
								textField='description'
								dataItemKey='id'
								required={true}
								onChange={this.props.handleChange}
								filterable
								onFilterChange={this.props.handleExtractFilterChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<MultiSelect
								listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
								key='wind-select'
								name='wind_sensor'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.windSpeed', defaultMessage: 'WIND SPEED' })}
								data={this.props.sensors}
								value={this.props.graph.variables.wind.sensor}
								textField='name'
								dataItemKey='id'
								itemRender={this.itemRender}
								onChange={this.props.handleChange}
								filterable={true}
								onFilterChange={this.props.handleFilterChange}
								style={{ height: 'auto' }} />
							<DropDownList
								key='wind-extract-select'
								name='wind_extract'
								required={true}
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.windExtract', defaultMessage: 'WIND SENSOR EXTRACT' })}
								data={this.props.extracts}
								value={this.props.graph.variables.wind.extract}
								textField='description'
								dataItemKey='id'
								onChange={this.props.handleChange}
								filterable
								onFilterChange={this.props.handleExtractFilterChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<NumericTextBox
								name='latitude'
								required={true}
								key='lat-input'
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.latitude', defaultMessage: 'LATITUDE' })}
								value={this.props.graph.variables.latitude}
								onChange={this.props.handleChange} />
							<NumericTextBox
								name='altitude'
								required={true}
								key='long-input'
								label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.altitude', defaultMessage: 'ALTITUDE' })}
								value={this.props.graph.variables.altitude}
								onChange={this.props.handleChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<Input
								// required={true}
								name='formula'
								label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.formula', defaultMessage: 'FORMULA' })}
								value={this.props.graph.variables.formula}
								onChange={this.props.handleChange} />
							<DropDownList
								label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.resampleFreq', defaultMessage: 'RESAMPLE FREQ' })}
								name='resample'
								data={[
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.doNotResample', defaultMessage: 'Do not resample' }), value: null },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' }), value: 'H' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' }), value: 'D' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' }), value: '7D' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' }), value: 'W' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' }), value: '15D' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' }), value: '2W' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' }), value: 'M' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.yearly', defaultMessage: 'Yearly' }), value: 'Y' }
								]}
								textField='text'
								dataItemKey='value'
								value={this.resampleValues[this.props.graph.resample]}
								onChange={this.props.handleChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<DropDownList
								label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.resampleFn', defaultMessage: 'RESAMPLE FN' })}
								name='resample_fn'
								data={[
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.sum', defaultMessage: 'Sum' }), value: 'sum' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.Mean', defaultMessage: 'Mean' }), value: 'avg' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.min', defaultMessage: 'Min' }), value: 'min' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.max', defaultMessage: 'Max' }), value: 'max' },
									{ text: this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.diff', defaultMessage: 'Diff' }), value: 'diff' }
								]}
								textField='text'
								dataItemKey='value'
								value={this.resampleFnValues[this.props.graph.resample_fn]}
								onChange={this.props.handleChange} />
							{/* <div></div> */}
						</div>
						<KCForm
							intl={this.props.intl}
							graph={this.props.graph}
							handleChange={this.props.handleChange}
							handleValuesChange={this.props.handlePlantValueChange}
							setPlantValues={this.props.setPlantValues} />

						<div className="modal-form-inputs-wrap-row">
							<DropDownList
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
								name='_type'
								data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
								textField='text'
								dataItemKey='value'
								value={this.graphTypes[this.props.graph._type]}
								onChange={this.props.handleChange} />
							<Input
								type='color'
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
								name='color'
								data={this.state.color}
								value={this.props.graph.color}
								onChange={this.props.handleChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<Input
								required={true}
								name='label'
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
								value={this.props.graph.label}
								onChange={this.props.handleChange} />
							<Input
								name='axis_min'
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
								value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
								onChange={this.props.handleChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<Input
								name='axis_max'
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
								value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
								onChange={this.props.handleChange} />
							<Input
								required={true}
								name='y_axis_legend'
								label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
								value={this.props.graph.y_axis_legend}
								onChange={this.props.handleChange} />
						</div>
						<div className="modal-form-inputs-wrap-row">
							<div style={{ marginTop: '20px', color: 'gray' }}>
								<span style={{ paddingRight: '20px' }}>
									{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.dataLabels', defaultMessage: 'DATA LABELS' })}
								</span>
								<Switch
									name='data_labels'
									checked={this.props.graph.data_labels}
									onChange={this.props.handleChange}
								/>
							</div>
							<div></div>
						</div>
						<div className="modal-form-inputs-wrap-row">
							<div className="range-slider">
								<TagSlider
									setPlantDays={this.props.setPlantDays}
									graph={this.props.graph}
								/>
							</div>
						</div>

					</div>
					:
					<ETcRawSensorForm
						intl={this.props.intl}
						handlePlantValueChange={this.props.handlePlantValueChange}
						graph={this.props.graph}
						sensors={this.props.sensors}
						extracts={this.props.extracts}
						handleChange={this.props.handleChange}
						handleFilterChange={this.props.handleFilterChange}
						noData={this.props.noData}
						loadingSensors={this.props.loadingSensors}
						setPlantDays={this.props.setPlantDays}
						handleExtractFilterChange={this.props.handleExtractFilterChange}
					/>

				}
			</div>
		);
	}
}

export default ETcForm;


function KCForm(props) {
	const [plants, setPlants] = useState([]);
	const [allPlants, setAllPlants] = useState([]);


	useEffect(() => {
		let url = BASEURI + `/api/plants/`,
			options = {
				method: 'GET',
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				//no token exists or all tokens are expired
				if (token === false) {
					this.setState({
						loggedin: false
					});
					return false;
				}

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						return response.json();
					})
					.then((plants) => {
						setPlants(plants);
						setAllPlants(plants);
					})
					.catch(error => {
						console.error('Error:', error.code);
						this.setState({ loadingMarkers: false });
					});
			});
	}, []);


	function handleFilterChange(e) {
		let filtered = plants.filter(item => {
			let filterText = e.filter.value,
				itemText = `${item.plant_name.toLowerCase()}`;
			for (let i in filterText.split(' ')) {
				let textSplit = filterText.split(' ')[i];
				if (itemText.indexOf(textSplit) !== -1)
					return true
				else
					return false
			}
			return true;
		});

		setPlants(filtered);
		if (e.filter.value === "") {
			setPlants(allPlants);
		}
	}


	return (
		<React.Fragment>
			<div style={{ marginTop: 10 }} className="modal-form-inputs-wrap-row">
				<DropDownList
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.plant', defaultMessage: 'PLANT' })}
					name="plant"
					onChange={props.handleChange}
					data={plants}
					textField="plant_name"
					dataItemKey='id'
					filterable
					value={props.graph.variables.plant}
					onFilterChange={handleFilterChange}
				/>
				<Input
					name="plantdate"
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.plantDate', defaultMessage: 'PLANT DATE (DD/MM/YYYY)' })}
					placeholder="DD/MM/YYYY"
					value={props.graph.variables.plantdate}
					onChange={props.handleChange}
				/>
			</div>
			<div style={{ marginTop: 10 }} className="modal-form-inputs-wrap-row">

				<NumericTextBox
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.treeShadow', defaultMessage: 'TREE SHADOW (%)' })}
					name="treeshadow"
					value={props.graph.variables.treeshadow}
					step={0}
					spinners={false}
					onChange={props.handleChange}
				/>
				<NumericTextBox
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.initialStageKc', defaultMessage: 'INITIAL STAGE KC' })}
					name="initial_value"
					value={props.graph.variables.plant.initial_value}
					step={0}
					spinners={false}
					onChange={props.handleValuesChange}
				/>
			</div>
			<div className="modal-form-inputs-wrap-row">
				<NumericTextBox
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.midStageKc', defaultMessage: 'MID STAGE KC' })}
					name="mid_value"
					value={props.graph.variables.plant.mid_value}
					step={0}
					spinners={false}
					onChange={props.handleValuesChange}
				/>
				<NumericTextBox
					label={props.intl.formatMessage({ id: 'app.chart.etcRawForm.endStageKc', defaultMessage: 'END STAGE KC' })}
					name="late_value"
					value={props.graph.variables.plant.late_value}
					step={0}
					spinners={false}
					onChange={props.handleValuesChange}
				/>
			</div>
		</React.Fragment>
	)
}

export { KCForm }
